import React from 'react';
import { useTranslation } from 'react-i18next';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { ArrowRightIcon } from 'src/Components/Icons/ArrowRightIcon';
import { NotificationIcon } from 'src/Components/Icons/NotificationIcon';
import { useNavigation } from 'src/Router/useNavigation';
import { NotificationKey, SystemNotification } from 'src/graphql/types';

import { CircularProgressAnimation } from '../../../Components/CircularProgressAnimation';

export type NotificationFieldProps = {
	notification: SystemNotification;
};

const iconMapper = {
	EmailIcon: MailOutlineIcon,
	PhotoCamera: PhotoCameraOutlinedIcon,
	Wallpaper: WallpaperIcon,
	SportsSoccer: SportsSoccerIcon,
};

export const NotificationField: React.FC<NotificationFieldProps> = ({
	notification,
}) => {
	const navigation = useNavigation();
	const { t } = useTranslation('notifications');

	const link = notification.parameters.find(
		(param) => param.key === NotificationKey.Url,
	);

	const icon = notification.parameters.find(
		(param) => param.key === NotificationKey.Icon,
	);
	const score = icon?.value === 'Score';

	const button = notification.parameters.find(
		(param) => param.key === NotificationKey.Button,
	);

	const buttonLink = notification.parameters.find(
		(param) => param.key === NotificationKey.ButtonUrl,
	);

	const Icon = iconMapper[icon?.value as 'EmailIcon'] || NotificationIcon;

	const size = 40;
	const scoreValue = 75;

	return (
		<Box
			sx={{ cursor: link?.value ? 'pointer' : 'initial' }}
			onClick={() => link?.value && navigation.push(link.value)}
		>
			<Box
				display="flex"
				alignItems="center"
				py={2}
				px="20px"
				bgcolor="#151515"
			>
				{!score && (
					<Avatar
						sx={{
							width: 40,
							height: 40,
							bgcolor: 'primary.main',
						}}
					>
						<Icon sx={{ padding: '2px', color: 'black' }} />
					</Avatar>
				)}

				{score && (
					<Avatar
						sx={{
							width: 40,
							height: 40,
							bgcolor: 'primary.main',
						}}
					>
						<Box
							position="relative"
							width={size}
							height={size}
							borderRadius="50%"
							bgcolor="black"
						>
							<CircularProgressAnimation
								size={size}
								animate={true}
								duration={2_000}
								maxValue={scoreValue}
							/>

							<Box
								position="absolute"
								width={size}
								height={size}
								top={0}
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<Typography
									fontSize={'20px'}
									variant="h6"
									color={'white'}
								>
									{scoreValue}
								</Typography>
							</Box>
						</Box>
					</Avatar>
				)}

				<Box pl={2} />

				<Box display="flex" flexDirection="column" width={1}>
					<Typography variant="caption" color="textSecondary">
						{t(`notification.${notification.type}.title`)}
					</Typography>

					<Typography
						variant="caption"
						width={1}
						height={1}
						sx={{
							wordBreak: 'break-word',
						}}
					>
						{t(`notification.${notification.type}.description`)}
					</Typography>
				</Box>

				<Box pl={2} />

				{!!button?.value && (
					<Chip
						label={t(`notification.${notification.type}.button`)}
						variant="outlined"
						size="small"
						sx={{
							fontSize: 10,
							fontWeight: 600,
							textTransform: 'uppercase',
						}}
						onClick={() =>
							buttonLink?.value
								? navigation.push(buttonLink.value)
								: null
						}
					/>
				)}

				{!!link && <ArrowRightIcon color="white" />}
			</Box>
			<Divider />
		</Box>
	);
};
