import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { SlideLeft } from 'src/Components/Transitions/Slide';

import { OldTopBar } from './OldTopBar';

const StyledDialog = styled(Dialog)(() => ({
	[`& .${dialogClasses.container}`]: {
		alignItems: 'flex-end',
	},
	[`& .${dialogClasses.paper}`]: {
		backgroundImage: `url(${Background1})`,
		backgroundSize: 'cover',
		backgroundColor: 'black',
	},
}));

export type FullScreenDialogSecondaryProps = {
	title: string;
	onClose?: () => void;
	onGoBack?: () => void;
	open?: boolean;
};

export const FullScreenDialogSecondary: React.FC<
	FullScreenDialogSecondaryProps
> = ({ title, onGoBack, onClose, open = false, children }) => (
	<StyledDialog
		open={open}
		onClose={onClose}
		fullScreen
		TransitionComponent={SlideLeft}
	>
		<DialogTitle sx={{ p: 0 }}>
			<OldTopBar
				title={title}
				onClickLeft={() => onGoBack && onGoBack()}
				iconLeft={<ArrowBackIcon />}
				onClickRight={() => onClose && onClose()}
				iconRight={<CloseIcon />}
				colorRight="default"
			/>
		</DialogTitle>
		<DialogContent sx={{ px: '20px' }}>{children}</DialogContent>
	</StyledDialog>
);
