import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const BurgerMenuIcon: React.FC<CustomIconProps> = (props) => {
	const { color, strokeWidth } = useCustomIcon(props);

	return (
		<SvgIcon viewBox="0 0 32 32" sx={props.sx}>
			<g
				stroke="none"
				strokeWidth={strokeWidth}
				fill="none"
				fillRule="evenodd"
				strokeLinecap="square"
			>
				<g
					transform="translate(1.000000, 4.000000)"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				>
					<line x1="0.5" y1="12" x2="29.5" y2="12" />
					<line x1="0.5" y1="23.5" x2="29.5" y2="23.5" />
					<line x1="0.5" y1="0.5" x2="29.5" y2="0.5" />
				</g>
			</g>
		</SvgIcon>
	);
};
