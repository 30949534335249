import { yupResolver } from '@hookform/resolvers/yup';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { TextFieldForm } from 'src/Form/Inputs/TextFieldForm';
import { useMagicForm } from 'src/Form/MagicFormProvider';
import { transformLowercase } from 'src/Form/helpers/transformLowercase';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { SignUpFormValues } from '../SignUpScreen';

export const UserInfoForm: React.FC = () => {
	const { t } = useTranslation('sign-up-screen');
	const navigation = useNavigation();
	const { setFormValues, formValues } = useMagicForm<SignUpFormValues>();
	const emailFieldRef = useRef<HTMLInputElement>(null);
	const passwordFieldRef = useRef<HTMLInputElement>(null);
	const nextButtonRef = useRef<HTMLButtonElement>(null);

	const form = useForm<SignUpFormValues>({
		resolver: yupResolver(
			yup.object({
				email: yup
					.string()
					.lowercase()
					.trim()
					.email()
					.required()
					.emailExists()
					.validEmail(),
				password: yup.string().required().min(8),
			}),
		),
		defaultValues: formValues,
		reValidateMode: 'onBlur',
	});

	const { handleSubmit, formState } = form;

	const onSubmit = handleSubmit((formData) => {
		setFormValues(formData);

		navigation.push(AppRoutes.BirthdayForm);
	});

	const loading = formState.isSubmitting;

	return (
		<FormProvider {...form}>
			<Stack
				noValidate
				px="20px"
				alignItems="center"
				component="form"
				flexGrow={1}
				onSubmit={onSubmit}
			>
				<Stack
					alignItems="center"
					justifyContent="center"
					width={1}
					flexGrow={1}
					maxWidth={500}
				>
					<Box pt={4} />

					<Typography variant="h5" textAlign="center" maxWidth={250}>
						{t('user_info_title')}
					</Typography>

					<Box pt={4} />

					<TextFieldForm
						id="signup-email"
						fullWidth
						variant="outlined"
						name="email"
						type="email"
						autoComplete="username"
						placeholder="name@example.com"
						label={t('email_placeholder')}
						inputRef={emailFieldRef}
						nextRef={passwordFieldRef}
						disabled={loading}
						inputProps={{
							sx: {
								...transformLowercase,
							},
							autoCapitalize: 'off',
						}}
					/>

					<Box pt={2} />

					<TextFieldForm
						id="signup-new-password"
						fullWidth
						variant="outlined"
						name="password"
						type="password"
						autoComplete="new-password"
						label={t('password_placeholder')}
						inputRef={passwordFieldRef}
						nextRef={nextButtonRef}
						disabled={loading}
					/>
				</Stack>

				<Box pt={8} />

				<Button
					type="submit"
					color="inherit"
					variant="outlined"
					ref={nextButtonRef}
					disabled={loading}
				>
					{t('next_button')}
				</Button>

				<Box pt={8} />
			</Stack>
		</FormProvider>
	);
};
