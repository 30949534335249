import { yupResolver } from '@hookform/resolvers/yup';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { ResendToken } from 'src/Components/ResendToken';
import { SafeAreaTop } from 'src/Components/SafeArea';
import { TopBar } from 'src/Components/TopBar';
import { SeparatedDateForm } from 'src/Form/Inputs/SeparedDateForm';
import { TextFieldForm } from 'src/Form/Inputs/TextFieldForm';
import { useVerifyLink } from 'src/Form/useVerifyLink';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useShowSuccess } from 'src/Success/useShowSuccess';
import {
	SeparatedDate,
	useVerifyLegalGuardianMutation,
} from 'src/graphql/types';
import { useServerErrors } from 'src/helpers/useServerErrors';

export type ConfirmLegalGuardianObject = {
	id: string;
	type: string;
	fullName: string;
};

export type ConfirmLegalGuardianFormData = {
	firstName: string;
	lastName: string;
	phone: string;
	birthdate: SeparatedDate;
};

const formValues = {
	firstName: '',
	lastName: '',
	phone: '',
	birthdate: {
		day: '',
		month: '',
		year: '',
	},
};

export const ConfirmLegalGuardianScreen = () => {
	const { query, replace } = useNavigation();
	const [verifyLegalGuardianMutation, { loading }] =
		useVerifyLegalGuardianMutation();
	const showSuccess = useShowSuccess();
	const { t } = useTranslation('confirm-legal-guardian-screen');
	const type = query.get('type') || '';
	const uuid = query.get('uuid') || '';
	const sub = query.get('sub') || '';
	const { setServerErrors, resetServerErrors, ServerErrors } =
		useServerErrors();
	const lastNameFieldRef = useRef<HTMLInputElement>(null);
	const birthdateFieldRef = useRef<HTMLInputElement>(null);
	const phoneFieldRef = useRef<HTMLInputElement>(null);

	const [isTokenExpired, user] = useVerifyLink('consent_email');

	const fullName = user?.name;

	const form = useForm<ConfirmLegalGuardianFormData>({
		resolver: yupResolver(
			yup.object({
				firstName: yup.string().required(),
				lastName: yup.string().required(),
				phone: yup
					.string()
					.required()
					.min(5)
					.matches(/^\+?\d+$/, t('validations:phone')),
				birthdate: yup
					.object({
						year: yup.string().required(),
						month: yup.string().required(),
						day: yup.string().required(),
					})
					.separatedDate()
					.birthdate()
					.isAdult(),
			}),
		),
		defaultValues: formValues,
		reValidateMode: 'onBlur',
	});

	const { handleSubmit } = form;

	const onSubmit = handleSubmit(async (formData) => {
		resetServerErrors();

		const { errors } = await verifyLegalGuardianMutation({
			variables: {
				input: {
					type,
					sub,
					uuid,
					legalGuardian: formData,
				},
			},
		}).catch(convertNetworkErrors);

		if (errors?.length) {
			return setServerErrors(errors);
		}

		showSuccess({
			title: t('success_title_text'),
			subtitle: t('success_description_text'),
			nextRoute: AppRoutes.Login,
			duration: 3000,
		});
	});

	return (
		<FormProvider {...form}>
			<Stack
				height={1}
				alignItems="center"
				sx={{
					backgroundImage: `url(${Background1})`,
					backgroundSize: 'cover',
					overflowY: 'auto',
				}}
			>
				<SafeAreaTop />

				<TopBar
					title={t('title_text')}
					leftComponent={
						<IconButton
							color="inherit"
							size="large"
							onClick={() => {
								replace(AppRoutes.Login);
							}}
						>
							<ArrowBackIcon />
						</IconButton>
					}
				/>

				<Stack
					noValidate
					px="20px"
					alignItems="center"
					justifyContent="center"
					component="form"
					flexGrow={1}
					onSubmit={onSubmit}
				>
					{!isTokenExpired && !fullName && (
						<CircularProgress color="inherit" />
					)}
					{isTokenExpired && !fullName && <ResendToken />}
					{!isTokenExpired && !!fullName && (
						<>
							<Stack
								flexGrow={1}
								justifyContent="center"
								maxWidth={500}
							>
								<Typography variant="body1" align="center">
									{t('description_text')}
									<strong>{fullName}</strong>
								</Typography>

								<Box pt={4} />

								<TextFieldForm
									fullWidth
									id="legal-guardian-first-name"
									variant="outlined"
									name="firstName"
									autoComplete="given-name"
									label={t('first_name_placeholder')}
									disabled={loading}
									nextRef={lastNameFieldRef}
								/>

								<Box pt={2} />

								<TextFieldForm
									fullWidth
									id="legal-guardian-last-name"
									variant="outlined"
									name="lastName"
									autoComplete="family-name"
									label={t('last_name_placeholder')}
									disabled={loading}
									inputRef={lastNameFieldRef}
									nextRef={birthdateFieldRef}
								/>

								<Box pt={2} />

								<SeparatedDateForm
									id="legal-guardian-birthdate"
									name="birthdate"
									autoComplete="bday"
									disabled={loading}
									ref={birthdateFieldRef}
									nextRef={phoneFieldRef}
								/>

								<Box pt={4} />

								<TextFieldForm
									fullWidth
									id="legal-guardian-phonr"
									variant="outlined"
									name="phone"
									autoComplete="tel"
									label={t('phone_placeholder')}
									disabled={loading}
									inputRef={phoneFieldRef}
								/>
							</Stack>

							<Box pt={4} />

							{loading && <CircularProgress color="inherit" />}

							<ServerErrors />

							<Box pt={4} />

							<Button
								color="inherit"
								variant="outlined"
								type="submit"
								disabled={loading}
							>
								{t('submit_button')}
							</Button>

							<Box pt={8} />
						</>
					)}
				</Stack>
			</Stack>
		</FormProvider>
	);
};
