export const CircularGradient = () => (
	<svg width="0" height="0" display="block">
		<defs>
			<linearGradient
				id="circularGradient"
				gradientUnits="objectBoundingBox"
				x1="0"
				y1="0"
				x2="1"
				y2="1"
			>
				<stop offset="25%" stopColor="#80FF00" />
				<stop offset="64%" stopColor="#00FF98" />
			</linearGradient>
		</defs>
	</svg>
);
