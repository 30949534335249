import _ from 'lodash';
import React from 'react';
import {
	Controller,
	ControllerProps,
	ControllerRenderProps,
	FieldPath,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

export type Color = OverridableStringUnion<
	'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
>;

export interface FieldControllerRenderProps<
	TFieldValues,
	TName extends FieldPath<TFieldValues>,
> extends ControllerRenderProps<TFieldValues, TName> {
	color?: Color;
	error: boolean;
	helperText: React.ReactElement;
}

export type RenderProps<
	TFieldValues,
	TName extends FieldPath<TFieldValues>,
> = Parameters<ControllerProps<TFieldValues, TName>['render']>[0] & {
	field: FieldControllerRenderProps<TFieldValues, TName>;
};

export interface FieldControllerProps<
	TFieldValues,
	TName extends FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
	color?: Color;
	translation: string;

	render(props: RenderProps<TFieldValues, TName>): React.ReactElement;
}

export const FieldController = <
	TFieldValues,
	TName extends FieldPath<TFieldValues>,
>({
	render,
	name,
	translation,
	color,
	...props
}: FieldControllerProps<TFieldValues, TName>) => {
	const { t } = useTranslation(translation);
	return (
		<Controller
			{...props}
			name={name}
			render={(props) => {
				const {
					fieldState: { isTouched, invalid, error: fieldError },
					formState: { isSubmitted },
				} = props;
				const error = (isTouched || isSubmitted) && invalid;
				const helperText = error ? (
					<>
						{_.keys(fieldError?.types)
							.filter((type) => _.get(fieldError?.types, type))
							.map((type) => (
								<Box component={'span'} key={type}>
									{t(`${name}.error.${type}`)}
								</Box>
							))}
					</>
				) : (
					<></>
				);
				return render(
					_.merge(props, {
						field: {
							error,
							color: error ? 'error' : color,
							helperText,
						},
					}),
				);
			}}
		/>
	);
};
