import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export type TopBarProps = {
	leftComponent?: JSX.Element;
	rigthComponent?: JSX.Element;
	title?: string;
};

export const TopBar: React.FC<TopBarProps> = ({
	leftComponent,
	rigthComponent,
	title,
}) => {
	return (
		<Stack
			width={1}
			p="20px"
			direction="row"
			justifyContent="space-between"
			alignItems="center"
		>
			<Box height={48} width={48}>
				{leftComponent || null}
			</Box>

			{!!title && (
				<Typography variant="subtitle1" color="inherit" align="center">
					{title}
				</Typography>
			)}

			<Box height={48} width={48}>
				{rigthComponent || null}
			</Box>
		</Stack>
	);
};
