import { Capacitor } from '@capacitor/core';
import { ApiProvider } from '@reduxjs/toolkit/query/react';
import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

import App from './App';
import './Theme/fonts';
import { api } from './redux/rtk-query';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const initSentry = async () => {
	if (process.env.REACT_APP_DISABLE_SENTRY) return;

	const Sentry = await import('@sentry/capacitor');

	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DNS,
		environment: process.env.REACT_APP_ENV_CONFIG,
	});
};

const startApp = async () => {
	smoothscroll.polyfill();
	initSentry();

	const Gleap = await import('gleap').then((value) => value.default);
	Gleap.initialize(process.env.REACT_APP_GLEAP_API_KEY as string);

	ReactDOM.render(
		<React.StrictMode>
			<ApiProvider api={api}>
				<App />
			</ApiProvider>
		</React.StrictMode>,
		document.getElementById('root'),
	);
};

if (Capacitor.isNativePlatform()) {
	document.addEventListener(
		'gesturestart',
		(event: any) => event.preventDefault(),
		false,
	);
} else {
	serviceWorkerRegistration.register({
		onUpdate: (registration) => {
			const waitingServiceWorker = registration.waiting;

			if (waitingServiceWorker) {
				waitingServiceWorker.addEventListener(
					'statechange',
					(event: any) => {
						if (event?.target?.state === 'activated') {
							window.location.reload();
						}
					},
				);
				waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
			}
		},
	});
}

startApp();
