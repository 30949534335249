import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { CircularProgressAnimation } from 'src/Components/CircularProgressAnimation';
import { useSessions } from 'src/Sessions/useSessions';
import { Score, Session, UserRole } from 'src/graphql/types';

import { useTopScoreAnimation } from './useTopScoreAnimation';

type TopScoreProps = {
	value?: number;
	selectedSession?: Session | Score | null;
};

export const TopScore: React.FC<TopScoreProps> = ({
	value,
	selectedSession = null,
}) => {
	const { t } = useTranslation(['top-score', 'months']);
	const { loading, lastSession, selectedRole } = useSessions();

	if (_.isNil(selectedSession)) {
		selectedSession = lastSession;
	}

	const selectedScore = Math.round(value || selectedSession?.score || 0);

	const duration = 2_000;

	const { started, frameValue } = useTopScoreAnimation(
		selectedScore,
		duration,
		loading,
	);

	const currentDate = new Date(selectedSession?.date);

	return (
		<Box position="relative">
			<Box
				width={280}
				height={280}
				display="flex"
				justifyContent="center"
				alignItems="center"
			>
				<Box
					width={150}
					height={180}
					display="flex"
					flexDirection="column"
					alignItems="center"
				>
					<Box height={40} display="flex" alignItems="center">
						<Typography
							variant="button"
							align="center"
							display="block"
						>
							{!loading ? (
								selectedRole === UserRole.Player ? (
									t('title')
								) : (
									t('titleTrainer')
								)
							) : (
								<Skeleton variant="text" width={60} />
							)}
						</Typography>
					</Box>

					<Box height={100} display="flex" alignItems="center">
						{loading ? (
							<Typography variant="h1" align="center">
								<Skeleton variant="text" width={100} />
							</Typography>
						) : null}

						{!loading && selectedSession ? (
							<Typography variant="h1" align="center">
								{frameValue}
							</Typography>
						) : null}

						{!loading && !selectedSession ? (
							<Typography
								variant="subtitle2"
								align="center"
								color="textSecondary"
								display="block"
							>
								{t('noTrackingData')}
							</Typography>
						) : null}
					</Box>

					<Box height={40} display="flex" alignItems="center">
						{!loading && selectedSession ? (
							<Typography
								variant="caption"
								align="center"
								color="textSecondary"
								display="block"
							>
								{`${t(
									'months:' +
										currentDate.getMonth().toString(),
								)} ${currentDate.getFullYear()}`}
							</Typography>
						) : null}
					</Box>
				</Box>

				<CircularProgressAnimation
					duration={loading ? 0 : duration}
					animate={started}
					maxValue={loading ? 0 : selectedScore}
					size="100%"
				/>
			</Box>
		</Box>
	);
};
