import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { EmailIcon } from 'src/Components/Icons/EmailIcon';

export const ContactUsScreen: React.FC = () => {
	const { t } = useTranslation('contact-us-screen');

	return (
		<>
			<Box pt={10} />

			<Typography variant="body1" align="center">
				{t('description')}
			</Typography>

			<Box pt={10} />

			<Box display="flex" flexDirection="column" alignItems="center">
				<Typography variant="body1">
					{process.env.REACT_APP_SALES_EMAIL}
				</Typography>

				<Box pt={2} />

				<Button
					color="inherit"
					variant="outlined"
					href={`mailto:${process.env.REACT_APP_SALES_EMAIL}`}
					target="_blank"
					startIcon={<EmailIcon color="white" />}
				>
					{t('email')}
				</Button>
			</Box>
		</>
	);
};
