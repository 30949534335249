import { createContext } from 'react';

import { Score, Session } from 'src/graphql/types';

import { LoginRole } from './SessionProvider';

import { CustomScoring } from '../Screens/Dashboard/SlideControls/SlideControlScreen';
import { Assessment } from '../Screens/Dashboard/SpiderScore';

export const SessionContext = createContext<
	| {
			assessment: Assessment;
			customScoring: CustomScoring;
			isVerified: boolean;
			lastSession: Score | Session | null;
			loading: boolean;
			refetch: () => void;
			selectedRole: LoginRole;
			sessions: Score[] | Session[] | null;
			setAssessment: (assessment: Assessment) => void;
			setCustomScoring: (customScoring: CustomScoring) => void;
			setSelectedRole: (role: LoginRole) => void;
			setSessions: (sessions: Score[] | Session[]) => void;
	  }
	| undefined
>(undefined);
