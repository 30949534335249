import _ from 'lodash';
import moment from 'moment';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { CustomAccordion } from 'src/Components/CustomAccordion';
import { OldTopBar } from 'src/Components/OldTopBar';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useListEventSessionsQuery } from 'src/graphql/types';

import { EventSessionDetailsCard } from './EventSessionDetailsCard';

import { useEvent } from '../useEvent';

export const CurrentEventSessionsScreen = () => {
	const { event } = useEvent();
	const { t } = useTranslation('current-session');
	const navigation = useNavigation();
	const params = useParams<{ eventNumber: string }>();
	const eventNumber = _.toInteger(params.eventNumber);
	const { data, loading } = useListEventSessionsQuery({
		variables: { eventNumber },
	});
	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			height={1}
			sx={{
				backgroundImage: `url(${Background1})`,
				backgroundSize: 'cover',
			}}
		>
			<OldTopBar
				title={event?.club?.name ?? undefined}
				subTitle={event?.name}
				border
				iconLeft={<ArrowBackIcon />}
				onClickLeft={() => navigation.replace(AppRoutes.CurrentEvents)}
			/>

			<Box
				px="20px"
				width={1}
				sx={{ overflowY: 'auto', overflowX: 'hidden' }}
			>
				<Box pt={4} />

				<Typography variant="h5" align="center">
					{t('title')}
				</Typography>

				<Box pt={4} />

				{loading && (
					<Skeleton
						height={58}
						width="100%"
						variant="rectangular"
					></Skeleton>
				)}

				{!loading && !data?.listEventSessions.length && (
					<>
						<Box pt={4} />
						<Typography variant="body2" align="center">
							{t('noSessions')}
						</Typography>
					</>
				)}

				{!loading &&
					data?.listEventSessions?.map((operatorSession) => (
						<Fragment key={operatorSession.sessionNumber}>
							<CustomAccordion
								title={(open) => (
									<>
										<Typography
											noWrap
											variant="body1"
											fontWeight={500}
											maxWidth={120}
											hidden={!sm}
										>
											{`${event?.club?.name ?? ''}`}
										</Typography>

										<Typography
											variant="body1"
											fontWeight={500}
											hidden={!sm}
										>
											{`${event?.name ?? ''}`}
										</Typography>

										<Typography
											variant="body1"
											fontWeight={500}
										>
											{`${operatorSession.ageGroup}`}
										</Typography>

										<Box pl={1} />
										<Typography
											variant="caption"
											fontWeight={500}
										>
											{moment(
												operatorSession.date,
											).format('L')}
										</Typography>

										<Box pl={1} />
										<Typography
											variant="inherit"
											color={
												open
													? 'black'
													: operatorSession.scored
													? 'primary'
													: 'error'
											}
											fontWeight={900}
										>
											{operatorSession.sessionNumber}
										</Typography>
									</>
								)}
								content={
									<EventSessionDetailsCard
										session={operatorSession}
									/>
								}
							/>
							<Box pt={2} />
						</Fragment>
					))}

				<Box pt={18} />
			</Box>

			{((moment().isAfter(moment(event?.start)) &&
				_.reduce(
					data?.listEventSessions,
					(ss, s) => ss - s.playersCount,
					event?.players?.length ?? 0,
				)) ||
				null) && (
				<Box position="fixed" bottom={0} pb={6}>
					<Button
						color="primary"
						variant="contained"
						onClick={() =>
							navigation.push(
								AppRoutes.EditEventSession.replace(
									':eventNumber',
									`${eventNumber}`,
								),
							)
						}
					>
						{t('addSession')}
					</Button>
				</Box>
			)}
		</Box>
	);
};
