import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const ExternalLinkIcon: React.FC<CustomIconProps> = (props) => {
	const { color } = useCustomIcon(props);

	return (
		<SvgIcon viewBox="0 0 32 32" sx={props.sx}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					transform="translate(4.000000, 3.500000)"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				>
					<g transform="translate(12.000000, 0.000000)">
						<polyline points="4 9.46953137e-13 12.4862285 0 12.4862285 8.52772601" />
						<line
							x1="12"
							y1="0.5"
							x2="0.5"
							y2="13"
							id="Line-5"
							strokeLinecap="square"
						/>
					</g>
					<polyline points="9.94829713 1.47752542 0.124374789 1.47752542 0.124374789 24.4775254 23.1243748 24.4775254 23.1243748 15.5656254" />
				</g>
			</g>
		</SvgIcon>
	);
};
