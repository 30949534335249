import React, { useMemo } from 'react';

import { useSessions } from 'src/Sessions/useSessions';
import { useWatchComparisonsQuery } from 'src/graphql/types';

import { WatchComparisonsContext } from './WatchComparisonsContext';

export const WatchComparisonsProvider: React.FC = ({ children }) => {
	const { selectedRole } = useSessions();
	const { data, loading, refetch } = useWatchComparisonsQuery({
		variables: {
			role: selectedRole,
		},
	});

	const value = useMemo(
		() => ({
			watchComparisons: data?.watchComparisons || [],
			loading,
			refetch,
		}),
		[data, loading, refetch],
	);

	return (
		<WatchComparisonsContext.Provider value={value}>
			{children}
		</WatchComparisonsContext.Provider>
	);
};
