import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import './validations';

export const supportedLanguages = ['en', 'de', 'es', 'ar'] as const;
export const fallbackLanguage = 'en';

export default i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.use(Backend)
	.init({
		fallbackNS: 'translation',
		fallbackLng: fallbackLanguage,
		supportedLngs: supportedLanguages,
		detection: {
			order: ['path', 'localStorage', 'navigator'],
			lookupFromPathIndex: 0,
			lookupLocalStorage: 'locale',
			caches: ['localStorage'],
			cookieMinutes: 10,
		},
		interpolation: {
			escapeValue: false,
		},
		backend: {
			loadPath: '/locales/{{ns}}/{{ns}}.{{lng}}.json',
		},
	});

i18n.on('languageChanged', (lng) => {
	document.documentElement.setAttribute('lang', lng);
});
