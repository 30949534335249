import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import React, { useEffect } from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

export type ShowWarningProps = {
	title?: string;
	subtitle?: string;
};

export const ShowWarning: React.FC<ShowWarningProps> = ({
	title,
	subtitle,
}) => {
	useEffect(() => {
		if (Capacitor.getPlatform() === 'ios') {
			StatusBar.setStyle({ style: Style.Light });

			return () => {
				StatusBar.setStyle({ style: Style.Dark });
			};
		}
	}, []);

	return (
		<Fade in={true}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				height={1}
				sx={{
					bgcolor: 'error.main',
					backgroundSize: 'cover',
				}}
			>
				<WarningIcon sx={{ color: 'black', fontSize: 240 }} />

				<Box pt={8} />

				<Typography
					variant="subtitle2"
					color="secondary"
					align="center"
					maxWidth={300}
					fontWeight={600}
				>
					{title}
				</Typography>

				<Box pt={2} />

				{subtitle ? (
					<Typography
						variant="subtitle2"
						color="secondary"
						align="center"
						maxWidth={300}
						fontWeight={600}
					>
						{subtitle}
					</Typography>
				) : null}
			</Box>
		</Fade>
	);
};
