import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps } from './CustomIcon';

export const CheckMarkIcon: React.FC<CustomIconProps> = (props) => {
	return (
		<SvgIcon viewBox="0 0 135 98" sx={props.sx}>
			<defs>
				<linearGradient
					x1="100%"
					y1="18.6274161%"
					x2="-3.55271368e-13%"
					y2="18.6274161%"
					id="linearGradient-1"
				>
					<stop stopColor="#46FF44" offset="0%" />
					<stop stopColor="#0BFF8A" offset="100%" />
				</linearGradient>
			</defs>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="checkmark"
					transform="translate(-4.000000, -26.000000)"
					fill="url(#linearGradient-1)"
				>
					<path
						d="M132.652893,90.5849403 L116.652893,90.5849403 L116.652,39 L11,39 L11,23 L132.652893,23 L132.652893,90.5849403 Z"
						transform="translate(71.826446, 56.792470) rotate(-225.000000) translate(-71.826446, -56.792470) "
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
