import { UnwrapPromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import _, { throttle } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';

import {
	Autocomplete,
	Box,
	Button,
	FormControl,
	FormHelperText,
	TextField,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { AppRoutes } from '../../../Router/AppRoutes';
import { useNavigation } from '../../../Router/useNavigation';
import { useCreateEventMutation } from '../../../graphql/types';
import { Club, UserRole } from '../../../graphql/types/rtk-query';
import {
	api,
	useLazyClubsQuery,
	useSearchUsersQuery,
} from '../../../redux/rtk-query';
import { CheckboxController } from '../Components/CheckboxController';
import { DateTimePickerController } from '../Components/DateTimePickerController';
import { FieldController } from '../Components/FieldController';
import { TextFieldController } from '../Components/TextFieldController';
import { VirtualListController } from '../Components/VirtualListController';

export interface CreateEventForm extends FieldValues {
	club: Club | null;
	end: Moment | null;
	externalSources: string[];
	fixedGoalKeepers: boolean;
	name: string;
	shotTest: boolean;
	sprintTest: boolean;
	start: Moment | null;
	override?: boolean;
}

export const CreateEvent = () => {
	const { t } = useTranslation('create-event');

	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));

	const [trigger] = useLazyClubsQuery({});

	const { handleSubmit, control, setError } = useForm<CreateEventForm>({
		defaultValues: {
			club: null,
			end: null,
			externalSources: [],
			fixedGoalKeepers: false,
			name: '',
			shotTest: false,
			sprintTest: false,
			start: null,
		},
		mode: 'all',
		criteriaMode: 'all',
		resolver: (values, _context, _options) => {
			const errors: FieldErrors<CreateEventForm> = {};
			const setError = (
				field: keyof CreateEventForm,
				errorKey: string,
			) => {
				const error = _.defaultsDeep(_.get(errors, field, {}), {
					type: 'validate',
					types: { [errorKey]: true },
				});
				_.set(errors, field, error);
				return error;
			};

			const required: (keyof CreateEventForm)[] = [
				'club',
				'name',
				'end',
				'start',
			];

			_.forEach(required, (field) => {
				const value = _.get(values, field);
				if (_.isNil(value) || _.isEmpty(value)) {
					setError(field, 'required');
				}
			});

			if (values.start?.isBefore(moment())) {
				setError('start', 'before-now');
			}

			if (values.end?.isBefore(values.start)) {
				setError('end', 'before-start');
			}

			return {
				errors,
				values,
			};
		},
	});

	const [createEventMutation] = useCreateEventMutation();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [errors, setErrors] = useState<string[]>([]);

	const { refetch } = api.endpoints.listEvents.useQuerySubscription({});
	const { goBackOrGoTo } = useNavigation();

	const onSubmit = handleSubmit(
		async ({ club, start, end, ...form }, _event) => {
			if (submitting) {
				return;
			}
			setErrors([]);
			setSubmitting(true);
			const { data, errors: serverErrors } = await createEventMutation({
				variables: {
					input: {
						clubId: club?.id ?? 0,
						start: start?.toDate(),
						end: end?.toDate(),
						...form,
					},
				},
			})
				.catch((reason) => ({ data: null, errors: [reason] }))
				.finally(() => setSubmitting(false));
			if (serverErrors?.length ?? 0 > 0) {
				serverErrors?.forEach(({ message }) => {
					const [field, error] = message?.split('.');
					if (_.isEmpty(field) || _.isEmpty(error)) {
						setErrors((errors) => _.concat(errors, message));
					}
					setError(field, {
						type: 'validate',
						types: { [error]: true },
					});
				});
			} else if (!_.isNil(data)) {
				refetch();
				goBackOrGoTo(AppRoutes.Events);
			}
		},
	);

	const { data, isLoading } = useSearchUsersQuery({
		roles: [UserRole.ExternalSource],
		externalSource: '',
	});

	const [minDateTime, setMinDateTime] = useState<Moment | null>(moment());
	const [clubsInput, setClubsInput] = useState<string>('');
	const [clubs, setClubs] = useState<Club[]>([]);

	const fetch = useMemo(
		() =>
			throttle(
				(
					args: Parameters<typeof trigger>[0],
					callback: (
						args: UnwrapPromise<ReturnType<typeof trigger>>['data'],
					) => void,
				) =>
					trigger(args)
						?.then(({ data }) => data)
						?.then(callback),
				500,
			),
		[trigger],
	);

	useEffect(() => {
		let active = true;
		fetch({ name: clubsInput }, (result) => {
			if (active) {
				setClubs(result?.clubs ?? []);
			}
		});
		return () => {
			active = false;
		};
	}, [clubsInput, fetch]);
	return (
		<Box component={'form'} onSubmit={onSubmit}>
			<FieldController
				name={'club'}
				control={control}
				translation={'create-event'}
				render={({ field }) => {
					return (
						<Autocomplete
							disabled={submitting}
							renderInput={(params) => {
								return (
									<TextField
										{...params}
										{...field}
										fullWidth
										label={t('club')}
										size={sm ? 'medium' : 'small'}
										variant="filled"
									/>
								);
							}}
							isOptionEqualToValue={(option, value) =>
								option?.id === value?.id
							}
							onChange={(__, value) => field.onChange(value)}
							onInputChange={(__, value) => setClubsInput(value)}
							getOptionLabel={(option) => option?.name ?? ''}
							options={clubs}
						/>
					);
				}}
			/>
			<Box py={1} />
			<TextFieldController
				control={control}
				name={'name'}
				label={'name'}
				translation={'create-event'}
				disabled={submitting}
			/>
			<Box py={1} />
			<CheckboxController
				control={control}
				name={'fixedGoalKeepers'}
				label={'fixed-goal-keepers'}
				translation={'create-event'}
				disabled={submitting}
			/>
			<Box py={1} />
			<FormControl
				fullWidth
				variant="filled"
				sx={{ display: 'flex', flexDirection: sm ? 'row' : 'column' }}
			>
				<DateTimePickerController
					control={control}
					name={'start'}
					translation={'create-event'}
					pickerProps={{
						onChange: (value) => {
							const now = moment();
							if (value?.isBefore(now)) {
								value?.hours(now.hours());
								value?.minutes(now.minutes());
								value?.add(1, 'minutes');
							}
							setMinDateTime(value);
						},
						minDateTime: moment(),
						disabled: submitting,
					}}
				/>
				<Box p={1} />
				<DateTimePickerController
					control={control}
					name={'end'}
					translation={'create-event'}
					pickerProps={{
						onChange: (value, field) => {
							if (_.isNil(field.value)) {
								value?.hours(23);
								value?.minutes(59);
							}
						},
						minDateTime,
						disabled: submitting,
					}}
				/>
			</FormControl>
			<Box py={1} />
			<VirtualListController
				visibleItems={3}
				control={control}
				name={'externalSources'}
				items={data?.searchUsers ?? []}
				loading={isLoading}
				valueKey={'externalSource'}
				primaryKey={'firstName'}
				secondaryKey={'lastName'}
				disabled={submitting}
			/>
			<Box py={2} />
			<FormControl
				fullWidth
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					flexDirection: 'row',
				}}
			>
				<Button
					size={'small'}
					sx={{
						borderRadius: 1,
						padding: '6px 16px',
						minHeight: 'unset',
						height: 'unset',
						lineHeight: '24.5px',
						border: 'none',
					}}
					variant={'contained'}
					type={'submit'}
					disabled={submitting}
				>
					{t('submit')}
				</Button>
			</FormControl>
			<FormControl>
				<FormHelperText className={'Mui-error'}>
					{_.map(errors, (message, index) => (
						<Box key={`${index}`} component={'span'}>
							{message}
						</Box>
					))}
				</FormHelperText>
			</FormControl>
		</Box>
	);
};
