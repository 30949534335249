const kickidAppDomain = process.env.REACT_APP_KICKID_APP || '';

export function convertValidCode(code: string) {
	let validCode = code;

	if (code.startsWith(kickidAppDomain)) {
		const regex = new RegExp(
			`${kickidAppDomain}/.*/add-session/check/([0-9A-z]{0,})`,
		);
		const result = regex.exec(validCode);
		const code = result?.[1];

		if (code) {
			validCode = code;
		}
	}

	return validCode;
}
