import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { FloatingStepper } from 'src/Components/FloatingStepper';
import { FullScreenDialog } from 'src/Components/FullScreenDialog';
import { ComparisonIcon } from 'src/Components/Icons/ComparisonIcon';
import { TeamIcon } from 'src/Components/Icons/TeamIcon';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessionNumber } from 'src/Sessions/useSessionNumber';
import { useSessions } from 'src/Sessions/useSessions';
import { useUserScores } from 'src/Sessions/useUserScores';
import { Score, Session, UserRole } from 'src/graphql/types';

import { PlayerListScreen } from './PlayerList/PlayerListScreen';
import { SessionSelector } from './SessionSelector';
import { StatsOverTime } from './StatsOverTime/StatsOverTime';
import { SubScoreCard } from './SubScoreCard/SubScoreCard';
import { UserWithProgress } from './UserWithProgress';

export const subScores = [
	'Control',
	'Impact',
	'Passing',
	'Physical',
	'Dribbling',
	'Defense',
	'Shot',
	'Pace',
];

export const PerformanceStatsScreen: React.FC = () => {
	const { sessions, selectedRole, lastSession } = useSessions();
	const { userScores, lastUserScore } = useUserScores();
	const { t } = useTranslation('performance-stats');
	const navigation = useNavigation();
	const {
		selectedSession,
		previousSession,
		selectedIndex,
		nextSession,
		loading: _loading,
	} = useSessionNumber();
	const playerList = Boolean(navigation.query.get('player-list'));
	const params = useParams<{ userId: string }>();

	const chosenSessions = params.userId ? userScores : sessions;
	const chosenLastSession = params.userId ? lastUserScore : lastSession;

	const swipeable = useSwipeable({
		onSwipedLeft: () =>
			nextSession &&
			navigation.setQuery(
				'sessionNumber',
				nextSession.sessionNumber.toString(),
			),
		onSwipedRight: () =>
			previousSession &&
			navigation.setQuery(
				'sessionNumber',
				previousSession.sessionNumber.toString(),
			),
	});

	const subScoreElement =
		(chosenLastSession as Session)?.averages ||
		(chosenLastSession as Score)?.subScores;

	const filteredSubScores = subScores.filter((skill) =>
		subScoreElement
			? subScoreElement.some(
					(subScore) =>
						subScore.type === skill && subScore.value !== null,
			  )
			: true,
	);

	const loading =
		_loading ||
		(!_.isNil(params.userId) && lastUserScore?.user?.sub !== params.userId);

	if (loading) {
		return <></>;
	}

	return (
		<Box height={1} px="20px" sx={{ overflowY: 'auto' }}>
			<Box {...swipeable}>
				<UserWithProgress />

				<SessionSelector />

				{filteredSubScores.map((subScore) => (
					<SubScoreCard key={subScore} subScore={subScore} />
				))}
			</Box>

			{chosenLastSession?.sessionNumber !== 0 ? <StatsOverTime /> : null}

			{!loading && !chosenLastSession && <Box pt={5} />}

			{!loading &&
				chosenLastSession &&
				chosenLastSession.sessionNumber !== 0 &&
				selectedRole === UserRole.Player && (
					<Box display="flex" justifyContent="center" pb={10}>
						<Button
							variant="outlined"
							color="inherit"
							startIcon={<ComparisonIcon color="white" />}
							onClick={() =>
								navigation.push(AppRoutes.CreateComparison)
							}
						>
							{t('compare')}
						</Button>
					</Box>
				)}

			{!loading &&
				!params?.userId &&
				chosenLastSession &&
				selectedSession &&
				selectedRole === UserRole.Trainer && (
					<>
						<Box pb={2} display="flex" justifyContent="center">
							<Button
								color="inherit"
								variant="outlined"
								startIcon={<TeamIcon color="white" />}
								onClick={() =>
									navigation.setQuery('player-list', 'true')
								}
							>
								{t('viewPlayerDetails')}
							</Button>
						</Box>
						<Box
							paddingBottom={10}
							display="flex"
							justifyContent="center"
						>
							<Button
								color="inherit"
								variant="outlined"
								startIcon={<TeamIcon color="white" />}
								onClick={() =>
									navigation.push(
										`${AppRoutes.SlideControl}?sessionNumber=${selectedSession.sessionNumber}`,
									)
								}
							>
								Schieberegler
							</Button>
						</Box>
						<FullScreenDialog
							open={playerList}
							onClose={() =>
								navigation.replace(AppRoutes.PerformanceStats)
							}
							title={t('viewPlayerDetails')}
						>
							<PlayerListScreen
								sessionNumber={selectedSession?.sessionNumber}
							/>
						</FullScreenDialog>
					</>
				)}

			{(chosenSessions?.length || 0) > 1 ? (
				<FloatingStepper
					backButton={null}
					nextButton={null}
					steps={chosenSessions?.length || 0}
					activeStep={selectedIndex}
				/>
			) : null}
		</Box>
	);
};
