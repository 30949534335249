import { createContext } from 'react';

import { NotificationsOutput } from 'src/graphql/types';

type NotificationsContextType = {
	notifications?: NotificationsOutput | null;
	loading: boolean;
	refetch: () => void;
	openHint: boolean;
};

export const NotificationsContext = createContext<
	NotificationsContextType | undefined
>(undefined);
