import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigation = () => {
	const navigate = useNavigate();
	const { search, pathname } = useLocation();
	const { i18n } = useTranslation();

	const url = pathname;

	const push = useCallback(
		(path: string, state?: any) => {
			if (state) {
				navigate(`/${i18n.language}${path}`, {
					state,
				});
			} else {
				navigate(`/${i18n.language}${path}`);
			}
		},
		[i18n.language, navigate],
	);

	const replace = useCallback(
		(path: string, state?: any) => {
			if (state) {
				navigate(`/${i18n.language}${path}`, {
					state,
					replace: true,
				});
			} else {
				navigate(`/${i18n.language}${path}`, {
					replace: true,
				});
			}
		},
		[navigate, i18n.language],
	);

	const changeLanguage = useCallback(
		(lang: string) => {
			const fullUrl = url + search;
			const path = fullUrl.replace(`/${i18n.language}`, `/${lang}`);

			i18n.changeLanguage(lang);

			navigate(path, {
				replace: true,
			});
		},
		[url, search, i18n, navigate],
	);

	const query = useMemo(() => new URLSearchParams(search), [search]);

	const setQuery = useCallback(
		(key: string, value: string | null) => {
			if (value === null) {
				query.delete(key);
			} else {
				query.set(key, value);
			}

			navigate(`${url}?${query}`, {
				replace: true,
			});
		},
		[query, navigate, url],
	);

	const goBack = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const goBackOrGoTo = useCallback(
		(path: string) => {
			if (window.history.state && window.history.state.idx > 0) {
				navigate(-1);
			} else {
				replace(path);
			}
		},
		[navigate, replace],
	);

	const reload = useCallback(() => {
		navigate(0);
	}, [navigate]);

	return {
		push,
		goBack,
		replace,
		query,
		setQuery,
		changeLanguage,
		reload,
		goBackOrGoTo,
	};
};
