import { useTranslation } from 'react-i18next';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { DinamicAvatarWithProgress } from 'src/Components/DinamicAvatarWithProgress';
import { FoundComparison, UserRole } from 'src/graphql/types';

import {
	isFromPlayer,
	isFromTeam,
	isPreviousType,
	isToSamePlayer,
	isToTeam,
} from '../comparisonTypeComparations';

type ComparisonBarProps = {
	comparison: FoundComparison | null;
	loading: boolean;
};

export const ComparisonBar: React.FC<ComparisonBarProps> = ({
	comparison,
	loading,
}) => {
	const { t } = useTranslation(['create-comparison', 'months']);

	const scoreA = comparison?.compareFrom?.subScores?.find(
		(subScore) => subScore.type === 'Rank',
	);

	const scoreB = comparison?.compareTo?.subScores?.find(
		(subScore) => subScore.type === 'Rank',
	);

	const translateDate = (text: string | null = '', dateString: string) => {
		const date = new Date(dateString);

		return `${text?.split(' ')[0]} (${t(
			'months:' + date.getMonth().toString(),
		).substring(0, 3)} ${date.getFullYear().toString().substring(2, 4)})`;
	};

	const fromTitle = isFromTeam(comparison?.type)
		? t('team')
		: isFromPlayer(comparison?.type)
		? t('you')
		: comparison?.compareFrom?.name;

	const toTitle = isToTeam(comparison?.type)
		? t('team')
		: isToSamePlayer(comparison?.type)
		? t('you')
		: comparison?.compareTo?.name;

	return (
		<>
			<Grid container alignItems="center" direction="row">
				<Grid
					xs
					item
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					borderBottom={4}
					borderColor="transparent"
					py="10px"
					px="20px"
				>
					<DinamicAvatarWithProgress
						size={40}
						iconUrl={comparison?.compareFrom?.avatar}
						loading={loading}
						role={
							isFromTeam(comparison?.type)
								? UserRole.Trainer
								: UserRole.Player
						}
						score={scoreA?.value}
					/>

					<Typography variant="caption" color="textPrimary">
						{loading ? (
							<Skeleton variant="text" width={50} />
						) : (
							fromTitle
						)}
					</Typography>
				</Grid>

				<Divider orientation="vertical" flexItem />

				<Grid
					xs
					item
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					borderBottom={4}
					borderColor="transparent"
					py="10px"
					px="20px"
				>
					<Typography
						variant="caption"
						color="textPrimary"
						textOverflow="ellipsis"
						overflow="hidden"
						whiteSpace="nowrap"
					>
						{loading ? (
							<Skeleton variant="text" width={50} />
						) : null}
						{!loading && isPreviousType(comparison?.type)
							? translateDate(
									toTitle,
									comparison?.compareTo?.date,
							  )
							: toTitle}
					</Typography>

					<DinamicAvatarWithProgress
						size={40}
						iconUrl={comparison?.compareTo?.avatar}
						loading={loading}
						role={
							isToTeam(comparison?.type)
								? UserRole.Trainer
								: UserRole.Player
						}
						score={scoreB?.value}
						reverse
					/>
				</Grid>
			</Grid>

			<Divider />
		</>
	);
};
