import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const ScoringIcon: React.FC<CustomIconProps> = (props) => {
	const { color, strokeWidth } = useCustomIcon(props);

	return (
		<SvgIcon viewBox="0 0 32 32" sx={props.sx}>
			<g
				stroke="none"
				strokeWidth={strokeWidth}
				fill="none"
				fillRule="evenodd"
				strokeLinecap="square"
				strokeLinejoin="round"
			>
				<path
					d="M16,2 C23.4304069,2 30,7.9952079 30,16 C30,24.0047921 23.4304069,30 16,30 C8.56959313,30 2,24.0325446 2,16"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				/>
			</g>
		</SvgIcon>
	);
};
