import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';

export const useSocialShare = () => {
	const share = (title: string, url: string, text?: string) => {
		return Share.share({
			dialogTitle: title,
			title,
			text,
			url,
		}).catch(() => null);
	};

	const canShare = Capacitor.isNativePlatform() || !!window.navigator.share;

	return {
		share,
		canShare,
	};
};
