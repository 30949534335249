import { GraphQLError } from 'graphql';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { ArrowUpIcon } from 'src/Components/Icons/ArrowUpIcon';
import { CheckMarkIcon } from 'src/Components/Icons/CheckMarkIcon';
import { CloseIcon } from 'src/Components/Icons/CloseIcon';
import { ToogleButton } from 'src/Components/ToggleButton';
import { useSessions } from 'src/Sessions/useSessions';
import { useWatchComparisons } from 'src/WatchComparisons/useWatchComparisons';
import {
	FoundComparison,
	RawData,
	SubScores,
	useCreateWatchComparisonMutation,
	useDeleteWatchComparisonMutation,
} from 'src/graphql/types';

import { useColorSubScore } from './useColorSubScore';

const Icon = (props: { active?: boolean }) =>
	props.active ? <CheckMarkIcon /> : <CloseIcon color="red" />;

export type SubScoreBodyComparisonProps = {
	selected: boolean;
	onClose: () => void;
	selectedSubScore: any;
	defaultSubScore: string;
	myValue?: number | null;
	yourValue?: number | null;
	comparison: FoundComparison | null;
	onEntered: () => void;
};

export const SubScoreBodyComparison: React.FC<SubScoreBodyComparisonProps> = ({
	selected,
	selectedSubScore,
	onClose,
	defaultSubScore,
	comparison,
	myValue,
	yourValue,
	onEntered,
}) => {
	const { t } = useTranslation(['comparison', 'subscores', 'server-error']);
	const { selectedRole } = useSessions();

	const { watchComparisons, refetch } = useWatchComparisons();

	const [loadingToggleButton, setLoadingToggleButton] =
		useState<boolean>(false);

	const [createWatchComparisonMutation] = useCreateWatchComparisonMutation();
	const [deleteWatchComparisonMutation] = useDeleteWatchComparisonMutation();

	const [serverErrors, setServerErrors] = useState<readonly GraphQLError[]>(
		[],
	);

	const checked = watchComparisons
		.filter((data) => data.type === comparison?.type)
		.some((value) => value.skill === defaultSubScore.toUpperCase());

	const colorLeft = useColorSubScore(yourValue, myValue, 'colorLeft');
	const colorRight = useColorSubScore(yourValue, myValue, 'colorRight');

	const rawData = comparison?.compareFrom?.subScores?.find(
		(subScoreDataA: SubScores) =>
			subScoreDataA.type === selectedSubScore.type,
	)?.rawData;

	const findRawDataB = (
		rawDataA: RawData,
		callback: (rawDataB?: RawData) => ReactNode,
	) => {
		const rawDataB = comparison?.compareTo?.subScores
			?.find(
				(subScoreDataB: SubScores) =>
					subScoreDataB.type === selectedSubScore.type,
			)
			?.rawData?.find(
				(eachRawDataB: RawData) => rawDataA.name === eachRawDataB.name,
			);
		return callback(rawDataB);
	};

	const mapRawData = (rawData?: RawData) => {
		if (rawData?.stringValue?.includes('-')) {
			return rawData.stringValue;
		}

		if (rawData?.stringValue === 'True') {
			return <Icon active />;
		}

		if (rawData?.stringValue === 'False') {
			return <Icon />;
		}

		return rawData?.value
			? `${rawData?.value}${rawData?.unit || ''}`
			: '-.-';
	};

	return (
		<Collapse in={selected} onEntered={onEntered} appear={selected}>
			<Box
				border="1px solid #252525"
				borderTop={0}
				borderBottom={0}
				borderRadius="2px"
				bgcolor="#151515"
			>
				<Box>
					{rawData?.map((eachRawDataA: RawData) => {
						return (
							!!eachRawDataA.value && (
								<React.Fragment key={eachRawDataA.name}>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="space-between"
										px={2}
										height={60}
										width={1}
										border={1}
										borderLeft={0}
										borderRight={0}
										borderBottom={1}
										borderTop={0}
										color="#252525"
									>
										<Typography
											variant="body1"
											fontWeight={600}
											color={colorLeft}
											width={70}
										>
											{mapRawData(eachRawDataA)}
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											align="center"
											sx={{
												wordBreak: 'break-word',
											}}
										>
											{t(
												'subscores:' +
													eachRawDataA.name,
											)}
										</Typography>
										{findRawDataB(
											eachRawDataA,
											(rawDataB) => (
												<Typography
													width={70}
													key={eachRawDataA.name}
													variant="body1"
													fontWeight={600}
													align="right"
													color={colorRight}
												>
													{mapRawData(rawDataB)}
												</Typography>
											),
										)}
									</Box>
								</React.Fragment>
							)
						);
					})}
				</Box>
			</Box>

			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				p={2}
				border="1px solid #252525"
				borderRadius="2px"
				bgcolor="#151515"
			>
				<Typography
					variant="caption"
					color="textSecondary"
					display="block"
					width={1}
				>
					{t('info.' + defaultSubScore)}
				</Typography>

				<Box
					display="flex"
					width={1}
					mt={1}
					justifyContent="space-between"
					alignItems="flex-end"
				>
					<Box>
						{!!selectedSubScore?.value && !!comparison && (
							<ToogleButton
								name={defaultSubScore}
								onChange={async () => {
									if (checked) {
										setLoadingToggleButton(true);

										setServerErrors([]);
										const { errors } =
											await deleteWatchComparisonMutation(
												{
													variables: {
														input: {
															role: selectedRole,
															skill: defaultSubScore.toUpperCase(),
															type: comparison.type,
															sub: comparison.sub,
															subFrom:
																comparison.subFrom,
															sessionNumber:
																comparison?.sessionNumber,
														},
													},
												},
											);

										await refetch();
										setLoadingToggleButton(false);

										if (errors) {
											setServerErrors(errors);
										}
									} else {
										setLoadingToggleButton(true);

										setServerErrors([]);

										const { errors } =
											await createWatchComparisonMutation(
												{
													variables: {
														input: {
															role: selectedRole,
															skill: defaultSubScore.toUpperCase(),
															type: comparison.type,
															sub: comparison.sub,
															subFrom:
																comparison.subFrom,
															sessionNumber:
																comparison?.sessionNumber,
														},
													},
												},
											);

										await refetch();
										setLoadingToggleButton(false);

										if (errors) {
											setServerErrors(errors);
										}
									}
								}}
								checked={checked}
								loading={loadingToggleButton}
								values={[VisibilityOffIcon, VisibilityIcon]}
								colors={['#43FF47', '#777777']}
							/>
						)}
					</Box>
					<Box>
						<IconButton size="small" onClick={onClose}>
							<ArrowUpIcon color="white" />
						</IconButton>
					</Box>
				</Box>
			</Box>
			{serverErrors.map((error) => (
				<>
					<Box pt={3} />
					<Alert key={error.message} severity="error">
						{t('server-error:' + error.message) || error.message}
					</Alert>
				</>
			))}
		</Collapse>
	);
};
