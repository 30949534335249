import { Capacitor } from '@capacitor/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { useAuth } from 'src/Auth/useAuth';
import { CheckboxForm } from 'src/Form/Inputs/CheckboxForm';
import { TextFieldForm } from 'src/Form/Inputs/TextFieldForm';
import { useMagicForm } from 'src/Form/MagicFormProvider';
import { useShowSuccess } from 'src/Success/useShowSuccess';
import { UserRole, useSignUpMutation } from 'src/graphql/types';
import { useServerErrors } from 'src/helpers/useServerErrors';

import { SignUpFormValues } from '../SignUpScreen';

export const FullNameForm: React.FC = () => {
	const { t } = useTranslation('sign-up-screen');
	const lastNameFieldRef = useRef<HTMLInputElement>(null);
	const newsletterFieldRef = useRef<HTMLInputElement>(null);
	const [signUpMutation, { loading }] = useSignUpMutation();
	const { setUser, setTokens } = useAuth();
	const showSuccess = useShowSuccess();
	const { setServerErrors, resetServerErrors, ServerErrors } =
		useServerErrors();
	const { formValues, setFormValues } = useMagicForm<SignUpFormValues>();

	const form = useForm<SignUpFormValues>({
		resolver: yupResolver(
			yup.object({
				firstName: yup.string().trim().required(),
				lastName: yup.string().trim().required(),
				newsletterSubscription: yup.boolean(),
			}),
		),
		defaultValues: formValues,
	});

	const { handleSubmit } = form;

	const onSubmit = handleSubmit(async (formData) => {
		setFormValues(formData);
		resetServerErrors();

		const { data, errors } = await signUpMutation({
			variables: {
				input: formData,
			},
		}).catch(convertNetworkErrors);

		if (errors) {
			setServerErrors(errors);
		}

		if (data?.signUp) {
			const { user, accessToken, refreshToken } = data.signUp;

			showSuccess({
				title: t('success_title'),
				subtitle: t('success_subtitle'),
				onClose: () => {
					if (data) {
						setTokens(accessToken, refreshToken);
						setUser(user);

						if (Capacitor.getPlatform() === 'ios') {
							SavePassword.promptDialog({
								username: formData.email,
								password: formData.password,
							});
						}
					}
				},
				duration: 3000,
			});
		}
	});

	return (
		<FormProvider {...form}>
			<Stack
				noValidate
				px="20px"
				alignItems="center"
				component="form"
				flexGrow={1}
				onSubmit={onSubmit}
			>
				<Stack
					alignItems="center"
					justifyContent="center"
					width={1}
					flexGrow={1}
					maxWidth={500}
				>
					<Box pt={4} />

					<Typography variant="h5" textAlign="center" maxWidth={250}>
						{formValues.role === UserRole.Trainer
							? t('full_name_title')
							: t('full_name_player_title')}
					</Typography>

					<Box pt={4} />

					<TextFieldForm
						fullWidth
						id="signup-first-name"
						variant="outlined"
						name="firstName"
						autoComplete="given-name"
						label={t('first_name_placeholder')}
						nextRef={lastNameFieldRef}
						disabled={loading}
					/>

					<Box pt={2} />

					<TextFieldForm
						fullWidth
						id="signup-last-name"
						variant="outlined"
						name="lastName"
						autoComplete="family-name"
						label={t('last_name_placeholder')}
						inputRef={lastNameFieldRef}
						nextRef={newsletterFieldRef}
						disabled={loading}
					/>

					<Box pt={3} />

					<CheckboxForm
						ref={newsletterFieldRef}
						name="newsletterSubscription"
						label={t('newsletter_checkbox')}
						disabled={loading}
					/>

					<Box pt={2} />

					<Typography
						width={1}
						variant="caption"
						color="textSecondary"
					>
						<Trans
							i18nKey="terms_and_conditions"
							ns="sign-up-screen"
							components={[
								<Link
									key="link"
									href={
										process.env
											.REACT_APP_TERMS_AND_CONDITIONS_URL
									}
									color="textSecondary"
									target="_blank"
								/>,
							]}
						/>
					</Typography>

					<Box pt={4} />

					{loading && <CircularProgress color="inherit" />}

					<ServerErrors />
				</Stack>

				<Box pt={4} />

				<Button color="inherit" variant="outlined" type="submit">
					{t('register_button')}
				</Button>

				<Box pt={8} />
			</Stack>
		</FormProvider>
	);
};
