import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from 'src/Auth/useAuth';
import { AppRoutes } from 'src/Router/AppRoutes';

type LocationState = {
	referrer?: string;
} | null;

export const AnonymousRoute: React.FC = () => {
	const { isAuthorized } = useAuth();
	const location = useLocation();
	const { i18n } = useTranslation();

	const locationState = location.state as LocationState;

	if (isAuthorized) {
		return (
			<Navigate
				replace
				to={
					locationState?.referrer ||
					`/${i18n.language}${AppRoutes.Scoring}`
				}
			/>
		);
	}

	return <Outlet />;
};
