import { RouteObject } from 'react-router-dom';

import { AdminForm } from 'src/Screens/ScoutingEventCreator/AdminForm';
import { CreateEventSessionsScreen } from 'src/Screens/ScoutingEventCreator/CreateEventSession/CreateEventSessionsScreen';
import { CurrentEventSessionsScreen } from 'src/Screens/ScoutingEventCreator/CurrentEventSessions/CurrentEventSessionsScreen';

import { AddEventSessionCoachScreen } from '../../Screens/ScoutingEventCreator/AddEventSessionCoach/AddEventSessionCoachScreen';
import { AddEventSessionPlayerScreen } from '../../Screens/ScoutingEventCreator/AddEventSessionPlayer/AddEventSessionPlayerScreen';
import { ScanEventPlayerCodeScreen } from '../../Screens/ScoutingEventCreator/AddEventSessionPlayer/ScanEventPlayerCodeScreen';
import { CreateEventScreen } from '../../Screens/ScoutingEventCreator/CreateEvent/CreateEventScreen';
import { CurrentEventsScreen } from '../../Screens/ScoutingEventCreator/CurrentEvents/CurrentEventsScreen';
import { EventProvider } from '../../Screens/ScoutingEventCreator/EventProvider';
import { ProcessEventSessionScreen } from '../../Screens/ScoutingEventCreator/ProcessEventSession/ProcessEventSessionScreen';

export default [
	{
		path: '',
		element: <EventProvider />,
		children: [
			{
				path: '',
				element: <CurrentEventsScreen />,
			},
			{
				path: ':eventNumber',
				children: [
					{
						path: '',
						element: <CurrentEventSessionsScreen />,
					},
					{
						path: 'edit',
						element: <AdminForm />,
						children: [
							{
								path: '',
								element: <CreateEventScreen />,
							},
						],
					},
					{
						path: 'edit-event-session',
						element: <AdminForm />,
						children: [
							{
								path: '',
								element: <CreateEventSessionsScreen />,
							},
						],
					},
					{
						path: 'add-session-coach',
						element: <AdminForm />,
						children: [
							{
								path: '',
								element: <AddEventSessionCoachScreen />,
							},
						],
					},
					{
						path: 'add-session-player',
						element: <AdminForm />,
						children: [
							{
								path: '',
								element: <AddEventSessionPlayerScreen />,
							},
							{
								path: 'scan',
								element: <ScanEventPlayerCodeScreen />,
							},
						],
					},
					{
						path: 'process-event-session',
						element: <ProcessEventSessionScreen />,
					},
				],
			},
			{
				path: 'add',
				element: <AdminForm />,
				children: [
					{
						path: '',
						element: <CreateEventScreen />,
					},
				],
			},
		],
	},
] as RouteObject[];
