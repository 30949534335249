import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const CloseIcon: React.FC<CustomIconProps> = (props) => {
	const { color } = useCustomIcon(props);
	return (
		<SvgIcon viewBox="0 0 24 24" sx={props.sx}>
			<g
				id="close"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
				strokeLinecap="square"
			>
				<path
					d="M12.5,6 L12.5,19 M19,12.5 L6,12.5"
					id="Combined-Shape"
					stroke={color}
					transform="translate(12.500000, 12.500000) rotate(-315.000000) translate(-12.500000, -12.500000) "
				/>
			</g>
		</SvgIcon>
	);
};
