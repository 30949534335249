import React, { useState } from 'react';

export type ImageDecorationProps = {
	width: number;
	height: number;
	src: string | null | undefined;
	color?: string;
	strokeWidth?: number;
	fill?: string;
};

let _id = 0;

export const ImageDecoration: React.FC<ImageDecorationProps> = ({
	src,
	width,
	height,
	color,
	strokeWidth,
	fill,
}) => {
	const [id] = useState(() => _id++);

	return (
		<svg width={width} height={height} viewBox={`0 0 48 74`}>
			<defs>
				<clipPath id={`clip-path-${id}`}>
					<path
						d="M49.714,0H16.994L0,23.926,16.994,48h32.72C63.093,48,72,37.272,72,24S63.13,0,49.714,0"
						transform="translate(0 73) rotate(-90)"
						stroke={color || '#454545'}
						fill={fill || 'none'}
						strokeWidth={strokeWidth || 1}
					/>
				</clipPath>
			</defs>
			<rect width="48" height="72" fill="none" />
			<g>
				<path
					d="M49.714,0H16.994L0,23.926,16.994,48h32.72C63.093,48,72,37.272,72,24S63.13,0,49.714,0"
					transform="translate(0 73) rotate(-90)"
					stroke={color || '#454545'}
					fill={fill || 'none'}
					strokeWidth={strokeWidth || 1}
				/>
				<g clipPath={`url(#clip-path-${id})`}>
					{src ? (
						<image
							width="100%"
							height="100%"
							preserveAspectRatio="xMidYMid slice"
							transform="translate(0 1)"
							xlinkHref={src}
						/>
					) : (
						<></>
					)}
				</g>
			</g>
		</svg>
	);
};
