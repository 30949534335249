import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import {
	OrientationType,
	ScreenOrientation,
} from '@robingenz/capacitor-screen-orientation';
import _ from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
	PlayArrow,
} from '@mui/icons-material';
import { Box, Button, Fab, MobileStepper } from '@mui/material';
import { Paper } from '@mui/material';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getLocalStorageAccessToken } from '../../../Auth/localStorageAccessToken';
import { CustomPlayer } from '../../../Components/CustomPlayer';
import { TrainingsVideo } from '../../../graphql/types/rtk-query';

const baseUrl = process.env.REACT_APP_KICKID_API;

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface MobileVideoSwiperProps {
	videos: TrainingsVideo[];
}

export const MobileVideoSwiper = ({ videos }: MobileVideoSwiperProps) => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);
	const [playing, setPlaying] = React.useState<string | null>(null);

	const maxSteps = videos.length;

	const handlePlay = (uuid: string | null) => {
		setPlaying((current) => (current === uuid ? null : uuid));
	};

	const handleNext = () => {
		setPlaying(null);
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setPlaying(null);
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step: number) => {
		setPlaying(null);
		setActiveStep(step);
	};

	useEffect(() => {
		if (Capacitor.getPlatform() !== 'android') {
			return;
		}

		StatusBar.hide();
		ScreenOrientation.unlock();

		return () => {
			StatusBar.show();
			ScreenOrientation.lock({ type: OrientationType.PORTRAIT });
		};
	}, []);

	return (
		<Box
			className={'fullwidth'}
			sx={{
				position: 'fixed',
				maxWidth: 960,
				height: '100%',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'stretch',
				justifyContent: 'center',
				flexGrow: 1,
				top: 0,
			}}
		>
			<Box
				className={_.isNil(playing) ? '' : 'Mui-hidden'}
				sx={{
					transform: 'scaleY(1)',
					transition:
						'scaleY 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					transformOrigin: 'center top',
					overflow: 'hidden',
					height: '48px',
					width: '100%',
					'&.Mui-hidden': {
						transform: 'scaleY(0)',
					},
					'& + *': {
						overflow: 'hidden',
						margin: '-48px 0',
						zIndex: -1,
						position: 'relative',

						'& .react-swipeable-view-container': {
							height: '100% !important',
							width: '100% !important',
						},
					},
				}}
			>
				<Paper
					square
					elevation={0}
					sx={{
						background: 'rgba(0, 0, 0, .81)',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: '0 8px',
						height: '48px',
					}}
				>
					<Typography
						sx={{
							alignItems: 'center',
							display: 'flex',
						}}
						textTransform={'uppercase'}
						fontWeight={'bold'}
					>
						{_.get(videos, `[${activeStep}].texts.title`, '')}
					</Typography>
				</Paper>
			</Box>

			<AutoPlaySwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={activeStep}
				autoplay={false}
				onChangeIndex={handleStepChange}
				enableMouseEvents
			>
				{videos.map((step, index) => (
					<Box
						key={step.uuid}
						sx={{
							position: 'relative',
							overflow: 'hidden !important',
							height: '100% !important',
							width: '100% !important',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
						onClick={() => {
							handlePlay(step.uuid ?? '');
						}}
					>
						{Math.abs(activeStep - index) <= 2 ? (
							<>
								<Box
									component="img"
									sx={{
										display: 'flex',
										width: 'auto',
										height: 'auto',
										maxHeight: '100%',
										maxWidth: '100%',
										opacity: 1,
										position: 'relative',
										'& + *': {
											top: '-2px',
											right: '-2px',
											bottom: '-2px',
											left: '-2px',
											position: 'absolute !important',
											overflow: 'hidden !important',
											width: 'auto !important',
											height: 'auto !important',
										},
									}}
									src={step.previewUrl ?? ''}
									alt={step?.texts?.title}
								/>
								{step?.uuid === playing ? (
									<CustomPlayer
										height={'100%'}
										width={'100%'}
										config={{
											hlsOptions: {
												xhrSetup: (
													xhr: XMLHttpRequest,
												) => {
													xhr.setRequestHeader(
														'Authorization',
														`Bearer ${getLocalStorageAccessToken()}`,
													);
													xhr.withCredentials = true;
												},
											},
										}}
										playing={true}
										onClickPreview={() => {
											handlePlay(step.uuid ?? '');
										}}
										url={`${baseUrl}/videos/${step.uuid}/hls/master.m3u8`}
										customControls={true}
										controls={false}
										muted
									/>
								) : (
									<Box
										width={'100%'}
										height={'100%'}
										display={'flex'}
										alignItems={'center'}
										justifyContent={'center'}
									>
										<Fab size={'large'} color={'secondary'}>
											<PlayArrow />
										</Fab>
									</Box>
								)}
							</>
						) : null}
					</Box>
				))}
			</AutoPlaySwipeableViews>

			<Box
				className={_.isNil(playing) ? '' : 'Mui-hidden'}
				sx={{
					transform: 'scaleY(1)',
					transition:
						'scaleY 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					transformOrigin: 'center bottom',
					overflow: 'hidden',
					height: '48px',
					width: '100%',
					'&.Mui-hidden': {
						transform: 'scaleY(0)',
					},
				}}
			>
				<MobileStepper
					sx={{
						display: 'flex',
						background: 'transparent !important',
						'& > .MuiMobileStepper-dots': {
							alignItems: 'center',
							padding: '0 16px',
							height: '32px !important',
							background: 'rgba(0, 0, 0, .81)',
							borderRadius: '2px',
						},
					}}
					steps={maxSteps}
					position="static"
					activeStep={activeStep}
					nextButton={
						<Button
							sx={{
								borderRadius: '2px',
								padding: '4px 16px',
								minHeight: 'unset',
								height: 'unset',
								border: 'none',
								maxWidth: 'unset',
								background: 'rgba(0, 0, 0, .81)',
								'&:hover, &:active, &:focus': {
									background: 'rgba(0, 0, 0, .81) !important',
								},
							}}
							size="small"
							onClick={handleNext}
							disabled={activeStep === maxSteps - 1}
						>
							Next
							{theme.direction === 'rtl' ? (
								<KeyboardArrowLeft />
							) : (
								<KeyboardArrowRight />
							)}
						</Button>
					}
					backButton={
						<Button
							sx={{
								borderRadius: '2px',
								padding: '4px 16px',
								minHeight: 'unset',
								height: 'unset',
								border: 'none',
								maxWidth: 'unset',
								background: 'rgba(0, 0, 0, .81)',
								'&:hover, &:active, &:focus': {
									background: 'rgba(0, 0, 0, .81) !important',
								},
							}}
							size="small"
							onClick={handleBack}
							disabled={activeStep === 0}
						>
							{theme.direction === 'rtl' ? (
								<KeyboardArrowRight />
							) : (
								<KeyboardArrowLeft />
							)}
							Back
						</Button>
					}
				/>
			</Box>
		</Box>
	);
};
