export type DefaultSubScore = {
	name: string;
	subScores: {
		name: string;
		unit: string;
		fullWidth?: boolean;
	}[];
};

export const defaultSubScores: DefaultSubScore[] = [
	{
		name: 'Control',
		subScores: [
			{
				name: 'pressured_first_contact_rate',
				unit: '%',
			},
			{
				name: 'unpressured_first_contact_rate',
				unit: '%',
			},
			{
				name: 'control_total_count',
				unit: '',
			},
			{
				name: 'control_total_successful',
				unit: '',
			},
			{
				name: 'control_total_rate',
				unit: '',
			},
			{
				name: 'control_p_total_count',
				unit: '',
			},
			{
				name: 'control_p_total_successful',
				unit: '',
			},
			{
				name: 'control_p_total_rate',
				unit: '',
			},
			{
				name: 'control_np_total_count',
				unit: '',
			},
			{
				name: 'control_np_total_successful',
				unit: '',
			},
			{
				name: 'control_np_total_rate',
				unit: '',
			},
		],
	},
	{
		name: 'Impact',
		subScores: [
			{
				name: 'avg_goals_scored',
				unit: '',
			},
			{
				name: 'avg_assists',
				unit: '',
			},
			{
				name: 'avg_shots_on_goal',
				unit: '',
			},
			{
				name: 'impact_goal_count',
				unit: '',
			},
			{
				name: 'impact_assist_count',
				unit: '',
			},
			{
				name: 'impact_shot_count',
				unit: '',
			},
			{
				name: 'impact_shot_assist_count',
				unit: '',
			},
			{
				name: 'impact_clean_sheet_count',
				unit: '',
			},
			{
				name: 'impact_match_1_result',
				unit: '',
			},
			{
				name: 'impact_match_1_personal_goal_count',
				unit: '',
			},
			{
				name: 'impact_match_1_clean_sheet',
				unit: '',
				fullWidth: true,
			},
			{
				name: 'impact_match_2_result',
				unit: '',
			},
			{
				name: 'impact_match_2_personal_goal_count',
				unit: '',
			},
			{
				name: 'impact_match_2_clean_sheet',
				unit: '',
				fullWidth: true,
			},
			{
				name: 'impact_match_3_result',
				unit: '',
			},
			{
				name: 'impact_match_3_personal_goal_count',
				unit: '',
			},
			{
				name: 'impact_match_3_clean_sheet',
				unit: '',
				fullWidth: true,
			},
			{
				name: 'impact_match_4_result',
				unit: '',
			},
			{
				name: 'impact_match_4_personal_goal_count',
				unit: '',
			},
			{
				name: 'impact_match_4_clean_sheet',
				unit: '',
				fullWidth: true,
			},
		],
	},
	{
		name: 'Passing',
		subScores: [
			{
				name: 'avg_offpass_count',
				unit: '',
			},
			{
				name: 'avg_offpass_rate',
				unit: '%',
			},
			{
				name: 'avg_defpass_count',
				unit: '',
			},
			{
				name: 'avg_defpass_rate',
				unit: '%',
			},
			{
				name: 'avg_enemy_overplayed',
				unit: '',
			},
			{
				name: 'avg_space_won',
				unit: 'm',
			},
			{
				name: 'passing_total_count',
				unit: '',
			},
			{
				name: 'passing_total_successful',
				unit: '',
			},
			{
				name: 'passing_np_total_count',
				unit: '',
			},
			{
				name: 'passing_np_total_successful',
				unit: '',
			},
			{
				name: 'passing_p_total_count',
				unit: '',
			},
			{
				name: 'passing_p_total_successful',
				unit: '',
			},
			{
				name: 'passing_space_won_total',
				unit: '',
			},
			{
				name: 'passing_overplayed_count',
				unit: '',
			},
			{
				name: 'passing_overplayed_avg',
				unit: '',
			},
			{
				name: 'passing_off_total',
				unit: '',
			},
			{
				name: 'passing_off_successful',
				unit: '',
			},
			{
				name: 'passing_off_rate',
				unit: '',
			},
		],
	},
	{
		name: 'Physical',
		subScores: [
			{
				name: 'avg_total_distance',
				unit: 'm',
			},
			{
				name: 'avg_highintense_rate',
				unit: '%',
			},
			{
				name: 'avg_sprint_rate',
				unit: '%',
			},
			{
				name: 'avg_acc_count',
				unit: '',
			},
			{
				name: 'avg_inpossession_runs',
				unit: '',
			},
			{
				name: 'avg_against_possession_runs',
				unit: '',
			},
			{
				name: 'phy_total_dist',
				unit: '',
			},
			{
				name: 'phy_total_dist_avg',
				unit: '',
			},
			{
				name: 'phy_high_run_total',
				unit: '',
			},
			{
				name: 'phy_high_run_count',
				unit: '',
			},
			{
				name: 'phy_high_run_rate',
				unit: '',
			},
			{
				name: 'phy_sprint_total',
				unit: '',
			},
			{
				name: 'phy_sprint_count',
				unit: '',
			},
			{
				name: 'phy_sprint_rate',
				unit: '',
			},
			{
				name: 'phy_max_velo',
				unit: '',
			},
		],
	},
	{
		name: 'Dribbling',
		subScores: [
			{
				name: 'avg_dribbling_count',
				unit: '',
			},
			{
				name: 'avg_duel_count',
				unit: '',
			},
			{
				name: 'duel_rate',
				unit: '%',
			},
			{
				name: 'avg_enemies_overplayed',
				unit: '',
			},
			{
				name: 'avg_space_won',
				unit: 'm',
			},
			{
				name: 'dribbling_total_count',
				unit: '',
			},
			{
				name: 'dribbling_total_successful',
				unit: '',
			},
			{
				name: 'dribbling_total_rate',
				unit: '',
			},
			{
				name: 'dribbling_space_won_total',
				unit: '',
			},
			{
				name: 'dribbling_overplayed_count',
				unit: '',
			},
			{
				name: 'dribbling_off_duel_count',
				unit: '',
			},
			{
				name: 'dribbling_off_duel_successful',
				unit: '',
			},
			{
				name: 'dribbling_off_duel_rate',
				unit: '',
			},
		],
	},
	{
		name: 'Defense',
		subScores: [
			{
				name: 'defense_rate',
				unit: '%',
			},
			{
				name: 'average_intercepted_passes',
				unit: '',
			},
			{
				name: 'defense_duel_count',
				unit: '',
			},
			{
				name: 'defense_duel_successful',
				unit: '',
			},
			{
				name: 'defense_duel_rate',
				unit: '',
			},
			{
				name: 'defense_intercepted_total',
				unit: '',
			},
		],
	},
	{
		name: 'Shot',
		subScores: [
			{
				name: 'peakshotspeed',
				unit: 'km/h',
			},
			{
				name: 'shot_speed',
				unit: '',
			},
		],
	},
	{
		name: 'Pace',
		subScores: [
			{
				name: 'pace_5m_time',
				unit: 'sec',
			},
			{
				name: 'pace_10m_time',
				unit: 'sec',
			},
			{
				name: 'pace_20m_time',
				unit: 'sec',
			},
			{
				name: 'pace_30m_time',
				unit: 'sec',
			},
		],
	},
];
