import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
	VictoryAxis,
	VictoryChart,
	VictoryContainer,
	VictoryLabel,
	VictoryLine,
	VictoryScatter,
} from 'victory';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import { useUserScores } from 'src/Sessions/useUserScores';
import { Score, Session, SubScores, UserRole } from 'src/graphql/types';

import { statsOverTimeStyles } from './StatsOverTime.styles';
import { chartTabs } from './chartTabs';

const LinearGradient = () => (
	<defs>
		<linearGradient
			id="linearGradient"
			transform="rotate(230)"
			gradientUnits="userSpaceOnUse"
		>
			<stop offset="0%" stopColor="#7DFF03" />
			<stop offset="100%" stopColor="#03FF95" />
		</linearGradient>
	</defs>
);

export const StatsOverTime = () => {
	const { t } = useTranslation(['performance-stats', 'months']);
	const { query, setQuery } = useNavigation();
	const { sessions, selectedRole } = useSessions();
	const params = useParams<{ userId: string }>();
	const { userScores } = useUserScores();

	const choosenSessions = params.userId ? userScores : sessions;

	const customField =
		selectedRole === UserRole.Player || params?.userId
			? 'subScores'
			: 'averages';

	const filteredChartTabs = chartTabs.filter((tab) =>
		choosenSessions?.length
			? (choosenSessions as any).some((sessions: Session | Score) =>
					(sessions as any)?.[customField].find(
						(subScore: SubScores) =>
							subScore.type === tab.subScore &&
							subScore.value !== null,
					),
			  )
			: true,
	);

	const queryChart = filteredChartTabs.find(
		(chartTab) => chartTab.subScore === query.get('chart'),
	);

	const selectedChart =
		filteredChartTabs.find(
			(chart) => chart.subScore === queryChart?.subScore,
		) || filteredChartTabs[0];

	const selectedChartIndex = filteredChartTabs.findIndex(
		(chart) => chart.subScore === selectedChart.subScore,
	);

	const filteredSessions = (choosenSessions as Session[])
		?.filter((session: any) => {
			return session?.[customField]?.find(
				(subScore: SubScores) =>
					subScore.type === selectedChart.subScore,
			)?.value;
		})
		.reverse();

	const chartProps = {
		animate: {
			duration: 1000,
			onLoad: {
				duration: 1000,
			},
		},
		data: filteredSessions,
		x: (datum: any) => datum.date,
		y: (datum: any) =>
			datum?.[customField]?.find(
				(subScore: SubScores) =>
					subScore.type === selectedChart.subScore,
			)?.value || 0,
	};

	const formatDate = (value: string) => {
		if (!value) return '';

		const date = new Date(value);

		return `${t('months:' + date.getMonth().toString()).substring(
			0,
			3,
		)} ${date.getFullYear().toString().substring(2, 4)}`;
	};

	return (
		<>
			<Box pt={5} />

			<Typography variant="subtitle1" align="center">
				{t('statsOverTimeTitle')}
			</Typography>

			<Box pt={5} />

			<Tabs
				value={selectedChartIndex}
				onChange={(_: any, newValue) =>
					setQuery('chart', filteredChartTabs[newValue].subScore)
				}
				indicatorColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				sx={{ mx: '-20px' }}
			>
				{filteredChartTabs.map((chart) => (
					<Tab key={chart.subScore} label={chart.label} />
				))}
			</Tabs>

			<Divider sx={{ mx: '-20px' }} />

			<Box display="flex" justifyContent="center">
				<VictoryChart
					height={340}
					padding={{ top: 40, left: 20, right: 40, bottom: 40 }}
					domainPadding={{ x: 30 }}
					containerComponent={
						<VictoryContainer
							responsive={true}
							style={statsOverTimeStyles.container}
						/>
					}
				>
					<LinearGradient />

					<VictoryAxis
						dependentAxis
						orientation="left"
						tickValues={[0, 25, 50, 75, 100]}
						tickFormat={() => ''}
						style={{
							axis: statsOverTimeStyles.axis,
							grid: statsOverTimeStyles.grid,
						}}
					/>

					<VictoryAxis
						orientation="top"
						tickFormat={() => ''}
						style={{
							grid: statsOverTimeStyles.gridDashed,
						}}
					/>

					<VictoryAxis
						orientation="top"
						tickFormat={() => ''}
						style={{
							axis: statsOverTimeStyles.axis,
						}}
					/>

					<VictoryAxis
						crossAxis
						orientation="bottom"
						scale="time"
						tickFormat={formatDate}
						padding={{ left: 10, right: 10 }}
						style={{
							axis: statsOverTimeStyles.axis,
							tickLabels: statsOverTimeStyles.tickLabels,
						}}
					/>

					<VictoryAxis
						dependentAxis
						orientation="right"
						tickValues={[0, 25, 50, 75, 100]}
						style={{
							axis: statsOverTimeStyles.axis,
							tickLabels: statsOverTimeStyles.tickLabels,
						}}
					/>

					<VictoryLine
						{...chartProps}
						style={{
							data: statsOverTimeStyles.line,
						}}
					/>

					<VictoryScatter
						{...chartProps}
						size={8}
						style={{
							data: statsOverTimeStyles.scatter,
						}}
					/>

					{!filteredSessions?.length ? (
						<VictoryLabel
							textAnchor="middle"
							verticalAnchor="middle"
							x={225}
							y={170}
							text={t('noTrackingData')}
							style={statsOverTimeStyles.label}
						/>
					) : null}
				</VictoryChart>
			</Box>

			{choosenSessions?.length ? <Box pt={4} /> : null}
		</>
	);
};
