import { GraphQLError } from 'graphql';
import { stringify } from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { DinamicAvatar } from 'src/Components/DinamicAvatar';
import { ArrowRightIcon } from 'src/Components/Icons/ArrowRightIcon';
import { CloseIcon } from 'src/Components/Icons/CloseIcon';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import { useWatchComparisons } from 'src/WatchComparisons/useWatchComparisons';
import {
	UserRole,
	WatchComparison,
	useDeleteWatchComparisonMutation,
} from 'src/graphql/types';

import { LineComparison } from './LineComparison/LineComparison';
import { VerticalBar } from './VerticalBar/VerticalBar';
import {
	isFromPlayer,
	isFromTeam,
	isNotPreviousType,
	isPreviousType,
	isToSamePlayer,
	isToTeam,
} from './comparisonTypeComparations';

// import { BarVerticalComparison } from './BarVerticalComparison/BarVerticalComparison';

export type WatchComparisonResultProps = {
	watchComparison: WatchComparison;
};

export const WatchComparisonResult: React.FC<WatchComparisonResultProps> = ({
	watchComparison,
}) => {
	const { t } = useTranslation(['comparison', 'months', 'server-error']);
	const navigation = useNavigation();
	const { selectedRole } = useSessions();
	const { refetch } = useWatchComparisons();
	const [deleteWatchComparisonMutation, { loading }] =
		useDeleteWatchComparisonMutation();
	const [serverErrors, setServerErrors] = useState<readonly GraphQLError[]>(
		[],
	);

	const sizeAvatar = 40;

	const fromTitle = isFromTeam(watchComparison.type)
		? t('team')
		: isFromPlayer(watchComparison.type)
		? t('you')
		: watchComparison.compareFrom.name;

	const toTitle = isToTeam(watchComparison.type)
		? t('team')
		: isToSamePlayer(watchComparison.type)
		? t('you')
		: watchComparison.compareTo.name;

	const onOpenComparison = () => {
		const queryParams = stringify(
			{
				type: watchComparison.type,
				skill: watchComparison.skill,
				sessionNumber: watchComparison.sessionNumber,
				sub: watchComparison.sub,
				subFrom: watchComparison.subFrom,
			},
			{
				skipNull: true,
			},
		);

		const url = `${AppRoutes.ViewComparison}?${queryParams}`;

		navigation.push(url);
	};

	const onClickNext = () => {};

	const onDeleteWatchComparison = async (
		event: React.MouseEvent<HTMLElement>,
	) => {
		event.stopPropagation();

		const { errors } = await deleteWatchComparisonMutation({
			variables: {
				input: {
					role: selectedRole,
					skill: watchComparison.skill,
					type: watchComparison.type,
					sessionNumber: watchComparison.sessionNumber,
					subFrom: watchComparison.subFrom,
					sub: watchComparison.sub,
				},
			},
		}).catch(convertNetworkErrors);

		if (errors) {
			setServerErrors(errors);
		} else {
			refetch();
		}
	};

	const translateDate = (text: string | null = '', dateString: string) => {
		const date = new Date(dateString);

		return `${text?.split(' ')[0]} (${t(
			'months:' + date.getMonth().toString(),
		).substring(0, 3)} ${date.getFullYear().toString().substring(2, 4)})`;
	};

	return (
		<>
			<Box
				border={1}
				borderColor="#252525"
				width={1}
				onClick={onOpenComparison}
				sx={{ cursor: 'pointer' }}
			>
				<Grid container alignItems="center" direction="row" p={1}>
					<Grid onClick={onDeleteWatchComparison} item display="flex">
						{loading ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							<CloseIcon
								strokeWidth={2}
								color="white"
								sx={{ fontSize: 24 }}
							/>
						)}
					</Grid>

					<Grid textAlign="center" xs item>
						<Typography
							variant="subtitle2"
							color="inherit"
							align="center"
							sx={{
								textTransform: 'capitalize',
							}}
						>
							{watchComparison.skill === 'RANK'
								? 'Top Score'
								: watchComparison.skill.toLowerCase()}
						</Typography>
					</Grid>

					<Grid onClick={onClickNext} item display="flex">
						<ArrowRightIcon color="white" sx={{ fontSize: 24 }} />
					</Grid>
				</Grid>

				<Grid
					container
					alignItems="center"
					direction="row"
					px="4px"
					pt={0}
					pb={2}
				>
					<Grid item>
						{/* first icon */}
						<Box
							display="flex"
							justifyContent="space-between"
							flexDirection="column"
							alignItems="center"
							width={86}
							height={100}
						>
							<DinamicAvatar
								iconUrl={watchComparison.compareFrom.avatar}
								role={
									isFromTeam(watchComparison.type)
										? UserRole.Trainer
										: UserRole.Player
								}
								size={sizeAvatar}
							/>

							<Typography
								variant="caption"
								align="center"
								fontSize={11}
								textOverflow="ellipsis"
								overflow="hidden"
								width={86}
								whiteSpace="nowrap"
							>
								{fromTitle}
							</Typography>

							<Typography
								variant="h4"
								color="inherit"
								align="center"
								lineHeight={0.8}
							>
								{watchComparison.compareFrom.value}
							</Typography>
						</Box>
					</Grid>

					<Grid xs item>
						{isPreviousType(watchComparison.type) && (
							<LineComparison
								scoreFirst={watchComparison.compareFrom.value}
								scoreSecond={watchComparison.compareTo.value}
							/>
						)}

						{isNotPreviousType(watchComparison.type) && (
							<Box display="flex" justifyContent="space-around">
								<VerticalBar
									width={0.3}
									maxWidth={56}
									height={100}
									value={watchComparison.compareFrom.value}
								/>

								<VerticalBar
									width={0.3}
									maxWidth={56}
									height={100}
									value={watchComparison.compareTo.value}
								/>
							</Box>
						)}
					</Grid>

					<Grid item>
						{/* second icon */}
						<Box
							display="flex"
							justifyContent="space-between"
							flexDirection="column"
							alignItems="center"
							width={86}
							height={100}
						>
							<DinamicAvatar
								iconUrl={watchComparison.compareTo.avatar}
								size={sizeAvatar}
								role={
									isToTeam(watchComparison.type)
										? UserRole.Trainer
										: UserRole.Player
								}
							/>

							<Typography
								variant="caption"
								align="center"
								fontSize={11}
								textOverflow="ellipsis"
								overflow="hidden"
								width={86}
								whiteSpace="nowrap"
							>
								{isPreviousType(watchComparison.type)
									? translateDate(
											toTitle,
											watchComparison.compareTo.date,
									  )
									: toTitle}
							</Typography>

							<Typography
								variant="h4"
								color="inherit"
								align="center"
								lineHeight={0.8}
							>
								{watchComparison.compareTo.value}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Box>

			{serverErrors.map((error) => (
				<>
					<Box pt={2} />

					<Alert key={error.message} severity="error">
						{t('server-error:' + error.message) || error.message}
					</Alert>
				</>
			))}
		</>
	);
};
