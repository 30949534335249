import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { GraphQLError } from 'graphql';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { Autocomplete, TextField, useMediaQuery } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { CloseIcon } from 'src/Components/Icons/CloseIcon';
import { GoalkeeperLeftIcon } from 'src/Components/Icons/GoalkeeperLeftIcon';
import { OldTopBar } from 'src/Components/OldTopBar';
import { useCustomForm } from 'src/Form/useCustomForm';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { User } from '../../../graphql/types/rtk-query';
import { useFindEventPlayerQuery } from '../../../redux/rtk-query';
import { matchesExpected } from '../../SessionCreator/AddPlayer/matchesExcepted';
import { CreateSessionFormData } from '../../SessionCreator/AdminForm';
import { useEvent } from '../useEvent';

const filterUsers = (
	users: User[],
	search: string,
	fields: string[],
): User[] => {
	const needles = _.toLower(search)
		.trim()
		.split(/\s| /)
		.map((s) => s.trim());
	users = users.filter((u) => {
		const haystack = _.toLower(
			_.map(fields, (f) => _.get(u, f))
				.filter((v) => _.isString(v))
				.join(' ')
				.trim(),
		);
		return _.reduce(
			needles,
			(ns, n) => ns && haystack.includes(n),
			true as boolean,
		);
	});
	return users;
};

export const AddEventSessionPlayerScreen = () => {
	const { event } = useEvent();
	const eventRoute = (route: AppRoutes) => {
		return route.replace(':eventNumber', `${event?.eventNumber ?? 0}`);
	};
	const eventNumber = event?.eventNumber ?? 0;

	const { t } = useTranslation(['add-player', 'server-error']);

	const [invalidCode, setInvalidCode] = useState<boolean>(false);
	const [playersLeft, setPlayersLeft] = useState<boolean>(false);

	const [players, setPlayers] = useState<User[] | []>([]);
	const [options, setOptions] = useState<User[] | []>([]);
	const [value, setValue] = useState<User | null>(null);
	const [inputValue, setInputValue] = useState('');

	const { form, setForm } = useCustomForm<CreateSessionFormData>();
	const navigation = useNavigation();

	const [serverErrors, setServerErrors] = useState<readonly GraphQLError[]>(
		[],
	);

	useEffect(() => {
		if (!form.ageGroup) {
			navigation.push(
				`${
					AppRoutes.EditEventSession.replace(
						':eventNumber',
						`${event?.eventNumber ?? 0}`,
					) +
					(form.sessionNumber
						? `?sessionNumber=${form.sessionNumber}`
						: '')
				}`,
			);
		}
	}, [form.ageGroup, form.sessionNumber, navigation, event?.eventNumber]);

	const AddPlayer = useCallback(
		async (code: string) => {
			setServerErrors([]);

			const existingCode =
				form.playerCodes?.includes(code) ||
				form.trainerCodes?.includes(code);

			if (existingCode) {
				setInvalidCode(true);
				return;
			}

			const playing = (form.playerCodes?.length ?? 0) + 1;
			const total = players?.length ?? 0;

			if (total - playing < 10) {
				setPlayersLeft(true);
				return;
			}

			setForm({
				...form,
				playerCodes: [...(form?.playerCodes || []), code],
				playersCount: form.playersCount + 1,
			});
		},
		[form, players?.length, setForm],
	);

	const onSubmit = async () => {
		navigation.push(`${eventRoute(AppRoutes.EditEventSession)}?confirm`);
	};

	const removePlayer = (code: string) => {
		form.playersCount--;
		form.playerCodes = _.filter(form.playerCodes, (c) => c !== code);

		setForm({ ...form });
	};

	const args = eventNumber === 0 ? skipToken : { eventNumber, search: '' };
	const { isLoading, data } = useFindEventPlayerQuery(args, {});

	useEffect(() => {
		const users = _.map(data?.findEventPlayer, (p) => p.player) ?? [];
		setOptions(
			_.orderBy(
				users,
				['player.lastName', 'player.firstName', 'player.email'],
				['asc', 'asc', 'asc'],
			),
		);
		setPlayers(users);
	}, [data]);

	const getBirthdate = (
		player: User | undefined,
		asMoment = false,
		prefix = '',
	) => {
		if (_.isNil(player) || _.isNil(player?.birthdate)) {
			return '';
		}
		const birthdate = moment(
			`${player.birthdate.day}.${player.birthdate.month}.${player.birthdate.year}`,
			'DD.MM.YYYY',
		);
		if (!birthdate.isValid()) {
			return '';
		}
		if (asMoment) {
			return birthdate;
		}
		return `${prefix} ${birthdate.format('L')}`.trim();
	};

	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));

	const getOptionLabel = (option: any) => {
		let label = option.name ?? '';
		if (sm && !_.isNil(option.email)) {
			label = `${label} (${option.email})`;
		}
		if (!_.isNil(option.birthdate)) {
			label = `${label} - ${getBirthdate(option)}`;
		}
		return label;
	};

	const code = localStorage
		.getItem('player-code')
		?.toLowerCase()
		?.replace(/\|.*$/gim, '');
	const scannedRef = useRef(false);

	useEffect(() => {
		if (players?.length > 0 && !isLoading && code && !scannedRef.current) {
			const sub = code;
			if (!_.isNil(_.find(players, { sub }))) {
				AddPlayer(sub);
			}
			localStorage.removeItem('player-code');
			scannedRef.current = true;
		}
	}, [AddPlayer, code, isLoading, navigation, players]);

	return (
		<>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				width={1}
				height={1}
			>
				<OldTopBar
					title={event?.club?.name ?? undefined}
					subTitle={event?.name}
					border
					onClickLeft={() =>
						navigation.replace(
							eventRoute(AppRoutes.AddEventSessionCoach),
						)
					}
					iconLeft={<ArrowBackIcon />}
				/>

				<Box pt={4} />

				<Autocomplete
					value={value}
					fullWidth={true}
					sx={{ maxWidth: sm ? 728 : '100%', padding: '0 20px' }}
					onClose={() => {
						setValue(null);
						setInputValue('');
					}}
					onChange={(event: any, newValue: User | null) => {
						if (!_.isNil(newValue)) {
							AddPlayer(newValue.sub);
						}
						setValue(null);
						setInputValue('');
					}}
					filterOptions={(os) => {
						return filterUsers(
							_.filter(
								os,
								(o) => !form.playerCodes?.includes(o?.sub),
							).filter(
								(o) => !form.trainerCodes?.includes(o?.sub),
							),
							inputValue,
							[
								'email',
								'name',
								'firstName',
								'lastName',
								'username',
							],
						);
					}}
					loading={isLoading}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					getOptionLabel={(option) => getOptionLabel(option)}
					noOptionsText={''}
					getOptionDisabled={(option) => {
						return (
							(form.playerCodes?.includes(option?.sub) ??
								false) ||
							(form.trainerCodes?.includes(option?.sub) ?? false)
						);
					}}
					options={options}
					renderOption={(params, option) => {
						return (
							<li
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'stretch',
									justifyContent: 'center',
								}}
								{...params}
								key={option.sub}
							>
								<span
									style={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									{option.name ?? ''}
								</span>
								<span
									style={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									{option.email}
								</span>
								{!_.isNil(getBirthdate(option)) && (
									<span
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										{getBirthdate(option)}
									</span>
								)}
							</li>
						);
					}}
					renderInput={(params) => (
						<TextField {...params} label={t('player')} />
					)}
				/>

				<Box pt={2} />

				<Typography variant="body2">{`${t('numberPlayers')}${
					form.playersCount
				}`}</Typography>

				<Box pt={2} />

				<Box display="flex" justifyContent="center">
					<Button
						variant="contained"
						color="primary"
						disabled={form.playersCount >= 19}
						onClick={() =>
							navigation.replace(
								eventRoute(AppRoutes.AddEventSessionPlayerScan),
							)
						}
					>
						{t('scanCode')}
					</Button>
				</Box>

				<Box pt={2} />

				<Typography variant="body2">{`${t('matchesExpected')}${
					matchesExpected[form.playersCount as 10] ?? '-'
				}`}</Typography>

				<Box pt={2} />

				<Box
					display={'flex'}
					flexDirection={'column'}
					alignItems={'stretch'}
					borderTop="1px solid #252525"
					px="20px"
					width={1}
					sx={{ overflowY: 'auto', overflowX: 'hidden' }}
					pt={2}
				>
					{form.playerCodes?.map((code, index) => (
						<Box
							display="flex"
							p={2}
							border="1px solid #202020"
							key={index}
							width={1}
							height={66}
							bgcolor="#0d0d0d"
							alignItems="center"
							justifyContent="stretch"
							mb={2}
						>
							<Box
								display="flex"
								flex={1}
								justifyContent="stretch"
							>
								<Typography variant="button">
									{!sm
										? index + 1
										: `${t('player')} ${index + 1}`}
								</Typography>

								<Box pl={1} />

								<Typography variant="button">-</Typography>

								<Box pl={1} />

								{_.isNil(_.find(players, { sub: code })) ? (
									<Typography
										variant="button"
										flex={1}
										paddingRight={1}
									>
										<Skeleton
											sx={{ flex: 1 }}
											height={21}
											variant="rectangular"
										></Skeleton>
									</Typography>
								) : (
									<>
										<Typography
											variant="button"
											textTransform="initial"
										>
											{
												_.find(players, {
													sub: code,
												})?.name
											}
										</Typography>

										<Box pl={1} />

										<Typography
											hidden={!sm}
											variant="button"
											textTransform="initial"
										>
											(
											{
												_.find(players, {
													sub: code,
												})?.email
											}
											)
										</Typography>

										<Box hidden={!sm} pl={1} />

										{getBirthdate(
											_.find<User>(players, {
												sub: code,
											}),
											false,
										) && (
											<>
												<Typography variant="button">
													-
												</Typography>{' '}
												<Box pl={1} />
											</>
										)}

										<Typography variant="button">
											{getBirthdate(
												_.find<User>(players, {
													sub: code,
												}),
												false,
											)}
										</Typography>
									</>
								)}
							</Box>
							{index <= 1 && form.fixedGoalkeepers && (
								<GoalkeeperLeftIcon />
							)}

							<IconButton
								size="small"
								sx={{ backgroundColor: '#730100' }}
								onClick={() => removePlayer(code)}
							>
								<CloseIcon color="white" />
							</IconButton>
						</Box>
					))}

					{invalidCode ? (
						<>
							<Alert
								severity="error"
								onClose={() => setInvalidCode(false)}
							>
								{t('invalidCode')}
							</Alert>

							<Box pt={2} />
						</>
					) : null}

					{playersLeft ? (
						<Box
							boxSizing={'border-box'}
							position={'sticky'}
							bottom={64}
							flex={1}
						>
							<Alert
								severity="error"
								onClose={() => setPlayersLeft(false)}
							>
								{t('playersLeft')}
							</Alert>

							<Box pt={2} />
						</Box>
					) : null}

					{serverErrors.map((error) => (
						<>
							<Alert
								key={error.message}
								severity="error"
								onClose={() => setServerErrors([])}
							>
								{t('server-error:' + error.message) ||
									error.message}
							</Alert>

							<Box pt={2} />
						</>
					))}

					{form.playersCount >= 10 && form.playersCount < 20 ? (
						<Box
							bottom={16}
							display="flex"
							justifyContent="center"
							position={'sticky'}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={onSubmit}
							>
								{t('continue')}
							</Button>
						</Box>
					) : (
						<Box
							bgcolor="#0d0d0d"
							border="1px solid #202020"
							bottom={16}
							display="flex"
							p={1}
							position={'sticky'}
						>
							<Typography variant="body2" align="center">
								{t('playersContinue')}
							</Typography>
						</Box>
					)}

					<Box pb={2} />
				</Box>
			</Box>
		</>
	);
};
