import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';

export const LocaleLink = forwardRef<HTMLAnchorElement, LinkProps>(
	(props, ref) => {
		const { i18n } = useTranslation();

		const to = props.to ? `/${i18n.language}${props.to}` : '';

		return <Link ref={ref} {...props} to={to} />;
	},
);
