export enum AppRoutes {
	// Anonymous
	Start = '/',
	Login = '/login',
	ForgotPassword = '/forgot-password',
	SignUp = '/signup',
	UserInfoForm = '/signup/user-info',
	BirthdayForm = '/signup/birthday',
	UserRoleForm = '/signup/user-role',
	FullNameForm = '/signup/full-name',

	// Authorized
	Scoring = '/auth/dashboard/scoring',
	PerformanceStats = '/auth/dashboard/scoring/stats',
	PerformanceStatsPlayerTeam = '/auth/dashboard/scoring/stats/user',
	HowItWork = '/auth/dashboard/scoring/works',
	HowArrangeTracking = '/auth/dashboard/scoring/tracking',
	Contact = '/auth/dashboard/scoring/contact',
	TrackingVideos = '/auth/dashboard/scoring/videos',
	ShareScoreCard = '/auth/dashboard/scoring/share',
	ImagesDialog = '/auth/dashboard/scoring/images',
	Settings = '/auth/dashboard/scoring/settings',
	Notifications = '/auth/dashboard/scoring/notifications',
	QrCodeDialog = '/auth/dashboard/scoring/qrcode',

	SelfAssessmentDialog = '/auth/dashboard/scoring/self-assessment',

	SlideControl = '/auth/slidecontrol',

	Comparison = '/auth/dashboard/comparison',
	CreateComparison = '/auth/comparison/create',
	ViewComparison = '/auth/comparison/view',

	Videos = '/auth/dashboard/videos',

	AddSession = '/auth/add-session/scan',
	CheckSession = '/auth/add-session/check',
	AddManualSession = '/auth/add-session/manual',
	Payment = '/auth/add-session/payment',
	ShareWithCoach = '/auth/add-session/share',

	Workout = '/auth/workout',
	Team = '/auth/team',

	ChangePassword = '/auth/change-password',
	DeleteAccount = '/auth/delete-account',

	// Operator
	CurrentSessions = '/operator/session-creator',
	EditSession = '/operator/edit-session',
	ProcessSession = '/operator/process-session',
	AddCoach = '/operator/add-coach',
	AddCoachScan = '/operator/add-coach/scan',
	AddPlayer = '/operator/add-player',
	AddPlayerScan = '/operator/add-player/scan',

	// Scouting Events
	Events = '/operator/events',

	// Scouting-Event Operator
	CurrentEvents = '/operator/events',
	CreateEvent = '/operator/events/add',
	EditEvent = '/operator/events/:eventNumber/edit',
	AddEventCoach = '/operator/add-event-coach',
	AddEventPlayer = '/operator/add-event-player',
	CurrentEventSessions = '/operator/events/:eventNumber',
	EditEventSession = '/operator/events/:eventNumber/edit-event-session',
	ProcessEventSession = '/operator/events/:eventNumber/process-event-session',
	AddEventSessionCoach = '/operator/events/:eventNumber/add-session-coach',
	AddEventSessionPlayer = '/operator/events/:eventNumber/add-session-player',
	AddEventSessionPlayerScan = '/operator/events/:eventNumber/add-session-player/scan',

	// Any
	ResetPassword = '/reset-password',
	ConfirmUser = '/confirm-user',
	ConfirmLegalGuardian = '/confirm-legal-guardian',
	Faq = '/faq',
}

export const TabsDashboard = ['scoring', 'videos', 'comparison'] as const;

export const ScoringDialogs = [
	'stats',
	'videos',
	'tracking',
	'works',
	'share',
	'images',
	'settings',
	'contact',
	'qrcode',
	'notifications',
	'user-email',
	'self-assessment',
	'legal-guardian-email',
] as const;
