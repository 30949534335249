import { UseLazyQuery, UseMutation, UseQuery } from './types';

import * as RtkQuery from '../graphql/types/rtk-query';

export const useCreateEventMutation: UseMutation<
	RtkQuery.CreateEventMutationVariables,
	RtkQuery.CreateEventMutation
> = RtkQuery.useCreateEventMutation;

export const useGetTrainingVideosQuery: UseQuery<
	RtkQuery.GetTrainingVideosQueryVariables,
	RtkQuery.GetTrainingVideosQuery
> = RtkQuery.useGetTrainingVideosQuery;

export const useGetEventQuery: UseQuery<
	RtkQuery.GetEventQueryVariables,
	RtkQuery.GetEventQuery
> = RtkQuery.useGetEventQuery;

export const useListEventsQuery: UseQuery<
	RtkQuery.ListEventsQueryVariables,
	RtkQuery.ListEventsQuery
> = RtkQuery.useListEventsQuery;

export const useLazyClubsQuery: UseLazyQuery<
	RtkQuery.ClubsQueryVariables,
	RtkQuery.ClubsQuery
> = RtkQuery.useLazyClubsQuery;

export const useClubsQuery: UseQuery<
	RtkQuery.ClubsQueryVariables,
	RtkQuery.ClubsQuery
> = RtkQuery.useClubsQuery;

export const useSearchUsersQuery: UseQuery<
	RtkQuery.SearchUsersQueryVariables,
	RtkQuery.SearchUsersQuery
> = RtkQuery.useSearchUsersQuery;

export const useListEventSessionsQuery: UseQuery<
	RtkQuery.ListEventSessionsQueryVariables,
	RtkQuery.ListEventSessionsQuery
> = RtkQuery.useListEventSessionsQuery;

export const useFindEventPlayerQuery: UseQuery<
	RtkQuery.FindEventPlayerQueryVariables,
	RtkQuery.FindEventPlayerQuery
> = RtkQuery.useFindEventPlayerQuery;

export const useFindEventTrainerQuery: UseQuery<
	RtkQuery.FindEventTrainerQueryVariables,
	RtkQuery.FindEventTrainerQuery
> = RtkQuery.useFindEventTrainerQuery;

export const api = RtkQuery.api;
