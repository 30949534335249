import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const KickIdLogo: React.FC<SvgIconProps> = (props) => {
	return (
		<SvgIcon viewBox="0 0 50.13 13.02" {...props}>
			<g>
				<defs>
					<rect id="SVGID_1_" y="0" width="50.13" height="13.02" />
				</defs>
				<clipPath id="SVGID_2_">
					<use
						xlinkHref="#SVGID_1_"
						style={{ overflow: 'visible' }}
					/>
				</clipPath>
				<path
					clipPath="url(#SVGID_2_)"
					d="M44.46,4.19c-0.34-0.17-0.76-0.25-1.26-0.25h-1.01v5.13h1.04c0.49,0,0.9-0.08,1.24-0.25
		c0.34-0.17,0.6-0.44,0.77-0.81c0.17-0.38,0.26-0.88,0.26-1.51c0-0.63-0.09-1.13-0.27-1.5C45.06,4.63,44.81,4.36,44.46,4.19"
				/>
				<path
					clipPath="url(#SVGID_2_)"
					d="M44.15,0h-8.78l-4.56,6.49l4.56,6.53h8.78c3.59,0,5.98-2.91,5.98-6.51C50.13,2.91,47.75,0,44.15,0
		 M38.45,10.87h-2.11V2.15h2.11V10.87z M47.1,8.85c-0.35,0.65-0.85,1.15-1.49,1.5c-0.65,0.35-1.41,0.52-2.3,0.52h-3.23V2.15h3.22
		c0.89,0,1.66,0.17,2.3,0.52c0.64,0.35,1.14,0.85,1.49,1.5c0.35,0.65,0.52,1.43,0.52,2.34C47.62,7.41,47.44,8.2,47.1,8.85"
				/>
				<polygon
					clipPath="url(#SVGID_2_)"
					points="7.31,2.15 5.1,2.15 1.96,6 1.84,6 1.84,2.15 0,2.15 0,10.87 1.84,10.87 1.84,8.39 2.75,7.29
		5.14,10.87 7.35,10.87 4.07,6.05"
				/>
				<rect
					x="8.66"
					y="2.15"
					clipPath="url(#SVGID_2_)"
					width="1.85"
					height="8.73"
				/>
				<path
					clipPath="url(#SVGID_2_)"
					d="M14.92,3.99c0.34-0.22,0.73-0.33,1.18-0.33c0.25,0,0.48,0.03,0.7,0.11c0.21,0.07,0.4,0.17,0.57,0.31
		c0.16,0.13,0.3,0.3,0.41,0.48c0.1,0.19,0.17,0.4,0.21,0.65h1.87c-0.06-0.51-0.2-0.95-0.41-1.35c-0.21-0.39-0.49-0.72-0.82-0.99
		c-0.33-0.27-0.72-0.48-1.15-0.62c-0.43-0.14-0.9-0.21-1.41-0.21c-0.77,0-1.46,0.18-2.07,0.53s-1.1,0.86-1.46,1.53
		C12.17,4.75,12,5.56,12,6.51c0,0.95,0.18,1.76,0.53,2.42c0.35,0.67,0.84,1.18,1.45,1.53c0.61,0.35,1.31,0.53,2.09,0.53
		c0.56,0,1.06-0.09,1.51-0.25c0.44-0.17,0.83-0.4,1.15-0.69c0.32-0.29,0.58-0.62,0.77-0.99c0.19-0.37,0.31-0.76,0.36-1.16
		l-1.87-0.01c-0.04,0.23-0.12,0.44-0.23,0.62c-0.11,0.18-0.25,0.34-0.41,0.46c-0.16,0.13-0.35,0.22-0.56,0.29
		c-0.21,0.06-0.44,0.1-0.69,0.1c-0.44,0-0.83-0.11-1.16-0.32c-0.34-0.22-0.6-0.54-0.78-0.96c-0.19-0.42-0.28-0.95-0.28-1.57
		c0-0.61,0.09-1.12,0.28-1.54C14.33,4.54,14.59,4.22,14.92,3.99"
				/>
				<polygon
					clipPath="url(#SVGID_2_)"
					points="28.67,2.15 26.45,2.15 23.31,6 23.2,6 23.2,2.15 21.35,2.15 21.35,10.87 23.2,10.87 23.2,8.39
		24.11,7.29 26.5,10.87 28.7,10.87 25.43,6.05"
				/>
			</g>
		</SvgIcon>
	);
};
