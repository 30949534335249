import { useTranslation } from 'react-i18next';

import ShareIcon from '@mui/icons-material/Share';
import Button, { ButtonProps } from '@mui/material/Button';

import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { OtherButton, OtherButtonsProps } from './OtherButton';

export const ShareButton = (props: ButtonProps) => {
	const navigation = useNavigation();
	const { t } = useTranslation('scoring');

	return (
		<Button
			startIcon={<ShareIcon />}
			onClick={() => navigation.push(AppRoutes.ShareScoreCard)}
			{...props}
		>
			{t('share')}
		</Button>
	);
};

export const ShareGroupButton: React.FC<OtherButtonsProps> = ({ onClick }) => {
	const { t } = useTranslation('scoring');

	return (
		<OtherButton
			label={t('share')}
			link={AppRoutes.ShareScoreCard}
			onClick={onClick}
		>
			<ShareIcon />
		</OtherButton>
	);
};
