import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useCreateSessionValidations = () => {
	const { t } = useTranslation('validations');

	const validationSchema = useMemo(
		() =>
			yup.object({
				ageGroup: yup.string().required(t('required')),
				club: yup
					.object()
					.nullable()
					.test('required', t('required'), (value) => {
						return !!value?.id;
					}),
			}),
		[t],
	);

	const resolver = yupResolver(validationSchema);

	return resolver;
};
