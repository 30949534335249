import { useTranslation } from 'react-i18next';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button, { ButtonProps } from '@mui/material/Button';

import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

export const AddCodeButton = (props: ButtonProps) => {
	const navigation = useNavigation();
	const { t } = useTranslation('scoring');

	return (
		<Button
			startIcon={<AddOutlinedIcon />}
			onClick={() => navigation.push(AppRoutes.AddSession)}
			{...props}
		>
			{t('addCode')}
		</Button>
	);
};
