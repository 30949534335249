import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { SlideUp } from 'src/Components/Transitions/Slide';
import { Club, useCreateClubMutation } from 'src/graphql/types';

import { useAddClubDialogValidations } from './AddClubDialog.validations';

export type AddClubDialogProps = {
	open: boolean;
	clubName: string;
	setClubName: (clubName: string) => void;
	onClose: () => void;
	onCreate: (club: Club) => void;
};

export const AddClubDialog: FC<AddClubDialogProps> = ({
	open,
	clubName,
	onClose,
	onCreate,
}) => {
	const { t } = useTranslation(['create-session', 'server-error']);
	const [createClubMutation] = useCreateClubMutation();
	const resolver = useAddClubDialogValidations();

	const { handleSubmit, control, setValue, getValues, setError, reset } =
		useForm<{ clubName: string }>({
			defaultValues: {
				clubName: clubName,
			},
			resolver,
		});

	useEffect(() => {
		if (clubName !== getValues('clubName')) {
			setValue('clubName', clubName);
		}
	}, [clubName, getValues, setValue]);

	const close = () => {
		reset();
		onClose();
	};

	const submit = handleSubmit(async (formData) => {
		const { data, errors } = await createClubMutation({
			variables: {
				input: {
					name: formData.clubName,
				},
			},
		}).catch(convertNetworkErrors);

		if (errors) {
			const invalidError = errors.find(
				(error) => error.message === 'ClubAlreadyCreated',
			);

			if (invalidError) {
				setError('clubName', {
					message: t(invalidError.message),
				});
			}
		} else {
			onCreate(data?.createClub as Club);
		}
	});

	return (
		<Dialog open={open} onClose={close} TransitionComponent={SlideUp}>
			<DialogContent
				sx={{
					background: 'white',
					color: 'black',
					padding: '20px',
					pb: 0,
				}}
			>
				<Box display="flex" alignItems="center" flexDirection="column">
					<Box pt={3} />

					<Typography variant="body1" color="initial" align="center">
						{t('clubDialog.description')}
					</Typography>

					<Box pt={4} />
				</Box>

				<Box mx="-20px" height="1px" bgcolor="#E5E5E5" />

				<Box pt={2} />

				<Controller
					name="clubName"
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							{...field}
							autoFocus
							fullWidth
							sx={{
								'& .MuiInputBase-input': {
									color: 'black',
								},
								'& label.Mui-focused': {
									color: 'green',
								},

								'& .MuiFormHelperText-root.Mui-error': {
									color: 'red',
								},

								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: 'green',
									},
									fieldset: {
										borderColor: '#E5E5E5',
									},
									'&.Mui-error .MuiOutlinedInput-notchedOutline':
										{
											borderColor: 'red',
										},
								},
							}}
							placeholder={t('clubName')}
							variant="outlined"
							helperText={fieldState.error?.message}
							error={!!fieldState.error}
						/>
					)}
				/>

				<Box pt={2} />

				<Box mx="-20px" height="1px" bgcolor="#E5E5E5" />
			</DialogContent>

			<DialogActions
				sx={{
					background: 'white',
					color: 'black',
				}}
			>
				<Button onClick={close} sx={{ color: 'text.secondary' }}>
					{t('clubDialog.cancel')}
				</Button>

				<Button onClick={submit} sx={{ color: 'green' }}>
					{t('clubDialog.create')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
