import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { DinamicAvatarWithProgress } from 'src/Components/DinamicAvatarWithProgress';
import { ArrowRightIcon } from 'src/Components/Icons/ArrowRightIcon';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { UserRole, useFindPlayersQuery } from 'src/graphql/types';

type PlayerListScreenProps = {
	sessionNumber: number;
};

export const PlayerListScreen: React.FC<PlayerListScreenProps> = ({
	sessionNumber,
}) => {
	const size = 40;
	const navigation = useNavigation();
	const { data, loading } = useFindPlayersQuery({
		variables: {
			sessionNumber,
		},
	});

	const { t } = useTranslation('performance-stats');

	return (
		<Box mx="-20px">
			<Box pt={1} />

			{loading && (
				<>
					<Skeleton variant="rectangular" height={70} />
					<Box pt={1} />

					<Skeleton variant="rectangular" height={70} />
					<Box pt={1} />

					<Skeleton variant="rectangular" height={70} />
					<Box pt={1} />

					<Skeleton variant="rectangular" height={70} />
					<Box pt={1} />

					<Skeleton variant="rectangular" height={70} />
					<Box pt={1} />

					<Skeleton variant="rectangular" height={70} />
					<Box pt={1} />

					<Skeleton variant="rectangular" height={70} />
					<Box pt={1} />

					<Skeleton variant="rectangular" height={70} />
				</>
			)}

			{!loading && <Divider />}

			{data?.findPlayers?.map((player) => (
				<React.Fragment key={player.playerSessionId}>
					<Box
						display="flex"
						alignItems="center"
						py={2}
						px="20px"
						bgcolor="#151515"
						sx={{
							cursor: player.released ? 'pointer' : 'not-allowed',
							opacity: player.released ? 1 : 0.6,
						}}
						onClick={() =>
							player.userId &&
							player.released &&
							navigation.push(
								`${AppRoutes.PerformanceStatsPlayerTeam}/${player.userId}`,
							)
						}
					>
						<DinamicAvatarWithProgress
							size={size}
							score={player.score}
							role={UserRole.Player}
							iconUrl={player.avatarUrl}
							disabled={!player.released}
						/>

						<Box pl={2} />

						<Typography variant="body1" width={1}>
							{`${player.firstName || t('player')} ${
								player.lastName || player.playerSessionId
							}`}
						</Typography>

						{player.released && <ArrowRightIcon color="white" />}
					</Box>
					<Divider />
				</React.Fragment>
			))}
			<Box pt={4} />
		</Box>
	);
};
