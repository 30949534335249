import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const TickIcon: React.FC<CustomIconProps> = (props) => {
	const { color } = useCustomIcon(props);
	return (
		<SvgIcon viewBox="0 0 8.799 8.799" sx={props.sx}>
			<g transform="translate(-1.085 -1.116)">
				<g
					transform="translate(10.329 3.814) rotate(135)"
					fill={color}
					style={{
						transition:
							'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				>
					<path
						d="M6.4,4.444V1.555H0V0H8V4.444Z"
						transform="translate(0.629 0)"
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
