import moment from 'moment';
import 'moment/locale/ar';
import 'moment/locale/de';
import 'moment/locale/es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { arEG, deDE, enUS, esES } from '@mui/material/locale';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useDeepLinks } from 'src/Plugins/useDeeplinks';
import { AnonymousRoute } from 'src/Router/Guards/AnonymousRoute';
import { AuthorizedRoute } from 'src/Router/Guards/AuthorizedRoute';
import { OperatorRoute } from 'src/Router/Guards/OperatorRoute';
import { AddSessionLayout } from 'src/Screens/AddSession/AddSessionLayout';
import { CheckCodeScreen } from 'src/Screens/AddSession/CheckCode/CheckCodeScreen';
import { ConfirmLegalGuardianScreen } from 'src/Screens/ConfirmLegalGuardian/ConfirmLegalGuardianScreen';
import { ConfirmUserScreen } from 'src/Screens/ConfirmUser/ConfirmUserScreen';
import { FaqScreen } from 'src/Screens/Dashboard/Settings/Faq/FaqScreen';
import { ResetPasswordScreen } from 'src/Screens/ResetPassword/ResetPasswordScreen';
import { useLocaleRoutes } from 'src/i18n/useLocaleRoutes';

import { AppRoutes } from './AppRoutes';
import { EventOperatorRoute } from './Guards/EventOperatorRoute';
import AnonymousRoutes from './Routes/AnonymousRoutes';
import AuthorizedRoutes from './Routes/AuthorizedRoutes';
import EventOperatorRoutes from './Routes/EventOperatorRoutes';
import EventOperatorRoutesV2 from './Routes/EventOperatorRoutesV2';
import OperatorRoutes from './Routes/OperatorRoutes';

import { useAuth } from '../Auth/useAuth';
import { UserRole } from '../graphql/types';

const Router: React.FC = () => {
	const { i18n } = useTranslation();
	useDeepLinks();
	const { user } = useAuth();
	const eventVersion = localStorage.getItem('event-version') ?? 'v1';
	const useV2 = user?.roles?.includes(UserRole.Admin) && eventVersion == 'v2';

	const localeRoutes = useLocaleRoutes([
		{
			path: '',
			element: <AnonymousRoute />,
			children: AnonymousRoutes,
		},
		{
			path: 'auth',
			element: <AuthorizedRoute />,
			children: AuthorizedRoutes,
		},
		// TODO: Temporal route until QR Codes have new url -> /auth/add-session/check/:code
		{
			path: 'add-session',
			element: <AuthorizedRoute />,
			children: [
				{
					path: 'check/:code',
					element: (
						<AddSessionLayout>
							<CheckCodeScreen />
						</AddSessionLayout>
					),
				},
			],
		},
		{
			path: 'operator',
			children: [
				{
					path: '',
					element: <OperatorRoute />,
					children: OperatorRoutes,
				},
				{
					path: 'events',
					element: <EventOperatorRoute />,
					children: useV2
						? EventOperatorRoutesV2
						: EventOperatorRoutes,
				},
			],
		},
		{
			path: 'reset-password',
			element: <ResetPasswordScreen />,
		},
		{
			path: 'confirm-user',
			element: <ConfirmUserScreen />,
		},
		{
			path: 'confirm-legal-guardian',
			element: <ConfirmLegalGuardianScreen />,
		},
		{
			path: 'faq',
			element: <FaqScreen />,
		},
		{
			path: '*',
			element: <Navigate replace to={AppRoutes.Start} />,
		},
	]);

	moment.locale(i18n.language);
	const theme = useTheme();

	const themeWithLocale = React.useMemo(() => {
		return createTheme(
			theme,
			i18n.language === 'de'
				? deDE
				: i18n.language === 'es'
				? esES
				: i18n.language === 'ar'
				? arEG
				: enUS,
		);
	}, [i18n.language, theme]);

	return (
		<LocalizationProvider
			dateAdapter={AdapterMoment}
			adapterLocale={i18n.language}
		>
			<ThemeProvider theme={themeWithLocale}>
				{localeRoutes}
			</ThemeProvider>
		</LocalizationProvider>
	);
};

export default Router;
