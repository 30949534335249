import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { DinamicAvatarWithProgress } from 'src/Components/DinamicAvatarWithProgress';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import {
	Club,
	ComparisonSelected,
	FoundComparisonType,
	UserRole,
	WatchComparisonResult,
} from 'src/graphql/types';

type TeamBoxProps = {
	club?: Club | null;
	compareFrom?: WatchComparisonResult | null;
};

export const TeamBox: React.FC<TeamBoxProps> = ({ club, compareFrom }) => {
	const navigation = useNavigation();
	const { t } = useTranslation('create-comparison');
	const size = 40;
	const selected = navigation.query.get('selected') as ComparisonSelected;
	const subFrom = navigation.query.get('subFrom');

	const onClick = () => {
		if (!compareFrom) {
			return navigation.push(
				`${AppRoutes.CreateComparison}?selected=${ComparisonSelected.Team}`,
			);
		}

		if (selected === ComparisonSelected.Team) {
			return navigation.push(
				`${AppRoutes.ViewComparison}?type=${FoundComparisonType.PlayerVsTeam}`,
			);
		}

		if (selected === ComparisonSelected.Player) {
			return navigation.push(
				`${AppRoutes.ViewComparison}?type=${FoundComparisonType.SelectedPlayerVsTeam}&subFrom=${subFrom}`,
			);
		}

		navigation.push(
			`${AppRoutes.ViewComparison}?type=${FoundComparisonType.PlayerVsTeam}`,
		);
	};

	return (
		<Box
			display="flex"
			alignItems="center"
			py={2}
			px="20px"
			bgcolor="#151515"
			sx={{
				cursor: 'pointer',
			}}
			onClick={onClick}
		>
			<DinamicAvatarWithProgress
				size={size}
				iconUrl={club?.logoUrl}
				role={UserRole.Trainer}
				score={club?.score}
			/>

			<Box pl={2} />

			<Typography variant="body1">{t('yourTeam')}</Typography>
		</Box>
	);
};
