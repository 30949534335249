import React, { useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Autocomplete, {
	autocompleteClasses,
	createFilterOptions,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useAuth } from 'src/Auth/useAuth';
import { useCustomForm } from 'src/Form/useCustomForm';
import {
	Club,
	OperatorSession,
	UserRole,
	useClubsQuery,
} from 'src/graphql/types';

import { AddClubDialog } from './AddClubDialog';
import { ageGroups } from './ageGroups';

export type CreateSessionsScreenControllersProps = {
	loading: boolean;
	form: UseFormReturn<OperatorSession>;
	edit?: boolean;
	confirm?: boolean;
};

const filterOptions = createFilterOptions({
	matchFrom: 'any',
	stringify: (option: ClubWithValue) => option?.name || '',
	limit: 50,
	ignoreCase: true,
	trim: true,
});

let timeout: NodeJS.Timeout;

type ClubWithValue = Club & { value?: string };

export const CreateSessionsControllers = ({
	loading,
	form: { control, setValue },
	edit = false,
	confirm = false,
}: CreateSessionsScreenControllersProps) => {
	const { form } = useCustomForm<OperatorSession>();
	const [addClubDialogOpen, toggleClubDialog] = useState(false);
	const [clubName, setClubName] = useState('');
	const { user } = useAuth();

	const { data, loading: clubsLoading, refetch } = useClubsQuery();

	const { t } = useTranslation('create-session');

	const onCloseDialog = () => {
		toggleClubDialog(false);
	};

	const onCreate = (club: Club) => {
		toggleClubDialog(false);

		setValue('club', club);
	};

	return (
		<>
			<Box width={1}>
				<Typography
					align="left"
					variant="caption"
					color="textSecondary"
				>
					{t('clubName')}
				</Typography>
			</Box>

			<Box pt={1} />

			<AddClubDialog
				onClose={onCloseDialog}
				onCreate={onCreate}
				open={addClubDialogOpen}
				clubName={clubName}
				setClubName={setClubName}
			/>

			<Controller
				name="club"
				control={control}
				render={({ field, fieldState }) => (
					<Autocomplete
						{...field}
						disabled={
							clubsLoading || loading || confirm || form.processed
						}
						fullWidth
						options={(data?.clubs || []) as ClubWithValue[]}
						getOptionLabel={(option) =>
							typeof option === 'string'
								? option
								: option?.name || ''
						}
						freeSolo
						selectOnFocus
						clearOnBlur
						handleHomeEndKeys
						loading={clubsLoading || loading}
						filterOptions={(options, state) => {
							const filtered = filterOptions(options, state);

							if (state.inputValue.length < 1) return [];

							const isExisting = filtered.find(
								(filter) =>
									filter.name?.trim().toLowerCase() ===
									state.inputValue.trim().toLowerCase(),
							);

							if (
								!isExisting &&
								user?.roles?.includes(UserRole.Clubcreator)
							) {
								filtered.push({
									id: null,
									value: state.inputValue,
									name: t('add', {
										value: state.inputValue,
									}),
								});
							}

							return filtered;
						}}
						sx={{
							'& .MuiIconButton-root': {
								border: 0,
							},
							[`& .${autocompleteClasses.paper}`]: {
								background: '#151515',
							},
						}}
						onChange={(
							event,
							value: string | ClubWithValue | null,
						) => {
							if (
								typeof value !== 'string' &&
								!value?.id &&
								value?.value
							) {
								toggleClubDialog(true);
								setClubName(value.value);
								return;
							}

							field.onChange(value);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								fullWidth
								autoCapitalize="off"
								autoCorrect="off"
								type="text"
								name="club"
								variant="outlined"
								onChange={(event: {
									target: { value: string };
								}) => {
									clearTimeout(timeout);

									timeout = setTimeout(
										() =>
											refetch({
												name: event.target.value,
											}),
										500,
									);
								}}
								disabled={
									clubsLoading ||
									loading ||
									confirm ||
									form.processed
								}
								placeholder={
									edit
										? form?.club?.name || ''
										: t('clubName')
								}
								helperText={fieldState.error?.message}
								error={!!fieldState.error}
							/>
						)}
					/>
				)}
			/>

			<Box pt={2} />

			<Box width={1}>
				<Typography
					align="left"
					variant="caption"
					color="textSecondary"
				>
					{t('ageGroup')}
				</Typography>
			</Box>

			<Box pt={1} />

			<Controller
				name="ageGroup"
				control={control}
				render={({ field, fieldState }) => (
					<>
						<Select
							{...field}
							fullWidth
							variant="outlined"
							displayEmpty
							error={!!fieldState.error}
							disabled={loading || confirm || form.processed}
						>
							<MenuItem value="" disabled>
								{t('ageGroup')}
							</MenuItem>
							{ageGroups.map((group, index) => (
								<MenuItem key={index} value={group.value}>
									{group.name}
								</MenuItem>
							))}
						</Select>

						{fieldState.error ? (
							<Box width={1} pl="14px" pt="3px">
								<Typography variant="caption" color="error">
									{fieldState.error.message}
								</Typography>
							</Box>
						) : null}
					</>
				)}
			/>
			<Box pt={2} />

			<Box width={1}>
				<Typography
					align="left"
					variant="caption"
					color="textSecondary"
				>
					{t('goalDimensions')}
				</Typography>
			</Box>

			<Box pt={1} />
			<Grid container direction="row" spacing={2}>
				<Grid item xs>
					<Controller
						name="ground.goalWidth"
						control={control}
						render={({ field, fieldState }) => (
							<>
								<TextField
									{...field}
									fullWidth
									variant="outlined"
									label={t('width')}
									disabled={
										loading || confirm || form.processed
									}
								></TextField>

								{fieldState.error ? (
									<Box width={1} pl="14px" pt="3px">
										<Typography
											variant="caption"
											color="error"
										>
											{fieldState.error.message}
										</Typography>
									</Box>
								) : null}
							</>
						)}
					/>
				</Grid>
				<Grid item xs>
					<Controller
						name="ground.goalHeight"
						control={control}
						render={({ field, fieldState }) => (
							<>
								<TextField
									{...field}
									fullWidth
									variant="outlined"
									label={t('height')}
									disabled={
										loading || confirm || form.processed
									}
								></TextField>

								{fieldState.error ? (
									<Box width={1} pl="14px" pt="3px">
										<Typography
											variant="caption"
											color="error"
										>
											{fieldState.error.message}
										</Typography>
									</Box>
								) : null}
							</>
						)}
					/>
				</Grid>
			</Grid>

			<Box pt={2} />

			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
			>
				<Controller
					name="fixedGoalkeepers"
					control={control}
					render={({ field }) => (
						<Box display="flex" alignItems="center">
							<Checkbox
								sx={{ pl: 0 }}
								onChange={(event) =>
									field.onChange(event.target.checked)
								}
								checked={!!field.value}
								disabled={loading || confirm || form.processed}
							/>
							<Typography
								variant="body2"
								color={
									form.processed || confirm
										? 'textSecondary'
										: 'inherit'
								}
							>
								{t('fixedKeeper')}
							</Typography>
						</Box>
					)}
				/>
			</Grid>

			{/* <Box pt={2} /> */}

			{/* <Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
			>
				<Controller
					name="sprintTest"
					control={control}
					render={({ field }) => (
						<Box display="flex" alignItems="center">
							<Checkbox
								sx={{ pl: 0 }}
								onChange={(event) =>
									field.onChange(event.target.checked)
								}
								checked={!!field.value}
								disabled={loading || confirm || form.processed}
							/>
							<Typography
								variant="body2"
								color={
									form.processed || confirm
										? 'textSecondary'
										: 'inherit'
								}
							>
								{t('sprintTest')}
							</Typography>
						</Box>
					)}
				/>
			</Grid>

			<Box pt={2} />

			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
			>
				<Controller
					name="shotTest"
					control={control}
					render={({ field }) => (
						<Box display="flex" alignItems="center">
							<Checkbox
								sx={{ pl: 0 }}
								onChange={(event) =>
									field.onChange(event.target.checked)
								}
								checked={!!field.value}
								disabled={loading || confirm || form.processed}
							/>
							<Typography
								variant="body2"
								color={
									form.processed || confirm
										? 'textSecondary'
										: 'inherit'
								}
							>
								{t('shotTest')}
							</Typography>
						</Box>
					)}
				/>
			</Grid> */}

			{confirm ? (
				<>
					<Box pt={2} />

					<Box width={1}>
						<Typography
							align="left"
							variant="caption"
							color="textSecondary"
						>
							{t('coaches')}
						</Typography>
					</Box>

					<Box pt={1} />

					<Controller
						name="trainersCount"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								fullWidth
								type="number"
								variant="outlined"
								disabled
								value={form?.trainersCount?.toString()}
							/>
						)}
					/>

					<Box pt={2} />

					<Box width={1}>
						<Typography
							align="left"
							variant="caption"
							color="textSecondary"
						>
							{t('players')}
						</Typography>
					</Box>

					<Box pt={1} />

					<Controller
						name="playersCount"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								fullWidth
								type="text"
								variant="outlined"
								disabled
								value={form?.playersCount?.toString()}
							/>
						)}
					/>
				</>
			) : null}
		</>
	);
};
