import { GraphQLError } from 'graphql';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { OldTopBar } from 'src/Components/OldTopBar';
import { FormLayout } from 'src/Form/FormLayout';
import { useCustomForm } from 'src/Form/useCustomForm';
import { useGeolocation } from 'src/Plugins/useGeolocation';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import {
	useCreateOperatorSessionMutation,
	useDeleteOperatorSessionMutation,
	useFindOperatorSessionByIdLazyQuery,
	useUpdateOperatorSessionMutation,
} from 'src/graphql/types';

import { useCreateSessionValidations } from './CreateSession.validations';
import { CreateSessionsControllers } from './CreateSessionsScreen.controllers';

import { CreateSessionFormData } from '../AdminForm';

export const CreateSessionsScreen = () => {
	const [createOperatorSessionMutation] = useCreateOperatorSessionMutation();
	const [updateOperatorSessionMutation] = useUpdateOperatorSessionMutation();
	const [deleteOperatorSessionMutation] = useDeleteOperatorSessionMutation();
	const [findOperatorSessionById, { data, loading }] =
		useFindOperatorSessionByIdLazyQuery();
	const { t } = useTranslation(['create-session', 'server-error']);
	const { form, setForm, resetForm } = useCustomForm<CreateSessionFormData>();
	const resolver = useCreateSessionValidations();
	const getGeolocation = useGeolocation();
	const formUseForm = useForm<CreateSessionFormData>({
		defaultValues: Object.assign(
			{
				club: null,
				ageGroup: '',
				fixedGoalkeepers: false,
				sprintTest: false,
				shotTest: false,
				playersCount: 0,
				trainersCount: 0,
				trainerCodes: [],
				playerCodes: [],
				matchesCount: 0,
				processed: false,
				ground: { goalWidth: 5, goalHeight: 2 },
			},
			form,
		),
		resolver,
	});

	const { handleSubmit, setValue } = formUseForm;
	const [serverErrors, setServerErrors] = useState<readonly GraphQLError[]>(
		[],
	);

	const navigation = useNavigation();
	const sessionNumber = navigation.query.get('sessionNumber') as string;
	const confirm = navigation.query.get('confirm') !== null;
	const [loadingSave, setLoadingSave] = useState(false);
	const resetFormRef = useRef<boolean>();
	const updateFromRef = useRef<boolean>();

	useEffect(() => {
		if (sessionNumber && !loading && !data) {
			findOperatorSessionById({
				variables: {
					sessionNumber: parseInt(sessionNumber),
				},
			});
		}

		if (data && !loading && sessionNumber && !updateFromRef.current) {
			const session = data?.findOperatorSessionById;

			if (session) {
				setForm({ ...form, ...session });

				setValue('ageGroup', session.ageGroup);
				setValue('club', session.club);
				setValue('fixedGoalkeepers', session.fixedGoalkeepers);
				setValue('sprintTest', session.sprintTest);
				setValue('shotTest', session.shotTest);
				setValue('playersCount', session.playersCount);
				setValue('trainersCount', session.trainersCount);
				setValue('trainerCodes', Array(session.trainersCount).fill(''));
				setValue('processed', session.processed);
				if (session.ground) setValue('ground', session.ground);
				setValue('processed', session.processed);
			} else {
				navigation.setQuery('sessionNumber', null);
			}

			updateFromRef.current = true;
		}

		if (!sessionNumber && !confirm && !resetFormRef.current) {
			resetForm();
			resetFormRef.current = true;
		}

		if (confirm && Object.keys(form).length === 0) {
			navigation.setQuery('confirm', null);
		}
	}, [
		sessionNumber,
		data,
		loading,
		confirm,
		findOperatorSessionById,
		form,
		navigation,
		resetForm,
		setForm,
		setValue,
	]);

	const onSubmitCreate = handleSubmit(async (formData) => {
		setForm({ ...form, ...formData });

		if (confirm) {
			setServerErrors([]);
			setLoadingSave(true);
			const geolocation = await getGeolocation();

			const { data, errors } = await createOperatorSessionMutation({
				variables: {
					input: {
						ageGroup: form.ageGroup,
						fixedGoalkeepers: form.fixedGoalkeepers,
						sprintTest: form.sprintTest,
						shotTest: form.shotTest,
						clubId: form.club.id as number,
						playerCodes: form.playerCodes as string[],
						trainerCodes: form.trainerCodes as string[],
						geolocation: geolocation,
						ground: {
							goalHeight: parseFloat(
								form.ground?.goalHeight?.toString() || '2',
							),
							goalWidth: parseFloat(
								form.ground?.goalWidth?.toString() || '5',
							),
						},
					},
				},
			}).catch(convertNetworkErrors);

			setLoadingSave(false);

			if (errors) setServerErrors(errors);

			if (data?.createOperatorSession.created) {
				navigation.replace(AppRoutes.ProcessSession);
			}
		} else {
			navigation.push(AppRoutes.AddCoach);
		}
	});

	const onSubmitToCoach = handleSubmit(async (formData) => {
		setForm({ ...form, ...formData });

		navigation.push(`${AppRoutes.AddCoach}?sessionNumber=${sessionNumber}`);
	});

	const onSubmitDelete = async () => {
		setServerErrors([]);
		setLoadingSave(true);

		const { data, errors } = await deleteOperatorSessionMutation({
			variables: {
				sessionNumber: parseInt(sessionNumber),
			},
		}).catch(convertNetworkErrors);

		setLoadingSave(false);

		if (errors) setServerErrors(errors);

		if (data?.deleteOperatorSession.deleted) {
			navigation.replace(AppRoutes.CurrentSessions);
		}
	};

	const onSubmitEdit = handleSubmit(async (formData) => {
		setServerErrors([]);
		setLoadingSave(true);

		const { data, errors } = await updateOperatorSessionMutation({
			variables: {
				input: {
					sessionNumber: parseInt(sessionNumber),
					ageGroup: formData.ageGroup,
					fixedGoalkeepers: formData.fixedGoalkeepers,
					sprintTest: formData.sprintTest,
					shotTest: formData.shotTest,
					clubId: formData.club.id,
					ground: {
						goalHeight: parseFloat(
							formData.ground?.goalHeight?.toString() || '2',
						),
						goalWidth: parseFloat(
							formData.ground?.goalWidth?.toString() || '5',
						),
					},
				},
			},
		}).catch(convertNetworkErrors);

		setLoadingSave(false);

		if (errors) setServerErrors(errors);

		if (data?.updateOperatorSession.updated) {
			navigation.replace(AppRoutes.CurrentSessions);
		}
	});

	const confirmButton = confirm
		? {
				onClickLeft: () => navigation.push(AppRoutes.AddPlayer),
		  }
		: {
				onClickLeft: () =>
					navigation.replace(AppRoutes.CurrentSessions),
		  };

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			width={1}
			height={1}
		>
			<OldTopBar
				title={sessionNumber ? t('title') : t('titleNewSession')}
				border
				iconLeft={<ArrowBackIcon />}
				{...confirmButton}
			/>

			<Box width={1} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
				<FormLayout
					onSubmit={onSubmitCreate}
					title={
						confirm
							? t('titleConfirm')
							: sessionNumber
							? moment(form.date).format('L')
							: t('enterSessionInfo')
					}
					button={
						<>
							{(confirm || !sessionNumber) && (
								<Button
									onClick={() => onSubmitCreate()}
									fullWidth
									variant="contained"
									color="primary"
									disabled={loading}
								>
									{confirm
										? t('confirmStart')
										: t('createSession')}
								</Button>
							)}

							{!confirm && sessionNumber && (
								<>
									<Button
										onClick={() => onSubmitToCoach()}
										fullWidth
										variant={
											form.processed
												? 'contained'
												: 'outlined'
										}
										color={
											form.processed
												? 'primary'
												: 'inherit'
										}
										disabled={loading}
									>
										{t('addCoach')}
									</Button>

									{!form.processed && (
										<>
											<Box pt={2} />

											<Button
												onClick={() => onSubmitEdit()}
												fullWidth
												variant="contained"
												color="primary"
												disabled={loading}
											>
												{t('finishEdit')}
											</Button>

											<Box pt={6} />

											<Button
												color="inherit"
												fullWidth
												variant="outlined"
												onClick={() => onSubmitDelete()}
												disabled={loading}
											>
												{t('deleteSession')}
											</Button>

											<Box pt={3} />

											<Typography
												variant="body2"
												color="inherit"
												align="center"
											>
												{t('deleteSessionText')}
											</Typography>
										</>
									)}
								</>
							)}
						</>
					}
					controls={
						<>
							<CreateSessionsControllers
								loading={!!sessionNumber && loading}
								form={formUseForm}
								edit={!!sessionNumber}
								confirm={confirm}
							/>

							{loadingSave && (
								<>
									<Box pt={3} />
									<CircularProgress color="inherit" />
								</>
							)}

							{serverErrors.map((error) => (
								<>
									<Box pt={3} />
									<Alert key={error.message} severity="error">
										{t('server-error:' + error.message) ||
											error.message}
									</Alert>
								</>
							))}
						</>
					}
				/>
			</Box>
		</Box>
	);
};
