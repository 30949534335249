import React, {
	ReactElement,
	Ref,
	forwardRef,
	useEffect,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowBackIos } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Dialog,
	Grow,
	Slide,
	SxProps,
	useMediaQuery,
} from '@mui/material';
import { SlideProps } from '@mui/material/Slide/Slide';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';

import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { OldTopBar } from 'src/Components/OldTopBar';

const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
};

const useWindowDimensions = () => {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions(),
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
};

export const SlideTransition = forwardRef(
	(
		{
			children,
			direction = 'up',
			...props
		}: TransitionProps & {
			children: ReactElement;
		} & SlideProps,
		ref: Ref<unknown>,
	) => {
		return (
			<Slide
				ref={ref}
				{...props}
				timeout={{ appear: 0, enter: 250, exit: 250 }}
				direction={direction}
				unmountOnExit
			>
				{children}
			</Slide>
		);
	},
);

export const GrowTransition = forwardRef(
	(
		{
			children,
			...props
		}: TransitionProps & {
			children: ReactElement;
		},
		ref: Ref<unknown>,
	) => {
		return (
			<Grow
				ref={ref}
				{...props}
				timeout={{ appear: 0, enter: 250, exit: 250 }}
				unmountOnExit
			>
				{children}
			</Grow>
		);
	},
);

export interface EventDialogProps {
	isOpen: boolean;
	transition: 'Grow' | 'Slide';
	title?: string;
	subTitle?: string;
	rect?: DOMRect | null;
	onClose: <TResult>(result?: TResult | Error) => void;
	useClose?: boolean;
}

export const EventDialog: React.FC<EventDialogProps> = ({
	children,
	isOpen,
	onClose,
	title,
	transition,
	rect,
	useClose = false,
}) => {
	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));

	const { t } = useTranslation(title);
	const { height } = useWindowDimensions();
	const py =
		(((rect?.y ?? height / 2) - (rect?.height ?? 0) / 2) * 100) / height;

	const sxProps: SxProps =
		transition === 'Grow'
			? {
					transformOrigin: `center ${py}%`,
					transitionProperty: 'all !important',
					opacity: isOpen ? 1 : 0,
			  }
			: {};

	return (
		<Dialog
			sx={{
				'& > .MuiDialog-container': sxProps,
			}}
			fullScreen
			open={isOpen}
			onClose={() => onClose()}
			TransitionComponent={
				transition === 'Slide' ? SlideTransition : GrowTransition
			}
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent={'stretch'}
				height={1}
				sx={
					useClose
						? {}
						: {
								backgroundImage: `url(${Background1})`,
								backgroundSize: 'cover',
						  }
				}
			>
				<Box
					flexGrow={0}
					width={1}
					height={'fit-content'}
					sx={{ borderBottom: '1px solid #252525' }}
				>
					{useClose ? (
						<OldTopBar
							iconRight={<CloseIcon />}
							onClickRight={() => onClose('back')}
							title={t(title ?? '')}
							border={false}
						/>
					) : (
						<OldTopBar
							iconLeft={<ArrowBackIos />}
							onClickLeft={() => onClose('back')}
							title={t(title ?? '')}
						/>
					)}
				</Box>
				<Box
					flex={1}
					width={1}
					display={'flex'}
					alignItems={useClose ? 'center' : 'flex-start'}
					justifyContent={'center'}
					sx={{ overflowY: 'auto', overflowX: 'hidden' }}
				>
					<Box
						flex={1}
						p={useClose ? 0 : sm ? '44px' : '22px'}
						mt={useClose ? '-86px' : 0}
						display={'flex'}
						alignItems={'flex-start'}
						justifyContent={'center'}
						maxWidth={960}
						sx={{
							'& > *:not(.fullwidth)': { maxWidth: 500, flex: 1 },
						}}
					>
						{children}
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
};
