import { forwardRef } from 'react';

import Slide, { SlideProps } from '@mui/material/Slide';

const SlideForwardRef = (direction: SlideProps['direction']) =>
	forwardRef<unknown, SlideProps>((props, ref) => {
		return <Slide direction={direction} ref={ref} {...props} />;
	});

export const SlideUp = SlideForwardRef('up');
export const SlideRight = SlideForwardRef('right');
export const SlideDown = SlideForwardRef('down');
export const SlideLeft = SlideForwardRef('left');
