import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { DinamicAvatarWithProgress } from 'src/Components/DinamicAvatarWithProgress';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import {
	ComparisonSelected,
	FoundComparisonType,
	Player,
	UserRole,
	WatchComparisonResult,
} from 'src/graphql/types';

type PlayerBoxProps = {
	player: Player;
	compareFrom?: WatchComparisonResult | null;
};

export const PlayerBox: React.FC<PlayerBoxProps> = ({
	player,
	compareFrom,
}) => {
	const navigation = useNavigation();
	const { t } = useTranslation('create-comparison');
	const size = 40;

	const selected = navigation.query.get('selected') as ComparisonSelected;
	const subFrom = navigation.query.get('subFrom');

	const onClick = () => {
		if (!player.released) return;

		if (!compareFrom) {
			return navigation.push(
				`${AppRoutes.CreateComparison}?selected=${ComparisonSelected.Player}&subFrom=${player.userId}`,
			);
		}

		if (selected === ComparisonSelected.Team) {
			navigation.push(
				`${AppRoutes.ViewComparison}?type=${FoundComparisonType.TeamVsPlayer}&sub=${player.userId}`,
			);
		} else if (selected === ComparisonSelected.Player) {
			navigation.push(
				`${AppRoutes.ViewComparison}?type=${FoundComparisonType.SelectedPlayerVsPlayer}&sub=${player.userId}&subFrom=${subFrom}`,
			);
		} else {
			navigation.push(
				`${AppRoutes.ViewComparison}?type=${FoundComparisonType.PlayerVsPlayer}&sub=${player.userId}`,
			);
		}
	};

	return (
		<Box
			display="flex"
			alignItems="center"
			py={2}
			px="20px"
			bgcolor="#151515"
			sx={{
				cursor: player.released ? 'pointer' : 'not-allowed',
				opacity: player.released ? 1 : 0.6,
			}}
			onClick={onClick}
		>
			<DinamicAvatarWithProgress
				size={size}
				score={player.score}
				role={UserRole.Player}
				iconUrl={player.avatarUrl}
				disabled={!player.released}
			/>

			<Box pl={2} />

			<Typography variant="body1">
				{`${player.firstName || t('player')} ${
					player.lastName || player.playerSessionId
				}`}
			</Typography>
		</Box>
	);
};
