import {
	PaymentElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { AppRoutes } from 'src/Router/AppRoutes';
import { useSessions } from 'src/Sessions/useSessions';
import { useShowSuccess } from 'src/Success/useShowSuccess';
import { Score, UserRole } from 'src/graphql/types';

type LocationState = {
	session: Score;
};

export const CheckoutForm: React.FC<{ clientSecret: string }> = ({
	clientSecret,
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const [loading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [checkStatus, setCheckStatus] = useState(false);
	const showSuccess = useShowSuccess();
	const { t } = useTranslation('add-session');
	const { code } = useParams<{ code: string }>();
	const location = useLocation();
	const { refetch, setSelectedRole } = useSessions();

	const locationState = location.state as LocationState;
	const session = locationState.session;

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		setLoading(true);
		setErrorMessage('');

		const { error } = await stripe.confirmPayment({
			elements,
			redirect: 'if_required',
		});

		if (error) {
			setErrorMessage(error.message || null);
		} else {
			setCheckStatus(true);
		}
	};

	useEffect(() => {
		if (!stripe) {
			return;
		}

		if (checkStatus) {
			setCheckStatus(false);
			setErrorMessage('');

			stripe
				.retrievePaymentIntent(clientSecret)
				.then(({ paymentIntent }) => {
					switch (paymentIntent?.status) {
						case 'succeeded':
							setTimeout(() => {
								setLoading(false);
								setSelectedRole(UserRole.Player);
								refetch();

								showSuccess({
									title: t('successfulPaid'),
									nextRoute: `${AppRoutes.ShareWithCoach}/${code}`,
									nextRouteState: {
										session,
									},
									duration: 3000,
								});
							}, 2000);
							break;

						case 'processing':
							setLoading(true);
							setTimeout(() => setCheckStatus(true), 1000);
							break;

						case 'canceled':
							setLoading(false);
							setErrorMessage(t('errors.paymentCanceled'));
							break;

						case 'requires_payment_method':
							setLoading(false);
							setErrorMessage(t('errors.paymentFailed'));
							break;

						default:
							setLoading(false);
							setErrorMessage(t('errors.somethingWentWrong'));
							break;
					}
				});
		}
	}, [
		stripe,
		clientSecret,
		checkStatus,
		code,
		session,
		showSuccess,
		setSelectedRole,
		refetch,
		t,
	]);

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement onReady={() => setLoaded(true)} />

			{!loaded ? (
				<Box display="flex" justifyContent="center" py={2}>
					<CircularProgress color="inherit" />
				</Box>
			) : (
				<>
					{!!errorMessage && (
						<React.Fragment key={errorMessage}>
							<Box pt={3} />

							<Alert severity="error">{errorMessage}</Alert>
						</React.Fragment>
					)}

					<Box pt={3} />

					<Box display="flex" justifyContent="center">
						{loading ? (
							<CircularProgress color="inherit" />
						) : (
							<Button
								variant="contained"
								type="submit"
								disabled={loading}
							>
								{t('pay')}
							</Button>
						)}
					</Box>

					<Box pt={1} />
				</>
			)}
		</form>
	);
};
