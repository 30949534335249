import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useCustomForm } from 'src/Form/useCustomForm';
import { ageGroups } from 'src/Screens/SessionCreator/CreateSession/ageGroups';
import { OperatorSession } from 'src/graphql/types';

export type CreateSessionsScreenControllersProps = {
	loading: boolean;
	form: UseFormReturn<OperatorSession>;
	edit?: boolean;
	confirm?: boolean;
};

export const CreateEventSessionsControllers = ({
	loading,
	form: { control },
	confirm = false,
}: CreateSessionsScreenControllersProps) => {
	const { form } = useCustomForm<OperatorSession>();
	const { t } = useTranslation('create-session');

	return (
		<>
			<Box width={1}>
				<Typography
					align="left"
					variant="caption"
					color="textSecondary"
				>
					{t('ageGroup')}
				</Typography>
			</Box>

			<Box pt={1} />

			<Controller
				name="ageGroup"
				control={control}
				render={({ field, fieldState }) => (
					<>
						<Select
							{...field}
							fullWidth
							variant="outlined"
							displayEmpty
							error={!!fieldState.error}
							disabled={loading || confirm || form.processed}
						>
							<MenuItem value="" disabled>
								{t('ageGroup')}
							</MenuItem>
							{ageGroups.map((group, index) => (
								<MenuItem key={index} value={group.value}>
									{group.name}
								</MenuItem>
							))}
						</Select>

						{fieldState.error ? (
							<Box width={1} pl="14px" pt="3px">
								<Typography variant="caption" color="error">
									{fieldState.error.message}
								</Typography>
							</Box>
						) : null}
					</>
				)}
			/>
			<Box pt={2} />

			<Box width={1}>
				<Typography
					align="left"
					variant="caption"
					color="textSecondary"
				>
					{t('goalDimensions')}
				</Typography>
			</Box>

			<Box pt={1} />
			<Grid container direction="row" spacing={2}>
				<Grid item xs>
					<Controller
						name="ground.goalWidth"
						control={control}
						render={({ field, fieldState }) => (
							<>
								<TextField
									{...field}
									fullWidth
									variant="outlined"
									label={t('width')}
									disabled={
										loading || confirm || form.processed
									}
								></TextField>

								{fieldState.error ? (
									<Box width={1} pl="14px" pt="3px">
										<Typography
											variant="caption"
											color="error"
										>
											{fieldState.error.message}
										</Typography>
									</Box>
								) : null}
							</>
						)}
					/>
				</Grid>
				<Grid item xs>
					<Controller
						name="ground.goalHeight"
						control={control}
						render={({ field, fieldState }) => (
							<>
								<TextField
									{...field}
									fullWidth
									variant="outlined"
									label={t('height')}
									disabled={
										loading || confirm || form.processed
									}
								></TextField>

								{fieldState.error ? (
									<Box width={1} pl="14px" pt="3px">
										<Typography
											variant="caption"
											color="error"
										>
											{fieldState.error.message}
										</Typography>
									</Box>
								) : null}
							</>
						)}
					/>
				</Grid>
			</Grid>

			<Box pt={2} />

			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
			>
				<Controller
					name="fixedGoalkeepers"
					control={control}
					render={({ field }) => (
						<Box display="flex" alignItems="center">
							<label
								style={{
									userSelect: 'none',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Checkbox
									sx={{ ml: -1 }}
									onChange={(event) =>
										field.onChange(event.target.checked)
									}
									checked={!!field.value}
									disabled={
										loading || confirm || form.processed
									}
								/>
								<Typography
									variant="body2"
									color={
										form.processed || confirm
											? 'textSecondary'
											: 'inherit'
									}
								>
									{t('fixedKeeper')}
								</Typography>
							</label>
						</Box>
					)}
				/>
			</Grid>

			{confirm ? (
				<>
					<Box pt={2} />

					<Box width={1}>
						<Typography
							align="left"
							variant="caption"
							color="textSecondary"
						>
							{t('coaches')}
						</Typography>
					</Box>

					<Box pt={1} />

					<Controller
						name="trainersCount"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								fullWidth
								type="number"
								variant="outlined"
								disabled
								value={form?.trainersCount?.toString()}
							/>
						)}
					/>

					<Box pt={2} />

					<Box width={1}>
						<Typography
							align="left"
							variant="caption"
							color="textSecondary"
						>
							{t('players')}
						</Typography>
					</Box>

					<Box pt={1} />

					<Controller
						name="playersCount"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								fullWidth
								type="text"
								variant="outlined"
								disabled
								value={form?.playersCount?.toString()}
							/>
						)}
					/>
				</>
			) : null}
		</>
	);
};
