import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useChangePasswordValidations = () => {
	const { t } = useTranslation('validations');

	const validationSchema = useMemo(
		() =>
			yup.object({
				currentPassword: yup.string().required(t('required')),
				password: yup
					.string()
					.required(t('required'))
					.min(8, t('min8')),
				passwordConfirmation: yup
					.string()
					.oneOf([yup.ref('password'), null], t('mustMatch'))
					.required(t('required')),
			}),
		[t],
	);

	const resolver = yupResolver(validationSchema);

	return resolver;
};
