import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { CustomAccordion } from 'src/Components/CustomAccordion';

export type TestCardProps = {
	title: string;
	playerCount: number;
	onOpen?: () => void;
};

export const TestCard: React.FC<TestCardProps> = ({
	playerCount,
	title,
	onOpen = () => {},
}) => {
	const { t } = useTranslation('process-session');
	const playerCountArray = Array.from(Array(playerCount).keys()).map(
		(n) => n + 1,
	);

	const oddPlayer = playerCountArray.filter((player) => player % 2 !== 0);
	const evenPlayer = playerCountArray.filter((player) => player % 2 === 0);

	return (
		<CustomAccordion
			onToggle={onOpen}
			title={() => (
				<Typography variant="body1" fontWeight={500}>
					{title}
				</Typography>
			)}
			content={
				<>
					<Box
						display="flex"
						justifyContent="space-evenly"
						alignItems="center"
					>
						<Box flex={1}>
							<Typography
								variant="body2"
								align="center"
								fontWeight={500}
							>
								{t('black').toUpperCase()}
							</Typography>
						</Box>

						<Box mx={2} />

						<Box flex={1}>
							<Typography
								variant="body2"
								align="center"
								fontWeight={500}
							>
								{t('white').toUpperCase()}
							</Typography>
						</Box>
					</Box>
					<Box pt={2} />
					<Box display="flex" alignItems="flex-start">
						<Box bgcolor="black" flex={1} py={1}>
							{oddPlayer.map((odd) => (
								<Typography
									key={odd}
									variant="h6"
									align="center"
								>
									{odd}
								</Typography>
							))}
						</Box>

						<Box mx={2} />

						<Box color="black" bgcolor="black" flex={1} py={1}>
							{evenPlayer.map((even) => (
								<Typography
									key={even}
									variant="h6"
									align="center"
								>
									{even}
								</Typography>
							))}
						</Box>
					</Box>
				</>
			}
		/>
	);
};
