import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { BurgerMenuIcon } from 'src/Components/Icons/BurgerMenuIcon';
import { NotificationIcon } from 'src/Components/Icons/NotificationIcon';
import { NumberBadge } from 'src/Components/NumberBadge';
import { SafeAreaTop } from 'src/Components/SafeArea';
import { useNotifications } from 'src/Notifications/useNotifications';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { NotificationsMenu } from '../Notifications/NotificationsMenu';
import { SettingsMenu } from '../Settings/SettingsMenu';

export const FloatingButtons = () => {
	const navigation = useNavigation();
	const params = useParams<{ dialog: string }>();
	const { notifications } = useNotifications();

	const unreadCount = notifications?.unreadCount || 0;

	return (
		<>
			<Box
				position="absolute"
				left="20px"
				top="20px"
				display="flex"
				flexDirection="column"
				zIndex="appBar"
			>
				<SafeAreaTop />

				<IconButton
					onClick={() => navigation.push(AppRoutes.Settings)}
					size="large"
				>
					<BurgerMenuIcon color="white" strokeWidth={1.3} />
				</IconButton>
			</Box>

			<Box
				position="absolute"
				right="20px"
				top="20px"
				display="flex"
				flexDirection="column"
				zIndex="appBar"
			>
				<SafeAreaTop />

				<NumberBadge
					badgeContent={unreadCount > 9 ? '9+' : unreadCount}
				>
					<IconButton
						onClick={() => navigation.push(AppRoutes.Notifications)}
						size="large"
					>
						<NotificationIcon color="white" strokeWidth={1.3} />
					</IconButton>
				</NumberBadge>
			</Box>
			<SettingsMenu open={params.dialog === 'settings'} />
			<NotificationsMenu open={params.dialog === 'notifications'} />
		</>
	);
};
