import { Trans, useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { KickIdLogo } from 'src/Components/Icons/KickIdLogo';
import { LanguageMenu } from 'src/Components/LanguageMenu';
import { SafeAreaBottom, SafeAreaTop } from 'src/Components/SafeArea';
import { AppRoutes } from 'src/Router/AppRoutes';
import { LocaleLink } from 'src/Router/LocaleLink';

import StartBackground from './StartBackground.jpg';

export const StartScreen = () => {
	const { t } = useTranslation('start-screen');

	return (
		<Box
			height={1}
			position="relative"
			sx={{
				backgroundImage: `url(${StartBackground})`,
				backgroundPosition: 'top center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
			}}
		>
			<Box position="absolute" right={0} p={2}>
				<SafeAreaTop />
				<LanguageMenu />
			</Box>

			<Stack height={1} alignItems="center" justifyContent="flex-end">
				<SafeAreaTop />

				<Box height={1} maxHeight="210px" />

				<Stack
					flex={1}
					spacing={2}
					maxWidth={260}
					justifyContent="center"
				>
					<Box>
						<KickIdLogo
							sx={{
								width: 1,
								height: 1,
							}}
						/>
					</Box>

					<Typography variant="h5" textAlign="center">
						<Trans
							i18nKey="title_text"
							ns="start-screen"
							components={[
								<Box
									key="no-br"
									component="span"
									whiteSpace="nowrap"
								/>,
							]}
						/>
					</Typography>
				</Stack>

				<Box pt={4} />

				<Stack spacing={4}>
					<Button
						variant="contained"
						color="primary"
						component={LocaleLink}
						to={AppRoutes.SignUp}
					>
						{t('signup_button')}
					</Button>

					<Button
						variant="text"
						color="inherit"
						component={LocaleLink}
						to={AppRoutes.Login}
					>
						{t('login_button')}
					</Button>
				</Stack>

				<Box pt={8} />

				<SafeAreaBottom />
			</Stack>
		</Box>
	);
};
