import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import { FoundComparison } from 'src/graphql/types';

import { SubScoreBodyComparison } from './SubScoreBodyComparison';
import { SubScoreTitleComparison } from './SubScoreTitleComparison';

import { chartTabs } from '../../../Scoring/PerformanceStats/StatsOverTime/chartTabs';
import { defaultSubScores } from '../../../Scoring/PerformanceStats/SubScoreCard/defaultSubScores';
import { useScrollSubScoreCard } from '../../../Scoring/PerformanceStats/SubScoreCard/useScrollSubScoreCard';

export type SubScoreComparisonProps = {
	subScore: string;
	comparison: FoundComparison | null;
};

export const SubScoreComparison: React.FC<SubScoreComparisonProps> = ({
	subScore,
	comparison,
}) => {
	const { query, setQuery } = useNavigation();
	const { loading } = useSessions();
	const [isOpen, setIsOpen] = useState(false);

	const defaultSubScore =
		defaultSubScores.find(
			(defaultSubScore) =>
				defaultSubScore.name.toUpperCase() === subScore.toUpperCase(),
		) || defaultSubScores[0];

	const selectedType = chartTabs.find(
		(chartTab) =>
			chartTab.label.toUpperCase() === query.get('skill')?.toUpperCase(),
	);

	const selected =
		!loading &&
		selectedType?.subScore.toUpperCase() ===
			defaultSubScore.name.toUpperCase();

	const { ref, onEntered } = useScrollSubScoreCard(selected, !loading);

	const selectedSubScore = comparison?.compareFrom?.subScores?.find(
		(score) =>
			score.type.toUpperCase() === defaultSubScore.name.toUpperCase(),
	);

	const selectedSubScoreYou = comparison?.compareTo?.subScores?.find(
		(score) =>
			score.type.toUpperCase() === defaultSubScore.name.toUpperCase(),
	);

	const onClose = () => {
		setIsOpen(false);
		if (selected) {
			setQuery('skill', null);
		}
	};

	useEffect(() => {
		if (selected) {
			setIsOpen(true);
		}
	}, [selected, setIsOpen]);

	return (
		<Box py={1} px="20px" ref={ref}>
			<SubScoreTitleComparison
				loading={loading}
				selected={isOpen}
				title={defaultSubScore.name}
				myValue={selectedSubScore?.value}
				yourValue={selectedSubScoreYou?.value}
				onClose={onClose}
			/>

			<SubScoreBodyComparison
				selected={isOpen}
				defaultSubScore={defaultSubScore.name}
				selectedSubScore={selectedSubScore}
				onClose={onClose}
				myValue={selectedSubScore?.value}
				yourValue={selectedSubScoreYou?.value}
				comparison={comparison}
				onEntered={onEntered}
			/>
		</Box>
	);
};
