import React from 'react';

import Box from '@mui/material/Box';

import { TopNavigationBar } from './TopNavigationBar';

export const AddSessionLayout: React.FC = ({ children }) => {
	return (
		<Box display="flex" flexDirection="column" height={1}>
			<TopNavigationBar />

			{children}
		</Box>
	);
};
