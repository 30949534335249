import { Capacitor } from '@capacitor/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { ResendToken } from 'src/Components/ResendToken';
import { SafeAreaTop } from 'src/Components/SafeArea';
import { TopBar } from 'src/Components/TopBar';
import { TextFieldForm } from 'src/Form/Inputs/TextFieldForm';
import { useVerifyLink } from 'src/Form/useVerifyLink';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useShowSuccess } from 'src/Success/useShowSuccess';
import { useResetUserPasswordMutation } from 'src/graphql/types';
import { useServerErrors } from 'src/helpers/useServerErrors';

export type ResetPasswordFormData = {
	newPassword: string;
};

export type ResetPasswordTokenObject = {
	username: string;
	email: string;
};

export const ResetPasswordScreen = () => {
	const { t } = useTranslation(['reset-password-screen']);
	const [resetUserPasswordMutation, { loading }] =
		useResetUserPasswordMutation();
	const showSuccess = useShowSuccess();
	const { query, replace } = useNavigation();
	const type = query.get('type') || '';
	const uuid = query.get('uuid') || '';
	const sub = query.get('sub') || '';
	const form = useForm<ResetPasswordFormData>({
		defaultValues: {
			newPassword: '',
		},
		resolver: yupResolver(
			yup.object({
				newPassword: yup.string().required().min(8),
			}),
		),
	});
	const { handleSubmit } = form;
	const { setServerErrors, resetServerErrors, ServerErrors } =
		useServerErrors();
	const [isTokenExpired, user] = useVerifyLink('forgot_password');

	const onSubmit = handleSubmit(async (formData) => {
		resetServerErrors();

		const { errors, data } = await resetUserPasswordMutation({
			variables: {
				input: {
					type,
					sub,
					uuid,
					password: formData.newPassword,
				},
			},
		}).catch(convertNetworkErrors);

		if (errors?.length) {
			return setServerErrors(errors);
		}

		if (data?.resetUserPassword.reset) {
			if (Capacitor.getPlatform() === 'ios') {
				SavePassword.promptDialog({
					username: user?.username || '',
					password: formData.newPassword,
				});
			}

			showSuccess({
				title: t('success_title_text'),
				subtitle: t('success_subtitle_text'),
				duration: 3000,
				nextRoute: AppRoutes.Login,
			});
		}
	});

	return (
		<FormProvider {...form}>
			<Stack
				height={1}
				alignItems="center"
				sx={{
					backgroundImage: `url(${Background1})`,
					backgroundSize: 'cover',
					overflowY: 'auto',
				}}
			>
				<SafeAreaTop />

				<TopBar
					title={t('title_text')}
					leftComponent={
						<IconButton
							color="inherit"
							size="large"
							onClick={() => {
								replace(AppRoutes.Login);
							}}
						>
							<ArrowBackIcon />
						</IconButton>
					}
				/>

				<Stack
					noValidate
					px="20px"
					alignItems="center"
					justifyContent="center"
					component="form"
					flexGrow={1}
					width={1}
					onSubmit={onSubmit}
				>
					{!isTokenExpired && !user && (
						<CircularProgress color="inherit" />
					)}
					{isTokenExpired && !user && <ResendToken />}
					{!isTokenExpired && !!user && (
						<>
							<Stack
								width={1}
								flexGrow={1}
								justifyContent="center"
								alignItems="center"
								maxWidth={500}
							>
								<Typography
									variant="h5"
									textAlign="center"
									fontSize={18}
									maxWidth={250}
								>
									{t('description_text')}
								</Typography>

								<Box pt={4} />

								<TextFieldForm
									fullWidth
									id="reset-password"
									variant="outlined"
									name="newPassword"
									type="password"
									autoComplete="new-password"
									label={t('password_placeholder')}
									disabled={loading}
									inputProps={{
										enterKeyHint: 'go',
									}}
								/>
							</Stack>

							<Box pt={4} />

							{loading && <CircularProgress color="inherit" />}

							<ServerErrors />

							<Box pt={4} />

							<Button
								color="inherit"
								variant="outlined"
								type="submit"
								disabled={loading}
							>
								{t('submit_button')}
							</Button>

							<Box pt={8} />
						</>
					)}
				</Stack>
			</Stack>
		</FormProvider>
	);
};
