import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const SafeAreaTop = styled(Box)({
	paddingTop: 'env(safe-area-inset-top)',
});

export const SafeAreaBottom = styled(Box)({
	paddingBottom: 'env(safe-area-inset-bottom)',
});
