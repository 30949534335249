import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const ArrowUpIcon: React.FC<CustomIconProps> = (props) => {
	const { color } = useCustomIcon(props);
	return (
		<SvgIcon viewBox="0 0 16 16" sx={props.sx}>
			<g transform="translate(4.072 9) rotate(-45)">
				<path
					d="M.056,0V5.06"
					transform="translate(5.233 0.036)"
					fill="none"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
					strokeLinecap="square"
					strokeMiterlimit="10"
					strokeWidth="1"
				/>
				<path
					d="M4.561.056H0"
					transform="translate(0.448 0)"
					fill="none"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
					strokeLinecap="square"
					strokeMiterlimit="10"
					strokeWidth="1"
				/>
			</g>
		</SvgIcon>
	);
};
