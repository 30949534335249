import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from 'src/Auth/useAuth';
import { AppRoutes } from 'src/Router/AppRoutes';

export const AuthorizedRoute: React.FC = () => {
	const { isAuthorized } = useAuth();
	const location = useLocation();
	const { i18n } = useTranslation();

	if (!isAuthorized) {
		return (
			<Navigate
				replace
				to={`/${i18n.language}${AppRoutes.Login}`}
				state={{
					referrer: location.pathname + location.search,
				}}
			/>
		);
	}

	return <Outlet />;
};
