import React, { useEffect } from 'react';

import CircularProgress, {
	circularProgressClasses,
} from '@mui/material/CircularProgress';

export type CircularProgressAnimationProps = {
	animate: boolean;
	maxValue: number;
	duration: number;
	size: string | number;
};

export const CircularProgressAnimation: React.FC<
	CircularProgressAnimationProps
> = (props) => {
	const { animate, maxValue, size } = props;
	const [progress, setProgress] = React.useState(0);

	useEffect(() => {
		if (animate) {
			setTimeout(() => setProgress(maxValue));
		}
	}, [animate, maxValue]);

	return (
		<>
			<CircularProgress
				variant="determinate"
				value={100}
				sx={{
					position: 'absolute',
					top: 0,
					color: '#202020',
				}}
				size={size}
				thickness={2}
			/>

			<CircularProgress
				variant="determinate"
				value={progress}
				sx={{
					position: 'absolute',
					top: 0,
					[`& .${circularProgressClasses.circleDeterminate}`]: {
						stroke: 'url(#circularGradient)',
						transition: `stroke-dashoffset ${props.duration}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
					},
				}}
				size={size}
				thickness={2}
			/>
		</>
	);
};
