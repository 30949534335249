import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { OldTopBar } from 'src/Components/OldTopBar';
import { useCustomForm } from 'src/Form/useCustomForm';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useQRScanner } from 'src/Screens/AddSession/ScanCode/useQRScanner';

import { CreateSessionFormData } from '../../SessionCreator/AdminForm';
import { useEvent } from '../useEvent';

export const ScanEventPlayerCodeScreen = () => {
	const { event } = useEvent();
	const eventRoute = (route: AppRoutes) => {
		return route.replace(':eventNumber', `${event?.eventNumber ?? 0}`);
	};

	const { t } = useTranslation('add-player');
	const navigation = useNavigation();
	const { form } = useCustomForm<CreateSessionFormData>();

	const QRScannerComponents = useQRScanner((scannedCode) => {
		localStorage.setItem('player-code', scannedCode);
		navigation.replace(eventRoute(AppRoutes.AddEventSessionPlayer));
	});

	useEffect(() => {
		if (!form.ageGroup) {
			navigation.push(
				AppRoutes.EditEventSession.replace(
					':eventNumber',
					`${event?.eventNumber ?? 0}`,
				),
			);
		}
	}, [event?.eventNumber, form.ageGroup, form.sessionNumber, navigation]);

	return (
		<Box display="flex" width={1} height={1}>
			<QRScannerComponents />

			<OldTopBar
				title={t('scanCode')}
				iconRight={<CloseIcon />}
				onClickRight={() =>
					navigation.replace(
						eventRoute(AppRoutes.AddEventSessionPlayer),
					)
				}
			/>

			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				width={1}
				flexDirection="column"
				bgcolor="none"
				overflow="hidden"
			>
				<Box
					border="1px solid"
					borderColor="primary.main"
					width={280}
					height={280}
					sx={{ outline: '10000px solid rgba(0,0,0, 0.9)' }}
				></Box>
				<Box width={280} padding={2} textAlign="center">
					<Typography variant="body2">{t('hint')}</Typography>
				</Box>
			</Box>
		</Box>
	);
};
