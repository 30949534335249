import { ApolloProvider } from '@apollo/client';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { apolloClient } from './Apollo/ApolloClient';
import { AuthProvider } from './Auth/AuthProvider';
import { CircularGradient } from './Components/CircularGradient';
import './Form/validations';
import Router from './Router/Router';
import { SessionProvider } from './Sessions/SessionProvider';
import { SuccessProvider } from './Success/SuccessProvider';
import { theme } from './Theme/theme';
import { WarningProvider } from './Warning/WarningProvider';
import './i18n/i18next';

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<CircularGradient />
			<ApolloProvider client={apolloClient}>
				<BrowserRouter>
					<Suspense fallback={null}>
						<AuthProvider>
							<SessionProvider>
								<SuccessProvider>
									<WarningProvider>
										<Router />
									</WarningProvider>
								</SuccessProvider>
							</SessionProvider>
						</AuthProvider>
					</Suspense>
				</BrowserRouter>
			</ApolloProvider>
		</ThemeProvider>
	);
};

export default App;
