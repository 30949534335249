import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LanguageIcon from '@mui/icons-material/Language';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu, { menuClasses } from '@mui/material/Menu';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { useNavigation } from 'src/Router/useNavigation';
import { supportedLanguages } from 'src/i18n/i18next';

const StyledMenu = styled(Menu)(({ theme }) => ({
	[`& .${menuClasses.list}`]: {
		border: '1px solid rgba(255, 255, 255, 0.16)',
		borderBottom: 0,
		borderRadius: 0,
		padding: 0,
	},

	[`& .${menuItemClasses.root}`]: {
		padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
		borderBottom: '1px solid rgba(255, 255, 255, 0.16)',
		justifyContent: 'center',
		'&.Mui-selected': {
			backgroundColor: '#151515',
		},
	},
}));

export type LanguageMenuProps = {
	showIcon?: boolean;
};

export const LanguageMenu: React.FC<LanguageMenuProps> = ({
	showIcon = true,
}) => {
	const { t, i18n } = useTranslation('translation');
	const navigation = useNavigation();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button onClick={handleClick} color="inherit">
				{!!showIcon && (
					<>
						<LanguageIcon sx={{ fontSize: '1rem' }} />
						<Box pl={1} />
					</>
				)}
				{t('languages.' + i18n.language)}
			</Button>

			<StyledMenu
				open={openMenu}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{supportedLanguages.map((lang) => (
					<MenuItem
						key={lang}
						value={lang}
						selected={lang === i18n.language}
						onClick={() => {
							navigation.changeLanguage(lang);
							handleClose();
						}}
					>
						<Typography variant="button">
							{t('languages.' + lang)}
						</Typography>
					</MenuItem>
				))}
			</StyledMenu>
		</>
	);
};
