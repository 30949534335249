import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { useAuth } from 'src/Auth/useAuth';
import { TickIcon } from 'src/Components/Icons/TickIcon';
import { UserIcon } from 'src/Components/Icons/UserIcon';
import { ImageDecoration } from 'src/Components/ImageDecoration';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import { UserRole } from 'src/graphql/types';

// import { TeamIcon } from 'src/Components/Icons/TeamIcon';

export const UserSection = () => {
	const { user } = useAuth();
	const { lastSession, loading, isVerified, selectedRole } = useSessions();
	const navigation = useNavigation();
	const { t } = useTranslation('scoring');

	return (
		<Grid container alignItems="center" direction="column">
			<Grid
				item
				onClick={() => navigation.push(AppRoutes.ImagesDialog)}
				sx={{ cursor: 'pointer' }}
				position="relative"
			>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					position="relative"
				>
					<ImageDecoration
						width={48}
						height={72}
						src={user?.avatarUrl}
						fill="#00000030"
					/>

					<Box position="absolute">
						{!user ? (
							<Skeleton
								variant="circular"
								width={32}
								height={32}
							/>
						) : !user.avatarUrl ? (
							<UserIcon color="white" />
						) : null}
					</Box>
				</Box>

				{/* TODO Change when we have the club logos

				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					position="absolute"
					bottom={0}
					right={-13}
				>
					<ImageDecoration
						width={26}
						height={39}
						src={lastSession?.club?.logoUrl}
						strokeWidth={2}
						fill="#000"
					/>

					<Box position="absolute">
						{!user || loading ? (
							<Skeleton
								variant="circular"
								width={14}
								height={14}
							/>
						) : !lastSession?.club?.logoUrl ? (
							<TeamIcon color="white" sx={{ fontSize: 14 }} />
						) : null}
					</Box>
				</Box> */}
			</Grid>
			<Grid
				item
				onClick={() => navigation.push(AppRoutes.ImagesDialog)}
				sx={{ cursor: 'pointer' }}
			>
				<Box display="flex" mt={1} alignItems="center">
					<Typography variant="subtitle1" lineHeight={1}>
						{!user ? (
							<Skeleton variant="text" width={100} />
						) : (
							user.firstName
						)}
					</Typography>

					{selectedRole === UserRole.Trainer && (
						<>
							<Box pl={0.5} />
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
								height={16}
								borderRadius="14px"
								px="4px"
								bgcolor="text.secondary"
							>
								<Typography
									variant="overline"
									color="secondary"
								>
									{t('coach')}
								</Typography>
							</Box>
						</>
					)}

					{isVerified ? (
						<Box
							ml="4px"
							display="flex"
							alignItems="center"
							justifyContent="center"
							width={16}
							height={16}
							borderRadius="50%"
							pb="2px"
							bgcolor="primary.main"
						>
							<TickIcon color="black" sx={{ fontSize: 9 }} />
						</Box>
					) : null}
				</Box>
			</Grid>

			{!user || loading ? <Skeleton variant="text" width={100} /> : null}

			{!loading && user ? (
				<Grid
					item
					onClick={() => navigation.push(AppRoutes.ImagesDialog)}
					sx={{ cursor: 'pointer' }}
				>
					<Typography
						variant="caption"
						color="textSecondary"
						lineHeight={1}
					>
						{lastSession?.club?.name || t('noClub')}
						{lastSession?.ageGroupValue || t('noYouth')}
					</Typography>
				</Grid>
			) : null}
		</Grid>
	);
};
