import React from 'react';

import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

export type PaymentMethodProps = {
	checked: boolean;
	onClick: () => void;
	text: string;
	Image: JSX.Element;
	Content: JSX.Element;
};

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
	checked,
	onClick,
	text,
	Image,
	Content,
}) => {
	return (
		<Box
			p={2}
			onClick={onClick}
			sx={{
				borderRadius: 2,
				borderWidth: 1,
				borderStyle: 'solid',
				borderColor: checked ? 'white' : '#252525',
			}}
		>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Box display="flex" alignItems="center">
					<Radio
						color="default"
						checked={checked}
						sx={{ p: 0, pr: 2 }}
					/>
					<Typography variant="body1">{text}</Typography>
				</Box>

				{Image}
			</Box>

			{checked ? <Box pt={2}>{Content}</Box> : null}
		</Box>
	);
};
