import { useContext } from 'react';

import { WatchComparisonsContext } from './WatchComparisonsContext';

export const useWatchComparisons = () => {
	const context = useContext(WatchComparisonsContext);

	if (!context)
		throw new Error(
			'WatchComparisonsContext must be used with WatchComparisonsProvider!',
		);

	return context;
};
