import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { SafeAreaBottom, SafeAreaTop } from 'src/Components/SafeArea';
import { TopBar } from 'src/Components/TopBar';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { ManualCodeForm } from './ManualCodeForm';

export type ManualCodeFormValues = {
	code: string;
};

export const ManualCodeScreen = () => {
	const { t } = useTranslation('add-session');
	const form = useForm<ManualCodeFormValues>({
		defaultValues: {
			code: '',
		},
		resolver: yupResolver(
			yup.object({
				code: yup.string().uppercase().required().min(8).max(8),
			}),
		),
	});

	const navigation = useNavigation();

	return (
		<Stack height={1}>
			<SafeAreaTop />

			<TopBar
				title={t('title')}
				rigthComponent={
					<IconButton
						onClick={() => navigation.goBackOrGoTo(AppRoutes.Start)}
						size="large"
					>
						<CloseIcon />
					</IconButton>
				}
			/>

			<FormProvider {...form}>
				<ManualCodeForm />
			</FormProvider>

			<SafeAreaBottom />
		</Stack>
	);
};
