import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { CheckMarkIcon } from 'src/Components/Icons/CheckMarkIcon';
import { CloseIcon } from 'src/Components/Icons/CloseIcon';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { OperatorSession } from 'src/graphql/types';

const Icon = (props: { active: boolean }) =>
	props.active ? <CheckMarkIcon /> : <CloseIcon color="red" />;

export type DetailsCardProps = {
	session: OperatorSession;
};

export const DetailsCard: React.FC<DetailsCardProps> = ({ session }) => {
	const { t } = useTranslation('current-session');
	const navigation = useNavigation();

	return (
		<Grid container spacing={3}>
			<Grid item xs={6}>
				<Typography variant="h6">{session.playersCount}</Typography>
				<Typography variant="body2" color="textSecondary">
					{t('playerNumber')}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="h6">{session.trainersCount}</Typography>
				<Typography variant="body2" color="textSecondary">
					{t('coachesNumber')}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="h6">{session.matchesCount}</Typography>
				<Typography variant="body2" color="textSecondary">
					{t('matchesNumber')}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="h6">
					<Icon active={session.sprintTest} />
				</Typography>
				<Typography variant="body2" color="textSecondary">
					{t('sprintTest')}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="h6">
					<Icon active={session.shotTest} />
				</Typography>
				<Typography variant="body2" color="textSecondary">
					{t('shotTest')}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="h6">
					<Icon active={session.processed} />
				</Typography>
				<Typography variant="body2" color="textSecondary">
					{t('processed')}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="h6">
					<Icon active={session.scored} />
				</Typography>
				<Typography variant="body2" color="textSecondary">
					{t('scored')}
				</Typography>
			</Grid>
			<Grid container item spacing={3}>
				{!session.processed && (
					<Grid item xs>
						<Button
							color="inherit"
							variant="outlined"
							fullWidth
							onClick={() =>
								navigation.push(
									`${AppRoutes.ProcessSession}?sessionNumber=${session.sessionNumber}`,
								)
							}
						>
							{t('processSession')}
						</Button>
					</Grid>
				)}
				<Grid item xs>
					<Button
						color="inherit"
						variant="outlined"
						fullWidth
						onClick={() =>
							navigation.push(
								`${AppRoutes.EditSession}?sessionNumber=${session.sessionNumber}`,
							)
						}
					>
						{t('editSession')}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};
