import moment from 'moment';

import { USER_LEGAL_AGE } from 'src/defaults';
import { SeparatedDate } from 'src/graphql/types';

export const checkIfOlderThan = (
	birthday: SeparatedDate | null,
	minYears: number = USER_LEGAL_AGE,
) => {
	if (!birthday?.day || !birthday?.month || !birthday?.year) {
		return false;
	}

	const today = moment();
	const date = moment(`${birthday.year}-${birthday.month}-${birthday.day}`);
	const minBirthdate = today.subtract(minYears, 'years');

	return date.isBefore(minBirthdate);
};
