import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';

import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { CircularProgressAnimation } from '../../../../Components/CircularProgressAnimation';

export const SelfAssessmentDialog = () => {
	const { t } = useTranslation('self-assessment-dialog');

	const navigation = useNavigation();
	const size = 48;
	const score = 75;

	return (
		<Stack
			noValidate
			component="form"
			bgcolor="white"
			color="black"
			pt={4}
			flexGrow={1}
		>
			<Box px={2}>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
				>
					<Box
						position="relative"
						width={size}
						height={size}
						borderRadius="50%"
						bgcolor="black"
						sx={{
							'& > span:first-of-type': {
								width: '50px !important',
								height: '50px !important',
								margin: '-1px',
							},
						}}
					>
						<CircularProgressAnimation
							size={size}
							animate={true}
							duration={2_000}
							maxValue={score}
						/>

						<Box
							position="absolute"
							width={size}
							height={size}
							top={0}
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<Typography variant="h6" color={'white'}>
								{score}
							</Typography>
						</Box>
					</Box>

					<Box pt={4} />

					<Typography variant="body1" textAlign="center">
						{t('title_text')}
					</Typography>

					<Box pt={3} />

					<Typography
						variant="body2"
						color="textSecondary"
						textAlign="center"
					>
						{t('description_text')}
					</Typography>

					<Box pt={4} />
				</Box>
			</Box>

			<Box height="1px" bgcolor="#E5E5E5" />

			<Button
				sx={{ p: 2 }}
				size="small"
				color="inherit"
				onClick={() =>
					navigation.replace(
						`${AppRoutes.Scoring}?action=self-assessment`,
					)
				}
			>
				{t('confirm_button')}
			</Button>
		</Stack>
	);
};
