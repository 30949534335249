import PullToRefreshJs from 'pulltorefreshjs';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledPullToRefresh = styled(Box)(({ theme }) => ({
	'.ptr--ptr': {
		position: 'absolute',
		backgroundImage: 'linear-gradient(180deg, #46FF44, #0BFF8A)',
		borderBottomLeftRadius: '50%',
		borderBottomRightRadius: '50%',
		zIndex: theme.zIndex.tooltip,
	},
	'.ptr--content': {
		paddingBottom: 5,
	},
	'.ptr--text, .ptr--icon': {
		color: 'black !important',
		opacity: 0.8,
		fontSize: '14px !important',
		fontFamily: 'Inter !important',
		fontWeight: 400,
	},
}));

export type PullToRefreshProps = {
	onRefresh: () => void;
	scrollableRef: React.MutableRefObject<HTMLDivElement | undefined>;
	enabled?: boolean;
};

export const PullToRefresh: React.FC<PullToRefreshProps> = ({
	onRefresh,
	enabled = true,
	scrollableRef,
}) => {
	const { t } = useTranslation('pull-to-refresh');
	const scrollRef = useRef<HTMLDivElement | undefined>();

	useEffect(() => {
		if (scrollRef.current) {
			const instance = PullToRefreshJs.init({
				mainElement: scrollRef.current as any,
				triggerElement: 'body',
				onRefresh,
				distMax: 75,
				distReload: 75,
				refreshTimeout: 300,
				instructionsPullToRefresh: t('pullToRefresh'),
				instructionsReleaseToRefresh: t('releaseToRefresh'),
				instructionsRefreshing: t('refreshing'),
				shouldPullToRefresh: () =>
					enabled && !scrollableRef.current?.scrollTop,
			});
			return () => instance.destroy();
		}
	}, [enabled, t, onRefresh, scrollableRef]);

	return (
		<StyledPullToRefresh position="relative" width={1}>
			<Box ref={scrollRef} />
		</StyledPullToRefresh>
	);
};
