import _ from 'lodash';
import React from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

type OldTopBarProps = {
	title?: string;
	subTitle?: string;
	iconLeft?: JSX.Element;
	iconRight?: JSX.Element;
	onClickLeft?: any;
	onClickRight?: any;
	colorLeft?: 'inherit' | 'primary' | 'secondary' | 'default';
	colorRight?: 'inherit' | 'primary' | 'secondary' | 'default';
	border?: boolean;
};
export const OldTopBar: React.FC<OldTopBarProps> = ({
	iconLeft,
	iconRight,
	title,
	subTitle,
	onClickLeft,
	onClickRight,
	colorLeft = 'inherit',
	colorRight = 'inherit',
	border,
}) => {
	return (
		<>
			<Box
				zIndex={1}
				position="fixed"
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				p="20px"
				pt="max(env(safe-area-inset-top), 20px)"
				width={1}
				borderBottom={border ? '1px solid #252525' : 0}
			>
				<Box height={46} width={46} onClick={onClickLeft}>
					<IconButton color={colorLeft} size="large">
						{iconLeft}
					</IconButton>
				</Box>
				<Box flexGrow={1}>
					<Stack>
						<Typography
							variant="subtitle1"
							color="inherit"
							align="center"
							whiteSpace={'nowrap'}
						>
							{title}
						</Typography>
						{!_.isEmpty(subTitle) ? (
							<Typography
								variant="h6"
								fontWeight={'bold'}
								color="inherit"
								align="center"
								whiteSpace={'nowrap'}
							>
								{subTitle}
							</Typography>
						) : null}
					</Stack>
				</Box>
				<Box height={46} width={46} onClick={onClickRight}>
					<IconButton color={colorRight} size="large">
						{iconRight}
					</IconButton>
				</Box>
			</Box>
			{!_.isEmpty(subTitle) ? (
				<Box pt="max(calc(env(safe-area-inset-top) + 66px), 108px)" />
			) : (
				<Box pt="max(calc(env(safe-area-inset-top) + 66px), 86px)" />
			)}
		</>
	);
};
