import React from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import { ArrowRightIcon } from 'src/Components/Icons/ArrowRightIcon';
import { UserIcon } from 'src/Components/Icons/UserIcon';
import { SubScorePlayers } from 'src/graphql/types';

export type BestAndWorstSectionProps = {
	subScorePlayers?: SubScorePlayers[] | null;
	color: string;
	text: string;
};

export const BestAndWorstSection: React.FC<BestAndWorstSectionProps> = ({
	subScorePlayers,
	color,
	text,
}) => {
	const size = 48;

	return (
		<>
			<Box display="flex">
				{subScorePlayers?.map((player, index) =>
					player.avatarUrl ? (
						<Avatar
							key={player.playerSessionId}
							src={player.avatarUrl || ''}
							sx={{
								width: size,
								height: size,
								marginLeft: index > 0 ? '-14px' : 0,
							}}
						/>
					) : (
						<Avatar
							key={player.playerSessionId}
							sx={{
								width: size,
								height: size,
								backgroundColor: '#151515',
								border: '1px solid #454545',
								marginLeft: index > 0 ? '-14px' : 0,
							}}
						>
							<UserIcon
								color="white"
								strokeWidth={2}
								sx={{ fontSize: 14 }}
							/>
						</Avatar>
					),
				)}
			</Box>
			<Box pt={1} />
			<Box display="flex" alignItems="center">
				<Typography variant="body2" color={color as 'primary'}>
					{text}
				</Typography>
				{/* <ArrowRightIcon
					color="white"
					sx={{ fontSize: 14, marginLeft: '4px' }}
				></ArrowRightIcon> */}
			</Box>
		</>
	);
};
