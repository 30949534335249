import { useEffect } from 'react';

export const usePreventReload = () => {
	useEffect(() => {
		const onUnload = (event: BeforeUnloadEvent) => {
			event.preventDefault();
			event.returnValue = '';
		};

		window.addEventListener('beforeunload', onUnload);

		return () => {
			window.removeEventListener('beforeunload', onUnload);
		};
	}, []);
};
