import React from 'react';
import { useSwipeable } from 'react-swipeable';

import Box from '@mui/material/Box';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Theme, styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';

import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { ArrowDownIcon } from 'src/Components/Icons/ArrowDownIcon';
import { SlideUp } from 'src/Components/Transitions/Slide';
import { useNavigation } from 'src/Router/useNavigation';

import { ArrowLeftIcon } from './Icons/ArrowLeftIcon';

const StyledDialog = styled(Dialog)(() => ({
	left: '-1px !important',
	right: '-1px !important',

	[`& .${dialogClasses.container}`]: {
		alignItems: 'flex-end',
	},
	[`& .${dialogClasses.paper}`]: {
		height: 'calc(100% - 52px - env(safe-area-inset-top))',
		border: '1px solid #252525',
		borderBottom: 0,
		borderTopLeftRadius: 30,
		borderTopRightRadius: 30,
		backgroundImage: `url(${Background1})`,
		backgroundSize: 'cover',
		backgroundColor: 'black',
	},
}));

export type CustomDialogProps = {
	goBack: boolean;
	onClose?: () => void;
	open?: boolean;
	sx?: SxProps<Theme>;
	title: string;
};

export const CustomDialog: React.FC<CustomDialogProps> = ({
	children,
	goBack = false,
	onClose,
	open = false,
	sx = {},
	title,
}) => {
	const navigation = useNavigation();
	const swipeable = useSwipeable({
		onSwipedDown: () => onClose && onClose(),
	});

	return (
		<StyledDialog
			open={open}
			onClose={onClose}
			fullScreen
			TransitionComponent={SlideUp}
			sx={sx}
		>
			<DialogTitle
				sx={{
					p: '20px',
				}}
				{...swipeable}
			>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					{!goBack ? (
						<Box className={'MuiSpacer'} width={50} />
					) : (
						<IconButton
							onClick={() => navigation.goBack()}
							size="large"
						>
							<ArrowLeftIcon color="white" />
						</IconButton>
					)}
					<Typography variant="subtitle1" align="center">
						{title}
					</Typography>
					<IconButton
						onClick={() => onClose && onClose()}
						size="large"
					>
						<ArrowDownIcon color="white" />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent sx={{ p: 0 }}>{children}</DialogContent>
		</StyledDialog>
	);
};
