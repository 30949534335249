import { Capacitor } from '@capacitor/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { GraphQLError } from 'graphql';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { useAuth } from 'src/Auth/useAuth';
import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { OldTopBar } from 'src/Components/OldTopBar';
import { FormLayout } from 'src/Form/FormLayout';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useShowSuccess } from 'src/Success/useShowSuccess';
import { useUpdateUserPasswordMutation } from 'src/graphql/types';

import { ChangePasswordController } from './ChangePassword.controllers';
import { useChangePasswordValidations } from './ChangePassword.validations';

export type ChangePasswordFormData = {
	currentPassword: string;
	password: string;
	passwordConfirmation: string;
};

export const ChangePasswordScreen = () => {
	const { t } = useTranslation(['change-password-screen', 'server-error']);
	const { user } = useAuth();
	const [changePasswordMutation] = useUpdateUserPasswordMutation();
	const resolver = useChangePasswordValidations();
	const form = useForm<ChangePasswordFormData>({
		defaultValues: {
			currentPassword: '',
			password: '',
			passwordConfirmation: '',
		},
		resolver,
	});
	const navigation = useNavigation();
	const [loading, setLoading] = useState(false);
	const showSuccess = useShowSuccess();

	const { handleSubmit } = form;
	const [serverErrors, setServerErrors] = useState<readonly GraphQLError[]>(
		[],
	);

	const onSubmit = handleSubmit(async (formData) => {
		setServerErrors([]);
		setLoading(true);

		const { errors } = await changePasswordMutation({
			variables: {
				input: {
					currentPassword: formData.currentPassword,
					password: formData.password,
					passwordConfirmation: formData.passwordConfirmation,
				},
			},
		}).catch(convertNetworkErrors);

		setLoading(false);

		if (!errors) {
			showSuccess({
				title: t('successTitle'),
				subtitle: t('successMessage'),
				nextRoute: AppRoutes.Settings,
				duration: 3000,
				onClose: () => {
					if (Capacitor.getPlatform() === 'ios') {
						SavePassword.promptDialog({
							username: user?.username || '',
							password: formData.password,
						});
					}
				},
			});
		}

		if (errors) {
			const invalidError = errors.find(
				(error) => error.message === 'InvalidPassword',
			);

			if (invalidError) {
				form.setError('currentPassword', {
					message: t(invalidError.message),
				});
			}

			const alreadyUsedError = errors.find(
				(error) => error.message === 'PasswordAlreadyUsed',
			);

			if (alreadyUsedError) {
				form.setError('password', {
					message: t(alreadyUsedError.message),
				});
			}

			if (!invalidError && !alreadyUsedError) {
				setServerErrors(errors);
			}
		}
	});

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			height={1}
			sx={{
				backgroundImage: `url(${Background1})`,
				backgroundSize: 'cover',
			}}
		>
			<OldTopBar
				onClickLeft={() => navigation.goBack()}
				iconLeft={<ArrowBackIcon />}
			/>

			<FormLayout
				onSubmit={onSubmit}
				title={t('title')}
				controls={
					<>
						<ChangePasswordController
							form={form}
							disabled={loading}
						/>

						<Box pt={4} />

						{loading && <CircularProgress color="inherit" />}

						{serverErrors.map((error) => (
							<Alert key={error.message} severity="error">
								{t('server-error:' + error.message) ||
									error.message}
							</Alert>
						))}
					</>
				}
				button={
					<Button
						color="inherit"
						variant="outlined"
						type="submit"
						disabled={loading}
					>
						{t('submit')}
					</Button>
				}
			/>
		</Box>
	);
};
