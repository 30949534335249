import { CSSProperties } from 'react';
import {
	VictoryArea,
	VictoryChart,
	VictoryContainer,
	VictoryLabel,
	VictoryPolarAxis,
	VictoryTheme,
} from 'victory';

import { FoundComparison } from 'src/graphql/types';

export type PolarPlayerGraphProps = {
	score?: number;
	comparison: FoundComparison | null;
};

export const polarStyles: { [key: string]: CSSProperties } = {
	container: {
		pointerEvents: 'auto',
		touchAction: 'auto',
		maxWidth: 500,
	},
};

export const PolarPlayerGraph: React.FC<PolarPlayerGraphProps> = ({
	comparison,
}) => {
	const skills = [
		'Control',
		'Impact',
		'Passing',
		'Physical',
		'Dribbling',
		'Defense',
		'Shot',
		'Pace',
	].filter(
		(skill) =>
			comparison?.compareFrom?.subScores?.some(
				(subScore) =>
					subScore.type === skill && subScore.value !== null,
			) &&
			comparison?.compareTo?.subScores?.some(
				(subScore) =>
					subScore.type === skill && subScore.value !== null,
			),
	);

	const step = 360 / skills.length;

	const tickValues = skills.map((value, index) => {
		return index * step;
	});

	const differences = skills.map(
		(skill) =>
			comparison?.difference?.find((subScore) => subScore.type === skill)
				?.value || 0,
	);

	const newData1 = skills.map((skill, index) => {
		const element = comparison?.compareTo?.subScores?.find(
			(subScore) => subScore.type === skill,
		);
		return { x: step * index, y: element?.value };
	});

	const newData2 = skills.map((skill, index) => {
		const element = comparison?.compareFrom?.subScores?.find(
			(subScore) => subScore.type === skill,
		);
		return { x: step * index, y: element?.value };
	});

	return (
		<>
			<VictoryChart
				width={360}
				height={360}
				polar
				padding={60}
				containerComponent={
					<VictoryContainer
						responsive={true}
						style={polarStyles.container}
					/>
				}
			>
				<VictoryPolarAxis
					tickValues={tickValues}
					labelPlacement="perpendicular"
					standalone={false}
					theme={VictoryTheme.material}
					style={{
						axis: { stroke: '#202020' },
						ticks: { stroke: 'transparent' },
						tickLabels: {
							fontFamily: 'Inter',
							fill: '#777777',
							fontSize: 10,
							padding: 13,
						},
					}}
					animate={{
						duration: 1000,
						easing: 'bounce',
					}}
					tickFormat={skills}
				/>
				<VictoryPolarAxis
					tickValues={tickValues}
					labelPlacement="vertical"
					standalone={false}
					theme={VictoryTheme.material}
					style={{
						axis: { stroke: 'transparent' },
						ticks: { stroke: 'transparent' },
						tickLabels: {
							fill: ({ text }: any) =>
								Math.sign(text) === 1
									? '#43FF47'
									: Math.sign(text) === 0
									? 'white'
									: '#FFBF28',
							fontFamily: 'Inter',
							fontSize: 24,
							fontWeight: 600,
							padding: 30,
						},
					}}
					tickFormat={differences}
				/>

				<VictoryPolarAxis
					dependentAxis
					axisAngle={90}
					tickValues={[0, 25, 50, 75, 100]}
					labelPlacement="perpendicular"
					theme={VictoryTheme.material}
					standalone={false}
					tickLabelComponent={<VictoryLabel />}
					style={{
						grid: { stroke: '#202020' },
						axis: { stroke: '#202020' },
						ticks: { stroke: 'transparent' },
						tickLabels: {
							fontFamily: 'Inter',
							fill: '#777777',
							fontSize: 10,
						},
					}}
				/>

				<VictoryArea
					key={2}
					data={newData1}
					style={{
						data: {
							stroke: '#FFBF28',
							strokeWidth: 1,
							fill: '#FFBF28',
							fillOpacity: 0.5,
						},
					}}
					animate={{
						duration: 1000,
						easing: 'circle',
					}}
				/>

				<VictoryArea
					key={1}
					data={newData2}
					style={{
						data: {
							stroke: '#43FF47',
							strokeWidth: 1,
							fill: '#43FF47',
							fillOpacity: 0.5,
						},
					}}
					animate={{
						duration: 1000,
						easing: 'circle',
					}}
				/>
			</VictoryChart>
		</>
	);
};
