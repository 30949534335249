import MobileStepper, {
	mobileStepperClasses,
} from '@mui/material/MobileStepper';
import { styled } from '@mui/material/styles';

export const FloatingStepper = styled(MobileStepper)(({ theme }) => ({
	backgroundColor: 'transparent',
	justifyContent: 'center',
	paddingBottom: theme.spacing(3),
	opacity: 0.8,

	[`& .${mobileStepperClasses.dots}`]: {
		backgroundColor: '#171717',
		padding: 11,
		borderRadius: 16,
	},

	[`& .${mobileStepperClasses.dot}`]: {
		width: 10,
		height: 10,
		backgroundColor: '#252525',
	},

	[`& .${mobileStepperClasses.dotActive}`]: {
		backgroundColor: theme.palette.primary.main,
	},
}));
