import { FoundComparisonType } from 'src/graphql/types';

export const isPreviousType = (type?: FoundComparisonType) =>
	type &&
	[
		FoundComparisonType.PlayerVsPrev,
		FoundComparisonType.TeamVsPrev,
		FoundComparisonType.SelectedPlayerVsPrev,
	].includes(type);

export const isNotPreviousType = (type?: FoundComparisonType) =>
	type &&
	[
		FoundComparisonType.PlayerVsPlayer,
		FoundComparisonType.PlayerVsTeam,
		FoundComparisonType.TeamVsPlayer,
		FoundComparisonType.SelectedPlayerVsPlayer,
		FoundComparisonType.SelectedPlayerVsTeam,
	].includes(type);

export const isFromTeam = (type?: FoundComparisonType) =>
	type &&
	[FoundComparisonType.TeamVsPrev, FoundComparisonType.TeamVsPlayer].includes(
		type,
	);

export const isToTeam = (type?: FoundComparisonType) =>
	type &&
	[
		FoundComparisonType.TeamVsPrev,
		FoundComparisonType.PlayerVsTeam,
		FoundComparisonType.SelectedPlayerVsTeam,
	].includes(type);

export const isToSamePlayer = (type?: FoundComparisonType) =>
	type && [FoundComparisonType.PlayerVsPrev].includes(type);

export const isFromPlayer = (type?: FoundComparisonType) =>
	type &&
	[
		FoundComparisonType.PlayerVsPrev,
		FoundComparisonType.PlayerVsPlayer,
		FoundComparisonType.PlayerVsTeam,
	].includes(type);

export const isSameTeamOrSamePlayer = (type?: FoundComparisonType) =>
	type &&
	[
		FoundComparisonType.TeamVsPrev,
		FoundComparisonType.TeamVsPlayer,
		FoundComparisonType.PlayerVsPrev,
		FoundComparisonType.PlayerVsTeam,
	].includes(type);
