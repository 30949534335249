import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CircularProgress, FormControl, useMediaQuery } from '@mui/material';
import Autocomplete, {
	autocompleteClasses,
	createFilterOptions,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { useAuth } from 'src/Auth/useAuth';
import { useCustomForm } from 'src/Form/useCustomForm';
import {
	Club,
	ScoutingEvent,
	User,
	UserRole,
	useClubsQuery,
	useSearchPlayersQuery,
	useSearchTrainersQuery,
} from 'src/graphql/types';

import { useSearchUsersQuery } from '../../../redux/rtk-query';
import { CheckboxController } from '../../Events/Components/CheckboxController';
import { DateTimePickerController } from '../../Events/Components/DateTimePickerController';
import { VirtualListController } from '../../Events/Components/VirtualListController';
import { CreateEventForm } from '../../Events/Views/CreateEvent';
import { AddClubDialog } from '../../SessionCreator/CreateSession/AddClubDialog';

export type CreateEventsScreenControllersProps = {
	loading: boolean;
	form: UseFormReturn<CreateEventForm>;
	edit?: boolean;
	event?: ScoutingEvent;
};

const filterOptions = createFilterOptions({
	matchFrom: 'any',
	stringify: (option: ClubWithValue) => option?.name || '',
	limit: 50,
	ignoreCase: true,
	trim: true,
});

let timeout: NodeJS.Timeout;
let usersTimeout: NodeJS.Timeout;

type ClubWithValue = Club & { value?: string };

export const CreateEventsControllers = ({
	loading,
	form: { control, setValue },
	edit = false,
	event,
}: CreateEventsScreenControllersProps) => {
	const { form, setForm } = useCustomForm<CreateEventForm>();
	const [addClubDialogOpen, toggleClubDialog] = useState(false);
	const [clubName, setClubName] = useState('');

	const [playersSearching, setPlayersSearching] = useState(false);
	const [players, setPlayers] = useState<User[]>([]);

	const [trainersSearching, setTrainersSearching] = useState(false);
	const [trainers, setTrainers] = useState<User[]>([]);

	const { user } = useAuth();

	const { data, loading: clubsLoading, refetch } = useClubsQuery();

	const { data: searchUsersData, isLoading: isSearchUsersLoading } =
		useSearchUsersQuery({
			roles: [UserRole.ExternalSource],
			externalSource: '',
		});

	const { t } = useTranslation('create-event');

	const onCloseDialog = () => {
		toggleClubDialog(false);
	};

	const onCreate = (club: Club) => {
		toggleClubDialog(false);

		setValue('club', club);
	};

	const {
		refetch: playersReFetch,
		loading: playersLoading,
		data: playersData,
	} = useSearchPlayersQuery({
		variables: {
			externalSource: edit ? _.first(event?.externalSources) ?? '' : '',
		},
	});

	useEffect(() => {
		const newPlayers = playersData?.searchPlayers ?? [];
		setPlayers(newPlayers);
		setValue('players', newPlayers);
		setPlayersSearching(false);
	}, [
		playersReFetch,
		playersLoading,
		playersData,
		setValue,
		setPlayersSearching,
	]);

	const {
		refetch: trainersReFetch,
		loading: trainersLoading,
		data: trainersData,
	} = useSearchTrainersQuery({
		variables: {
			externalSource: edit ? _.first(event?.externalSources) ?? '' : '',
		},
	});

	useEffect(() => {
		const newTrainers = trainersData?.searchTrainers ?? [];
		setTrainers(newTrainers);
		setValue('trainers', newTrainers);
		setTrainersSearching(false);
	}, [
		trainersReFetch,
		trainersLoading,
		trainersData,
		setValue,
		setTrainersSearching,
	]);

	const [minDateTime, setMinDateTime] = useState<Moment | null>(moment());

	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));

	useEffect(() => {
		if (edit && !_.isNil(event)) {
			setForm(event);
			_.entries(event).forEach(([k, v]) => {
				setValue(k, v);
			});
		}
	}, [edit, event, setForm, setValue]);

	const [override, setOverride] = useState(form.override);

	return (
		<>
			<AddClubDialog
				onClose={onCloseDialog}
				onCreate={onCreate}
				open={addClubDialogOpen}
				clubName={clubName}
				setClubName={setClubName}
			/>

			<Controller
				name="club"
				control={control}
				render={({ field, fieldState }) => (
					<Autocomplete
						{...field}
						disabled={
							clubsLoading ||
							loading ||
							moment().isAfter(moment(form.start))
						}
						fullWidth
						options={(data?.clubs || []) as ClubWithValue[]}
						getOptionLabel={(option) =>
							typeof option === 'string'
								? option
								: option?.name || ''
						}
						freeSolo
						selectOnFocus
						clearOnBlur
						handleHomeEndKeys
						loading={clubsLoading || loading}
						filterOptions={(options, state) => {
							const filtered = filterOptions(options, state);

							if (state.inputValue.length < 1) return [];

							const isExisting = filtered.find(
								(filter) =>
									filter.name?.trim().toLowerCase() ===
									state.inputValue.trim().toLowerCase(),
							);

							if (
								!isExisting &&
								user?.roles?.includes(UserRole.Clubcreator)
							) {
								filtered.push({
									id: null,
									value: state.inputValue,
									name: t('add', {
										value: state.inputValue,
									}),
								});
							}

							return filtered;
						}}
						sx={{
							'& .MuiIconButton-root': {
								border: 0,
							},
							[`& .${autocompleteClasses.paper}`]: {
								background: '#151515',
							},
						}}
						onChange={(
							event,
							value: string | ClubWithValue | null,
						) => {
							if (
								typeof value !== 'string' &&
								!value?.id &&
								value?.value
							) {
								toggleClubDialog(true);
								setClubName(value.value);
								return;
							}

							field.onChange(value);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								fullWidth
								autoCapitalize="off"
								autoCorrect="off"
								type="text"
								name="club"
								variant="outlined"
								onChange={(event: {
									target: { value: string };
								}) => {
									clearTimeout(timeout);

									timeout = setTimeout(
										() =>
											refetch({
												name: event.target.value,
											}),
										500,
									);
								}}
								disabled={
									edit ||
									clubsLoading ||
									loading ||
									moment().isAfter(moment(form.start))
								}
								label={
									edit
										? form?.club?.name || ''
										: t('clubName')
								}
								helperText={fieldState.error?.message}
								error={!!fieldState.error}
							/>
						)}
					/>
				)}
			/>

			<Box pt={2} />

			<Controller
				name="name"
				control={control}
				render={({ field, fieldState }) => (
					<>
						<TextField
							{...field}
							fullWidth
							disabled={loading}
							variant="outlined"
							label={t('eventName')}
						></TextField>

						{fieldState.error ? (
							<Box width={1} pl="14px" pt="3px">
								<Typography variant="caption" color="error">
									{fieldState.error.message}
								</Typography>
							</Box>
						) : null}
					</>
				)}
			/>

			<Box pt={2} />

			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
			>
				<Controller
					name="fixedGoalKeepers"
					control={control}
					render={({ field }) => (
						<Box display="flex" alignItems="center">
							<Checkbox
								sx={{ ml: '-12px' }}
								onChange={(event) =>
									field.onChange(event.target.checked)
								}
								checked={!!field.value}
								disabled={loading}
							/>
							<Typography
								variant="body2"
								color={
									moment().isAfter(moment(form.start))
										? 'textSecondary'
										: 'inherit'
								}
							>
								{t('fixedKeeper')}
							</Typography>
						</Box>
					)}
				/>
			</Grid>

			<Box pt={2} />

			<FormControl
				fullWidth
				variant="outlined"
				sx={{ display: 'flex', flexDirection: sm ? 'row' : 'column' }}
				disabled={loading}
			>
				<DateTimePickerController
					datetime={false}
					control={control}
					name={'start'}
					translation={'create-event'}
					variant={'outlined'}
					size={'medium'}
					pickerProps={{
						onChange: (value) => {
							const now = moment().startOf('date');
							if (value?.isBefore(now) && !edit) {
								value?.startOf('date');
							}
							setMinDateTime(value);
						},
						disabled: loading,
					}}
				/>
				<Box p={1} />
				<DateTimePickerController
					datetime={false}
					control={control}
					name={'end'}
					translation={'create-event'}
					variant={'outlined'}
					size={'medium'}
					pickerProps={{
						onChange: (value, field) => {
							if (_.isNil(field.value)) {
								value?.endOf('date');
							}
						},
						minDate: minDateTime,
						disabled: loading,
					}}
				/>
			</FormControl>
			<Box py={1} />

			<VirtualListController
				visibleItems={3}
				control={control}
				name={'externalSources'}
				items={searchUsersData?.searchUsers ?? []}
				loading={isSearchUsersLoading}
				valueKey={'externalSource'}
				primaryKey={'firstName'}
				secondaryKey={'lastName'}
				disabled={loading}
				onChange={(externalSources) => {
					setValue('externalSources', externalSources);
					form.externalSources = externalSources;
					const [externalSource] = externalSources;
					setPlayersSearching(true);
					setTrainersSearching(true);
					clearTimeout(usersTimeout);

					usersTimeout = setTimeout(() => {
						playersReFetch({
							externalSource: externalSource ?? '',
						})?.finally(() => setPlayersSearching(false));
						trainersReFetch({
							externalSource: externalSource ?? '',
						})?.finally(() => setTrainersSearching(false));
					}, 500);
				}}
			/>
			<Box pt={2} />

			<Grid
				container
				direction="row"
				justifyContent="stretch"
				alignItems="center"
			>
				<Box
					bgcolor="#0d0d0d"
					border="1px solid #202020"
					borderRadius={1}
					bottom={16}
					alignItems={'center'}
					flexWrap={'wrap'}
					justifyContent={'space-evenly'}
					display="flex"
					flex={1}
					p={1}
					position={'sticky'}
				>
					<Box flex={1} display="flex" minWidth={195}>
						<Typography variant="body2">
							{`${
								edit && !override
									? t('numberNewCoaches')
									: t('numberCoaches')
							} ${
								trainersLoading || trainersSearching
									? ''
									: _.difference(
											_.map(trainers, 'sub'),
											override
												? []
												: _.map(event?.trainers, 'sub'),
									  )?.length ?? 0
							}`}
						</Typography>
						{trainersLoading || trainersSearching ? (
							<CircularProgress
								sx={{ marginLeft: '6px' }}
								color="inherit"
								size={20}
							/>
						) : null}
					</Box>

					<Box pt={2} />

					<Box flex={1} display="flex" minWidth={195}>
						<Typography variant="body2">
							{`${
								edit && !override
									? t('numberNewPlayers')
									: t('numberPlayers')
							} ${
								playersLoading || playersSearching
									? ''
									: _.difference(
											_.map(players, 'sub'),
											override
												? []
												: _.map(event?.players, 'sub'),
									  )?.length ?? 0
							}`}
						</Typography>
						{playersLoading || playersSearching ? (
							<CircularProgress
								sx={{ marginLeft: '6px' }}
								color="inherit"
								size={20}
							/>
						) : null}
					</Box>

					<Box py={1} />
				</Box>
			</Grid>

			<Box pt={2} />

			{!edit ? null : (
				<CheckboxController
					control={control}
					name={'override'}
					label={'override'}
					translation={'create-event'}
					disabled={loading}
					onChange={setOverride}
				/>
			)}

			<Box pt={2} />

			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
			>
				<Controller
					name="players"
					defaultValue={trainers}
					control={control}
					render={() => <></>}
				/>
				<Controller
					name="trainers"
					defaultValue={trainers}
					control={control}
					render={() => <></>}
				/>
			</Grid>
		</>
	);
};
