import { CSSProperties } from 'react';

export const lineComparisonStyles: { [key: string]: CSSProperties } = {
	container: {
		height: 'auto',
		pointerEvents: 'auto',
		maxWidth: 600,
		touchAction: 'auto',
	},
	axis: {
		stroke: 'transparent',
	},
	gridDashed: { strokeDasharray: 3 },
	scatter: {
		fill: '#3BFF51',
		stroke: 'white',
		strokeWidth: 4,
	},
	line: {
		stroke: 'url(#linearGradient)',
		strokeWidth: 8,
	},
};
