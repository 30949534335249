import { QueryStatus } from '@reduxjs/toolkit/query';
import '@rtk-query/graphql-request-base-query';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import 'react-redux';

import Box from '@mui/material/Box';

import { useNavigation } from 'src/Router/useNavigation';
import { useSessionNumber } from 'src/Sessions/useSessionNumber';
import { Session } from 'src/graphql/types';

import { SubScoreBody } from './SubScoreBody';
import { SubScoreTitle } from './SubScoreTitle';
import { defaultSubScores } from './defaultSubScores';
import { useScrollSubScoreCard } from './useScrollSubScoreCard';

import { useSessions } from '../../../../../Sessions/useSessions';
import { useSessionPlayerArgs } from '../../../../../redux/hooks';
import { api } from '../../../../../redux/rtk-query';
import { chartTabs } from '../StatsOverTime/chartTabs';

export type SubScoreCardProps = {
	subScore: string;
};

export const SubScoreCard: React.FC<SubScoreCardProps> = ({ subScore }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { query, setQuery } = useNavigation();

	const { selectedRole } = useSessions();
	const { selectedSession, loading } = useSessionNumber();

	const defaultSubScore =
		defaultSubScores.find(
			(defaultSubScore) => defaultSubScore.name === subScore,
		) || defaultSubScores[0];

	const selectedType = chartTabs.find(
		(chartTab) => chartTab.label === query.get('subScore'),
	);

	const selected =
		!loading && selectedType?.subScore === defaultSubScore.name;

	const { ref, onEntered } = useScrollSubScoreCard(selected, !loading);

	const selectedScores =
		selectedSession?.__typename === 'Score'
			? selectedSession?.subScores
			: (selectedSession as Session)?.averages;

	const selectedSubScore = selectedScores?.find(
		(score) => score.type === defaultSubScore.name,
	);

	const onClose = () => {
		setIsOpen(false);
		if (selected) {
			setQuery('subScore', null);
		}
	};

	useEffect(() => {
		if (selected) {
			setIsOpen(true);
		}
	}, [selected, setIsOpen]);

	const { data, status } = api.endpoints.getTrainingVideos.useQueryState(
		useSessionPlayerArgs(selectedSession, selectedRole),
	);
	const trainingVideos =
		_.find(data?.getTrainingVideos, {
			sessionNumber: selectedSession?.sessionNumber,
			type: selectedSubScore?.type,
		})?.trainingsVideos ?? [];

	const isLoading = status !== QueryStatus.fulfilled;
	return (
		<Box ref={ref} py={1}>
			<SubScoreTitle
				loading={loading}
				selected={isOpen}
				title={defaultSubScore.name}
				value={selectedSubScore?.value}
				onClose={onClose}
			/>

			<SubScoreBody
				selected={isOpen}
				isLoading={isLoading}
				trainingVideos={trainingVideos}
				defaultSubScore={defaultSubScore}
				selectedSubScore={selectedSubScore}
				onClose={onClose}
				onEntered={onEntered}
			/>
		</Box>
	);
};
