import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { RadioButtonForm } from 'src/Form/Inputs/RadioButtonForm';
import { useMagicForm } from 'src/Form/MagicFormProvider';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { UserRole } from 'src/graphql/types';

import { SignUpFormValues } from '../SignUpScreen';

export const UserRoleForm: React.FC = () => {
	const { t } = useTranslation('sign-up-screen');
	const navigation = useNavigation();
	const { setFormValues, formValues } = useMagicForm<SignUpFormValues>();

	const form = useForm<SignUpFormValues>({
		resolver: yupResolver(
			yup.object({
				role: yup.string(),
			}),
		),
		defaultValues: formValues,
	});

	const { handleSubmit } = form;

	const onSubmit = handleSubmit((formData) => {
		setFormValues(formData);

		navigation.push(AppRoutes.FullNameForm);
	});

	return (
		<FormProvider {...form}>
			<Stack
				noValidate
				flexGrow={1}
				px="20px"
				alignItems="center"
				component="form"
				onSubmit={onSubmit}
			>
				<Stack
					alignItems="center"
					justifyContent="center"
					width={1}
					flexGrow={1}
					maxWidth={500}
				>
					<Box pt={4} />

					<Typography variant="h5" textAlign="center" maxWidth={250}>
						{t('user_role_title')}
					</Typography>

					<Box pt={4} />

					<Box width={200}>
						<RadioButtonForm
							name="role"
							defaultValue={UserRole.Player}
							options={[
								{
									value: UserRole.Player,
									label: t('player_radio_button'),
								},
								{
									value: UserRole.Trainer,
									label: t('trainer_radio_button'),
								},
							]}
						/>
					</Box>
				</Stack>

				<Box pt={8} />

				<Button color="inherit" variant="outlined" type="submit">
					{t('next_button')}
				</Button>

				<Box pt={8} />
			</Stack>
		</FormProvider>
	);
};
