import _ from 'lodash';
import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';

import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	InputAdornment,
} from '@mui/material';

import { AppRoutes } from '../../../Router/AppRoutes';
import { useNavigation } from '../../../Router/useNavigation';
import { useCreateEventSessionMutation } from '../../../graphql/types';
import { GeoLocation, GroundInput } from '../../../graphql/types/rtk-query';
import { useEventArgs } from '../../../redux/hooks';
import {
	api,
	useFindEventPlayerQuery,
	useFindEventTrainerQuery,
	useGetEventQuery,
} from '../../../redux/rtk-query';
import { ageGroups } from '../../SessionCreator/CreateSession/ageGroups';
import { CheckboxController } from '../Components/CheckboxController';
import { SelectController } from '../Components/SelectController';
import { TextFieldController } from '../Components/TextFieldController';

interface CreateEventSessionForm extends FieldValues {
	ageGroup: string;
	clubId: number;
	fixedGoalkeepers: boolean;
	geolocation: GeoLocation;
	ground: GroundInput;
	playerCodes: string[];
	trainerCodes: string[];
}

export const CreateEventSession = () => {
	const { t } = useTranslation('create-event-session');

	const { data: eventData } = useGetEventQuery(useEventArgs());
	const event = eventData?.getEvent;

	useFindEventTrainerQuery(useEventArgs({ search: '' }));
	useFindEventPlayerQuery(useEventArgs({ search: '' }));

	const { handleSubmit, control, setError } = useForm<CreateEventSessionForm>(
		{
			defaultValues: {
				ageGroup: '',
				clubId: event?.club?.id ?? 0,
				fixedGoalkeepers: event?.fixedGoalKeepers,
				geolocation: { latitude: undefined, longitude: undefined },
				ground: { goalWidth: 5, goalHeight: 2 },
				playerCodes: [],
				trainerCodes: [],
			},
			mode: 'all',
			criteriaMode: 'all',
			resolver: (values, _context, _options) => {
				const errors: FieldErrors<CreateEventSessionForm> = {};
				const setError = (
					field: keyof CreateEventSessionForm,
					errorKey: string,
				) => {
					const error = _.defaultsDeep(_.get(errors, field, {}), {
						type: 'validate',
						types: { [errorKey]: true },
					});
					_.set(errors, field, error);
					return error;
				};

				const required: (keyof CreateEventSessionForm)[] = [
					'ageGroup',
					'clubId',
				];

				_.forEach(required, (field) => {
					const value = _.get(values, field);
					if (_.isNil(value) || _.isEmpty(value)) {
						setError(field, 'required');
					}
				});

				return {
					errors,
					values,
				};
			},
		},
	);

	const [createEventSessionMutation] = useCreateEventSessionMutation();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [errors, setErrors] = useState<string[]>([]);

	const { refetch } = api.endpoints.getEvent.useQuerySubscription(
		useEventArgs(),
	);
	const { goBackOrGoTo } = useNavigation();

	const onSubmit = handleSubmit(async (form) => {
		const eventNumber = event?.eventNumber ?? 0;
		if (submitting || eventNumber < 1) {
			return;
		}
		setErrors([]);
		setSubmitting(true);
		const { data, errors: serverErrors } = await createEventSessionMutation(
			{
				variables: {
					eventNumber,
					args: form,
				},
			},
		)
			.catch((reason) => ({ data: null, errors: [reason] }))
			.finally(() => setSubmitting(false));
		if (serverErrors?.length ?? 0 > 0) {
			serverErrors?.forEach(({ message }) => {
				const [field, error] = message?.split('.');
				if (_.isEmpty(field) || _.isEmpty(error)) {
					setErrors((errors) => _.concat(errors, message));
				}
				setError(field, {
					type: 'validate',
					types: { [error]: true },
				});
			});
		} else if (!_.isNil(data)) {
			refetch();
			goBackOrGoTo(AppRoutes.Events);
		}
	});

	return (
		<Box component={'form'} onSubmit={onSubmit}>
			<SelectController
				options={ageGroups}
				translation={'create-event-session'}
				name={'ageGroup'}
				label={'age-group'}
				control={control}
				disabled={submitting}
			/>
			<Box py={1} />
			<CheckboxController
				control={control}
				name={'fixedGoalkeepers'}
				label={'fixed-goal-keepers'}
				translation={'create-event-session'}
				disabled={submitting}
			/>
			<Box py={1} />
			<FormControl
				fullWidth
				variant="filled"
				sx={{ display: 'flex', flexDirection: 'row' }}
			>
				<TextFieldController
					control={control}
					name={'ground.goalWidth'}
					label={'ground-goal-width'}
					translation={'create-event-session'}
					InputProps={{
						type: 'number',
						endAdornment: (
							<InputAdornment position={'end'}>m</InputAdornment>
						),
					}}
					disabled={submitting}
				/>
				<Box p={1} />
				<TextFieldController
					control={control}
					name={'ground.goalHeight'}
					label={'ground-goal-height'}
					translation={'create-event-session'}
					InputProps={{
						type: 'number',
						endAdornment: (
							<InputAdornment position={'end'}>m</InputAdornment>
						),
					}}
					disabled={submitting}
				/>
			</FormControl>
			<Box py={1} />
			<Box py={2} />
			<FormControl
				fullWidth
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					flexDirection: 'row',
				}}
			>
				<Button
					size={'small'}
					sx={{
						borderRadius: 1,
						padding: '6px 16px',
						minHeight: 'unset',
						height: 'unset',
						lineHeight: '24.5px',
						border: 'none',
					}}
					variant={'contained'}
					type={'submit'}
					disabled={submitting}
				>
					{t('submit')}
				</Button>
			</FormControl>
			<FormControl>
				<FormHelperText className={'Mui-error'}>
					{_.map(errors, (message, index) => (
						<Box key={`${index}`} component={'span'}>
							{message}
						</Box>
					))}
				</FormHelperText>
			</FormControl>
		</Box>
	);
};
