import React from 'react';

import Box from '@mui/material/Box';

export type VerticalBarProps = {
	value?: number;
	width: number | string;
	maxWidth?: number | string;
	height: number | string;
};

export const VerticalBar: React.FC<VerticalBarProps> = ({
	value = 0,
	width,
	maxWidth,
	height,
}) => {
	return (
		<Box
			display="flex"
			position="relative"
			border="1px solid #252525"
			borderRadius="2px"
			width={width}
			maxWidth={maxWidth}
			height={height}
			justifyContent="center"
			alignItems="flex-end"
		>
			{/* Gradient Background */}
			{value && (
				<Box
					position="absolute"
					height={value + '%'}
					width={1}
					display="flex"
					overflow="hidden"
					sx={{
						backgroundImage:
							'linear-gradient(180deg, #46FF44, #0BFF8A)',
					}}
				></Box>
			)}

			{/* Green line */}
			{value && (
				<Box
					position="absolute"
					height={value + '%'}
					width={1}
					bgcolor="#151515"
					borderTop="1px solid #28FF67"
				/>
			)}
		</Box>
	);
};
