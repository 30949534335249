import { useContext } from 'react';

import { FormContext } from './FormContext';

export function useCustomForm<T>() {
	const context = useContext(FormContext) as {
		form: T;
		setForm: (form: T) => void;
		resetForm: () => void;
	};

	if (!context)
		throw new Error('FormContext must be used with FormProvider!');

	return context;
}
