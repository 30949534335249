import gql from 'graphql-tag';

const VALIDATE_EMAIL_QUERY = gql`
	query validateEmail($input: ValidateEmailInput!) {
		validateEmail(validateEmailInput: $input) {
			email
			valid
		}
	}
`;

export default VALIDATE_EMAIL_QUERY;
