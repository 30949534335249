import { useTranslation } from 'react-i18next';
import {
	Navigate,
	RouteObject,
	useLocation,
	useRoutes,
} from 'react-router-dom';

import { supportedLanguages } from './i18next';

export function useLocaleRoutes(routes: RouteObject[] = []) {
	const redirects = formatRedirects(routes);

	const localeRoutes = [
		...supportedLanguages.map((localePath) => ({
			path: localePath,
			children: routes,
		})),
		...redirects,
	];

	return useRoutes(localeRoutes);
}

const RedirectWithLocale = () => {
	const { i18n } = useTranslation();
	const location = useLocation();

	return (
		<Navigate
			replace
			to={`/${i18n.language}${location.pathname || ''}${
				location.search || ''
			}`}
		/>
	);
};

const formatRedirects = (routes: RouteObject[] = []): RouteObject[] => {
	return routes.map((route) => ({
		index: route.index as any,
		caseSensitive: route.caseSensitive,
		path: route.path,
		children: formatRedirects(route.children),
		element: <RedirectWithLocale />,
	}));
};
