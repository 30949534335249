import _ from 'lodash';
import moment from 'moment';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { CustomAccordion } from 'src/Components/CustomAccordion';
import { OldTopBar } from 'src/Components/OldTopBar';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { EventDetailsCard } from './EventDetailsCard';

import { useAuth } from '../../../Auth/useAuth';
import {
	UserRole,
	useListEventsQuery,
	useSearchExternalSourcesQuery,
} from '../../../graphql/types';

export const CurrentEventsScreen = () => {
	const { t } = useTranslation('current-event');
	const navigation = useNavigation();

	const { data, loading } = useListEventsQuery();

	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));

	const { data: externalSourcesData } = useSearchExternalSourcesQuery({
		variables: {
			externalSource: '',
			roles: [UserRole.Trainer, UserRole.Player],
		},
		pollInterval: 60 * 1000,
	});

	const { user } = useAuth();

	const externalSources = _.reduce(
		_.map(externalSourcesData?.searchUsers, 'externalSource'),
		(ess, es) => {
			es = _.isNil(es) ? 'KickID' : es;
			_.set(ess, `${es}`, _.get(ess, `${es}`, 0) + 1);
			return ess;
		},
		{} as any,
	);

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			height={1}
			sx={{
				backgroundImage: `url(${Background1})`,
				backgroundSize: 'cover',
			}}
		>
			<OldTopBar
				iconLeft={<ArrowBackIcon />}
				onClickLeft={() => navigation.replace(AppRoutes.Scoring)}
				title={t('creator')}
				border
			/>

			{user?.roles?.includes(UserRole.Admin) ? (
				<Box
					px={'20px'}
					pt={2}
					display={'flex'}
					flexDirection={'column'}
				>
					{_.entries(externalSources).map(([s, n]) => (
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							key={s}
						>
							<Typography>{s}:</Typography>
							<Typography
								textAlign={'right'}
								minWidth={60}
							>{`${n}`}</Typography>
						</Box>
					))}
					<Box pt={2} />
				</Box>
			) : null}

			<Box
				px="20px"
				width={1}
				sx={{ overflowY: 'auto', overflowX: 'hidden' }}
			>
				<Box pt={4} />

				<Typography variant="h5" align="center">
					{t('title')}
				</Typography>

				<Box pt={4} />

				{loading && (
					<Skeleton
						height={58}
						width="100%"
						variant="rectangular"
					></Skeleton>
				)}

				{!loading && !data?.listEvents.length && (
					<>
						<Box pt={4} />
						<Typography variant="body2" align="center">
							{t('noEvent')}
						</Typography>
					</>
				)}

				{!loading &&
					data?.listEvents?.map((scoutingEvent) => (
						<Fragment key={scoutingEvent.eventNumber}>
							<CustomAccordion
								title={() => (
									<Box
										display={'flex'}
										flex={1}
										alignItems={'center'}
									>
										<Typography
											noWrap
											variant="body1"
											fontWeight={500}
											hidden={!sm}
											flex={1}
										>
											{`${scoutingEvent.club.name}`}
										</Typography>

										<Box pl={1} />

										<Typography
											variant="body1"
											fontWeight={500}
											flex={1}
										>
											{`${scoutingEvent?.name}`}
										</Typography>

										<Box pl={1} />

										<Box display={'flex'} flex={sm ? 1 : 0}>
											<Typography
												variant="caption"
												fontWeight={500}
											>
												{moment(
													scoutingEvent.start,
												).format(sm ? 'L LTS' : 'L')}
												{sm ? <>&nbsp;-&nbsp;</> : ' '}
												{moment(
													scoutingEvent.end,
												).format(sm ? 'L LTS' : 'L')}
											</Typography>
										</Box>

										<Box pl={1} />
										<Typography
											variant="inherit"
											fontWeight={500}
											hidden={!sm}
										>
											{scoutingEvent.eventNumber}
										</Typography>
									</Box>
								)}
								content={
									<EventDetailsCard event={scoutingEvent} />
								}
							/>
							<Box pt={2} />
						</Fragment>
					))}

				<Box pt={18} />
			</Box>

			<Box position="absolute" bottom={0} pb={6}>
				<Button
					color="primary"
					variant="contained"
					onClick={() => navigation.push(AppRoutes.CreateEvent)}
				>
					{t('addEvent')}
				</Button>
			</Box>
		</Box>
	);
};
