import { Capacitor } from '@capacitor/core';

import { common } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const basicTheme = createTheme();

export const muiTheme = createTheme({
	palette: {
		mode: 'dark',
		background: {
			default: common.black,
			paper: common.black,
		},
		primary: {
			main: '#43FF47',
		},
		secondary: {
			main: common.black,
		},
		text: {
			secondary: '#777777',
		},
	},
	spacing: 8,
	typography: {
		fontFamily: 'Inter',
		h1: {
			fontSize: 88,
			fontWeight: 800,
		},
		h2: {
			fontSize: 60,
			fontWeight: 'bold',
		},
		h3: {
			fontSize: 48,
			fontWeight: 'bold',
		},
		h4: {
			fontSize: 32,
			fontWeight: 700,
		},
		h5: {
			fontSize: 24,
			fontWeight: 800,
			textTransform: 'uppercase',
		},
		h6: {
			fontSize: 24,
			fontWeight: 600,
		},
		subtitle1: {
			fontSize: 16,
			fontWeight: 800,
			textTransform: 'uppercase',
		},
		subtitle2: {
			fontSize: 14,
			fontWeight: 500,
		},
		body1: {
			fontSize: 16,
		},
		body2: {
			fontSize: 14,
		},
		button: {
			fontSize: 12,
			fontWeight: 700,
			letterSpacing: 0.4,
		},
		caption: {
			fontSize: 12,
		},
		overline: {
			fontSize: 11,
			fontWeight: 700,
		},
	},
});

export const theme = createTheme(muiTheme, {
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				html: {
					userSelect: Capacitor.isNativePlatform()
						? 'none'
						: undefined,
					height: '100%',
					WebkitFontSmoothing: 'auto',
					width: '100%',
					position: 'fixed',
					overflow: 'hidden',
				},
				body: {
					height: '100%',
					width: '100%',
					position: 'fixed',
					overflow: 'hidden',
				},
				'#root': {
					height: '100%',
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					'&:-webkit-autofill': {
						boxShadow: '0 0 0 100px #000000 inset !important',
					},
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					backgroundColor: '#151515',
					border: `1px solid ${muiTheme.palette.divider}`,
					borderLeft: 0,
					borderRight: 0,
					'&:before': {
						display: 'none',
					},
					'&:not(:last-child)': {
						borderBottom: 0,
					},
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					'&.Mui-expanded': {
						background: 'linear-gradient(180deg, #46FF44, #0BFF8A)',
						'&, & .MuiSvgIcon-root': {
							color: 'black',
						},
					},
				},
				content: {
					paddingRight: muiTheme.spacing(2),
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					backgroundColor: '#0d0d0d',
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					border: '1px solid #454545',
					'&:hover': {
						backgroundColor: '#00000030',
					},
					backgroundColor: '#00000030',
				},
				colorPrimary: {
					backgroundImage:
						'linear-gradient(180deg, #46FF44, #0BFF8A)',
					color: 'black',
					border: 0,
				},
				colorSecondary: {
					'&:hover': {
						backgroundColor: 'black',
					},
					backgroundColor: 'black',
					color: 'white',
				},
				colorInfo: {
					'&:hover': {
						backgroundColor: 'white',
					},
					backgroundColor: 'white',
					color: 'black',
				},
				sizeLarge: {
					width: 48,
					height: 48,
				},
				sizeSmall: {
					padding: 4,
				},
				colorInherit: {
					border: 0,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				contained: {
					padding: muiTheme.spacing(0, 3),
					borderRadius: 24,
					height: 48,
					minHeight: 48,
				},
				containedPrimary: {
					border: '1px solid #454545',
					backgroundImage:
						'linear-gradient(180deg, #46FF44, #0BFF8A)',
				},
				containedSecondary: {
					border: '1px solid #454545',
				},
				outlined: {
					padding: muiTheme.spacing(0, 3),
					borderRadius: 24,
					border: '1px solid #454545',
					height: 48,
					backgroundColor: '#00000030',
					'&:hover': {
						backgroundColor: '#00000030',
					},
					minHeight: 48,
				},
			},
		},
		MuiBottomNavigation: {
			styleOverrides: {
				root: {
					height: 70,
					borderTop: '1px solid #252525',
				},
			},
		},
		MuiBottomNavigationAction: {
			styleOverrides: {
				label: {
					paddingTop: 4,
					fontSize: 10,

					'&.Mui-selected': {
						fontSize: '10px !important',
					},
				},
			},
		},

		MuiRadio: {
			styleOverrides: {
				root: {
					color: '#FFFF',
					borderColor: common.black,
				},
				colorSecondary: {
					'&.Mui-checked': {
						color: '#FFFF',
					},
				},
			},
		},

		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: '#FFFF',
					borderColor: common.black,
				},
				colorSecondary: {
					'&.Mui-checked': {
						color: '#FFFF',
					},
				},
			},
		},

		MuiTab: {
			styleOverrides: {
				root: {
					color: muiTheme.palette.text.secondary,
					opacity: 1,
					textTransform: 'capitalize',
					fontSize: 16,
					fontWeight: 500,

					'&.Mui-selected': {
						color: common.white,
						fontSize: '16px !important',
					},
				},
			},
		},

		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiIconButton-root': {
						border: 0,
					},
				},
			},
		},

		MuiDrawer: {
			styleOverrides: {
				root: {
					'& .MuiPaper-root': {
						background: 'black',
					},
				},
			},
		},
	},
});
