import {
	ReactNode,
	RefObject,
	createRef,
	forwardRef,
	useEffect,
	useState,
} from 'react';
import ReactPlayer, { FilePlayerProps } from 'react-player/file';

import {
	Fullscreen,
	Pause,
	PictureInPicture,
	PlayArrow,
	VolumeOff,
	VolumeUp,
} from '@mui/icons-material';
import {
	Box,
	CircularProgress,
	Fab,
	IconButton,
	LinearProgress,
	Slider,
} from '@mui/material';

const Wrapper: React.FC<{
	customControls?: boolean;
	children: ReactNode;
	buffering: boolean;
	playing: boolean;
	showControls: boolean;
	width?: number | string;
	height?: number | string;
	currentTime: number;
	duration: number;
	buffered: number;

	playerRef: RefObject<ReactPlayer>;
	hasAudio: boolean;
	videoHandler: () => void;
	normalise: (value: number) => number;
}> = ({
	customControls,
	children,
	buffering,
	playing,
	showControls,
	width,
	height,
	currentTime,
	duration,
	buffered,
	hasAudio,
	playerRef,
	videoHandler,
	normalise,
}) => {
	const videoPlayer = () =>
		playerRef.current?.getInternalPlayer() as HTMLVideoElement;

	const [muted, setMuted] = useState(videoPlayer()?.muted ?? false);

	useEffect(() => {
		const player =
			playerRef.current?.getInternalPlayer() as HTMLVideoElement;
		if (player) player.muted = muted;
	}, [muted, playerRef]);

	return customControls ? (
		<Box
			position={'absolute'}
			top={0}
			left={0}
			width={width}
			height={height}
			sx={{
				'.controls': {
					opacity: playing ? 0 : 1,
					transition: '200ms',
				},
				':hover .controls': {
					opacity: 1,
				},
			}}
		>
			{children}
			{buffering && showControls && (
				<CircularProgress
					sx={{
						position: 'absolute',
						top: 'calc(50% - 40px)',
						left: 'calc(50% - 40px)',
					}}
					size={80}
				/>
			)}
			{!buffering && showControls && (
				<>
					<Fab
						className={'controls'}
						color={'secondary'}
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							':hover': {
								opacity: 1,
							},
						}}
						onClick={() => videoHandler()}
					>
						{playing ? <Pause /> : <PlayArrow />}
					</Fab>
					<Box
						className={'controls'}
						position={'absolute'}
						right={0}
						bottom={40}
						sx={{
							button: {
								border: 'none',
								marginLeft: '4px',
							},
						}}
					>
						{hasAudio && (
							<IconButton
								id={'volumeButton'}
								onClick={() => setMuted((muted) => !muted)}
							>
								{muted ? <VolumeOff /> : <VolumeUp />}
							</IconButton>
						)}
						{document.pictureInPictureEnabled &&
							!videoPlayer()?.disablePictureInPicture && (
								<IconButton
									id={'pipButton'}
									onClick={() => {
										if (document.pictureInPictureElement)
											document.exitPictureInPicture();
										else
											videoPlayer()?.requestPictureInPicture();
									}}
								>
									<PictureInPicture />
								</IconButton>
							)}
						<IconButton
							id={'maximizeButton'}
							onClick={() => videoPlayer()?.requestFullscreen()}
						>
							<Fullscreen />
						</IconButton>
					</Box>
					<LinearProgress
						className={'controls'}
						variant={'buffer'}
						value={normalise(currentTime)}
						valueBuffer={normalise(buffered)}
						sx={{
							width: 'calc(100% - 16px)',
							position: 'absolute',
							bottom: 13,
							left: '8px',
							right: '8px',
							':hover + #thumb': {
								opacity: 1,
							},
						}}
					/>
					<Slider
						className={'controls'}
						value={currentTime}
						max={duration}
						step={0.01}
						onChange={(event) => {
							playerRef.current?.seekTo(
								(event.target as any).value as number,
								'seconds',
							);
						}}
						sx={{
							width: 'calc(100% - 16px)',
							position: 'absolute',
							bottom: 0,
							left: '8px',
							right: '8px',
							padding: '13px 0 !important',
							'& .MuiSlider-track, & .MuiSlider-rail, & .MuiSlider-thumb':
								{
									opacity: 0,
									transition: '200ms',
								},
							'& .MuiSlider-thumb': {
								'&.Mui-active': {
									opacity: 1,
								},
							},
							'&:hover': {
								'& .MuiSlider-thumb': {
									opacity: 1,
								},
							},
						}}
					/>
				</>
			)}
		</Box>
	) : (
		<>{children}</>
	);
};

export const CustomPlayer = forwardRef<
	ReactPlayer,
	FilePlayerProps & { customControls?: boolean }
>((props, ref) => {
	const { customControls, width, height, ...playerProps } = props;

	const playerRef = (ref ??
		createRef<ReactPlayer>()) as RefObject<ReactPlayer>;

	const [playing, setPlaying] = useState(playerProps.playing ?? false);
	const [buffered, setBuffered] = useState(0);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [buffering, setBuffering] = useState(true);
	const [showControls, setShowControls] = useState(
		playerProps.playing ?? false,
	);
	const [hasAudioState, setHasAudioState] = useState(false);

	const customProps: FilePlayerProps = {
		progressInterval: 200,
		onClickPreview: (event) => {
			props.onClickPreview?.(event);
			setShowControls(true);
		},
		onReady: () => setBuffering(false),
		onPlay: () => setPlaying(true),
		onPause: () => setPlaying(false),
		onDuration: (duration: number) => setDuration(duration),
		onProgress: ({ playedSeconds, loadedSeconds }) => {
			setCurrentTime(playedSeconds);
			setBuffered(loadedSeconds);
		},
	};

	const normalise = (value: number) => value * (100 / duration);

	const videoHandler = () => {
		const current =
			playerRef.current?.getInternalPlayer() as HTMLVideoElement;

		if (!hasAudioState) setHasAudioState(hasAudio(current));

		if (current?.paused) {
			current?.play();
			setPlaying(true);
		} else {
			current?.pause();
			setPlaying(false);
		}
	};

	const hasAudio = (video?: HTMLVideoElement | null) => {
		if (!video) return false;
		const tmp = video as any;
		return (
			tmp.mozHasAudio ||
			Boolean(tmp.webkitAudioDecodedByteCount) ||
			Boolean(tmp.audioTracks && tmp.audioTracks.length)
		);
	};

	return (
		<Box position={'relative'} width={width} height={height}>
			<Wrapper
				buffered={buffered}
				buffering={buffering}
				currentTime={currentTime}
				customControls={customControls}
				duration={duration}
				hasAudio={hasAudioState}
				normalise={normalise}
				playerRef={playerRef}
				playing={playing}
				showControls={showControls}
				videoHandler={videoHandler}
				height={height}
				width={width}
				key={playerProps.key ?? 'PlayerWrapper'}
			>
				<ReactPlayer
					ref={playerRef}
					{...playerProps}
					width={width}
					height={height}
					controls={!customControls}
					{...(customControls ? customProps : {})}
				/>
			</Wrapper>
		</Box>
	);
});
