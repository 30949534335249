import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const UserIcon: React.FC<CustomIconProps> = (props) => {
	const { color, strokeWidth } = useCustomIcon(props);

	return (
		<SvgIcon viewBox="0 0 32 32" sx={props.sx}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					transform="translate(5.000000, 1.014724)"
					stroke={color}
					strokeWidth={strokeWidth}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				>
					<path d="M10.9995,0.49999901 C12.8593034,0.496298528 14.1529674,1.13171002 15.0065557,2.13056897 C16.0998348,3.40991138 16.480438,5.2456074 16.4804609,6.99520188 C16.4629191,8.82787638 16.09275,10.6398467 15.0230158,11.8916415 C14.1732184,12.886068 12.8768934,13.5 10.9985051,13.5 C9.13142301,13.5 7.8177183,12.8733839 6.94957801,11.8600344 C5.87973197,10.6112409 5.480438,8.81263428 5.480438,6.97532274 C5.480438,5.24212486 5.89324915,3.42501122 6.99235371,2.15107909 C7.85988711,1.14555268 9.16002289,0.5 10.9995,0.49999901 Z" />
					<path
						d="M0,17.0355604 C0,17.5568051 0,18.6214742 0,20.2295678 C0.0660596797,24.3091363 2.94499097,29.077309 10.9208078,29.0536887 C19.0763292,29.030951 22.0202172,24.3081496 21.9996835,20.2295678 C21.9996835,17.3113109 21.9996835,18.387992 21.9996835,16.9855374"
						strokeLinecap="square"
						transform="translate(11.000000, 23.019613) rotate(-180.000000) translate(-11.000000, -23.019613) "
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
