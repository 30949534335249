import Badge, { badgeClasses } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

export const WipBadge = styled(Badge)(() => ({
	[`& .${badgeClasses.badge}`]: {
		height: 14,
		fontSize: 10,
		padding: 3,
	},
}));

WipBadge.defaultProps = {
	color: 'error',
	badgeContent: 'SOON',
};
