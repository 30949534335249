import React from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { CircularProgressAnimation } from 'src/Components/CircularProgressAnimation';
import { LockIcon } from 'src/Components/Icons/LockIcon';
import { UserIcon } from 'src/Components/Icons/UserIcon';
import { UserRole } from 'src/graphql/types';

import { TeamIcon } from './Icons/TeamIcon';

export type DinamicAvatarWithProgressProps = {
	size: number;
	iconUrl?: string | null;
	loading?: boolean;
	role?: UserRole;
	score?: number | null;
	disabled?: boolean | undefined;
	reverse?: boolean;
};

export const DinamicAvatarWithProgress: React.FC<
	DinamicAvatarWithProgressProps
> = ({ size, iconUrl, loading = false, role, score, disabled, reverse }) => {
	return (
		<>
			<Box display="flex" flexDirection={reverse ? 'row-reverse' : 'row'}>
				<Box position="relative">
					{/* Loading Avatar */}
					{loading ? (
						<Skeleton
							variant="circular"
							width={size}
							height={size}
						/>
					) : null}

					{/* User Avatar */}
					{!loading && iconUrl ? (
						<Avatar
							src={iconUrl || ''}
							sx={{ width: size, height: size }}
						/>
					) : null}

					{/* Default Avatar */}
					{!loading && !iconUrl ? (
						<Avatar
							sx={{
								width: size,
								height: size,
								backgroundColor: 'inherit',
								border: '1px solid #454545',
							}}
						>
							{role === UserRole.Trainer ? (
								<TeamIcon
									color="white"
									sx={{ fontSize: size * 0.5 }}
								/>
							) : (
								<UserIcon
									color="white"
									sx={{ fontSize: size * 0.5 }}
								/>
							)}
						</Avatar>
					) : null}
				</Box>

				<Box
					position="relative"
					display="flex"
					justifyContent="center"
					width={size}
					right={reverse ? -8 : 8}
					borderRadius="50%"
					bgcolor="black"
					border={
						!loading && disabled ? '1px solid #454545' : undefined
					}
				>
					{!disabled && (
						<CircularProgressAnimation
							size={size}
							animate={true}
							duration={2_000}
							maxValue={score || 0}
						/>
					)}

					<Box
						position="absolute"
						width={size}
						height={size}
						top={0}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						{/* Loading Score */}
						{loading && (
							<Typography variant="h6">
								<Skeleton width={20} />
							</Typography>
						)}

						{/* Score */}
						{!loading && !disabled ? (
							<Typography variant="subtitle1" color="inherit">
								{score}
							</Typography>
						) : null}

						{/* No Score */}
						{!loading && disabled && (
							<LockIcon
								color="white"
								sx={{ fontSize: size * 0.5 }}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};
