const useColorSubScore = (myValue: any, yourValue: any, type: string) => {
	switch (type) {
		case 'colorLeft':
			return myValue && yourValue && Math.sign(yourValue - myValue) === 1
				? '#46FF44'
				: myValue && yourValue && Math.sign(yourValue - myValue) === 0
				? 'white'
				: '#FFBF28';

		case 'colorRight':
			return myValue && yourValue && Math.sign(myValue - yourValue) === 1
				? '#46FF44'
				: myValue && yourValue && Math.sign(myValue - yourValue) === 0
				? 'white'
				: '#FFBF28';

		case 'colorLeftOpacity':
			return myValue && yourValue && Math.sign(yourValue - myValue) === 1
				? 'rgba(40, 255, 103, 0.3)'
				: myValue && yourValue && Math.sign(yourValue - myValue) === 0
				? 'rgba(255, 255, 255, 0.3)'
				: 'rgba(255, 191, 40, 0.3)';

		case 'colorRightOpacity':
			return myValue && yourValue && Math.sign(myValue - yourValue) === 1
				? 'rgba(40, 255, 103, 0.3)'
				: myValue && yourValue && Math.sign(myValue - yourValue) === 0
				? 'rgba(255, 255, 255, 0.3)'
				: 'rgba(255, 191, 40, 0.3)';
	}
};

export { useColorSubScore };
