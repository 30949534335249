import React from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { UserIcon } from 'src/Components/Icons/UserIcon';
import { UserRole } from 'src/graphql/types';

import { TeamIcon } from './Icons/TeamIcon';

export type DinamicAvatarProps = {
	size: number;
	iconUrl?: string | null;
	loading?: boolean;
	role?: UserRole;
};

export const DinamicAvatar: React.FC<DinamicAvatarProps> = ({
	size,
	iconUrl,
	loading = false,
	role,
}) => {
	return (
		<>
			<Box display="flex" justifyContent="center">
				{/* Loading Avatar */}
				{loading ? (
					<Skeleton variant="circular" width={size} height={size} />
				) : null}

				{/* User Avatar */}
				{!loading && iconUrl ? (
					<Avatar
						src={iconUrl || ''}
						sx={{ width: size, height: size }}
					/>
				) : null}

				{/* Default Avatar */}
				{!loading && !iconUrl ? (
					<Avatar
						sx={{
							width: size,
							height: size,
							backgroundColor: 'inherit',
							border: '1px solid #454545',
						}}
					>
						{role === UserRole.Trainer ? (
							<TeamIcon
								color="white"
								sx={{ fontSize: size * 0.5 }}
							/>
						) : (
							<UserIcon
								color="white"
								sx={{ fontSize: size * 0.5 }}
							/>
						)}
					</Avatar>
				) : null}
			</Box>
		</>
	);
};
