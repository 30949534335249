import gql from 'graphql-tag';

import USER_FRAGMENT from '../fragments/user';

const CHECK_EMAIL_TOKEN_QUERY = gql`
	query checkEmailToken($input: CheckEmailTokenInput!) {
		checkEmailToken(checkEmailTokenInput: $input) {
			user {
				...UserFragment
			}
			type
			uuid
			sub
			tokenValid
		}
	}

	${USER_FRAGMENT}
`;

export default CHECK_EMAIL_TOKEN_QUERY;
