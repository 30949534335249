import { createContext, useCallback, useState } from 'react';

import { useNavigation } from 'src/Router/useNavigation';

import { ShowSuccess } from './ShowSuccess';

export type SuccessProps = {
	title: string;
	subtitle?: string;
	nextRoute?: string;
	onClose?: () => void;
	nextRouteState?: any;
	duration?: number;
};

export const SuccessContext = createContext<
	((props: SuccessProps) => void) | undefined
>(undefined);

export const SuccessProvider: React.FC = ({ children }) => {
	const [open, setOpen] = useState<SuccessProps | null>(null);
	const navigation = useNavigation();

	const onOpen = useCallback(
		(successProps: SuccessProps) => {
			setOpen(successProps);

			setTimeout(() => {
				if (successProps.nextRoute) {
					navigation.replace(
						successProps.nextRoute,
						successProps.nextRouteState,
					);
				}

				if (successProps.onClose) {
					successProps.onClose();
				}

				setOpen(null);
			}, successProps.duration || 3000);
		},
		[navigation, setOpen],
	);

	return (
		<SuccessContext.Provider value={onOpen}>
			{open ? (
				<ShowSuccess title={open.title} subtitle={open.subtitle} />
			) : (
				children
			)}
		</SuccessContext.Provider>
	);
};
