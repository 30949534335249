import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { useAuth } from 'src/Auth/useAuth';
import { TextFieldForm } from 'src/Form/Inputs/TextFieldForm';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useShowWarning } from 'src/Warning/useShowSuccess';
import { useDeleteAccountMutation } from 'src/graphql/types';
import { useServerErrors } from 'src/helpers/useServerErrors';

import { DeleteAccountDialog } from './DeleteAccountDialog';
import { DeleteAccountFormValues } from './DeleteAccountScreen';

export const DeleteAccountForm = () => {
	const { t } = useTranslation('delete-account');
	const [deleteAccountMutation, { loading }] = useDeleteAccountMutation();
	const { handleSubmit, setError } =
		useFormContext<DeleteAccountFormValues>();
	const showWarning = useShowWarning();
	const { logout } = useAuth();
	const [confirmDeletionValues, setConfirmDeletionValues] =
		useState<DeleteAccountFormValues | null>(null);
	const { setServerErrors, resetServerErrors, ServerErrors } =
		useServerErrors();

	const onSubmit = handleSubmit((formData) => {
		setConfirmDeletionValues(formData);
	});

	const deleteAccount = async () => {
		resetServerErrors();

		const formData = confirmDeletionValues as DeleteAccountFormValues;

		const { errors } = await deleteAccountMutation({
			variables: {
				input: formData,
			},
		}).catch(convertNetworkErrors);

		setConfirmDeletionValues(null);

		if (errors) {
			const isInvalidPassword = errors.some(
				(error) => error.message === 'InvalidPassword',
			);

			if (isInvalidPassword) {
				setError('password', {});
			}

			setServerErrors(errors);
		} else {
			showWarning({
				title: t('account_deleted'),
				nextRoute: AppRoutes.Start,
				duration: 5000,
				onClose: () => logout(),
			});
		}
	};

	return (
		<Stack
			px="20px"
			alignItems="center"
			component="form"
			flexGrow={1}
			onSubmit={onSubmit}
			noValidate
		>
			<Stack
				width={1}
				alignItems="center"
				justifyContent="center"
				flexGrow={1}
				maxWidth={500}
			>
				<Box pt={4} />

				<Typography variant="h5" textAlign="center" fontSize={18}>
					{t('description_text')}
				</Typography>

				<Box pt={4} />

				<TextFieldForm
					fullWidth
					variant="outlined"
					name="password"
					type="password"
					autoComplete="password"
					label={t('password')}
					disabled={loading}
				/>

				<Box pt={2} />

				<Box pt={4} />

				{loading && <CircularProgress color="inherit" />}

				<ServerErrors />
			</Stack>

			<Box pt={4} />

			<Button
				color="inherit"
				variant="outlined"
				type="submit"
				disabled={loading}
			>
				{t('submit')}
			</Button>

			<Box pt={8} />

			<DeleteAccountDialog
				loading={loading}
				open={!!confirmDeletionValues}
				onClose={() => setConfirmDeletionValues(null)}
				handleDelete={deleteAccount}
			/>
		</Stack>
	);
};
