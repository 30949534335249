import gql from 'graphql-tag';

const REFRESH_TOKEN_MUTATION = gql`
	mutation refreshToken($input: RefreshTokenInput!) {
		refreshToken(refreshTokenInput: $input) {
			accessToken
			refreshed
		}
	}
`;

export default REFRESH_TOKEN_MUTATION;
