import { GraphQLError } from 'graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';

export const useServerErrors = () => {
	const { t } = useTranslation('server-error');
	const [errors, setServerErrors] = useState<readonly GraphQLError[]>([]);

	const handleClose = (error: GraphQLError) => {
		const newErrors = errors.filter((prevError) => error !== prevError);

		setServerErrors(newErrors);
	};

	const ServerErrors = () => {
		return (
			<>
				{errors.map((error) => (
					<Alert
						key={error.message}
						severity="error"
						onClose={() => handleClose(error)}
					>
						{t(error.message)}
					</Alert>
				)) || null}
			</>
		);
	};

	const resetServerErrors = () => {
		setServerErrors([]);
	};

	return {
		ServerErrors,
		setServerErrors,
		resetServerErrors,
	};
};
