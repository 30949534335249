import { useTranslation } from 'react-i18next';

import QrCode2Icon from '@mui/icons-material/QrCode2';
import Button, { ButtonProps } from '@mui/material/Button';

import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import { UserRole } from 'src/graphql/types';

import { OtherButton, OtherButtonsProps } from './OtherButton';

export const MyIdButton = (props: ButtonProps) => {
	const navigation = useNavigation();
	const { t } = useTranslation('scoring');

	const { selectedRole } = useSessions();

	return (
		<Button
			startIcon={<QrCode2Icon />}
			onClick={() => navigation.push(AppRoutes.QrCodeDialog)}
			{...props}
		>
			{selectedRole === UserRole.Trainer
				? t('myTrainerId')
				: t('myPlayerId')}
		</Button>
	);
};

export const MyIdGroupButton: React.FC<OtherButtonsProps> = ({ onClick }) => {
	const { t } = useTranslation('scoring');

	const { selectedRole } = useSessions();
	const label =
		selectedRole === UserRole.Trainer ? t('myTrainerId') : t('myPlayerId');

	return (
		<OtherButton
			label={label}
			link={AppRoutes.QrCodeDialog}
			onClick={onClick}
		>
			<QrCode2Icon />
		</OtherButton>
	);
};
