import { Navigate, RouteObject } from 'react-router-dom';

import { AddCoachScreen } from 'src/Screens/SessionCreator/AddCoach/AddCoachScreen';
import { ScanCoachCodeScreen } from 'src/Screens/SessionCreator/AddCoach/ScanCoachCodeScreen';
import { AddPlayerScreen } from 'src/Screens/SessionCreator/AddPlayer/AddPlayerScreen';
import { ScanPlayerCodeScreen } from 'src/Screens/SessionCreator/AddPlayer/ScanPlayerCodeScreen';
import { AdminForm } from 'src/Screens/SessionCreator/AdminForm';
import { CreateSessionsScreen } from 'src/Screens/SessionCreator/CreateSession/CreateSessionsScreen';
import { CurrentSessionsScreen } from 'src/Screens/SessionCreator/CurrentSessions/CurrentSessionsScreen';
import { ProcessSessionScreen } from 'src/Screens/SessionCreator/ProcessSession/ProcessSessionScreen';

import { AppRoutes } from '../AppRoutes';

export default [
	{
		path: 'session-creator',
		element: <CurrentSessionsScreen />,
	},
	{
		path: 'process-session',
		element: <ProcessSessionScreen />,
	},
	{
		path: 'edit-session',
		element: <AdminForm />,
		children: [
			{
				path: '',
				element: <CreateSessionsScreen />,
			},
		],
	},
	{
		path: 'add-coach',
		element: <AdminForm />,
		children: [
			{
				path: '',
				element: <AddCoachScreen />,
			},
			{
				path: 'scan',
				element: <ScanCoachCodeScreen />,
			},
		],
	},
	{
		path: 'add-player',
		element: <AdminForm />,
		children: [
			{
				path: '',
				element: <AddPlayerScreen />,
			},
			{
				path: 'scan',
				element: <ScanPlayerCodeScreen />,
			},
		],
	},
	{
		path: '*',
		element: <Navigate replace to={AppRoutes.CurrentSessions} />,
	},
] as RouteObject[];
