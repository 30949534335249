import { createContext, useCallback, useState } from 'react';

import { useNavigation } from 'src/Router/useNavigation';

import { ShowWarning } from './ShowWarning';

export type WarningProps = {
	title: string;
	subtitle?: string;
	nextRoute?: string;
	onClose?: () => void;
	nextRouteState?: any;
	duration?: number;
};

export const WarningContext = createContext<
	((props: WarningProps) => void) | undefined
>(undefined);

export const WarningProvider: React.FC = ({ children }) => {
	const [open, setOpen] = useState<WarningProps | null>(null);
	const navigation = useNavigation();

	const onOpen = useCallback(
		(warningProps: WarningProps) => {
			setOpen(warningProps);

			setTimeout(() => {
				if (warningProps.nextRoute) {
					navigation.replace(
						warningProps.nextRoute,
						warningProps.nextRouteState,
					);
				}

				if (warningProps.onClose) {
					warningProps.onClose();
				}

				setOpen(null);
			}, warningProps.duration || 3000);
		},
		[navigation, setOpen],
	);

	return (
		<WarningContext.Provider value={onOpen}>
			{open ? (
				<ShowWarning title={open.title} subtitle={open.subtitle} />
			) : (
				children
			)}
		</WarningContext.Provider>
	);
};
