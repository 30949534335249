export const ageGroups = [
	{ name: 'U10_male', value: 'U10_male' },
	{ name: 'U11_male', value: 'U11_male' },
	{ name: 'U12_male', value: 'U12_male' },
	{ name: 'U13_male', value: 'U13_male' },
	{ name: 'U14_male', value: 'U14_male' },
	{ name: 'U15_male', value: 'U15_male' },
	{ name: 'U16_male', value: 'U16_male' },
	{ name: 'U17_male', value: 'U17_male' },
	{ name: 'U18_male', value: 'U18_male' },
	{ name: 'U19_male', value: 'U19_male' },
	{ name: 'U20_male', value: 'U20_male' },
	{ name: 'U21_male', value: 'U21_male' },
	{ name: 'adult_male', value: 'adult_male' },
	{ name: 'senior_male', value: 'senior_male' },
	{ name: 'U10_female', value: 'U10_female' },
	{ name: 'U11_female', value: 'U11_female' },
	{ name: 'U12_female', value: 'U12_female' },
	{ name: 'U13_female', value: 'U13_female' },
	{ name: 'U14_female', value: 'U14_female' },
	{ name: 'U15_female', value: 'U15_female' },
	{ name: 'U16_female', value: 'U16_female' },
	{ name: 'U17_female', value: 'U17_female' },
	{ name: 'U18_female', value: 'U18_female' },
	{ name: 'U19_female', value: 'U19_female' },
	{ name: 'U20_female', value: 'U20_female' },
	{ name: 'U21_female', value: 'U21_female' },
	{ name: 'adult_female', value: 'adult_female' },
	{ name: 'senior_female', value: 'senior_female' },
];
