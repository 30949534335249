import { ApolloQueryResult } from '@apollo/client';
import { GraphQLError } from 'graphql';
import _, { throttle } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { Autocomplete, TextField, useMediaQuery } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { CloseIcon } from 'src/Components/Icons/CloseIcon';
import { OldTopBar } from 'src/Components/OldTopBar';
import { useCustomForm } from 'src/Form/useCustomForm';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import {
	FindEventTrainerQuery,
	User,
	useFindEventTrainerQuery,
} from 'src/graphql/types';

import { CreateSessionFormData } from '../../SessionCreator/AdminForm';
import { useEvent } from '../useEvent';

export const AddEventSessionCoachScreen = () => {
	const { event } = useEvent();
	const eventRoute = (route: AppRoutes) => {
		return route.replace(':eventNumber', `${event?.eventNumber ?? 0}`);
	};
	const eventNumber = event?.eventNumber ?? 0;

	const { t } = useTranslation(['add-coach', 'server-error']);

	const [invalidCode, setInvalidCode] = useState<boolean>(false);

	const [trainers, setTrainers] = useState<User[] | []>([]);
	const [options, setOptions] = useState<User[] | []>([]);
	const [value, setValue] = useState<User | null>(null);
	const [inputValue, setInputValue] = useState('');

	const { form, setForm } = useCustomForm<CreateSessionFormData>();
	const navigation = useNavigation();

	const [serverErrors, setServerErrors] = useState<readonly GraphQLError[]>(
		[],
	);

	useEffect(() => {
		if (!form.ageGroup) {
			navigation.push(
				`${
					AppRoutes.EditEventSession.replace(
						':eventNumber',
						`${event?.eventNumber ?? 0}`,
					) +
					(form.sessionNumber
						? `?sessionNumber=${form.sessionNumber}`
						: '')
				}`,
			);
		}
	}, [form.ageGroup, form.sessionNumber, navigation, event?.eventNumber]);

	const AddTrainer = useCallback(
		async (code: string) => {
			setServerErrors([]);

			const existingCode =
				form.playerCodes?.includes(code) ||
				form.trainerCodes?.includes(code);

			if (existingCode) {
				setInvalidCode(true);
				return;
			}

			setForm({
				...form,
				trainerCodes: [...(form?.trainerCodes || []), code],
				trainersCount: form.trainersCount + 1,
			});
		},
		[form, setForm],
	);

	const onSubmit = async () => {
		navigation.push(`${eventRoute(AppRoutes.AddEventSessionPlayer)}`);
	};

	const removeTrainer = (code: string) => {
		form.trainersCount--;
		form.trainerCodes = _.filter(form.trainerCodes, (c) => c !== code);

		setForm({ ...form });
	};

	const { loading, refetch } = useFindEventTrainerQuery({
		variables: { eventNumber, search: inputValue },
	});

	const fetch = useMemo(
		() =>
			throttle(
				(
					search: { eventNumber: number; search: string },
					callback: (
						search: { eventNumber: number; search: string },
						result: ApolloQueryResult<FindEventTrainerQuery>,
					) => void,
				) =>
					refetch(search)?.then((result) => callback(search, result)),
				200,
			),
		[refetch],
	);

	useEffect(() => {
		let active = true;

		fetch(
			{
				eventNumber,
				search: inputValue,
			},
			(search, result) => {
				if (active) {
					setOptions(
						_.orderBy(
							result?.data?.findEventTrainer ?? [],
							['lastName', 'firstName', 'email'],
							['asc', 'asc', 'asc'],
						),
					);
					if (_.isEmpty(_.trim(search.search))) {
						setTrainers(result?.data?.findEventTrainer ?? []);
					}
				}
			},
		);

		return () => {
			active = false;
		};
	}, [eventNumber, fetch, inputValue]);

	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));

	const getOptionLabel = (option: any) => {
		let label = option.name ?? '';
		if (sm && !_.isNil(option.email)) {
			label = `${label} (${option.email})`;
		}
		return label;
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			width={1}
			height={1}
		>
			<OldTopBar
				title={event?.club?.name ?? undefined}
				subTitle={event?.name}
				border
				onClickLeft={() =>
					navigation.push(
						`${
							eventRoute(AppRoutes.EditEventSession) +
							(form.sessionNumber
								? `?sessionNumber=${form.sessionNumber}`
								: '')
						}`,
					)
				}
				iconLeft={<ArrowBackIcon />}
			/>

			<Box pt={4} />

			<Autocomplete
				value={value}
				fullWidth={true}
				sx={{ maxWidth: sm ? 728 : '100%', padding: '0 20px' }}
				onClose={() => {
					setValue(null);
					setInputValue('');
				}}
				onChange={(event: any, newValue: User | null) => {
					if (!_.isNil(newValue)) {
						AddTrainer(newValue.sub);
					}
					setValue(null);
					setInputValue('');
				}}
				filterOptions={(o) => (loading ? [] : o)}
				loading={loading}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				getOptionLabel={(option) => getOptionLabel(option)}
				noOptionsText={''}
				getOptionDisabled={(option) => {
					return form.trainerCodes?.includes(option?.sub) ?? false;
				}}
				options={options}
				renderOption={(params, option) => {
					return (
						<li
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'stretch',
								justifyContent: 'center',
							}}
							{...params}
							key={option.sub}
						>
							<span
								style={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{option.name ?? ''}
							</span>
							<span
								style={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{option.email}
							</span>
						</li>
					);
				}}
				renderInput={(params) => (
					<TextField {...params} label={t('coach')} />
				)}
			/>

			<Box pt={2} />

			<Typography variant="body2">{`${t('numberCoaches')}${
				form.trainersCount
			}`}</Typography>

			<Box pt={2} />

			<Box
				borderTop="1px solid #252525"
				px="20px"
				width={1}
				sx={{ overflowY: 'auto', overflowX: 'hidden' }}
				pt={2}
			>
				{form.trainerCodes?.map((code, index) => (
					<Box
						display="flex"
						p={2}
						border="1px solid #202020"
						key={index}
						width={1}
						height={66}
						bgcolor="#0d0d0d"
						alignItems="center"
						justifyContent="stretch"
						mb={2}
					>
						<Box display="flex" flex={1} justifyContent="stretch">
							<Typography variant="button">
								{!sm
									? index + 1
									: `${t('player')} ${index + 1}`}
							</Typography>

							<Box pl={1} />

							<Typography variant="button">-</Typography>

							<Box pl={1} />

							{_.isNil(_.find(trainers, { sub: code })) ? (
								<Typography
									variant="button"
									flex={1}
									paddingRight={1}
								>
									<Skeleton
										sx={{ flex: 1 }}
										height={21}
										variant="rectangular"
									></Skeleton>
								</Typography>
							) : (
								<>
									<Typography
										variant="button"
										textTransform="initial"
									>
										{_.find(trainers, { sub: code })?.name}
									</Typography>

									<Box pl={1} />

									<Typography
										hidden={!sm}
										variant="button"
										textTransform="initial"
									>
										(
										{_.find(trainers, { sub: code })?.email}
										)
									</Typography>
								</>
							)}
						</Box>
						{!!code && (
							<IconButton
								size="small"
								sx={{ bgcolor: '#730100' }}
								onClick={() => removeTrainer(code)}
							>
								<CloseIcon color="white" />
							</IconButton>
						)}
					</Box>
				))}

				{invalidCode ? (
					<>
						<Alert
							severity="error"
							onClose={() => setInvalidCode(false)}
						>
							{t('invalidCode')}
						</Alert>

						<Box pt={2} />
					</>
				) : null}

				{serverErrors.map((error) => (
					<>
						<Alert
							key={error.message}
							severity="error"
							onClose={() => setServerErrors([])}
						>
							{t('server-error:' + error.message) ||
								error.message}
						</Alert>

						<Box pt={2} />
					</>
				))}

				<Box display="flex" justifyContent="center">
					<Button
						variant="contained"
						color="primary"
						onClick={onSubmit}
					>
						{form.sessionNumber ? t('finishEdit') : t('continue')}
					</Button>
				</Box>

				<Box pb={2} />
			</Box>
		</Box>
	);
};
