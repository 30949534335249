import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const PlayIcon: React.FC<CustomIconProps> = (props) => {
	const { color, strokeWidth } = useCustomIcon(props);

	return (
		<SvgIcon viewBox="0 0 24 24" sx={props.sx}>
			<defs>
				<path
					d="m13.204 2-7.418 9.97L13.204 22h4.725V2h-4.725Z"
					id="play-icon"
				/>
			</defs>
			<use
				stroke={color}
				strokeWidth={strokeWidth}
				transform="rotate(180 11.857 12)"
				xlinkHref="#play-icon"
				fillRule="evenodd"
			/>
		</SvgIcon>
	);
};
