import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { DinamicAvatarWithProgress } from 'src/Components/DinamicAvatarWithProgress';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import {
	ComparisonSelected,
	FoundComparisonType,
	PreviousSession,
	UserRole,
	WatchComparisonResult,
} from 'src/graphql/types';

type PreviousTrackingBoxProps = {
	session: PreviousSession;
	role: UserRole;
	compareFrom?: WatchComparisonResult | null;
};

export const PreviousTrackingBox: React.FC<PreviousTrackingBoxProps> = ({
	session,
	role,
	compareFrom,
}) => {
	const navigation = useNavigation();
	const { t } = useTranslation(['create-comparison', 'months']);

	const selected = navigation.query.get('selected') as ComparisonSelected;
	const subFrom = navigation.query.get('subFrom');

	const size = 40;

	const you =
		selected === ComparisonSelected.Team
			? t('team')
			: selected === ComparisonSelected.Player
			? compareFrom?.name || t('you')
			: t('you');

	const defaultRole =
		selected === ComparisonSelected.Player || role === UserRole.Player
			? UserRole.Player
			: UserRole.Trainer;

	const onClick = () => {
		if (!session.released) return;

		if (role === UserRole.Player) {
			navigation.push(
				`${AppRoutes.ViewComparison}?type=${FoundComparisonType.PlayerVsPrev}&sessionNumber=${session.sessionNumber}`,
			);
		} else if (selected === ComparisonSelected.Player) {
			navigation.push(
				`${AppRoutes.ViewComparison}?type=${FoundComparisonType.SelectedPlayerVsPrev}&subFrom=${subFrom}&sessionNumber=${session.sessionNumber}`,
			);
		} else if (selected === ComparisonSelected.Team) {
			navigation.push(
				`${AppRoutes.ViewComparison}?type=${FoundComparisonType.TeamVsPrev}&sessionNumber=${session.sessionNumber}`,
			);
		}
	};

	return (
		<Box
			display="flex"
			alignItems="center"
			py={2}
			px="20px"
			bgcolor="#151515"
			sx={{
				cursor: session.released ? 'pointer' : 'not-allowed',
				opacity: session.released ? 1 : 0.6,
			}}
			onClick={onClick}
		>
			<DinamicAvatarWithProgress
				size={size}
				iconUrl={session.avatar}
				role={defaultRole}
				score={session.value}
				disabled={!session.released}
			/>

			<Box pl={3} />

			<Typography variant="body1">
				{`${you?.split(' ')[0]} (${t(
					'months:' + new Date(session?.date).getMonth().toString(),
				).substring(0, 3)} ${new Date(session?.date)
					.getFullYear()
					.toString()
					.substring(2, 4)})`}
			</Typography>
		</Box>
	);
};
