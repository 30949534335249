import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Add as AddIcon, ArrowBackIos } from '@mui/icons-material';
import { Box, Fab, Zoom, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

import { EventDialog } from './EventDialog';
import { CreateEvent } from './Views/CreateEvent';
import { CreateEventSession } from './Views/CreateEventSession';
import { EventList } from './Views/EventList';
import { EventSessionList } from './Views/EventSessionList';

import Background1 from '../../Components/Backgrounds/Background1.svg';
import { OldTopBar } from '../../Components/OldTopBar';
import { AppRoutes } from '../../Router/AppRoutes';
import { useNavigation } from '../../Router/useNavigation';

const fabStyle: SxProps = {
	position: 'fixed',
	zIndex: 1500,
	justifySelf: 'flex-end',
};

type EventQueryType = number | 'create' | 'edit' | null;
type ViewType =
	| 'CreateEvent'
	| 'EditEvent'
	| 'EventList'
	| 'CreateEventSession'
	| 'EditEventSession'
	| 'EventSessionList';

interface EventQueries {
	event: EventQueryType;
	session: EventQueryType;
}

export const EventScreen = () => {
	const { t } = useTranslation('events');

	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));

	const { query, goBackOrGoTo } = useNavigation();
	const navigate = useNavigate();

	const parseEventQueries = useCallback(() => {
		const event = query.get('event');
		const session = query.get('session');

		const eventQueries: EventQueries = {
			event: [null, 'create', 'edit'].includes(event)
				? (event as EventQueryType)
				: _.toInteger(event) > 0
				? _.toInteger(event)
				: null,
			session: null,
		};
		if (!_.isNil(eventQueries.event)) {
			eventQueries.session = [null, 'create', 'edit'].includes(session)
				? (session as EventQueryType)
				: _.toInteger(session) > 0
				? _.toInteger(session)
				: null;
		}
		return eventQueries;
	}, [query]);

	const [eventQueries, setEventQueries] = useState<EventQueries>(
		parseEventQueries(),
	);

	const { pathname } = useLocation();

	const push = useCallback(
		(queries: Partial<EventQueries>) => {
			const s = _.entries(_.defaultsDeep(queries, eventQueries))
				.filter(([__, value]) => !_.isNil(value))
				.map((entry) => entry.join('='))
				.join('&')
				.trim();
			navigate(`${pathname}${_.isEmpty(s) ? '' : `?${s}`}`);
		},
		[navigate, eventQueries, pathname],
	);

	const viewVisible = useCallback(
		(view: ViewType, top = false) => {
			const { event: e, session: s } = eventQueries;
			switch (view) {
				case 'CreateEvent':
					return e === 'create';
				case 'EditEvent':
					return e === 'edit';
				case 'EventList':
					return _.isNil(e) || !top;
				case 'CreateEventSession':
					return (e ?? 0) > 0 && s === 'create';
				case 'EditEventSession':
					return (e ?? 0) > 0 && s === 'edit';
				case 'EventSessionList':
					return (e ?? 0) > 0 && (_.isNil(s) || !top);
				default:
					return false;
			}
		},
		[eventQueries],
	);

	const [index, _setIndex] = useState(0);

	const transitionDuration = {
		enter: theme.transitions.duration.enteringScreen,
		exit: theme.transitions.duration.leavingScreen,
	};

	const fabs = [
		{
			color: 'primary' as const,
			sx: fabStyle as SxProps,
			icon: <AddIcon />,
		},
		{
			color: 'primary' as const,
			sx: fabStyle as SxProps,
			icon: <AddIcon />,
		},
	];

	const setIndex = useCallback(
		(i: number) => _setIndex(i % (fabs.length + 1)),
		[fabs.length],
	);

	useEffect(() => {
		const eventQueries = parseEventQueries();
		setEventQueries(eventQueries);
	}, [setEventQueries, parseEventQueries]);

	useEffect(() => {
		setIndex(
			viewVisible('EventList', true)
				? 0
				: viewVisible('EventSessionList', true)
				? 1
				: 2,
		);
	}, [setIndex, viewVisible]);

	const [rect, setRect] = useState<DOMRect | null>(null);

	return (
		<>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent={'stretch'}
				height={1}
				sx={{
					backgroundImage: `url(${Background1})`,
					backgroundSize: 'cover',
				}}
			>
				<Box flexGrow={0} width={1} height={'fit-content'}>
					<OldTopBar
						iconLeft={<ArrowBackIos />}
						onClickLeft={() => goBackOrGoTo(AppRoutes.Scoring)}
						title={t('events')}
						border
					/>
				</Box>
				<Box
					flex={1}
					width={1}
					display={'flex'}
					alignItems={'flex-start'}
					justifyContent={'center'}
					sx={{ overflowY: 'auto', overflowX: 'hidden' }}
				>
					<Box
						display={'flex'}
						justifyContent={'flex-end'}
						flex={1}
						p={sm ? '44px' : '20px'}
						maxWidth={960}
						sx={{
							'& > .fab-button': {
								marginRight: sm
									? '-28px !important'
									: '-10px !important',
								bottom: sm ? '16px' : '10px',
							},
						}}
					>
						<EventList
							onRowClick={({ eventNumber: id }, rect) => {
								setRect(rect);
								push({ event: id > 0 ? id : null });
							}}
						/>
						{fabs.map((fab, i) => (
							<Zoom
								key={`fab-${fab.color}-${i}`}
								in={index === i}
								timeout={transitionDuration}
								style={{
									transitionDelay: `${
										index === i
											? transitionDuration.exit
											: 0
									}ms`,
								}}
								unmountOnExit
							>
								<Fab
									onClick={() => {
										if (viewVisible('EventList', true)) {
											push({ event: 'create' });
										} else if (
											viewVisible(
												'EventSessionList',
												true,
											)
										) {
											push({ session: 'create' });
										}
									}}
									className={'fab-button'}
									sx={fab.sx}
									size={sm ? 'large' : 'medium'}
									color={fab.color}
								>
									{fab.icon}
								</Fab>
							</Zoom>
						))}
					</Box>
				</Box>
			</Box>

			<EventDialog
				isOpen={viewVisible('CreateEvent')}
				onClose={() => {
					goBackOrGoTo(AppRoutes.Events);
				}}
				title={'create-event'}
				transition={'Slide'}
			>
				<CreateEvent />
			</EventDialog>

			<EventDialog
				isOpen={viewVisible('EventSessionList')}
				onClose={() => {
					goBackOrGoTo(AppRoutes.Events);
				}}
				title={'event-sessions'}
				transition={'Grow'}
				rect={rect}
			>
				<EventSessionList />
			</EventDialog>

			<EventDialog
				isOpen={viewVisible('CreateEventSession')}
				onClose={() => {
					goBackOrGoTo(AppRoutes.Events);
				}}
				title={'create-event-session'}
				transition={'Slide'}
			>
				<CreateEventSession />
			</EventDialog>
		</>
	);
};
