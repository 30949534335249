import _ from 'lodash';
import moment from 'moment';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ScoutingEvent } from '../../../graphql/types';
import { useListEventsQuery } from '../../../redux/rtk-query';
import { CollapsibleTable, HeadCell } from '../Components/DataTable';

export const EventList = ({
	onRowClick,
}: {
	onRowClick: (row: ScoutingEvent, rect: DOMRect) => void;
}) => {
	const { t } = useTranslation('events');
	const theme = useTheme();

	const u480 = useMediaQuery(theme.breakpoints.down(480));
	const u640 = useMediaQuery(theme.breakpoints.down(640));
	const u840 = useMediaQuery(theme.breakpoints.down(840));
	const u960 = useMediaQuery(theme.breakpoints.down(960));

	const headCells: HeadCell[] = [
		{
			sort: 'name',
			label: _.upperCase(t('name')),
		},
		{
			sort: 'club.name',
			label: _.upperCase(t('club-name')),
			hidden: u840,
		},
		{
			sort: 'start',
			label: _.upperCase(t('start')),
			hidden: u480,
			sx: { width: 0, whiteSpace: 'nowrap' },
		},
		{
			sort: 'end',
			label: _.upperCase(t('end')),
			hidden: u640,
			sx: { width: 0, whiteSpace: 'nowrap' },
		},
		{
			sort: 'players.length',
			label: _.upperCase(t('player')),
			sx: { textAlign: 'right', width: 0, whiteSpace: 'nowrap' },
		},
	];

	const queryResult = useListEventsQuery({});
	return (
		<Fragment>
			<CollapsibleTable
				loading={queryResult.isLoading}
				rows={_.orderBy(
					queryResult?.data?.listEvents ?? [],
					'eventNumber',
					'desc',
				)}
				keyName={'eventNumber'}
				headCells={headCells}
				onRowClick={onRowClick}
				renderRowCells={(row) => (
					<>
						<TableCell>{row.name}</TableCell>
						<TableCell hidden={u840}>{row.club.name}</TableCell>
						<TableCell
							hidden={u480}
							sx={{ width: 0, whiteSpace: 'nowrap' }}
						>
							{moment(row.start).format(!u960 ? 'L LT' : 'L')}
						</TableCell>
						<TableCell
							hidden={u640}
							sx={{ width: 0, whiteSpace: 'nowrap' }}
						>
							{moment(row.end).format(!u960 ? 'L LT' : 'L')}
						</TableCell>
						<TableCell
							align="right"
							sx={{ width: 0, whiteSpace: 'nowrap' }}
						>
							{row.players.length}
						</TableCell>
					</>
				)}
				empty={t('no-sessions')}
				rowsPerPageLabel={t('rows-per-page')}
			/>
		</Fragment>
	);
};
