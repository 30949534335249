import { CSSProperties } from 'react';

export const statsOverTimeStyles: { [key: string]: CSSProperties } = {
	container: {
		height: 'auto',
		pointerEvents: 'auto',
		maxWidth: 600,
		touchAction: 'auto',
	},
	axis: {
		stroke: '#777777',
	},
	tickLabels: {
		fill: '#777777',
		fontFamily: 'Inter',
		fontSize: 12,
		fontWeight: 500,
	},
	grid: { stroke: '#202020' },
	gridDashed: { stroke: '#202020', strokeDasharray: 3 },
	scatter: {
		fill: '#3BFF51',
		stroke: 'white',
		strokeWidth: 4,
	},
	line: {
		stroke: 'url(#linearGradient)',
		strokeWidth: 8,
	},
	label: {
		fontFamily: 'Inter',
		fontSize: 14,
		fill: '#777777',
		fontWeight: 400,
	},
};
