import { Navigate, RouteObject } from 'react-router-dom';

import { EventScreen } from '../../Screens/Events/EventScreen';
import { AppRoutes } from '../AppRoutes';

export default [
	{
		path: '',
		element: <EventScreen />,
	},
	{
		path: '*',
		element: <Navigate replace to={AppRoutes.Scoring} />,
	},
] as RouteObject[];
