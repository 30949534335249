import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { useAuth } from 'src/Auth/useAuth';
import { PullToRefresh } from 'src/Components/PullToRefresh';
import { WatchComparisonResult } from 'src/Screens/Dashboard/Comparison/WatchComparisonResult';
import { useSessions } from 'src/Sessions/useSessions';
import { useWatchComparisons } from 'src/WatchComparisons/useWatchComparisons';

import { ComparisonSelector } from './ComparisonSelector';
import { NoSessionAdded } from './NoSessionAdded';

export const ComparisonScreen: React.FC = () => {
	const { t } = useTranslation('comparison');
	const {
		sessions,
		loading: loadingSessions,
		refetch: refetchSessions,
	} = useSessions();
	const { refetch: refetchUser } = useAuth();
	const {
		watchComparisons,
		refetch: refetchWatchList,
		loading: loadingWatchList,
	} = useWatchComparisons();
	const scrollableRef = useRef<HTMLDivElement | undefined>();

	return (
		<>
			<PullToRefresh
				scrollableRef={scrollableRef}
				onRefresh={() => {
					refetchUser();
					refetchSessions();
					refetchWatchList();
				}}
			/>

			<Box
				ref={scrollableRef}
				display="flex"
				flexDirection="column"
				flexGrow={1}
				height={1}
				sx={{ overflowY: 'auto' }}
			>
				{loadingSessions && (
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						flexGrow={1}
					>
						<Skeleton width={250} />
						<Skeleton width={300} />
						<Box pt={3} />
						<Skeleton
							variant="rectangular"
							width={139}
							height={48}
						/>
					</Box>
				)}
				{!loadingSessions && !sessions?.length && <NoSessionAdded />}
				{!loadingSessions && !!sessions?.length && (
					<>
						<ComparisonSelector />

						<Box pt={2} />

						<Typography
							variant="subtitle2"
							color="inherit"
							align="center"
						>
							{t('watchList')}
						</Typography>

						<Box pt={2} />

						<Box
							px="20px"
							width={1}
							display="flex"
							alignItems="center"
							flexDirection="column"
						>
							<Box width={1} maxWidth={500}>
								{loadingWatchList && (
									<>
										<Skeleton
											variant="rectangular"
											height={160}
										/>

										<Box pt={2} />

										<Skeleton
											variant="rectangular"
											height={160}
										/>
									</>
								)}
								{!loadingWatchList &&
									watchComparisons?.map((watchComparison) => (
										<Box width={1} key={watchComparison.id}>
											<WatchComparisonResult
												watchComparison={
													watchComparison
												}
											/>
											<Box pt={2} />
										</Box>
									))}
							</Box>
						</Box>
					</>
				)}
			</Box>
		</>
	);
};
