import moment from 'moment';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { CustomAccordion } from 'src/Components/CustomAccordion';
import { OldTopBar } from 'src/Components/OldTopBar';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useOperatorSessionsQuery } from 'src/graphql/types';

import { DetailsCard } from './DetailsCard';

export const CurrentSessionsScreen = () => {
	const { t } = useTranslation('current-session');
	const navigation = useNavigation();

	const { data, loading } = useOperatorSessionsQuery();

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			height={1}
			sx={{
				backgroundImage: `url(${Background1})`,
				backgroundSize: 'cover',
			}}
		>
			<OldTopBar
				iconLeft={<ArrowBackIcon />}
				onClickLeft={() => navigation.replace(AppRoutes.Scoring)}
				title={t('creator')}
				border
			/>

			<Box
				px="20px"
				width={1}
				sx={{ overflowY: 'auto', overflowX: 'hidden' }}
			>
				<Box pt={4} />

				<Typography variant="h5" align="center">
					{t('title')}
				</Typography>

				<Box pt={4} />

				{loading && (
					<Skeleton
						height={58}
						width="100%"
						variant="rectangular"
					></Skeleton>
				)}

				{!loading && !data?.operatorSessions.length && (
					<>
						<Box pt={4} />
						<Typography variant="body2" align="center">
							{t('noSessions')}
						</Typography>
					</>
				)}

				{!loading &&
					data?.operatorSessions?.map((operatorSession) => (
						<Fragment key={operatorSession.sessionNumber}>
							<CustomAccordion
								title={(open) => (
									<>
										<Typography
											noWrap
											variant="body1"
											fontWeight={500}
											maxWidth={120}
										>
											{`${operatorSession.club.name}`}
										</Typography>

										<Typography
											variant="body1"
											fontWeight={500}
										>
											{`-`}
										</Typography>

										<Typography
											variant="body1"
											fontWeight={500}
										>
											{`${operatorSession.ageGroup}`}
										</Typography>

										<Box pl={1} />
										<Typography
											variant="caption"
											fontWeight={500}
										>
											{moment(
												operatorSession.date,
											).format('L')}
										</Typography>

										<Box pl={1} />
										<Typography
											variant="inherit"
											color={
												open
													? 'black'
													: operatorSession.scored
													? 'primary'
													: 'error'
											}
											fontWeight={900}
										>
											{operatorSession.sessionNumber}
										</Typography>
									</>
								)}
								content={
									<DetailsCard session={operatorSession} />
								}
							/>
							<Box pt={2} />
						</Fragment>
					))}

				<Box pt={18} />
			</Box>

			<Box position="absolute" bottom={0} pb={6}>
				<Button
					color="primary"
					variant="contained"
					onClick={() => navigation.push(AppRoutes.EditSession)}
				>
					{t('addSession')}
				</Button>
			</Box>
		</Box>
	);
};
