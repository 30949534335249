import i18n from 'i18next';
import moment from 'moment';
import * as yup from 'yup';

import { apolloClient } from 'src/Apollo/ApolloClient';
import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import CHECK_EMAIL_EXISTS_QUERY from 'src/graphql/documents/queries/checkEmailExist';
import CHECK_USERNAME_EXISTS_QUERY from 'src/graphql/documents/queries/checkUserNameExist';
import VALIDATE_EMAIL_QUERY from 'src/graphql/documents/queries/validateEmail';
import {
	CheckEmailExistQuery,
	CheckUserNameExistQuery,
	SeparatedDate,
	ValidateEmailQuery,
} from 'src/graphql/types';
import { checkIfOlderThan } from 'src/helpers/checkIfOlderThan';

declare module 'yup' {
	interface StringSchema {
		username(): StringSchema;
		emailExists(): StringSchema;
		validEmail(): StringSchema;
	}

	interface BaseSchema {
		separatedDate(): BaseSchema;
		birthdate(): BaseSchema;
		isAdult(): BaseSchema;
	}
}

yup.addMethod(yup.string, 'username', function () {
	return this.test(
		'username',
		i18n.t('validations:username'),
		async (value) => {
			const { data, errors } = await apolloClient
				.query<CheckUserNameExistQuery>({
					query: CHECK_USERNAME_EXISTS_QUERY,
					variables: {
						input: {
							username: value,
						},
					},
				})
				.catch(convertNetworkErrors);

			if (errors) {
				return false;
			}

			return !data?.checkUserNameExist.exist;
		},
	);
});

yup.addMethod(yup.string, 'validEmail', function () {
	return this.test(
		'validEmail',
		i18n.t('validations:email'),
		async (value) => {
			const { data, errors } = await apolloClient
				.query<ValidateEmailQuery>({
					query: VALIDATE_EMAIL_QUERY,
					variables: {
						input: {
							email: value,
						},
					},
				})
				.catch(convertNetworkErrors);

			if (errors) {
				return false;
			}

			return !!data?.validateEmail.valid;
		},
	);
});

yup.addMethod(yup.string, 'emailExists', function () {
	return this.test(
		'emailExists',
		i18n.t('validations:emailExists'),
		async (value) => {
			const { data, errors } = await apolloClient
				.query<CheckEmailExistQuery>({
					query: CHECK_EMAIL_EXISTS_QUERY,
					variables: {
						input: {
							email: value,
						},
					},
				})
				.catch(convertNetworkErrors);

			if (errors) {
				return false;
			}

			return !data?.checkEmailExist.exist;
		},
	);
});

yup.addMethod(yup.object, 'separatedDate', function () {
	return this.test(
		'separatedDate',
		i18n.t('validations:separated_date'),
		(value) => {
			if (!value.year) return false;
			if (!value.month) return false;
			if (!value.day) return false;

			const date = `${value.day}/${value.month}/${value.year}`;

			return moment(date, 'DD/MM/YYYY', true).isValid();
		},
	);
});

yup.addMethod(yup.object, 'isAdult', function () {
	return this.test('isAdult', i18n.t('validations:adult'), (value) => {
		if (value.year?.length && value.year.length < 4) return false;

		return checkIfOlderThan(value as SeparatedDate, 18);
	});
});

yup.addMethod(yup.object, 'birthdate', function () {
	return this.test(
		'birthdate',
		i18n.t('validations:separated_date'),
		(value) => {
			if (value.year < 1900) return false;

			const date = `${value.day}/${value.month}/${value.year}`;
			const today = moment();
			const momentDate = moment(date, 'DD/MM/YYYY', true);

			return today.isAfter(momentDate);
		},
	);
});
