import _ from 'lodash';
import React, { SyntheticEvent, useMemo, useRef, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ArrowForwardIosSharp } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import {
	AccordionDetails,
	AccordionProps,
	AccordionSummary,
	Box,
	Button,
	FormControl,
	FormLabel,
	Accordion as MuiAccordion,
	Slider,
	Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

import { Assessment, Skill, SpiderScore } from './SpiderScore';

import { getLocalStorageAccessToken } from '../../Auth/localStorageAccessToken';
import Background1 from '../../Components/Backgrounds/Background1.svg';
import { useNavigation } from '../../Router/useNavigation';
import { useSessions } from '../../Sessions/useSessions';
import { UserRole } from '../../graphql/types';

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props}>
		{props.children}
	</MuiAccordion>
))(({ theme }) => ({
	border: 'none',
	background: 'transparent',
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
	'& .MuiAccordionSummary-root': {
		padding: 0,
		minHeight: 'unset',
		flexDirection: 'row-reverse',
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(90deg)',
		},
		'& .MuiAccordionSummary-content': {
			margin: 0,
			marginLeft: theme.spacing(1),
			paddingRight: 0,
			display: 'flex',
			justifyContent: 'stretch',
			'& > *': {
				flex: 1,
			},
		},
		'&.Mui-expanded': {
			backgroundImage: 'unset',
			'& .MuiSvgIcon-root': {
				color: 'white',
			},
		},
	},
}));

export const SelfAssessment = () => {
	const { t: tInfo } = useTranslation('performance-stats');
	const { t } = useTranslation('self-assessment');
	const navigation = useNavigation();

	const info: Record<string, string> = tInfo('info', {
		returnObjects: true,
	});

	const accessToken = getLocalStorageAccessToken();

	const { selectedRole, assessment, setAssessment } = useSessions();
	const form: UseFormReturn<Assessment> = useForm<Assessment>({
		defaultValues: assessment,
	});
	const { setValue } = form;
	const [last, setLast] = useState(form.getValues());
	const [current, setCurrent] = useState(form.getValues());

	useMemo(() => {
		setLast(assessment);
		setCurrent(assessment);
		_.entries(assessment).forEach(([k, v]) =>
			setValue(k as keyof Assessment, v),
		);
	}, [assessment, setValue]);

	const skills: Skill[] = useMemo(
		() => [
			'control',
			'impact',
			'passing',
			'physical',
			'dribbling',
			'defense',
			// 'shot',
			// 'pace',
		],
		[],
	);
	const [expanded, setExpanded] = useState<Skill | false>(false);
	const handleChange =
		(panel: Skill) => (event: SyntheticEvent, newExpanded: boolean) => {
			const isSlider = Array.from(
				(event?.target as HTMLElement)?.classList,
			)
				.join()
				.includes('MuiSlider');
			if (isSlider) {
				return;
			}
			setExpanded(newExpanded ? panel : false);
		};

	const { handleSubmit } = form;
	const locked = useRef(false);
	const onSubmit = handleSubmit(async (data) => {
		if (locked.current) {
			return;
		}
		locked.current = true;

		fetch(
			`${process.env.REACT_APP_KICKID_API}/${
				selectedRole === UserRole.Trainer ? 'team' : 'self'
			}-assessment`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify(data),
			},
		)
			.then(() => setLast(data))
			.then(() => setAssessment(data))
			.then(() => navigation.setQuery('action', null))
			.finally(() => (locked.current = false));
	});

	return (
		<Box
			flex={1}
			flexDirection={'column'}
			display={'flex'}
			justifyContent={'flex-start'}
			alignItems={'center'}
			sx={{
				margin: '0 auto',
				'& > *:not(.fullwidth)': {
					maxWidth: 500,
					flex: 1,
				},
			}}
		>
			<Box
				className={'fullwidth'}
				sx={{
					zIndex: 1,
					maxHeight: '280px',
					width: '100%',
					backgroundImage: `url(${Background1})`,
					backgroundPositionY: '-88px',
					backgroundSize: 'cover',
					position: 'sticky',
					top: 0,
					'& svg': {
						overflow: 'visible',
					},
					'& *': {
						maxHeight: '280px',
					},
				}}
			>
				<SpiderScore
					current={current}
					last={last}
					skills={skills}
					values={_.map(skills, (s) => _.get(current, s))}
					readOnly={false}
				/>
			</Box>
			<Box
				display={'flex'}
				flexDirection={'column'}
				alignItems={'stretch'}
				justifyContent={'stretch'}
				component={'form'}
				onSubmit={onSubmit}
				sx={{
					padding: '0 16px',
					overflowX: 'hidden',
				}}
			>
				{_.map(skills, (s, i) => (
					<Box key={s}>
						{i > 0 ? <Divider sx={{ mx: '-20px' }} /> : null}
						<Accordion
							expanded={expanded === skills[i]}
							onChange={handleChange(skills[i])}
							sx={{
								paddingTop: 1,
								paddingBottom: 1,
							}}
						>
							<AccordionSummary
								expandIcon={
									<ArrowForwardIosSharp
										sx={{ fontSize: '0.9rem' }}
									/>
								}
							>
								<FormControl
									variant={'filled'}
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<FormLabel
										sx={{
											width: '65px',
											textAlign: 'left',
										}}
									>
										<Typography
											fontSize={'medium'}
											textTransform={'capitalize'}
											color={'white'}
										>
											{skills[i]}
										</Typography>
									</FormLabel>
									<Box
										margin={'0 16px'}
										flex={1}
										display={'flex'}
										alignItems={'center'}
									>
										<Slider
											sx={{
												padding: '16px 8px !important',
											}}
											size="small"
											value={_.get(current, s)}
											valueLabelDisplay="off"
											disableSwap
											onChange={(e, v) => {
												setValue(s, v as number);
												setCurrent(form.getValues());
											}}
										/>
									</Box>
									<FormLabel
										sx={{
											width: '40px',
											textAlign: 'right',
										}}
									>
										<Typography
											color={'primary'}
											fontSize={'medium'}
											textTransform={'uppercase'}
										>
											{_.get(current, s)}
										</Typography>
									</FormLabel>
								</FormControl>
							</AccordionSummary>
							<AccordionDetails
								sx={{
									padding: '0 40px 8px 14.39px',
									marginLeft: 1,
								}}
							>
								<Typography
									fontSize={'11px'}
									color={'white'}
									sx={{ opacity: 0.61 }}
								>
									{info[_.capitalize(s)]}
								</Typography>
							</AccordionDetails>
						</Accordion>
					</Box>
				))}
				<Divider sx={{ mx: '-20px' }} />
				<Box pt={2} />
				<Button
					sx={{
						maxWidth: 'fit-content',
						margin: '0 auto',
						transitionProperty: 'none',
					}}
					disabled={_.isEqual(last, current)}
					variant={
						_.isEqual(last, current) ? 'outlined' : 'contained'
					}
					type={'submit'}
					color={'primary'}
				>
					<SaveIcon fontSize="small" />
					<Box ml={1} />
					{t('submit-button')}
				</Button>
				<Box pt={2} />
			</Box>
		</Box>
	);
};
