import { GraphQLError } from 'graphql';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { OldTopBar } from 'src/Components/OldTopBar';
import { FormLayout } from 'src/Form/FormLayout';
import { useCustomForm } from 'src/Form/useCustomForm';
import { useGeolocation } from 'src/Plugins/useGeolocation';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import {
	useCreateEventSessionMutation,
	useDeleteEventSessionMutation,
	useGetEventSessionLazyQuery,
	useUpdateEventSessionMutation,
} from 'src/graphql/types';

import { CreateEventSessionsControllers } from './CreateEventSessionsScreen.controllers';

import { CreateSessionFormData } from '../../SessionCreator/AdminForm';
import { useCreateSessionValidations } from '../../SessionCreator/CreateSession/CreateSession.validations';
import { useEvent } from '../useEvent';

export const CreateEventSessionsScreen = () => {
	const { event } = useEvent();
	const eventRoute = (route: AppRoutes) => {
		return route.replace(':eventNumber', `${event?.eventNumber ?? 0}`);
	};

	const [createEventSessionMutation] = useCreateEventSessionMutation();
	const [updateEventSessionMutation] = useUpdateEventSessionMutation();
	const [deleteEventSessionMutation] = useDeleteEventSessionMutation();
	const [getEventSession, { data, loading }] = useGetEventSessionLazyQuery();

	const { t } = useTranslation(['create-event-session', 'server-error']);
	const { form, setForm, resetForm } = useCustomForm<CreateSessionFormData>();
	const resolver = useCreateSessionValidations();
	const getGeolocation = useGeolocation();
	const formUseForm = useForm<CreateSessionFormData>({
		defaultValues: Object.assign(
			{
				club: event?.club,
				ageGroup: '',
				fixedGoalkeepers: event?.fixedGoalKeepers,
				sprintTest: event?.sprintTest,
				shotTest: event?.shotTest,
				playersCount: 0,
				trainersCount: 0,
				trainerCodes: [],
				playerCodes: [],
				matchesCount: 0,
				processed: false,
				ground: { goalWidth: 5, goalHeight: 2 },
			},
			form,
		),
		resolver,
	});

	const { handleSubmit, setValue } = formUseForm;
	const [serverErrors, setServerErrors] = useState<readonly GraphQLError[]>(
		[],
	);

	const navigation = useNavigation();
	const sessionNumber = _.toInteger(
		navigation.query.get('sessionNumber') as string,
	);
	const confirm = navigation.query.get('confirm') !== null;
	const [loadingSave, setLoadingSave] = useState(false);
	const resetFormRef = useRef<boolean>();
	const updateFromRef = useRef<boolean>();

	useEffect(() => {
		if (sessionNumber && !loading && !data) {
			getEventSession({
				variables: {
					eventNumber: event?.eventNumber ?? 0,
					sessionNumber,
				},
			});
		}

		if (data && !loading && sessionNumber && !updateFromRef.current) {
			const session = data?.getEventSession;

			if (session) {
				setForm({ ...form, ...session });

				setValue('ageGroup', session.ageGroup);
				setValue('club', session.club);
				setValue('fixedGoalkeepers', session.fixedGoalkeepers);
				setValue('sprintTest', session.sprintTest);
				setValue('shotTest', session.shotTest);
				setValue('playersCount', session.playersCount);
				setValue('trainersCount', session.trainersCount);
				setValue('trainerCodes', Array(session.trainersCount).fill(''));
				setValue('processed', session.processed);
				if (session.ground) setValue('ground', session.ground);
				setValue('processed', session.processed);
			} else {
				navigation.setQuery('sessionNumber', null);
			}

			updateFromRef.current = true;
		}

		if (!sessionNumber && !confirm && !resetFormRef.current) {
			resetForm();
			resetFormRef.current = true;
		}

		if (confirm && Object.keys(form).length === 0) {
			navigation.setQuery('confirm', null);
		}
	}, [
		sessionNumber,
		data,
		loading,
		confirm,
		getEventSession,
		form,
		navigation,
		resetForm,
		setForm,
		setValue,
		event,
	]);

	const onSubmitCreate = handleSubmit(async (formData) => {
		setForm({ ...form, ...formData });

		if (confirm) {
			setServerErrors([]);
			setLoadingSave(true);
			const geolocation = await getGeolocation();

			const { data, errors } = await createEventSessionMutation({
				variables: {
					eventNumber: event?.eventNumber ?? 0,
					args: {
						ageGroup: form.ageGroup,
						fixedGoalkeepers: form.fixedGoalkeepers,
						sprintTest: form.sprintTest,
						shotTest: form.shotTest,
						clubId: form.club.id as number,
						playerCodes: form.playerCodes as string[],
						trainerCodes: form.trainerCodes as string[],
						geolocation: geolocation,
						ground: {
							goalHeight: parseFloat(
								form.ground?.goalHeight?.toString() || '2',
							),
							goalWidth: parseFloat(
								form.ground?.goalWidth?.toString() || '5',
							),
						},
					},
				},
			}).catch(convertNetworkErrors);

			setLoadingSave(false);

			if (errors) setServerErrors(errors);

			if (!_.isNil(data?.createEventSession)) {
				navigation.replace(eventRoute(AppRoutes.ProcessEventSession));
			}
		} else {
			navigation.push(eventRoute(AppRoutes.AddEventSessionCoach));
		}
	});

	const onSubmitToCoach = handleSubmit(async (formData) => {
		setForm({ ...form, ...formData });

		navigation.push(
			`${eventRoute(
				AppRoutes.AddEventSessionCoach,
			)}?sessionNumber=${sessionNumber}`,
		);
	});

	const onSubmitDelete = async () => {
		setServerErrors([]);
		setLoadingSave(true);

		const { data, errors } = await deleteEventSessionMutation({
			variables: {
				eventNumber: event?.eventNumber ?? 0,
				sessionNumber,
			},
		}).catch(convertNetworkErrors);

		setLoadingSave(false);

		if (errors) setServerErrors(errors);

		if (data?.deleteEventSession) {
			navigation.replace(eventRoute(AppRoutes.CurrentEventSessions));
		}
	};

	const onSubmitEdit = handleSubmit(async (formData) => {
		setServerErrors([]);
		setLoadingSave(true);

		const { data, errors } = await updateEventSessionMutation({
			variables: {
				eventNumber: event?.eventNumber ?? 0,
				session: {
					sessionNumber,
					ageGroup: formData.ageGroup,
					fixedGoalkeepers: formData.fixedGoalkeepers,
					sprintTest: formData.sprintTest,
					shotTest: formData.shotTest,
					clubId: formData.club.id,
					// ground: {
					// 	goalHeight: parseFloat(
					// 		formData.ground?.goalHeight?.toString() || '2',
					// 	),
					// 	goalWidth: parseFloat(
					// 		formData.ground?.goalWidth?.toString() || '5',
					// 	),
					// },
				},
			},
		}).catch(convertNetworkErrors);

		setLoadingSave(false);

		if (errors) setServerErrors(errors);

		if (data?.UpdateEventSession) {
			navigation.replace(eventRoute(AppRoutes.CurrentEventSessions));
		}
	});

	const confirmButton = confirm
		? {
				onClickLeft: () =>
					navigation.push(
						AppRoutes.AddEventSessionPlayer.replace(
							':eventNumber',
							`${event?.eventNumber ?? 0}`,
						),
					),
		  }
		: {
				onClickLeft: () =>
					navigation.replace(
						AppRoutes.CurrentEventSessions.replace(
							':eventNumber',
							`${event?.eventNumber ?? 0}`,
						),
					),
		  };

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			width={1}
			height={1}
		>
			<OldTopBar
				title={event?.club?.name ?? undefined}
				subTitle={event?.name}
				border
				iconLeft={<ArrowBackIcon />}
				{...confirmButton}
			/>

			<Box width={1} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
				<FormLayout
					onSubmit={onSubmitCreate}
					title={
						confirm
							? t('titleConfirm')
							: sessionNumber
							? moment(form.date).format('L')
							: t('enterSessionInfo')
					}
					button={
						<>
							{(confirm || sessionNumber == 0) && (
								<Button
									onClick={() => onSubmitCreate()}
									fullWidth
									variant="contained"
									color="primary"
									disabled={loading}
								>
									{confirm
										? t('confirmStart')
										: t('createSession')}
								</Button>
							)}

							{!confirm && sessionNumber != 0 && (
								<>
									<Button
										onClick={() => onSubmitToCoach()}
										fullWidth
										variant={
											form.processed
												? 'contained'
												: 'outlined'
										}
										color={
											form.processed
												? 'primary'
												: 'inherit'
										}
										disabled={loading}
									>
										{t('addCoach')}
									</Button>

									{!form.processed && (
										<>
											<Box pt={2} />

											<Button
												onClick={() => onSubmitEdit()}
												fullWidth
												variant="contained"
												color="primary"
												disabled={loading}
											>
												{t('finishEdit')}
											</Button>

											<Box pt={6} />

											<Button
												color="inherit"
												fullWidth
												variant="outlined"
												onClick={() => onSubmitDelete()}
												disabled={loading}
											>
												{t('deleteSession')}
											</Button>

											<Box pt={3} />

											<Typography
												variant="body2"
												color="inherit"
												align="center"
											>
												{t('deleteSessionText')}
											</Typography>
										</>
									)}
								</>
							)}
						</>
					}
					controls={
						<>
							<CreateEventSessionsControllers
								loading={!!sessionNumber && loading}
								form={formUseForm}
								edit={!!sessionNumber}
								confirm={confirm}
							/>

							{loadingSave && (
								<>
									<Box pt={3} />
									<CircularProgress color="inherit" />
								</>
							)}

							{serverErrors.map((error) => (
								<>
									<Box pt={3} />
									<Alert key={error.message} severity="error">
										{t('server-error:' + error.message) ||
											error.message}
									</Alert>
								</>
							))}
						</>
					}
				/>
			</Box>
		</Box>
	);
};
