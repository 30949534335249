import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';

import { useAuth } from 'src/Auth/useAuth';
import { ShareDataIcon } from 'src/Components/Icons/ShareDataIcon';
import { useSocialShare } from 'src/Plugins/useSocialShare';

const convertBlobToBase64 = (blob: Blob) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.readAsDataURL(blob);
	});

export const ShareScoreCard: React.FC = () => {
	const { t } = useTranslation('score-card');
	const { user } = useAuth();
	const [loaded, setLoaded] = useState(false);
	const [sharing, setSharing] = useState(false);
	const { share, canShare } = useSocialShare();

	const handleImageLoad = () => {
		setLoaded(true);
	};

	return (
		<Box
			height={1}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
			alignItems="center"
		>
			<Box />

			{!loaded && (
				<Box
					width={1}
					maxWidth={500}
					border="1px solid #252525"
					paddingTop="125%"
					position="relative"
				>
					<CircularProgress
						sx={{
							position: 'absolute',
							left: 'calc(50% - 20px)',
							bottom: 'calc(50% - 20px)',
						}}
					/>
				</Box>
			)}

			{!!user && (
				<Box
					display={loaded ? 'flex' : 'none'}
					width={1}
					alignItems="center"
					justifyContent="center"
				>
					<CardMedia
						sx={{
							maxWidth: 500,
							border: '1px solid #252525',
						}}
						onLoad={handleImageLoad}
						component="img"
						src={`${process.env.REACT_APP_KICKID_API_SCORECARD}/${user?.sub}/player-score-card.png`}
					/>
				</Box>
			)}

			<Box py={4}>
				{canShare && (
					<Button
						variant={
							!user || !loaded || sharing
								? 'outlined'
								: 'contained'
						}
						color={
							!user || !loaded || sharing ? 'inherit' : 'primary'
						}
						startIcon={
							!user || !loaded || sharing ? (
								<CircularProgress size={20} color="inherit" />
							) : (
								<ShareDataIcon color="black" />
							)
						}
						disabled={!user || !loaded || sharing}
						onClick={async () => {
							setSharing(true);

							const image = `${process.env.REACT_APP_KICKID_API_SCORECARD}/${user?.sub}/player-score-card.png`;

							const response = await fetch(image);
							const blob = await response.blob();
							const base64Data = await convertBlobToBase64(blob);

							const result = Capacitor.isNativePlatform()
								? await Filesystem.writeFile({
										path: `player-score-card.png`,
										data: base64Data as string,
										directory: Directory.External,
								  })
								: { uri: image };

							share(t('kickidScoreCard'), result.uri);

							setSharing(false);
						}}
					>
						{t('shareScorecard')}
					</Button>
				)}
			</Box>
		</Box>
	);
};
