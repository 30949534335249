import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';

import { useAuth } from 'src/Auth/useAuth';
import { CustomDialog } from 'src/Components/CustomDialog';
import { FullScreenDialog } from 'src/Components/FullScreenDialog';
import { FullScreenDialogSecondary } from 'src/Components/FullScreenDialogSecondary';
import { PullToRefresh } from 'src/Components/PullToRefresh';
import { SafeAreaTop } from 'src/Components/SafeArea';
import { useNotifications } from 'src/Notifications/useNotifications';
import { AppRoutes, ScoringDialogs } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { UserScoreProvider } from 'src/Sessions/UserSessionProvider';
import { useSessions } from 'src/Sessions/useSessions';
import { Score, UserRole } from 'src/graphql/types';

import { BackgroundImage } from './BackgroundImage';
import { ContactUsScreen } from './Dialogs/ContactUsScreen';
import { HowArrangeTrackingScreen } from './Dialogs/HowArrangeTracking';
import { HowItWorkScreen } from './Dialogs/HowItWorkScreen';
import { ImagesDialog } from './Dialogs/ImagesDialog';
import { LegalGuardianEmailDialog } from './Dialogs/LegalGuardianEmailDialog';
import { QrCodeDialog } from './Dialogs/QrCodeDialog';
import { SelfAssessmentDialog } from './Dialogs/SelfAssessmentDialog';
import { ShareScoreCard } from './Dialogs/ShareScoreCard';
import { UserEmailDialog } from './Dialogs/UserEmailDialog';
import { FloatingButtons } from './FloatingButtons';
import { PerformanceStatsScreen } from './PerformanceStats/PerformanceStatsScreen';
import { ScoringButtons } from './ScoringButtons';
import { TopScore } from './TopScore/TopScore';
import { UserSection } from './UserSection';

import { SelfAssessment } from '../SelfAssessment';
import { Skill, SpiderScore } from '../SpiderScore';

export const ScoringScreen = () => {
	const navigation = useNavigation();
	const { t, i18n } = useTranslation('scoring');
	const { selectedRole, refetch: refetchSessions } = useSessions();
	const { refetch: refetchUser } = useAuth();
	const { refetch: refetchNotifications } = useNotifications();
	const location = useLocation();
	const scrollableRef = useRef<HTMLDivElement | undefined>();

	const { sessions, assessment, loading } = useSessions();

	const skills: Skill[] = useMemo(
		() => [
			'control',
			'impact',
			'passing',
			'physical',
			'dribbling',
			'defense',
			// 'shot',
			// 'pace',
		],
		[],
	);

	const { dialog, userId } = useParams();
	const action = useMemo(
		() => navigation.query?.get('action') === 'self-assessment',
		[navigation.query],
	);

	if (
		dialog &&
		!ScoringDialogs.includes(dialog as typeof ScoringDialogs[number])
	) {
		return <Navigate replace to={AppRoutes.Scoring} />;
	}

	return (
		<>
			<BackgroundImage />

			<PullToRefresh
				onRefresh={() => {
					refetchUser();
					refetchSessions();
					refetchNotifications();
				}}
				scrollableRef={scrollableRef}
				enabled={
					!action &&
					_.isNil(dialog) &&
					location.pathname ===
						`/${i18n.language}${AppRoutes.Scoring}`
				}
			/>

			<SafeAreaTop />

			<FloatingButtons />

			<Box
				pt="20px"
				px="20px"
				ref={scrollableRef}
				flexGrow={1}
				position="relative"
				sx={{ overflowY: 'auto' }}
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="space-between"
			>
				<UserSection />

				<Box pt={2} />

				{!loading ? (
					!_.isEmpty(sessions) &&
					_.first(sessions as Score[])?.sessionNumber !== 0 ? (
						<TopScore />
					) : (
						<SpiderScore
							current={assessment}
							skills={skills}
							values={_.map(skills, (s) => _.get(assessment, s))}
						/>
					)
				) : null}

				<Box pt={2} />

				<ScoringButtons />

				<Box pt={2} />
			</Box>

			<FullScreenDialog
				open={dialog === 'works'}
				onClose={() => navigation.replace(AppRoutes.Scoring)}
				title={t('howItWork')}
			>
				<HowItWorkScreen />
			</FullScreenDialog>

			<FullScreenDialog
				open={dialog === 'tracking'}
				onClose={() => navigation.replace(AppRoutes.Scoring)}
				title={t('howArrangeTracking')}
			>
				<HowArrangeTrackingScreen />
			</FullScreenDialog>

			<FullScreenDialogSecondary
				open={dialog === 'contact'}
				onClose={() => navigation.replace(AppRoutes.Scoring)}
				onGoBack={() =>
					navigation.replace(AppRoutes.HowArrangeTracking)
				}
				title={t('contactTitle')}
			>
				<ContactUsScreen />
			</FullScreenDialogSecondary>

			<FullScreenDialog
				open={dialog === 'qrcode'}
				onClose={() => navigation.replace(AppRoutes.Scoring)}
				title={
					selectedRole === UserRole.Trainer
						? t('myTrainerId')
						: t('myPlayerId')
				}
			>
				<QrCodeDialog />
			</FullScreenDialog>

			<FullScreenDialog
				open={dialog === 'share'}
				onClose={() => navigation.replace(AppRoutes.Scoring)}
				title={t('shareScoreCardTitle')}
			>
				<ShareScoreCard />
			</FullScreenDialog>

			<CustomDialog
				open={dialog === 'stats'}
				onClose={() => navigation.replace(AppRoutes.Scoring)}
				goBack={!!userId}
				title={
					selectedRole === UserRole.Trainer && !userId
						? t('teamPerformance')
						: t('viewStatsTitle')
				}
			>
				<UserScoreProvider>
					<PerformanceStatsScreen />
				</UserScoreProvider>
			</CustomDialog>

			<ImagesDialog
				open={dialog === 'images'}
				onClose={() => navigation.replace(AppRoutes.Scoring)}
			/>

			<CustomDialog
				open={navigation.query?.get('action') === 'self-assessment'}
				onClose={() => navigation.setQuery('action', null)}
				goBack={false}
				title={
					selectedRole === UserRole.Trainer && !userId
						? t('teamAssessment')
						: t('selfAssessment')
				}
				sx={{
					'& .MuiDialogTitle-root': {
						paddingBottom: 0,
						'& .MuiSpacer': {
							width: 'unset',
						},
					},
				}}
			>
				<SelfAssessment />
			</CustomDialog>

			<Dialog
				open={dialog === 'self-assessment'}
				onClose={() => navigation.goBackOrGoTo(AppRoutes.Scoring)}
				TransitionComponent={Fade}
				fullWidth
				maxWidth="xs"
			>
				<SelfAssessmentDialog />
			</Dialog>

			<Dialog
				open={dialog === 'user-email'}
				onClose={() => navigation.goBackOrGoTo(AppRoutes.Scoring)}
				TransitionComponent={Fade}
				fullWidth
				maxWidth="xs"
			>
				<UserEmailDialog />
			</Dialog>

			<Dialog
				open={dialog === 'legal-guardian-email'}
				onClose={() => navigation.goBackOrGoTo(AppRoutes.Scoring)}
				TransitionComponent={Fade}
				fullWidth
				maxWidth="xs"
			>
				<LegalGuardianEmailDialog />
			</Dialog>
		</>
	);
};
