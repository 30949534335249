import React from 'react';
import {
	VictoryAxis,
	VictoryChart,
	VictoryContainer,
	VictoryLine,
	VictoryScatter,
} from 'victory';

import Box from '@mui/material/Box';

import { lineComparisonStyles } from './LineComparison.styles';

const LinearGradient = () => (
	<defs>
		<linearGradient
			id="linearGradient"
			transform="rotate(230)"
			gradientUnits="userSpaceOnUse"
		>
			<stop offset="0%" stopColor="#7DFF03" />
			<stop offset="100%" stopColor="#03FF95" />
		</linearGradient>
	</defs>
);

export type LineComparisonProps = {
	scoreFirst: number;
	scoreSecond: number;
};

export const LineComparison = (props: LineComparisonProps) => {
	const chartTabs = [
		{ number: 1, subScore: props.scoreFirst },
		{ number: 100, subScore: props.scoreSecond },
	];

	const chartProps = {
		animate: {
			duration: 1000,
			onLoad: {
				duration: 1000,
			},
		},
		data: chartTabs,
		x: (datum: typeof chartTabs[0]) => datum.number,
		y: (datum: typeof chartTabs[1]) => datum?.subScore,
	};

	return (
		<>
			<Box display="flex" justifyContent="center">
				<VictoryChart
					height={100}
					padding={{ left: 80, right: 90 }}
					containerComponent={
						<VictoryContainer
							responsive={true}
							style={lineComparisonStyles.container}
						/>
					}
				>
					<LinearGradient />

					<VictoryAxis
						dependentAxis
						orientation="bottom"
						tickValues={[0, 25, 50, 75, 100]}
						style={{
							axis: lineComparisonStyles.axis,
							tickLabels: { fill: 'transparent' },
						}}
					/>

					<VictoryLine
						{...chartProps}
						style={{
							data: lineComparisonStyles.line,
						}}
					/>

					<VictoryScatter
						{...chartProps}
						size={8}
						style={{
							data: lineComparisonStyles.scatter,
						}}
					/>
				</VictoryChart>
			</Box>
		</>
	);
};
