import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TypographyVariant } from '@mui/material/styles';

type FormLayoutProps = {
	onSubmit: any;
	title: string;
	controls: JSX.Element | JSX.Element[];
	button?: JSX.Element;
	disableTitleMaxWidth?: boolean;
	titleVariant?: TypographyVariant;
	buttonTop?: boolean;
	buttonBottom?: boolean;
};

export const FormLayout: React.FC<FormLayoutProps> = ({
	title,
	controls,
	button,
	onSubmit,
	disableTitleMaxWidth,
	titleVariant,
	buttonTop = true,
	buttonBottom = true,
}) => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			component="form"
			onSubmit={onSubmit}
			width={1}
			height={1}
			paddingX="20px"
			overflow="auto"
		>
			<Box
				display="flex"
				flex="1 0 auto"
				flexDirection="column"
				alignItems="center"
				justifyContent="space-between"
			>
				<Box
					maxWidth={500}
					flexGrow={1}
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					width={1}
				>
					<Box pt={4} />

					<Box maxWidth={!disableTitleMaxWidth ? 250 : undefined}>
						<Typography
							variant={titleVariant || 'h5'}
							align="center"
						>
							{title}
						</Typography>
					</Box>

					<Box pt={4} />

					{controls}
				</Box>
				<Box>
					{buttonTop ? <Box pt={4} /> : null}

					{button ? (
						<>
							{button}
							{buttonBottom ? <Box pt={4} /> : null}
						</>
					) : null}
				</Box>
			</Box>
		</Box>
	);
};
