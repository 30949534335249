import Box from '@mui/material/Box';

import { useAuth } from 'src/Auth/useAuth';

export const BackgroundImage = () => {
	const { user } = useAuth();

	return (
		<Box
			position="absolute"
			width={1}
			height={1}
			zIndex={-1}
			sx={{
				backgroundImage: user?.backgroundUrl
					? `url(${user.backgroundUrl})`
					: `url(${process.env.REACT_APP_DEFAULT_BACKGROUND_IMAGE})`,
				opacity: 0.15,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundPosition: 'top',
			}}
		/>
	);
};
