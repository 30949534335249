import { createContext } from 'react';

import { Score } from 'src/graphql/types';

export const UserScoreContext = createContext<
	| {
			loading: boolean;
			lastUserScore: Score | null;
			userScores: Score[] | null;
	  }
	| undefined
>(undefined);
