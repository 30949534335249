import { LoginRole } from '../Sessions/SessionProvider';

const ROLE_KEY = 'role';

export const getLocalStorageRole = (): LoginRole | null => {
	return (localStorage.getItem(ROLE_KEY) as LoginRole) ?? null;
};

export const setLocalStorageRole = (role: LoginRole) => {
	return localStorage.setItem(ROLE_KEY, role);
};

export const removeLocalStorageRole = () => {
	return localStorage.removeItem(ROLE_KEY);
};
