import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';

const handleMouseDownPassword = (
	event: React.MouseEvent<HTMLButtonElement>,
) => {
	event.preventDefault();
};

export const TextFieldForm: React.FC<
	TextFieldProps & {
		name: string;
		nextRef?: React.RefObject<HTMLInputElement | HTMLButtonElement>;
	}
> = ({ nextRef, ...props }) => {
	const { control } = useFormContext();
	const { t } = useTranslation('validations');
	const [showPassword, setShowPassword] = useState(false);

	const nextRefProps = nextRef
		? {
				enterKeyHint: 'next',
				onKeyDown: (
					event: React.KeyboardEvent<
						HTMLTextAreaElement | HTMLInputElement
					>,
				) => {
					if (event.key === 'Enter') {
						event.preventDefault();
						nextRef.current?.focus();
					}
				},
		  }
		: {};

	const passwordProps =
		props.type === 'password'
			? {
					type: showPassword ? 'text' : 'password',
					InputProps: {
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() =>
										setShowPassword(!showPassword)
									}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{showPassword ? (
										<VisibilityOff />
									) : (
										<Visibility />
									)}
								</IconButton>
							</InputAdornment>
						),
					},
			  }
			: {};

	return (
		<Controller
			name={props.name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<TextField
					{...field}
					helperText={error?.message ? t(error.message) : undefined}
					error={!!error}
					{...props}
					inputProps={{
						...nextRefProps,
						...props.inputProps,
					}}
					{...passwordProps}
				/>
			)}
		/>
	);
};
