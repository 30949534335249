import { Capacitor } from '@capacitor/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { useAuth } from 'src/Auth/useAuth';
import { TextFieldForm } from 'src/Form/Inputs/TextFieldForm';
import { transformLowercase } from 'src/Form/helpers/transformLowercase';
import { useGleap } from 'src/Plugins/Gleap';
import { AppRoutes } from 'src/Router/AppRoutes';
import { LocaleLink } from 'src/Router/LocaleLink';
import { useSignInMutation } from 'src/graphql/types';
import { useServerErrors } from 'src/helpers/useServerErrors';

import { LoginFormValues } from './LoginScreen';

export const LoginForm = () => {
	const { t } = useTranslation('login-screen');
	const { setTokens, setUser } = useAuth();
	const [signInMutation, { loading }] = useSignInMutation();
	const { handleSubmit, setError } = useFormContext<LoginFormValues>();
	const passwordFieldRef = useRef<HTMLInputElement>(null);
	const openGleap = useGleap();
	const { setServerErrors, resetServerErrors, ServerErrors } =
		useServerErrors();

	const onSubmit = handleSubmit(async (formData) => {
		resetServerErrors();

		const { data, errors } = await signInMutation({
			variables: {
				input: formData,
			},
		}).catch(convertNetworkErrors);

		if (errors) {
			const isInvalidPassword = errors.some(
				(error) => error.message === 'InvalidUsernameAndPassword',
			);

			if (isInvalidPassword) {
				setError('username', {});
				setError('password', {});
			}

			setServerErrors(errors);
		}

		if (data?.signIn) {
			if (Capacitor.getPlatform() === 'ios') {
				SavePassword.promptDialog({
					username: formData.username,
					password: formData.password,
				});
			}

			const { accessToken, refreshToken, user } = data.signIn;

			setTokens(accessToken, refreshToken);
			setUser(user);
		}
	});

	return (
		<Stack
			px="20px"
			alignItems="center"
			component="form"
			flexGrow={1}
			onSubmit={onSubmit}
		>
			<Stack
				width={1}
				alignItems="center"
				justifyContent="center"
				flexGrow={1}
				maxWidth={500}
			>
				<Box pt={4} />

				<Typography variant="h5" textAlign="center" maxWidth={250}>
					{t('title_text')}
				</Typography>

				<Box pt={4} />

				<TextFieldForm
					fullWidth
					id="login-username"
					variant="outlined"
					name="username"
					autoComplete="username"
					label={t('email_placeholder')}
					disabled={loading}
					nextRef={passwordFieldRef}
					inputProps={{
						sx: {
							...transformLowercase,
						},
						autoCapitalize: 'off',
					}}
				/>

				<Box pt={2} />

				<TextFieldForm
					inputRef={passwordFieldRef}
					fullWidth
					id="login-password"
					variant="outlined"
					name="password"
					type="password"
					autoComplete="current-password"
					label={t('password_placeholder')}
					disabled={loading}
					inputProps={{
						enterKeyHint: 'go',
					}}
				/>

				<Box pt={2} />

				<Link
					color="textSecondary"
					variant="caption"
					component={LocaleLink}
					to={AppRoutes.ForgotPassword}
				>
					{t('forgot_password_link')}
				</Link>

				<Box pt={2} />

				<Link
					type="button"
					component="button"
					color="textSecondary"
					variant="caption"
					onClick={openGleap}
				>
					{t('any_problems_link')}
				</Link>

				<Box pt={4} />

				{loading && <CircularProgress color="inherit" />}

				<ServerErrors />
			</Stack>

			<Box pt={4} />

			<Button
				color="inherit"
				variant="outlined"
				type="submit"
				disabled={loading}
			>
				{t('submit_button')}
			</Button>

			<Box pt={8} />
		</Stack>
	);
};
