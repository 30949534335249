import _ from 'lodash';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import {
	getLocalStorageRole,
	removeLocalStorageRole,
	setLocalStorageRole,
} from 'src/Auth/localStorageRole';
import { useAuth } from 'src/Auth/useAuth';
import {
	Score,
	Session,
	UserRole,
	usePlayerSessionsLazyQuery,
	useTrainerSessionsLazyQuery,
} from 'src/graphql/types';

import { SessionContext } from './SessionContext';

import { getLocalStorageAccessToken } from '../Auth/localStorageAccessToken';
import { CustomScoring } from '../Screens/Dashboard/SlideControls/SlideControlScreen';

export type LoginRole = UserRole.Player | UserRole.Trainer;

export const SWITCH_ROLE: Readonly<Record<LoginRole, LoginRole>> = {
	[UserRole.Player]: UserRole.Trainer,
	[UserRole.Trainer]: UserRole.Player,
};

export const SessionProvider: React.FC = ({ children }) => {
	const { isAuthorized, user, isConfirmed, loginRef } = useAuth();
	const sessionRef = useRef(false);

	const [sessionLoading, setSessionLoadingLoading] = useState(true);

	const [customScoringLoading, setCustomScoringLoading] =
		useState(isConfirmed);

	const [assessmentLoading, setAssessmentLoading] = useState(isConfirmed);

	const loading = useMemo(
		() => sessionLoading || customScoringLoading || assessmentLoading,
		[assessmentLoading, customScoringLoading, sessionLoading],
	);

	const [sessions, _setSessions] = useState<Score[] | Session[]>([]);
	const [lastSession, _setLastSession] = useState<Score | Session | null>(
		null,
	);

	const [selectedRole, _setSelectedRole] = useState<LoginRole | null>(
		getLocalStorageRole(),
	);

	const [customScoring, _setCustomScoring] = useState({
		control: 17,
		impact: 20,
		passing: 16,
		physical: 16,
		dribbling: 13,
		defense: 13,
		shot: 0,
		pace: 5,
	});

	const [assessment, _setAssessment] = useState({
		control: 0,
		impact: 0,
		passing: 0,
		physical: 0,
		dribbling: 0,
		defense: 0,
		shot: 0,
		pace: 0,
	});

	const scoreSessions = useCallback(
		(sessions: Session[], cs: CustomScoring) => {
			const pSum = Math.max(
				_.values(cs).reduce(
					(sum, pv) => sum + _.toNumber((pv / 100).toFixed(2)),
					0,
				),
				1,
			);

			return _.map(sessions, (s) => {
				s.score = Math.round(
					_.entries(cs)?.reduce((sum, [k, p]) => {
						const value =
							_.find(s.averages, { type: _.capitalize(k) })
								?.value ?? 0;
						return sum + value * _.toNumber((p / 100).toFixed(2));
					}, 0) / pSum,
				);
				return s;
			});
		},
		[],
	);

	const setSessions = useCallback(
		(ss: Score[] | Session[]) => {
			if (selectedRole === UserRole.Trainer) {
				ss = scoreSessions(ss as Session[], customScoring);
			}
			_setSessions(ss);
			_setLastSession(_.get(ss, '[0]') ?? null);
		},
		[customScoring, scoreSessions, selectedRole],
	);

	const setCustomScoring = useCallback(
		(cs: CustomScoring) => {
			_setCustomScoring(cs);
			if (selectedRole === UserRole.Trainer) {
				scoreSessions(sessions as Session[], cs);
			}
		},
		[scoreSessions, selectedRole, sessions],
	);

	const accessToken = getLocalStorageAccessToken();
	useEffect(() => {
		if (isConfirmed && isAuthorized) {
			fetch(`${process.env.REACT_APP_KICKID_API}/custom-scoring`, {
				headers: { Authorization: `Bearer ${accessToken}` },
			})
				.then((response) => response?.json())
				.then(([cs]) => {
					if (!_.isNil(cs)) {
						delete cs['createdAt'];
						_setCustomScoring(cs);
					}
				})
				.finally(() => setCustomScoringLoading(false));
		}
	}, [accessToken, isConfirmed, isAuthorized]);

	const [playerSessionsQuery] = usePlayerSessionsLazyQuery({
		onCompleted: (data) => {
			setSessions((data?.playerScores as Score[]) || []);
			setSessionLoadingLoading(false);
		},
	});

	const [sessionsQuery] = useTrainerSessionsLazyQuery({
		onCompleted: (data) => {
			setSessions((data?.sessions as Session[]) || []);
			setSessionLoadingLoading(false);
		},
	});

	const setSelectedRole = useCallback(
		(role: LoginRole | null) => {
			if (role) {
				sessionRef.current = false;
				setLocalStorageRole(role);
			} else {
				removeLocalStorageRole();
			}

			_setSelectedRole(role);
		},
		[_setSelectedRole],
	);

	const refetch = useCallback(() => {
		setSessionLoadingLoading(true);

		if (selectedRole === UserRole.Player) {
			playerSessionsQuery();
		} else if (selectedRole === UserRole.Trainer) {
			sessionsQuery();
		}
	}, [
		setSessionLoadingLoading,
		selectedRole,
		playerSessionsQuery,
		sessionsQuery,
	]);

	const setAssessment = useCallback(
		(as) => {
			const total = _.keys(as).reduce((sum, k) => sum + _.get(as, k), 0);
			if (sessions?.length === 0 && total > 0) {
				refetch();
			}
			_setAssessment(as);
		},
		[refetch, sessions?.length],
	);

	useEffect(() => {
		if (isConfirmed && isAuthorized && !_.isNil(selectedRole)) {
			if (!loginRef.current[selectedRole] && loginRef.current.loaded) {
				loginRef.current[selectedRole] = true;
				if (!loginRef.current[SWITCH_ROLE[selectedRole]]) {
					loginRef.current.loaded = false;
				}
			}

			fetch(
				`${process.env.REACT_APP_KICKID_API}/${
					selectedRole === UserRole.Trainer ? 'team' : 'self'
				}-assessment`,
				{
					headers: { Authorization: `Bearer ${accessToken}` },
				},
			)
				.then((response) => response?.json())
				.then(([cs]) => {
					if (!_.isNil(cs)) {
						delete cs['createdAt'];
						setAssessment(cs);
					} else {
						setAssessment({
							control: 0,
							impact: 0,
							passing: 0,
							physical: 0,
							dribbling: 0,
							defense: 0,
							shot: 0,
							pace: 0,
						});
					}
				})
				.finally(() => setAssessmentLoading(false));
		}
	}, [
		accessToken,
		isAuthorized,
		isConfirmed,
		loginRef,
		selectedRole,
		setAssessment,
	]);

	useEffect(() => {
		if (!user && selectedRole && sessionRef.current) {
			_setLastSession(null);
			setSessions([]);
			setSelectedRole(null);
			setSessionLoadingLoading(true);
			sessionRef.current = false;
		}
	}, [user, selectedRole, setSelectedRole, setSessions]);

	useEffect(() => {
		if (isAuthorized && user && !sessionRef.current) {
			const role =
				user?.roles?.find((role) => role === selectedRole) ||
				user?.roles?.find(
					(role) =>
						role === UserRole.Trainer || role === UserRole.Player,
				);

			setSelectedRole(role as LoginRole);
			setSessionLoadingLoading(true);

			if (role === UserRole.Player) {
				playerSessionsQuery();
			} else if (role === UserRole.Trainer) {
				sessionsQuery();
			}

			sessionRef.current = true;
		}
	}, [
		user,
		selectedRole,
		isAuthorized,
		playerSessionsQuery,
		sessionsQuery,
		setSelectedRole,
	]);

	const value = useMemo(
		() => ({
			assessment,
			customScoring,
			isConfirmed,
			isVerified: !!lastSession && isConfirmed,
			lastSession,
			loading,
			refetch,
			selectedRole: selectedRole as LoginRole,
			sessions,
			setAssessment,
			setCustomScoring,
			setSelectedRole,
			setSessions,
		}),
		[
			assessment,
			customScoring,
			isConfirmed,
			lastSession,
			loading,
			refetch,
			selectedRole,
			sessions,
			setAssessment,
			setCustomScoring,
			setSelectedRole,
			setSessions,
		],
	);

	return (
		<SessionContext.Provider value={value}>
			{children}
		</SessionContext.Provider>
	);
};
