import _ from 'lodash';
import React, {
	CSSProperties,
	FC,
	Fragment,
	useCallback,
	useEffect,
} from 'react';
import {
	VictoryArea,
	VictoryChart,
	VictoryContainer,
	VictoryLabel,
	VictoryPolarAxis,
	VictoryTheme,
} from 'victory';

import { Box, Typography } from '@mui/material';

import { TopScore } from './Scoring/TopScore/TopScore';
import { CustomScoring } from './SlideControls/SlideControlScreen';

import { useAuth } from '../../Auth/useAuth';
import { AppRoutes } from '../../Router/AppRoutes';
import { useNavigation } from '../../Router/useNavigation';
import { useSessions } from '../../Sessions/useSessions';
import { SubScores } from '../../graphql/types';

export const polarStyles: { [key: string]: CSSProperties } = {
	container: {
		pointerEvents: 'auto',
		touchAction: 'auto',
		maxWidth: 500,
	},
};

export type Assessment = {
	control: number;
	impact: number;
	passing: number;
	physical: number;
	dribbling: number;
	defense: number;
	shot: number;
	pace: number;
};

export type Skill =
	| 'control'
	| 'impact'
	| 'passing'
	| 'physical'
	| 'dribbling'
	| 'defense'
	| 'shot'
	| 'pace';

export interface SpiderScoreProps {
	current: Assessment;
	last?: Assessment;
	skills: Skill[];
	values: number[];
	readOnly?: boolean;
}

export const SpiderScore: FC<SpiderScoreProps> = ({
	current,
	last,
	skills,
	values,
	readOnly = true,
}) => {
	/*
	 * BEGIN: rotate hack
	 */
	const labels = _.cloneDeep(skills).reverse() as Skill[];
	labels.pop();
	labels.unshift('control');
	/*
	 * END: rotate hack
	 */

	if (_.isNil(last)) {
		last = current;
	}

	const step = 360 / skills.length;

	const tickValues = labels.map((value, index) => {
		return labels.indexOf(skills[index]) * step;
	});

	const assessment = labels.map((skill, index) => {
		return { x: step * index, y: values[labels.indexOf(skills[index])] };
	});

	const lastAssessment = labels.map((skill, index) => {
		return { x: step * index, y: _.get(last, labels[index]) };
	});

	const { loginRef, isConfirmed } = useAuth();
	const { loading, customScoring } = useSessions();
	const { replace } = useNavigation();

	const score = useCallback(
		(aoss: Assessment | SubScores[], cs: CustomScoring) => {
			const subScores: Pick<SubScores, 'type' | 'value'>[] = _.isArray(
				aoss,
			)
				? aoss
				: _.entries(aoss).map(([type, value]) => ({
						type: _.capitalize(type),
						value,
				  }));
			const pSum = Math.max(
				_.values(cs).reduce(
					(sum, pv) => sum + _.toNumber((pv / 100).toFixed(2)),
					0,
				),
				1,
			);

			return Math.round(
				_.entries(cs)?.reduce((sum, [k, p]) => {
					const value =
						_.find(subScores, { type: _.capitalize(k) })?.value ??
						0;
					return sum + value * _.toNumber((p / 100).toFixed(2));
				}, 0) / pSum,
			);
		},
		[],
	);

	useEffect(() => {
		// console.log(_.values(current).reduce((ss, s) => ss + s, 0));
		if (!loading && readOnly && score(current, customScoring) === 0) {
			if (isConfirmed && !loginRef.current.loaded) {
				loginRef.current.loaded = true;
				replace(AppRoutes.SelfAssessmentDialog);
			}
		}
	}, [
		current,
		customScoring,
		isConfirmed,
		loading,
		loginRef,
		readOnly,
		replace,
		score,
	]);

	if (readOnly && score(current, customScoring) === 0) {
		return (
			<Fragment>
				<Box
					sx={{
						alignItems: 'center',
						display: 'flex',
						justifyContent: 'center',
						margin: '0 auto',
						maxHeight: '280px',
						maxWidth: '280px !important',
						position: 'relative',
						'& .VictoryContainer': {
							maxHeight: '280px',
						},
					}}
				>
					<TopScore />
				</Box>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'center',
					margin: '0 auto',
					maxHeight: '280px',
					maxWidth: '280px !important',
					position: 'relative',
					'& .VictoryContainer': {
						maxHeight: '280px',
					},
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						maxWidth: '280px !important',
						maxHeight: '280px !important',
						zIndex: 1,
						'& *': {
							maxWidth: 'inherit !important',
							maxHeight: 'inherit !important',
						},
					}}
				>
					<Typography fontSize={'xxx-large'} fontWeight={'bold'}>
						{score(current, customScoring)}
					</Typography>
				</Box>
				<Box
					sx={{
						maxHeight: '280px',
						margin: '0 auto',
						maxWidth: '280px !important',
					}}
				>
					<VictoryChart
						width={280}
						height={280}
						polar
						startAngle={90}
						endAngle={450}
						padding={60}
						containerComponent={
							<VictoryContainer
								responsive={true}
								style={polarStyles.container}
							/>
						}
					>
						<VictoryPolarAxis
							tickValues={tickValues}
							labelPlacement="perpendicular"
							standalone={false}
							theme={VictoryTheme.material}
							style={{
								axis: { stroke: '#202020' },
								ticks: { stroke: 'transparent' },
								tickLabels: {
									fontFamily: 'Inter',
									fill: '#777777',
									fontSize: 10,
									padding: 13,
								},
							}}
							animate={{
								duration: 200,
								easing: 'bounce',
							}}
							tickFormat={_.map(skills, (s) => _.capitalize(s))}
						/>

						<VictoryPolarAxis
							tickValues={tickValues}
							labelPlacement="vertical"
							standalone={false}
							theme={VictoryTheme.material}
							style={{
								axis: { stroke: 'transparent' },
								ticks: { stroke: 'transparent' },
								tickLabels: {
									fill: ({ text, index }: any) => {
										const diff =
											text -
											_.get(last, skills[index], 0);
										return Math.sign(diff) === 1
											? '#43FF47'
											: Math.sign(diff) === 0
											? 'white'
											: '#FFBF28';
									},
									fontFamily: 'Inter',
									fontSize: 24,
									fontWeight: 600,
									padding: 30,
								},
							}}
							tickFormat={values}
						/>

						<VictoryPolarAxis
							dependentAxis
							axisAngle={90}
							tickValues={[0, 25, 50, 75, 100]}
							labelPlacement="perpendicular"
							theme={VictoryTheme.material}
							standalone={false}
							tickLabelComponent={<VictoryLabel />}
							style={{
								grid: { stroke: '#202020' },
								axis: { stroke: '#202020' },
								ticks: { stroke: 'transparent' },
								tickLabels: {
									fontFamily: 'Inter',
									fill: '#777777',
									fontSize: 10,
								},
							}}
						/>

						<VictoryArea
							key={1}
							data={lastAssessment}
							style={{
								data: {
									stroke: '#FFBF28',
									strokeWidth: 1,
									fill: '#FFBF28',
									fillOpacity: _.isEqual(last, current)
										? 0
										: 0.1,
								},
							}}
							animate={{
								duration: 200,
								easing: 'circle',
								onLoad: {
									duration: 0,
								},
							}}
						/>

						<VictoryArea
							key={2}
							data={assessment}
							style={{
								data: {
									stroke: '#43FF47',
									strokeWidth: 1,
									fill: '#43FF47',
									fillOpacity: 0.1,
								},
							}}
							animate={{
								duration: 200,
								easing: 'circle',
								onLoad: {
									duration: 0,
								},
							}}
						/>
					</VictoryChart>
				</Box>
			</Box>
		</Fragment>
	);
};
