import i18n from 'i18next';
import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: () => i18n.t('validations:required'),
	},
	string: {
		email: () => i18n.t('validations:email'),
		min: ({ min }) => i18n.t('validations:min', { min }),
		max: ({ max }) => i18n.t('validations:max', { max }),
	},
});
