import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { ToogleButton } from 'src/Components/ToggleButton';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import { useWatchComparisons } from 'src/WatchComparisons/useWatchComparisons';
import {
	FoundComparisonType,
	useCompareQuery,
	useCreateWatchComparisonMutation,
	useDeleteWatchComparisonMutation,
} from 'src/graphql/types';

import { ComparisonBar } from './ComparisonBar';
import { PolarPlayerGraph } from './PolarPlayerGraph/PolarPlayerGraph';
import { SubScoreComparison } from './SubScoreComparison/SubScoreComparison';

import { subScores } from '../../Scoring/PerformanceStats/PerformanceStatsScreen';

export const ViewComparisonScreen: React.FC = () => {
	const { t } = useTranslation('comparison');
	const navigation = useNavigation();
	const { selectedRole } = useSessions();

	const { watchComparisons, refetch } = useWatchComparisons();

	const [createWatchComparisonMutation] = useCreateWatchComparisonMutation();
	const [deleteWatchComparisonMutation] = useDeleteWatchComparisonMutation();

	const [loadingToggleButton, setLoadingToggleButton] =
		useState<boolean>(false);

	const sub = navigation.query.get('sub');
	const subFrom = navigation.query.get('subFrom');
	const type = navigation.query.get('type');
	const sessionNumber = navigation.query.get('sessionNumber');

	const SKILL_MAIN_COMPARISON = 'RANK';

	const { data, loading } = useCompareQuery({
		variables: {
			input: {
				role: selectedRole,
				type: type as FoundComparisonType,
				sessionNumber: sessionNumber ? parseInt(sessionNumber) : null,
				sub,
				subFrom,
			},
		},
	});

	const comparison = data?.compare || null;

	const filteredSubScores = subScores.filter(
		(skill) =>
			comparison?.compareFrom?.subScores?.some(
				(subScore) =>
					subScore.type === skill && subScore.value !== null,
			) &&
			comparison?.compareTo?.subScores?.some(
				(subScore) =>
					subScore.type === skill && subScore.value !== null,
			),
	);

	const checked = watchComparisons.some(
		(data) =>
			data.type === comparison?.type &&
			data.sessionNumber === comparison?.sessionNumber &&
			data.role === comparison?.role &&
			data.subFrom === comparison?.subFrom &&
			data.sub === comparison?.sub &&
			data.skill === SKILL_MAIN_COMPARISON,
	);

	const toggleWatchList = async () => {
		if (!comparison) return;

		setLoadingToggleButton(true);

		const input = {
			role: selectedRole,
			skill: SKILL_MAIN_COMPARISON,
			type: comparison.type,
			sub: comparison.sub,
			subFrom: comparison.subFrom,
			sessionNumber: comparison?.sessionNumber,
		};

		if (checked) {
			await deleteWatchComparisonMutation({
				variables: {
					input,
				},
			}).catch(convertNetworkErrors);
		} else {
			await createWatchComparisonMutation({
				variables: {
					input,
				},
			}).catch(convertNetworkErrors);
		}

		refetch();
		setLoadingToggleButton(false);
	};

	return (
		<Box height={1} display="flex" flexDirection="column">
			<Box
				position="fixed"
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				padding="20px"
				pt="max(env(safe-area-inset-top), 20px)"
				width={1}
			>
				<Box height={46} width={80} onClick={() => navigation.goBack()}>
					<IconButton color="inherit" size="large">
						<ArrowBackIcon />
					</IconButton>
				</Box>
				<Box flexGrow={1}>
					<Typography
						variant="subtitle1"
						color="inherit"
						align="center"
					>
						{t('compare')}
					</Typography>
				</Box>
				<Box
					alignItems="center"
					justifyContent="flex-end"
					display="flex"
					height={46}
					width={80}
				>
					<ToogleButton
						name="comparison"
						onChange={toggleWatchList}
						checked={checked}
						loading={loadingToggleButton || loading}
						values={[VisibilityOffIcon, VisibilityIcon]}
						colors={['#43FF47', '#777777']}
					/>
				</Box>
			</Box>
			<Box pt="max(calc(env(safe-area-inset-top) + 66px), 86px)" />

			<ComparisonBar comparison={comparison} loading={loading} />

			<Box height={1} sx={{ overflowY: 'auto' }}>
				{/*TODO:  Here goes Marwans Graph for F-youth or My Team */}

				<Box
					width={1}
					display="flex"
					justifyContent="center"
					alignItems="center"
					px={1}
				>
					{loading ? (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							height={380}
						>
							<Skeleton
								variant="circular"
								width={250}
								height={250}
							/>
						</Box>
					) : (
						<PolarPlayerGraph comparison={comparison} />
					)}
				</Box>

				{loading ? (
					<Box px="20px">
						<Skeleton variant="rectangular" height={96} />
						<Box pt={2} />
						<Skeleton variant="rectangular" height={96} />
						<Box pt={2} />
					</Box>
				) : null}

				{filteredSubScores.map((subScore) => (
					<SubScoreComparison
						key={subScore}
						subScore={subScore}
						comparison={comparison}
					/>
				))}

				<Box pt={4} />
			</Box>
		</Box>
	);
};
