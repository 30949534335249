import { SkipToken, skipToken } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';

import { useNavigation } from '../Router/useNavigation';
import { Score, Session, UserRole } from '../graphql/types/rtk-query';

export const useEventArgs = <TQueryArgs>(
	extras?: TQueryArgs,
): (TQueryArgs & { eventNumber: number }) | SkipToken => {
	const { query } = useNavigation();
	const queryEventNumber = _.toInteger(query.get('event') ?? '0');
	return queryEventNumber > 0
		? _.merge({ eventNumber: queryEventNumber }, extras)
		: skipToken;
};

export const useSessionPlayerArgs = (
	session: Score | Session | null,
	role?: UserRole,
) => {
	if (role === UserRole.Trainer) {
		return skipToken;
	}
	const { sessionNumber, playerSessionId } = _.defaultsDeep({}, session);
	if (_.isNil(sessionNumber) || _.isNil(playerSessionId)) {
		return skipToken;
	}
	return { sessionNumber, playerSessionId };
};
