import React from 'react';
import { ControllerProps, FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextField, useMediaQuery } from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { useTheme } from '@mui/material/styles';

import { FieldController } from './FieldController';

export interface TextFieldControllerProps<
	TFieldValues,
	TName extends FieldPath<TFieldValues>,
> extends Pick<ControllerProps<TFieldValues, TName>, 'control' | 'name'> {
	translation: string;
	label?: string;
	disabled?: boolean;
	InputProps?: Partial<StandardInputProps>;
}

export const TextFieldController = <
	TFieldValues,
	TName extends FieldPath<TFieldValues>,
>({
	translation,
	name,
	label,
	control,
	disabled,
	InputProps,
}: TextFieldControllerProps<TFieldValues, TName>) => {
	const { t } = useTranslation(translation);

	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));
	return (
		<FieldController
			name={name}
			control={control}
			translation={translation}
			render={({ field }) => {
				return (
					<TextField
						{...field}
						fullWidth
						label={t(label ?? name)}
						size={sm ? 'medium' : 'small'}
						variant="filled"
						disabled={disabled}
						InputProps={InputProps}
					/>
				);
			}}
		/>
	);
};
