import React, { ElementType } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export type ToggleButtonProps = {
	name: string;
	disabled?: boolean;
	checked: boolean;
	onChange: (checked: boolean) => void;
	width?: number;
	values?: [string | ElementType | null, string | ElementType];
	colors?: [string, string];
	loading?: boolean;
};

export const ToogleButton: React.FC<ToggleButtonProps> = (props) => {
	const { t } = useTranslation('translation');

	const { name, checked, onChange, values, disabled, loading } = props;

	const FirstIcon = typeof values?.[0] !== 'string' ? values?.[0] : null;
	const SecondIcon = typeof values?.[1] !== 'string' ? values?.[1] : null;

	return (
		<>
			<Box
				component="input"
				checked={checked}
				onChange={() => {
					return onChange(!checked);
				}}
				disabled={disabled}
				className="toggle-button-checkbox"
				sx={{
					height: 0,
					width: 0,
					visibility: 'hidden',
					'&:checked + .toggle-button-label .toggle-button-button': {
						left: '100%',
						transform: 'translateX(-100%)',
						bgcolor: props.colors?.[0] || 'primary.main',
					},
					'&:checked + .toggle-button-label .toggle-button-not-checked':
						{
							textAlign: 'left',
							transform:
								typeof props.values?.[0] !== 'string'
									? 'translateX(+10%) translateY(+10%)'
									: undefined,
						},
				}}
				id={'toggle-button-' + name}
				type="checkbox"
			/>
			<Box
				component="label"
				className="toggle-button-label"
				htmlFor={'toggle-button-' + name}
				sx={{
					opacity: props.disabled ? 0.7 : 1,
					cursor: props.disabled ? 'not-allowed' : 'pointer',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					minWidth:
						props.width || typeof props.values?.[0] !== 'string'
							? 80
							: 124,
					height: 25,
					background: 'black',
					border: '1px solid #252525',
					borderRadius: 16,
					position: 'relative',
					transition: 'background-color .2s',
					color: 'text.secondary',

					'& .toggle-button-button': {
						content: '""',
						color: 'black',
						position: 'absolute',
						minWidth:
							typeof props.values?.[0] !== 'string' ? 40 : 64,
						height: 25,
						borderRadius: 16,
						bgcolor: props.colors?.[1] || 'error.main',
						padding: '0 5px',
					},
				}}
			>
				<Box width={1} className="toggle-button-not-checked">
					{FirstIcon && SecondIcon ? null : (
						<Typography
							sx={{
								width: '50%',
								float: props.checked ? 'left' : 'right',
							}}
							variant="overline"
							align="center"
						>
							{checked
								? values?.[1] || t('no')
								: values?.[0] || t('yes')}
						</Typography>
					)}
				</Box>

				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					className="toggle-button-button"
				>
					{FirstIcon && SecondIcon ? (
						checked ? (
							loading ? (
								<CircularProgress size={20} color="secondary" />
							) : (
								<SecondIcon />
							)
						) : loading ? (
							<CircularProgress size={20} color="secondary" />
						) : (
							<FirstIcon />
						)
					) : (
						<Typography variant="overline">
							{checked
								? values?.[0] || t('yes')
								: values?.[1] || t('no')}
						</Typography>
					)}
				</Box>
			</Box>
		</>
	);
};
