import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const PlusIcon: React.FC<CustomIconProps> = (props) => {
	const { color } = useCustomIcon(props);
	return (
		<SvgIcon viewBox="0 0 24 24" sx={props.sx}>
			<g transform="translate(6 6)">
				<path
					d="M.462,0V12"
					transform="translate(5.538 0)"
					fill="none"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
					strokeLinecap="square"
					strokeMiterlimit="10"
					strokeWidth="1"
				/>
				<path
					d="M12,.462H0"
					transform="translate(0 5.538)"
					fill="none"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
					strokeLinecap="square"
					strokeMiterlimit="10"
					strokeWidth="1"
				/>
			</g>
		</SvgIcon>
	);
};
