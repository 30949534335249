import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { useAuth } from 'src/Auth/useAuth';
import { DinamicAvatar } from 'src/Components/DinamicAvatar';
import { PlusIcon } from 'src/Components/Icons/PlusIcon';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import { UserRole } from 'src/graphql/types';

export const ComparisonSelector: React.FC = () => {
	const { user, loading: userLoading } = useAuth();
	const {
		lastSession,
		selectedRole,
		loading: sessionLoading,
	} = useSessions();

	const navigation = useNavigation();

	const avatarUrl =
		selectedRole === UserRole.Trainer
			? lastSession?.club?.logoUrl
			: user?.avatarUrl;

	return (
		<>
			<Grid
				container
				alignItems="center"
				direction="row"
				pb={5}
				pt="max(calc(env(safe-area-inset-top) + 10px), 40px)"
			>
				<Grid xs item>
					{selectedRole === UserRole.Trainer ? (
						<Box display="flex" justifyContent="center">
							<Avatar
								sx={{
									width: 100,
									height: 100,
									backgroundColor: 'black',
									border: '1px solid #454545',
									cursor: 'pointer',
								}}
								onClick={() =>
									navigation.push(AppRoutes.CreateComparison)
								}
							>
								<PlusIcon color="white" sx={{ fontSize: 50 }} />
							</Avatar>
						</Box>
					) : (
						<DinamicAvatar
							size={100}
							iconUrl={avatarUrl}
							loading={userLoading || sessionLoading}
							role={selectedRole}
						/>
					)}
				</Grid>

				<Divider orientation="vertical" flexItem />

				<Grid xs item>
					<Box display="flex" justifyContent="center">
						<Avatar
							sx={{
								width: 100,
								height: 100,
								backgroundColor: 'black',
								border: '1px solid #454545',
								cursor: 'pointer',
							}}
							onClick={() =>
								navigation.push(AppRoutes.CreateComparison)
							}
						>
							<PlusIcon color="white" sx={{ fontSize: 50 }} />
						</Avatar>
					</Box>
				</Grid>
			</Grid>

			<Divider />
		</>
	);
};
