import React, { useState } from 'react';

import { FormContext } from './FormContext';

type FormProviderProps = {
	initialValue: any;
};

export const FormProvider: React.FC<FormProviderProps> = ({
	initialValue,
	children,
}) => {
	const [form, setForm] = useState(initialValue || {});

	const resetForm = () => {
		setForm({ ...form, ...initialValue });
	};

	return (
		<FormContext.Provider
			value={{
				form,
				setForm,
				resetForm,
			}}
		>
			{children}
		</FormContext.Provider>
	);
};
