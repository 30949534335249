import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import QrCode2Icon from '@mui/icons-material/QrCode2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { TextFieldForm } from 'src/Form/Inputs/TextFieldForm';
import { transformUppercase } from 'src/Form/helpers/transformUppercase';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { ManualCodeFormValues } from './ManualCodeScreen';

export const ManualCodeForm = () => {
	const { t } = useTranslation('add-session');

	const { handleSubmit } = useFormContext<ManualCodeFormValues>();

	const navigation = useNavigation();

	const onSubmit = handleSubmit(async (formData) => {
		navigation.push(`${AppRoutes.CheckSession}/${formData.code}`);
	});

	return (
		<Stack
			px="20px"
			alignItems="center"
			component="form"
			flexGrow={1}
			onSubmit={onSubmit}
		>
			<Stack
				width={1}
				alignItems="center"
				justifyContent="center"
				flexGrow={1}
				maxWidth={500}
			>
				<Box pt={4} />

				<Typography
					variant="body2"
					color="textSecondary"
					textAlign="center"
					maxWidth={224}
				>
					{t('codeHint')}
				</Typography>

				<Box pt={4} />

				<TextFieldForm
					fullWidth
					id="code-code"
					variant="outlined"
					name="code"
					label={t('code')}
					inputProps={{
						maxLength: 8,
						enterKeyHint: 'go',
						sx: {
							...transformUppercase,
						},
					}}
				/>

				<Box pt={4} />

				<Button color="inherit" variant="outlined" type="submit">
					{t('confirm')}
				</Button>
			</Stack>

			<Box pt={4} />

			<Button
				variant="outlined"
				color="inherit"
				onClick={() => navigation.goBackOrGoTo(AppRoutes.AddSession)}
			>
				<QrCode2Icon />
				<Box ml={1} />
				{t('scanInstead')}
			</Button>

			<Box pt={8} />
		</Stack>
	);
};
