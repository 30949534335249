import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { useNavigation } from 'src/Router/useNavigation';

import { useColorSubScore } from './useColorSubScore';

import { chartTabs } from '../../../Scoring/PerformanceStats/StatsOverTime/chartTabs';

export type SubScoreTitleComparisonProps = {
	loading: boolean;
	selected?: boolean;
	title: string;
	myValue?: number | null;
	yourValue?: number | null;
	onClose: () => void;
};

export const SubScoreTitleComparison: React.FC<
	SubScoreTitleComparisonProps
> = ({ loading, selected, myValue, yourValue, title, onClose }) => {
	const { setQuery } = useNavigation();
	const { t } = useTranslation('comparison');

	const titleValue =
		chartTabs.find((chartTab) => chartTab.subScore === title)?.label ||
		null;

	const colorLeft = useColorSubScore(yourValue, myValue, 'colorLeft');
	const colorRight = useColorSubScore(yourValue, myValue, 'colorRight');
	const colorLeftOpacity = useColorSubScore(
		yourValue,
		myValue,
		'colorLeftOpacity',
	);
	const colorRightOpacity = useColorSubScore(
		yourValue,
		myValue,
		'colorRightOpacity',
	);

	return (
		<Box
			display="flex"
			flexDirection="column"
			p={2}
			position="relative"
			border="1px solid #252525"
			borderRadius="2px"
			onClick={() =>
				!selected ? setQuery('skill', titleValue) : onClose()
			}
			sx={{ cursor: 'pointer' }}
		>
			<Box
				display="flex"
				width={1}
				alignItems="center"
				justifyContent="space-between"
			>
				<Box display="flex" alignItems="center">
					{loading && (
						<Typography variant="h6" zIndex={1} lineHeight={1}>
							<Skeleton width={40} />
						</Typography>
					)}

					{!loading && !myValue && (
						<Typography variant="caption" color="textSecondary">
							{t('noData')}
						</Typography>
					)}

					{!loading && myValue && !selected && (
						<Typography
							variant="h6"
							zIndex={1}
							color={colorLeft}
							lineHeight={1}
						>
							{myValue || 0}
						</Typography>
					)}
				</Box>

				<Typography
					variant="body1"
					color={yourValue ? 'textPrimary' : 'textSecondary'}
					zIndex={1}
					fontWeight={500}
				>
					{titleValue}
				</Typography>

				<Box display="flex" alignItems="center">
					{loading && (
						<Typography variant="h6" zIndex={1} lineHeight={1}>
							<Skeleton width={40} />
						</Typography>
					)}

					{!loading && !yourValue && (
						<Typography variant="caption" color="textSecondary">
							{t('noData')}
						</Typography>
					)}

					{!loading && yourValue && !selected && (
						<Typography
							variant="h6"
							zIndex={1}
							color={colorRight}
							lineHeight={1}
						>
							{yourValue || 0}
						</Typography>
					)}
				</Box>
			</Box>

			<Box display="flex" width={1} alignItems="center" pt={2}>
				<Box
					display="flex"
					alignItems="center"
					width={0.5}
					justifyContent="flex-end"
					border={1}
					borderColor="#252525"
				>
					{/* Gradient Background */}
					{!!myValue && selected && (
						<Box
							height={14}
							width={(myValue || 0) + '%'}
							display="flex"
							alignItems="center"
							overflow="hidden"
							pl="16px"
							bgcolor={colorLeft}
						></Box>
					)}

					{/* Green line */}
					{!!myValue && !selected && (
						<Box
							//position="absolute"
							height={14}
							left={0}
							width={(myValue || 0) + '%'}
							bgcolor={colorLeftOpacity}
							borderLeft={`1px solid ${colorLeft}`}
						/>
					)}
				</Box>

				<Box
					display="flex"
					alignItems="center"
					width={0.5}
					border={1}
					borderColor="#252525"
				>
					{/* Gradient Background */}
					{!!yourValue && selected && (
						<Box
							height={14}
							width={(yourValue || 0) + '%'}
							display="flex"
							alignItems="center"
							overflow="hidden"
							pl="16px"
							bgcolor={colorRight}
						></Box>
					)}

					{/* Green line */}
					{!!yourValue && !selected && (
						<Box
							height={14}
							left={0}
							width={(yourValue || 0) + '%'}
							bgcolor={colorRightOpacity}
							borderRight={`1px solid ${colorRight}`}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};
