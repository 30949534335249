import { useContext } from 'react';

import { UserScoreContext } from './UserScoreContext';

export const useUserScores = () => {
	const context = useContext(UserScoreContext);

	if (!context)
		throw new Error(
			'UserScoreContext must be used with UserScoreProvider!',
		);

	return context;
};
