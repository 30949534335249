import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const LockIcon: React.FC<CustomIconProps> = (props) => {
	const { color } = useCustomIcon(props);
	return (
		<SvgIcon viewBox="0 0 15 20" sx={props.sx}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					transform="translate(-5.000000, -2.000000)"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				>
					<g transform="translate(6.000000, 3.000000)">
						<path d="M0.000341290231,16.647539 C-0.000426612789,15.7562742 0.000341290231,9.68689822 0.000341290231,9.47039725 C-0.000280846345,8.86290515 0.659515402,7.98743948 1.83113968,8.00079738 C2.45471999,7.99909894 10.6034892,8.00061363 11.1340663,8.00079738 C12.7624742,8.00136136 12.9962549,9.41165187 12.9921747,9.74528398 C13.0097816,10.4515279 12.9921747,15.7266858 12.9921747,16.4941067 C12.9921747,16.8841635 12.5181953,18 11.1340663,18 C9.74993736,18 2.42133028,18 1.83113968,18 C0.633660572,18 0.000341290231,16.9312314 0.000341290231,16.647539 Z"></path>
						<path
							d="M2.5,0 C4.83752858,0 5.53735611,0.00766867138 6.45557979,0.00766867138 C7.84855504,-0.019564049 10.5,0.637705792 10.5,4.25380151 C10.3615792,7.47180713 7.68157758,7.98172125 6.46866646,7.98172125 C5.25575534,7.98172125 3.25595909,8 2.5,8"
							transform="translate(6.500000, 4.000000) rotate(-90.000000) translate(-6.500000, -4.000000) "
						></path>
						<line
							x1="6.5"
							y1="11"
							x2="6.5"
							y2="14"
							id="Line-17"
							strokeLinecap="square"
						></line>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
