import { Capacitor } from '@capacitor/core';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
	NotificationType,
	NotificationsQuery,
	useNotificationsQuery,
} from 'src/graphql/types';

import { ActionRequired } from './ActionRequired';
import { NotificationsContext } from './NotificationsContext';
import { UpdateApp } from './UpdateApp';

import { useAuth } from '../Auth/useAuth';

export const NotificationsProvider: React.FC = ({ children }) => {
	const [openUpdate, setOpenUpdate] = useState(false);
	const {
		data,
		loading,
		refetch: _refetch,
	} = useNotificationsQuery({
		onCompleted(completedData) {
			const appVersion = process.env.REACT_APP_VERSION || '';
			const minAppVersion = completedData.notifications.minAppVersion;

			if (Capacitor.isNativePlatform() && appVersion < minAppVersion) {
				setOpenUpdate(true);
			}
		},
	});
	const [reset, setReset] = useState(false);

	const { isConfirmed } = useAuth();
	const filterNotifications = useCallback(
		(data?: NotificationsQuery) => {
			const notifications = data?.notifications ?? null;
			if (!_.isNil(notifications)) {
				const readCount =
					!isConfirmed &&
					!_.isNil(
						_.find(notifications.notificationList, {
							type: 'SelfAssessment',
						}),
					)
						? 1
						: 0;
				notifications.notificationList = _.filter(
					notifications.notificationList,
					(n) => isConfirmed || n.type !== 'SelfAssessment',
				);
				notifications.unreadCount =
					notifications.unreadCount - readCount;
			}
			return notifications;
		},
		[isConfirmed],
	);

	const value = useMemo(
		() => ({
			notifications: filterNotifications(data),
			loading,
			refetch: () => {
				_refetch();
				setReset(true);
			},
		}),
		[_refetch, data, filterNotifications, loading],
	);

	const requiredNotifications = useMemo(
		() =>
			value.notifications?.notificationList.filter((notification) =>
				[
					NotificationType.EmailRequired,
					NotificationType.LegalGuardianRequired,
				].includes(notification.type as NotificationType),
			),
		[value],
	);

	const [open, setOpen] = useState(!!requiredNotifications?.length);
	const previous = useRef(requiredNotifications?.length || 0);

	useEffect(() => {
		if (previous.current !== requiredNotifications?.length || reset) {
			setOpen(!!requiredNotifications?.length);
			setReset(false);
			previous.current = requiredNotifications?.length || 0;
		}
	}, [requiredNotifications, reset]);

	return (
		<NotificationsContext.Provider value={{ ...value, openHint: open }}>
			{children}

			{openUpdate ? (
				<UpdateApp />
			) : (
				<ActionRequired
					notifications={requiredNotifications}
					open={open}
					setOpen={setOpen}
				/>
			)}
		</NotificationsContext.Provider>
	);
};
