import React from 'react';
import { useTranslation } from 'react-i18next';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ExternalLinkIcon } from 'src/Components/Icons/ExternalLinkIcon';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

export type HowArrangeTrackingStepProps = {
	step: number;
	text: string;
};

export const HowArrangeTrackingStep: React.FC<HowArrangeTrackingStepProps> = ({
	step,
	text,
}) => {
	return (
		<Grid container direction="column" alignItems="center">
			<Grid item>
				<Avatar
					sx={{
						width: 32,
						height: 32,
						color: 'black',
						backgroundColor: '#787878',
						fontSize: 16,
						fontWeight: 700,
					}}
				>
					{step}
				</Avatar>
			</Grid>

			<Box pt={1} />

			<Grid item xs>
				<Typography variant="body1" align="center">
					{text}
				</Typography>
			</Grid>
		</Grid>
	);
};

export const HowArrangeTrackingScreen: React.FC = () => {
	const { t } = useTranslation('how-arrange-tracking');
	const navigation = useNavigation();

	const steps = t('steps', { returnObjects: true }) as any;

	return (
		<Box
			px={2}
			height={1}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
			alignItems="center"
		>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				flexGrow={1}
			>
				{steps.map((value: any, index: number) => (
					<React.Fragment key={index}>
						<HowArrangeTrackingStep step={index + 1} text={value} />

						<Box pt={6} />
					</React.Fragment>
				))}
			</Box>

			<Box
				display="flex"
				flexDirection="column"
				pb={6}
				justifyContent="center"
			>
				<Button
					variant="contained"
					color="primary"
					onClick={() => navigation.replace(AppRoutes.Contact)}
				>
					{t('button')}
				</Button>

				<Box pt={4} />

				<Button
					color="inherit"
					variant="text"
					href="https://kickid.com/#pricing"
					target="_blank"
					startIcon={<ExternalLinkIcon color="white" />}
				>
					{t('pricesLink')}
				</Button>
			</Box>
		</Box>
	);
};
