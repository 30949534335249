import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useSessions } from 'src/Sessions/useSessions';
import { useShowSuccess } from 'src/Success/useShowSuccess';
import {
	Score,
	UserRole,
	usePayPlayerSessionWithPaypalMutation,
} from 'src/graphql/types';

declare global {
	interface Window {
		paypal: any;
	}
}

type LocationState = {
	session: Score;
};

function loadPaypalSdk(currency = 'EUR') {
	const script = document.createElement('script');
	script.src = `https://www.paypal.com/sdk/js?currency=${currency}&disable-funding=card,sofort&client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}`;
	document.body.appendChild(script);
}

export const PaypalForm = () => {
	const { t } = useTranslation('add-session');
	const [paypalLoaded, setPaypalLoaded] = useState(false);
	const { code } = useParams<{ code: string }>();
	const location = useLocation();
	const locationState = location.state as LocationState;
	const session = locationState?.session;
	const [loading, setLoading] = useState(false);
	const { setSelectedRole, refetch } = useSessions();
	const [payPlayerSessionWithPaypalMutation] =
		usePayPlayerSessionWithPaypalMutation();
	const showSuccess = useShowSuccess();

	const PayPalButton =
		paypalLoaded && window?.paypal?.Buttons
			? window.paypal.Buttons.driver('react', {
					React,
					ReactDOM,
			  })
			: null;

	useEffect(() => {
		if (!window?.paypal?.Buttons) {
			loadPaypalSdk(locationState.session.payment?.currency?.toString());
			setTimeout(() => setPaypalLoaded(true), 300);
		} else {
			setPaypalLoaded(true);
		}
	}, [setPaypalLoaded, locationState.session.payment?.currency]);

	const createOrder = (data: any, actions: any) => {
		return actions.order.create({
			purchase_units: [
				{
					custom_id: `${session.sessionNumber}/${session.playerSessionId}`,
					amount: {
						value: session.payment?.amount,
						currency_code: session.payment?.currency,
					},
				},
			],
		});
	};

	const onApprove = async (paypalData: any, actions: any) => {
		setLoading(true);

		await actions.order.capture();

		const { data } = await payPlayerSessionWithPaypalMutation({
			variables: {
				input: {
					sessionNumber: session.sessionNumber,
					playerSessionId: session.playerSessionId,
					orderId: paypalData.orderID,
					payerID: paypalData.payerID,
				},
			},
		}).catch(convertNetworkErrors);

		setLoading(false);

		setSelectedRole(UserRole.Player);

		refetch();

		if (data?.payPlayerSessionWithPaypal.paidAt) {
			showSuccess({
				title: t('successfulPaid'),
				nextRoute: `${AppRoutes.ShareWithCoach}/${code}`,
				nextRouteState: {
					session,
				},
				duration: 3000,
			});
		}
	};

	return (
		<>
			<Typography variant="caption" color="textSecondary">
				{t('paypalHint')}
			</Typography>
			{loading || !paypalLoaded ? (
				<Box display="flex" justifyContent="center" pt={3} pb={1}>
					<CircularProgress color="inherit" />
				</Box>
			) : null}
			<Box
				display="flex"
				justifyContent="center"
				pt={3}
				pb={4}
				sx={{ pointerEvents: loading ? 'none' : 'initial' }}
			>
				{paypalLoaded ? (
					<PayPalButton
						createOrder={createOrder}
						onApprove={onApprove}
					/>
				) : null}
			</Box>
		</>
	);
};
