import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';

export type CheckboxFormProps = {
	name: string;
	label: string;
	disabled?: boolean;
};

export const CheckboxForm = forwardRef<HTMLInputElement, CheckboxFormProps>(
	({ name, label, disabled }, ref) => {
		const { control } = useFormContext();

		return (
			<Controller
				name={name}
				control={control}
				render={({ field }) => (
					<FormGroup sx={{ width: 1 }}>
						<FormControlLabel
							control={
								<Checkbox
									inputRef={ref}
									color="default"
									onChange={(event: any) =>
										field.onChange(event.target.checked)
									}
									checked={field.value}
									disabled={disabled}
								/>
							}
							label={
								<Typography variant="body2">{label}</Typography>
							}
						/>
					</FormGroup>
				)}
			/>
		);
	},
);
