import { GraphQLError } from 'graphql';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { OldTopBar } from 'src/Components/OldTopBar';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useShowSuccess } from 'src/Success/useShowSuccess';
import {
	useFindOperatorSessionByIdLazyQuery,
	useProcessOperatorSessionMutation,
} from 'src/graphql/types';

import MatchCard from './MatchCard.lazy';
import { TestCard } from './TestCard';

type TeamGoals = {
	[key: number]: number[] | undefined;
};

type PlayerGoals = {
	[key: number]:
		| {
				[key: number]: number | undefined;
		  }
		| undefined;
};

const getTeamsGoalsFromLocalStorage = (sessionNumber: string) => {
	try {
		return (
			JSON.parse(
				localStorage.getItem(`teamGoals:${sessionNumber}`) || '',
			) || {}
		);
	} catch {
		return {};
	}
};

const getPlayerGoalsFromLocalStorage = (sessionNumber: string) => {
	try {
		return (
			JSON.parse(
				localStorage.getItem(`playerGoals:${sessionNumber}`) || '',
			) || {}
		);
	} catch {
		return {};
	}
};

export const ProcessSessionScreen = () => {
	const { t } = useTranslation(['process-session', 'server-error']);
	const navigation = useNavigation();
	const showSuccess = useShowSuccess();
	const sessionNumber = navigation.query.get('sessionNumber') as string;
	const loadedRef = useRef(false);
	const [findOperatorSessionById, { data, loading }] =
		useFindOperatorSessionByIdLazyQuery();
	const [processOperatorSession] = useProcessOperatorSessionMutation();

	const [playerGoalsCount, _setPlayerGoalsCount] = useState<PlayerGoals>(
		getPlayerGoalsFromLocalStorage(sessionNumber),
	);
	const [teamGoalsCount, _setTeamGoalsCount] = useState<TeamGoals>(
		getTeamsGoalsFromLocalStorage(sessionNumber),
	);

	const [isLastItem, setIsLastItem] = useState(false);
	const [loadingSave, setLoadingSave] = useState(false);
	const [serverErrors, setServerErrors] = useState<readonly GraphQLError[]>(
		[],
	);

	const setPlayerGoalsCount = (goals: PlayerGoals) => {
		_setPlayerGoalsCount(goals);
		if (sessionNumber) {
			localStorage.setItem(
				`playerGoals:${sessionNumber}`,
				JSON.stringify(goals),
			);
		}
	};

	const setTeamGoalsCount = (goals: TeamGoals) => {
		_setTeamGoalsCount(goals);
		if (sessionNumber) {
			localStorage.setItem(
				`teamGoals:${sessionNumber}`,
				JSON.stringify(goals),
			);
		}
	};

	useEffect(() => {
		if (!loadedRef.current) {
			if (sessionNumber) {
				findOperatorSessionById({
					variables: {
						sessionNumber: parseInt(sessionNumber),
					},
				});
			} else {
				navigation.replace(AppRoutes.CurrentSessions);
			}

			loadedRef.current = true;
		}
	}, [sessionNumber, findOperatorSessionById, navigation]);

	const shotTest = data?.findOperatorSessionById?.shotTest || false;
	const sprintTest = data?.findOperatorSessionById?.sprintTest || false;
	const fixedGoalkeepers =
		data?.findOperatorSessionById?.fixedGoalkeepers || false;
	const playersCount = data?.findOperatorSessionById?.playersCount || 10;
	const matchesCount = data?.findOperatorSessionById?.matchesCount || 4;

	const addGoal = (match: number, player: number, home: boolean) => {
		if (!playerGoalsCount[match]) {
			playerGoalsCount[match] = {};
		}

		const prevPlayersGoals: any = playerGoalsCount[match];

		if (!prevPlayersGoals[player]) {
			prevPlayersGoals[player] = 0;
		}

		prevPlayersGoals[player]++;

		setPlayerGoalsCount({ ...playerGoalsCount });

		const prevTeamGoal = teamGoalsCount[match] || [0, 0];
		const teamGoal = {
			[match]: home
				? [prevTeamGoal[0] + 1, prevTeamGoal[1]]
				: [prevTeamGoal[0], prevTeamGoal[1] + 1],
		};

		setTeamGoalsCount({ ...teamGoalsCount, ...teamGoal });
	};

	const removeGoal = (match: number, player: number, home: boolean) => {
		if (!playerGoalsCount[match]) {
			playerGoalsCount[match] = {};
		}

		const prevPlayersGoals: any = playerGoalsCount[match];

		if (!prevPlayersGoals[player]) {
			prevPlayersGoals[player] = 0;
			return;
		}

		prevPlayersGoals[player]--;

		setPlayerGoalsCount({ ...playerGoalsCount });

		const prevTeamGoal = teamGoalsCount[match] || [0, 0];

		if (home && !prevTeamGoal[0]) return;
		if (!home && !prevTeamGoal[1]) return;

		const teamGoal = {
			[match]: home
				? [prevTeamGoal[0] - 1, prevTeamGoal[1]]
				: [prevTeamGoal[0], prevTeamGoal[1] - 1],
		};

		setTeamGoalsCount({ ...teamGoalsCount, ...teamGoal });
	};

	const processSession = async () => {
		setServerErrors([]);
		setLoadingSave(true);

		const matchesArray = Array.from(Array(matchesCount).keys());

		const matches = matchesArray.map((match) => {
			const players: any = playerGoalsCount[match] || {};
			const playerGoals = Object.keys(players).map((player) => ({
				playerSessionId: parseInt(player),
				goals: parseInt(players[player]),
			}));
			return {
				players: playerGoals,
			};
		});

		const { errors } = await processOperatorSession({
			variables: {
				input: {
					sessionNumber: parseInt(sessionNumber),
					matches,
				},
			},
		});

		setLoadingSave(false);

		if (errors) {
			setServerErrors(errors);
		} else {
			showSuccess({
				title: t('sessionProcessed'),
				subtitle: t('pleaseUploadVideo'),
				nextRoute: AppRoutes.CurrentSessions,
				duration: 5000,
			});
		}
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			width={1}
			height={1}
			sx={{
				backgroundImage: `url(${Background1})`,
				backgroundSize: 'cover',
			}}
			alignItems="center"
		>
			<OldTopBar
				title={`${t('title')} - ${sessionNumber}`}
				iconRight={<CloseIcon />}
				onClickRight={() =>
					navigation.replace(AppRoutes.CurrentSessions)
				}
				border
			/>

			<Box
				p="20px"
				width={1}
				sx={{ overflowY: 'auto', overflowX: 'hidden' }}
			>
				{loading &&
					Array.from(Array(6).keys()).map((key) => (
						<Fragment key={key}>
							<Skeleton
								height={58}
								width="100%"
								variant="rectangular"
							></Skeleton>

							<Box pt={2} />
						</Fragment>
					))}

				{!loading && sprintTest && (
					<>
						<TestCard
							title="Sprint Test"
							playerCount={playersCount}
						/>
						<Box pt={2} />
					</>
				)}

				{!loading &&
					Array.from(Array(matchesCount).keys()).map(
						(match, index) => (
							<Fragment key={match}>
								<MatchCard
									match={match}
									sessionNumber={sessionNumber}
									playerCount={playersCount}
									fixedGoalkeepers={fixedGoalkeepers}
									onAddGoal={addGoal}
									onRemoveGoal={removeGoal}
									teamGoals={teamGoalsCount[match] || []}
									playerGoals={playerGoalsCount[match] || {}}
									onOpen={() =>
										!shotTest &&
										matchesCount === index + 1 &&
										setIsLastItem(true)
									}
									fallback={null}
								/>
								<Box pt={2} />
							</Fragment>
						),
					)}

				{!loading && shotTest && (
					<>
						<TestCard
							title="Shot Test"
							playerCount={playersCount}
							onOpen={() => setIsLastItem(true)}
						/>
						<Box pt={2} />
					</>
				)}

				<Box width={1} display="flex" justifyContent="center">
					{loadingSave && <CircularProgress color="inherit" />}

					{serverErrors.map((error) => (
						<Alert key={error.message} severity="error">
							{t('server-error:' + error.message) ||
								error.message}
						</Alert>
					))}
				</Box>

				<Box pt={9} />
			</Box>

			{isLastItem && (
				<Box position="absolute" bottom={0} pb={4} margin="auto">
					<Button
						variant="contained"
						color="primary"
						disabled={loadingSave}
						onClick={() => processSession()}
					>
						{t('finishSession')}
					</Button>
				</Box>
			)}
		</Box>
	);
};
