import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from 'src/Auth/useAuth';
import { AppRoutes } from 'src/Router/AppRoutes';
import { UserRole } from 'src/graphql/types';

export const OperatorRoute: React.FC = () => {
	const { isAuthorized, user } = useAuth();
	const location = useLocation();
	const { i18n } = useTranslation();

	return useMemo(() => {
		if (!isAuthorized) {
			return (
				<Navigate
					replace
					to={`/${i18n.language}${AppRoutes.Login}`}
					state={{
						referrer: location.pathname + location.search,
					}}
				/>
			);
		}

		if (user && !user.roles?.includes(UserRole.Operator)) {
			return (
				<Navigate
					replace
					to={`/${i18n.language}${AppRoutes.Scoring}`}
				/>
			);
		}

		return <Outlet />;
	}, [isAuthorized, user, i18n.language, location.pathname, location.search]);
};
