import _, { toNumber } from 'lodash';
import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SafeAreaBottom, SafeAreaTop } from 'src/Components/SafeArea';
import { TopBar } from 'src/Components/TopBar';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessionNumber } from 'src/Sessions/useSessionNumber';
import { Session } from 'src/graphql/types';

import { getLocalStorageAccessToken } from '../../../Auth/localStorageAccessToken';
import { useSessions } from '../../../Sessions/useSessions';
import { SessionSelector } from '../Scoring/PerformanceStats/SessionSelector';
import { TopScore } from '../Scoring/TopScore/TopScore';

export type CustomScoring = {
	control: number;
	impact: number;
	passing: number;
	physical: number;
	dribbling: number;
	defense: number;
	shot: number;
	pace: number;
};

export const SlideControlScreen = () => {
	const { t } = useTranslation('slide-control');
	const navigation = useNavigation();
	const { selectedSession } = useSessionNumber();

	const accessToken = getLocalStorageAccessToken();

	const { customScoring, setCustomScoring } = useSessions();
	const form: UseFormReturn<CustomScoring> = useForm<CustomScoring>({
		defaultValues: customScoring,
	});
	const { setValue } = form;
	const [last, setLast] = useState(form.getValues());
	const [current, setCurrent] = useState(form.getValues());

	useMemo(() => {
		setLast(customScoring);
		setCurrent(customScoring);
		_.entries(customScoring).forEach(([k, v]) =>
			setValue(k as keyof CustomScoring, v),
		);
	}, [customScoring, setValue]);

	const [score, setScore] = useState(0);

	function setPercentage(type: string, newValue: number) {
		const percentageCopy: CustomScoring = _.cloneDeep(current);
		_.set(percentageCopy, type?.toLowerCase(), newValue);

		const currentTotal = _.values(percentageCopy).reduce(
			(ps, s) => ps + s,
			0,
		);

		if (currentTotal <= 100 || total > currentTotal) {
			setCurrent(percentageCopy);
			setValue(type?.toLowerCase() as keyof CustomScoring, newValue);
		}
	}

	const filteredSubScores = useCallback(
		() =>
			(selectedSession as Session)?.averages.filter(
				(subScore) =>
					subScore.value !== null && subScore.type !== 'Rank',
			) || [],
		[selectedSession],
	);

	useEffect(() => {
		const pSum = Math.max(
			_.values(current).reduce(
				(sum, pv) => sum + toNumber((pv / 100).toFixed(2)),
				0,
			),
			1,
		);
		setScore(
			Math.round(
				filteredSubScores()?.reduce(
					(sum, ss) =>
						sum +
						(ss.value ?? 0) *
							toNumber(
								(
									_.get(current, ss.type?.toLowerCase()) / 100
								).toFixed(2),
							),
					0,
				) / pSum,
			),
		);
	}, [filteredSubScores, current]);

	const total = useMemo(() => {
		return _.values(current).reduce((ps, s) => ps + s, 0);
	}, [current]);

	const { handleSubmit } = form;
	const locked = useRef(false);
	const onSubmit = handleSubmit(async (data) => {
		if (locked.current) {
			return;
		}
		locked.current = true;

		fetch(`${process.env.REACT_APP_KICKID_API}/custom-scoring`, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify(data),
		})
			.then(() => setLast(data))
			.then(() => setCustomScoring(data))
			.finally(() => (locked.current = false));
	});

	return (
		<Stack
			height={1}
			sx={{
				overflowY: 'auto',
			}}
		>
			<SafeAreaTop />

			<TopBar
				title={t('title')}
				leftComponent={
					<IconButton
						color="inherit"
						size="large"
						onClick={() =>
							navigation.goBackOrGoTo(AppRoutes.PerformanceStats)
						}
					>
						<ArrowBackIos />
					</IconButton>
				}
			/>

			<Stack
				px="20px"
				flexGrow={1}
				width={1}
				maxWidth={'500px'}
				margin={'0 auto'}
			>
				<SessionSelector />

				<Box
					flexGrow={1}
					position="relative"
					display="flex"
					flexDirection="column"
					alignItems="stretch"
					justifyContent="space-between"
					px="20px"
					sx={{
						top: 0,
						zIndex: 1,
						position: 'sticky',
						mx: '-20px',
						background: 'black',
					}}
				>
					<Box pt="20px" px="20px">
						<Box
							maxWidth={'280px'}
							maxHeight={'280px'}
							margin={'0 auto'}
						>
							<TopScore
								value={score}
								selectedSession={selectedSession}
							/>
						</Box>
					</Box>
					<Box>
						<Stack
							direction="row"
							alignItems="center"
							py={2}
							spacing={2}
						>
							<Typography minWidth={70} color="textSecondary">
								Total
							</Typography>

							<Box flexGrow={1} />

							<Typography
								minWidth={50}
								textAlign="right"
								color={total < 100 ? 'error' : 'primary'}
							>
								{total}&nbsp;%
							</Typography>
						</Stack>

						<Divider sx={{ mx: '-20px' }} />
					</Box>
				</Box>

				{filteredSubScores().map((filteredSubScores) => {
					const percentage = _.get(
						current,
						filteredSubScores.type?.toLowerCase(),
					);

					return (
						<Fragment key={filteredSubScores.type}>
							<Stack
								direction="row"
								alignItems="center"
								py={1}
								spacing={2}
							>
								<Typography minWidth={70}>
									{filteredSubScores.type}
								</Typography>

								<Slider
									sx={{
										flexGrow: 1,
										padding: '16px 8px !important',
									}}
									size="small"
									value={percentage}
									valueLabelDisplay="off"
									disableSwap
									onChange={(event, value) =>
										setPercentage(
											filteredSubScores.type,
											value as number,
										)
									}
								/>

								<Typography minWidth={50} textAlign="right">
									{percentage}&nbsp;%
								</Typography>
							</Stack>

							<Divider sx={{ mx: '-20px' }} />
						</Fragment>
					);
				})}
			</Stack>

			<Box
				pt={2}
				pb={2}
				display="flex"
				flex={1}
				justifyContent="center"
				alignItems="center"
				component={'form'}
				onSubmit={onSubmit}
			>
				<Button
					type={'submit'}
					variant={
						total < 100 || _.isEqual(last, current)
							? 'outlined'
							: 'contained'
					}
					color="primary"
					disabled={total < 100 || _.isEqual(last, current)}
					sx={{
						transitionProperty: 'none',
					}}
				>
					<SaveIcon fontSize="small" />
					<Box ml={1} />
					{t('save')}
				</Button>
			</Box>

			<SafeAreaBottom />
		</Stack>
	);
};
