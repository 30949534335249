import { createContext } from 'react';

import { WatchComparison } from 'src/graphql/types';

export const WatchComparisonsContext = createContext<
	| {
			watchComparisons: WatchComparison[];
			loading: boolean;
			refetch: () => void;
	  }
	| undefined
>(undefined);
