import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Score } from 'src/graphql/types';

type LocationState = {
	session: Score;
};

export const ItemPrice = () => {
	const { t } = useTranslation('add-session');
	const location = useLocation();
	const locationState = location.state as LocationState;
	const session = locationState.session;

	const date = new Date(session.date);
	const formattedDate =
		date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();

	return (
		<>
			<Typography variant="caption" color="textSecondary">
				{t('item')}
			</Typography>

			<Box pt={1} />

			<Box p={2} border="1px solid #252525" bgcolor="#151515">
				<Grid container alignItems="center" spacing={2}>
					<Grid item xs>
						<Typography variant="body1">
							{`${t('trackingFor')} ${session.user?.firstName} ${
								session.user?.lastName
							} ${t('am')} ${formattedDate}`}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h6">
							{`${session.payment?.currency} ${session.payment?.amount}`}
						</Typography>
					</Grid>
				</Grid>
			</Box>

			<Box pt={1} />

			<Typography variant="caption" color="textSecondary">
				{t('noSubscription')}
			</Typography>
		</>
	);
};
