import { useTranslation } from 'react-i18next';

import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useAuth } from 'src/Auth/useAuth';
import { DinamicAvatarWithProgress } from 'src/Components/DinamicAvatarWithProgress';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import {
	ComparisonSelected,
	UserRole,
	WatchComparisonResult,
} from 'src/graphql/types';

export type ChooseBarProps = {
	compareFrom?: WatchComparisonResult | null;
};

export const ChooseBar: React.FC<ChooseBarProps> = ({ compareFrom }) => {
	const { loading: userLoading } = useAuth();
	const { selectedRole, loading: sessionLoading } = useSessions();
	const { t } = useTranslation('create-comparison');
	const navigation = useNavigation();

	const selected = navigation.query.get('selected') as ComparisonSelected;

	const textFrom =
		selectedRole === UserRole.Player
			? t('you')
			: selected === ComparisonSelected.Player
			? compareFrom?.name
			: t('team');

	const defaultRole =
		selectedRole === UserRole.Player
			? UserRole.Player
			: selected === ComparisonSelected.Player
			? UserRole.Player
			: UserRole.Trainer;

	return (
		<>
			<Grid container alignItems="center" direction="row">
				<Grid
					xs
					item
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					borderBottom={4}
					borderColor={compareFrom ? 'transparent' : '#43FF47'}
					py="10px"
					px="20px"
				>
					{compareFrom ? (
						<DinamicAvatarWithProgress
							size={40}
							iconUrl={compareFrom?.avatar}
							loading={userLoading || sessionLoading}
							role={defaultRole}
							score={compareFrom?.value}
						/>
					) : (
						<Avatar
							sx={{
								width: 40,
								height: 40,
								backgroundColor: 'black',
								border: '1px solid #43FF47',
							}}
						></Avatar>
					)}

					<Typography
						variant="caption"
						color={compareFrom ? 'textPrimary' : 'primary'}
					>
						{compareFrom ? textFrom : t('choose')}
					</Typography>
				</Grid>

				<Divider orientation="vertical" flexItem />

				<Grid
					xs
					item
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					borderBottom={4}
					borderColor={!compareFrom ? 'transparent' : '#43FF47'}
					py="10px"
					px="20px"
				>
					<Typography
						variant="caption"
						color={!compareFrom ? 'textPrimary' : 'primary'}
					>
						{t('choose')}
					</Typography>

					<Avatar
						sx={{
							width: 40,
							height: 40,
							backgroundColor: 'black',
							border: `1px solid  ${
								compareFrom ? '#43FF47' : 'white'
							}`,
						}}
					></Avatar>
				</Grid>
			</Grid>

			<Divider />
		</>
	);
};
