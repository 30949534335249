import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';

import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/system/Box';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { useAuth } from 'src/Auth/useAuth';
import { TextFieldForm } from 'src/Form/Inputs/TextFieldForm';
import { transformLowercase } from 'src/Form/helpers/transformLowercase';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useShowSuccess } from 'src/Success/useShowSuccess';
import { basicTheme } from 'src/Theme/theme';
import { useResendUserEmailMutation } from 'src/graphql/types';
import { useServerErrors } from 'src/helpers/useServerErrors';

type UserEmailDialogValues = {
	email: string;
};

const formValues = {
	email: '',
};

export const UserEmailDialog = () => {
	const { t, i18n } = useTranslation('user-email-dialog');
	const navigation = useNavigation();
	const { user, loading, refetch } = useAuth();
	const firstLoad = useRef(false);
	const [resendUserEmailMutation, { loading: saveLoading }] =
		useResendUserEmailMutation();
	const { setServerErrors, resetServerErrors, ServerErrors } =
		useServerErrors();
	const showSuccess = useShowSuccess();

	const form = useForm<UserEmailDialogValues>({
		resolver: yupResolver(
			yup.object({
				email: yup
					.string()
					.lowercase()
					.trim()
					.email()
					.required()
					.emailExists()
					.validEmail(),
			}),
		),
		defaultValues: formValues,
		reValidateMode: 'onBlur',
	});

	const { handleSubmit } = form;

	const onSubmit = handleSubmit(async (formData) => {
		resetServerErrors();

		const { data, errors } = await resendUserEmailMutation({
			variables: {
				input: {
					newEmail: formData.email,
				},
			},
		}).catch(convertNetworkErrors);

		if (errors) {
			setServerErrors(errors);
		}

		if (data?.resendUserEmail.emailSent) {
			showSuccess({
				title: t('success_title'),
				subtitle: t('success_subtitle'),
				onClose: () => {
					refetch();
					navigation.replace(AppRoutes.Scoring);
				},
				duration: 3000,
			});
		}
	});

	useEffect(() => {
		if (user && !firstLoad.current) {
			form.setValue('email', user?.email?.toString() ?? '');
			firstLoad.current = true;
		}
	}, [form, user]);

	if (user?.emailVerified) {
		return (
			<Navigate replace to={`/${i18n.language}${AppRoutes.Scoring}`} />
		);
	}

	return (
		<FormProvider {...form}>
			<Stack
				noValidate
				component="form"
				bgcolor="white"
				color="black"
				pt={4}
				flexGrow={1}
				onSubmit={onSubmit}
			>
				<Box px={2}>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
					>
						<Avatar
							sx={{
								bgcolor: 'primary.main',
								width: 48,
								height: 48,
							}}
						>
							<ForwardToInboxIcon />
						</Avatar>

						<Box pt={4} />

						<Typography variant="body1" textAlign="center">
							{t('title_text')}
						</Typography>

						<Box pt={2} />

						<Typography
							variant="body2"
							color="textSecondary"
							textAlign="center"
						>
							{t('description_text')}
						</Typography>

						<Box pt={3} />

						<ThemeProvider theme={basicTheme}>
							<TextFieldForm
								id="user-email"
								fullWidth
								variant="outlined"
								name="email"
								type="email"
								autoComplete="email"
								placeholder="name@example.com"
								label={t('email_placeholder')}
								disabled={loading}
								inputProps={{
									sx: {
										...transformLowercase,
									},
									autoCapitalize: 'off',
								}}
							/>

							<Box pt={2} />

							{saveLoading && (
								<CircularProgress color="inherit" />
							)}

							<ServerErrors />
						</ThemeProvider>

						<Box display="flex" justifyContent="center" py={1}>
							<Typography
								variant="caption"
								color="text.secondary"
								textAlign="center"
							>
								{t('check_spam_text')}
							</Typography>
						</Box>
					</Box>
				</Box>

				<Box height="1px" bgcolor="#E5E5E5" />

				<Button
					type="submit"
					sx={{ p: 2 }}
					size="small"
					color="inherit"
					disabled={loading}
				>
					{t('resend_button')}
				</Button>
			</Stack>
		</FormProvider>
	);
};
