import gql from 'graphql-tag';

const USER_FRAGMENT = gql`
	fragment UserFragment on User {
		sub
		username
		email
		name
		firstName
		lastName
		birthdate {
			day
			month
			year
		}
		avatarUrl
		backgroundUrl
		roles
		emailVerified
		newsletterSubscription
		externalSource
		legalGuardian {
			email
			firstName
			lastName
			phone
			consent
			birthdate {
				day
				month
				year
			}
		}
	}
`;

export default USER_FRAGMENT;
