import { GraphQLError } from 'graphql';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { OldTopBar } from 'src/Components/OldTopBar';
import { FormLayout } from 'src/Form/FormLayout';
import { useCustomForm } from 'src/Form/useCustomForm';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { useEventValidations } from './CreateEvent.validations';
import { CreateEventsControllers } from './CreateEventScreen.controllers';

import {
	ScoutingEvent,
	useCreateEventMutation,
	useUpdateEventMutation,
} from '../../../graphql/types';
import { CreateEventForm } from '../../Events/Views/CreateEvent';
import { useEvent } from '../useEvent';

export const CreateEventScreen = () => {
	const params = useParams<{ eventNumber: string }>();
	const eventNumber = _.toInteger(params.eventNumber);

	const { event } = useEvent();

	const [createEventMutation] = useCreateEventMutation();
	const [updateEventMutation] = useUpdateEventMutation();
	const { t } = useTranslation(['create-event', 'server-error']);

	const { form, setForm, resetForm } = useCustomForm<CreateEventForm>();

	const resolver = useEventValidations(eventNumber);
	const formUseForm = useForm<CreateEventForm>({
		defaultValues: _.assign(
			{
				club: null,
				end: null,
				externalSources: [],
				fixedGoalKeepers: false,
				name: '',
				players: [],
				sessions: [],
				shotTest: false,
				sprintTest: false,
				start: null,
				trainers: [],
				override: false,
			},
			form,
		),
		resolver,
	});

	const { handleSubmit, setValue } = formUseForm;
	const [serverErrors, setServerErrors] = useState<readonly GraphQLError[]>(
		[],
	);

	const navigation = useNavigation();
	const [loadingSave, setLoadingSave] = useState(false);
	const resetFormRef = useRef<boolean>();
	const updateFromRef = useRef<boolean>();

	useEffect(() => {
		if (event && eventNumber && !updateFromRef.current) {
			const scoutingEvent = event;

			if (scoutingEvent) {
				_.forEach(_.entries(scoutingEvent), ([k, v]) => {
					if (['start', 'end'].includes(k)) {
						v = moment(v);
					}
					setValue(k as keyof ScoutingEvent, v);
				});
			} else {
				navigation.setQuery('eventNumber', null);
			}

			updateFromRef.current = true;
		}

		if (eventNumber !== 0 && !resetFormRef.current) {
			resetForm();
			resetFormRef.current = true;
		}
	}, [event, eventNumber, form, navigation, resetForm, setForm, setValue]);

	const onSubmitCreate = handleSubmit(async (formData) => {
		formData = { ...form, ...formData };
		setForm(formData);

		setServerErrors([]);
		setLoadingSave(true);

		const { data, errors } = await createEventMutation({
			variables: {
				input: {
					clubId: formData?.club?.id ?? -1,
					end: formData.end,
					fixedGoalKeepers: formData.fixedGoalKeepers,
					name: formData.name,
					shotTest: formData.shotTest,
					sprintTest: formData.sprintTest,
					start: formData.start,
					playerIds: _.map(formData.players, (p) => p.sub),
					trainerIds: _.map(formData.trainers, (p) => p.sub),
					externalSources: formData.externalSources,
				},
			},
		}).catch(convertNetworkErrors);

		setLoadingSave(false);

		if (errors) setServerErrors(errors);

		if (!_.isNil(data?.createEvent)) {
			navigation.replace(AppRoutes.CurrentEvents);
		}
	});

	const onSubmitEdit = handleSubmit(async ({ override, ...formData }) => {
		setServerErrors([]);
		setLoadingSave(true);

		type UpdateScoutingEvent = Partial<ScoutingEvent> & {
			eventNumber: number;
			clubId: number;
			playersIds: string[];
			trainerIds: string[];
		};

		const input: UpdateScoutingEvent = _.defaultsDeep(
			{},
			{
				fixedGoalKeepers: formData.fixedGoalKeepers,
				name: formData.name,
				clubId: event?.club?.id as number,
				playersIds: override
					? []
					: _.uniq(
							_.map(
								_.concat(formData.players, event?.players),
								'sub',
							),
					  ),
				trainerIds: override
					? []
					: _.uniq(
							_.map(
								_.concat(formData.trainers, event?.trainers),
								'sub',
							),
					  ),
			},
			formData,
			event,
			{ override },
		);
		delete input.__typename;
		delete input.sessions;
		delete input.trainers;
		delete input.players;
		delete input.club;

		input.externalSources = formData.externalSources;

		const { data: updateData, errors } = await updateEventMutation({
			variables: { input },
		}).catch(convertNetworkErrors);

		setLoadingSave(false);

		if (errors) setServerErrors(errors);

		if (!_.isNil(updateData?.updateEvent)) {
			navigation.replace(AppRoutes.CurrentEvents);
		}
	});

	const confirmButton = {
		onClickLeft: () => navigation.replace(AppRoutes.CurrentEvents),
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			width={1}
			height={1}
		>
			<OldTopBar
				title={
					eventNumber
						? event?.club?.name ?? undefined
						: t('titleNewEvent')
				}
				subTitle={eventNumber ? event?.name : undefined}
				border
				iconLeft={<ArrowBackIcon />}
				{...confirmButton}
			/>

			<Box
				width={1}
				sx={{
					overflowY: 'auto',
					overflowX: 'hidden',
				}}
			>
				<FormLayout
					onSubmit={onSubmitCreate}
					title={t('enterEventInfo')}
					buttonTop={false}
					buttonBottom={false}
					button={
						<>
							{eventNumber === 0 ? (
								<Button
									onClick={() => onSubmitCreate()}
									fullWidth
									variant={
										loadingSave ? 'outlined' : 'contained'
									}
									disabled={loadingSave}
									sx={{ transitionProperty: 'none' }}
									color="primary"
									endIcon={
										loadingSave ? (
											<CircularProgress
												color="inherit"
												size={20}
											/>
										) : null
									}
								>
									{t('createEvent')}
								</Button>
							) : null}
							{eventNumber !== 0 ? (
								<Button
									onClick={() => onSubmitEdit()}
									fullWidth
									variant={
										loadingSave ? 'outlined' : 'contained'
									}
									disabled={loadingSave}
									sx={{ transitionProperty: 'none' }}
									color="primary"
									endIcon={
										loadingSave ? (
											<CircularProgress
												color="inherit"
												size={20}
											/>
										) : null
									}
								>
									{t('finishEdit')}
								</Button>
							) : null}{' '}
							<Box pt={4}></Box>
						</>
					}
					controls={
						<>
							<CreateEventsControllers
								loading={loadingSave}
								form={formUseForm}
								edit={eventNumber !== 0}
								event={event ?? undefined}
							/>

							{serverErrors.map((error) => (
								<>
									<Alert key={error.message} severity="error">
										{t('server-error:' + error.message) ||
											error.message}
									</Alert>
								</>
							))}
						</>
					}
				/>
			</Box>
		</Box>
	);
};
