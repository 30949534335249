import { useParams } from 'react-router-dom';

import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import { Score, Session } from 'src/graphql/types';

import { useUserScores } from './useUserScores';

export const useSessionNumber = () => {
	const { userScores, loading: scoresLoading } = useUserScores();
	const params = useParams<{ userId: string }>();
	const { loading, sessions, setSessions } = useSessions();
	const { query } = useNavigation();

	const sessionNumber = query.get('sessionNumber');

	const choosenSessions = params.userId ? userScores : sessions;
	const choosenLoading = params.userId ? scoresLoading : loading;

	if (choosenLoading || !choosenSessions?.length) {
		return {
			selectedIndex: -1,
			previousSession: null,
			selectedSession: null,
			nextSession: null,
			loading: choosenLoading,
			setSelectedSession: () => {},
		};
	}

	const index = choosenSessions.findIndex(
		(session: Session | Score) =>
			session.sessionNumber === parseInt(sessionNumber || '', 10),
	);

	const selectedIndex = index === -1 ? 0 : index;

	const previousSession = choosenSessions[selectedIndex - 1];
	const selectedSession = choosenSessions[selectedIndex];
	const nextSession = choosenSessions[selectedIndex + 1];

	const setSelectedSession = (session: Session | Score) => {
		if (!session || !sessions) return;
		sessions[index] = session;
		setSessions([...sessions] as []);
	};

	return {
		selectedIndex,
		previousSession,
		selectedSession,
		nextSession,
		loading: choosenLoading,
		setSelectedSession,
	};
};
