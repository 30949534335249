import _ from 'lodash';
import React from 'react';
import { ControllerProps, FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FieldController } from './FieldController';

export interface CheckboxControllerProps<
	TFieldValues,
	TName extends FieldPath<TFieldValues>,
> extends Pick<ControllerProps<TFieldValues, TName>, 'control' | 'name'> {
	disabled?: boolean;
	label?: string;
	onChange?: (value: boolean) => void;
	translation: string;
}

export const CheckboxController = <
	TFieldValues extends Record<string, boolean>,
	TName extends FieldPath<TFieldValues>,
>({
	control,
	disabled,
	label,
	name,
	onChange,
	translation,
}: CheckboxControllerProps<TFieldValues, TName>) => {
	const { t } = useTranslation(translation);

	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));
	return (
		<FieldController
			name={name}
			control={control}
			translation={translation}
			render={({ field: { helperText, error, ...field } }) => {
				const _onChange = field.onChange;

				type Params = Parameters<typeof _onChange>;

				field.onChange = (event: Params[0], value: Params[1]) => {
					_onChange(event, value);
					if (_.isFunction(onChange)) {
						onChange(value);
					}
				};

				return (
					<FormControl fullWidth variant="filled" error={error}>
						<FormControlLabel
							disabled={disabled}
							control={
								<Checkbox
									{...field}
									checked={field.value}
									size={sm ? 'medium' : 'small'}
								/>
							}
							label={t(label ?? name)}
						/>
						<FormHelperText>{helperText}</FormHelperText>
					</FormControl>
				);
			}}
		/>
	);
};
