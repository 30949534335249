import { createContext } from 'react';

import { ScoutingEvent } from 'src/graphql/types';

export const EventContext = createContext<
	| {
			loading: boolean;
			event: ScoutingEvent | null;
			setEvent: (event: ScoutingEvent) => void;
			refetch: () => void;
	  }
	| undefined
>(undefined);
