import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SeparatedDateForm } from 'src/Form/Inputs/SeparedDateForm';
import { TextFieldForm } from 'src/Form/Inputs/TextFieldForm';
import { useMagicForm } from 'src/Form/MagicFormProvider';
import { transformLowercase } from 'src/Form/helpers/transformLowercase';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { USER_LEGAL_AGE } from 'src/defaults';
import { SeparatedDate } from 'src/graphql/types';
import { checkIfOlderThan } from 'src/helpers/checkIfOlderThan';

import { SignUpFormValues } from '../SignUpScreen';

export const BirthdayForm: React.FC = () => {
	const { t } = useTranslation('sign-up-screen');
	const navigation = useNavigation();
	const birthdateFieldRef = useRef<HTMLInputElement>(null);
	const legalGuardianFieldRef = useRef<HTMLInputElement>(null);
	const nextButtonRef = useRef<HTMLButtonElement>(null);
	const { setFormValues, formValues } = useMagicForm<SignUpFormValues>();
	const [showLegalGuardianForm, setShowLegalGuardianForm] = useState(
		!!formValues.birthdate.day &&
			!checkIfOlderThan(formValues.birthdate, USER_LEGAL_AGE),
	);

	const form = useForm<SignUpFormValues>({
		resolver: yupResolver(
			yup.object({
				birthdate: yup
					.object({
						year: yup.string().required(),
						month: yup.string().required(),
						day: yup.string().required(),
					})
					.separatedDate()
					.birthdate(),
				legalGuardianEmail: yup.string().when('birthdate', {
					is: (birthdateNow: SeparatedDate) => {
						const hasLegalAge = checkIfOlderThan(
							birthdateNow,
							USER_LEGAL_AGE,
						);
						return birthdateNow.day && !hasLegalAge;
					},
					otherwise: yup.string().transform(() => ''),
					then: yup
						.string()
						.trim()
						.lowercase()
						.email()
						.notOneOf([formValues.email], 'validations:same_email')
						.required()
						.validEmail(),
				}),
			}),
		),
		defaultValues: formValues,
	});

	const { handleSubmit, watch, formState } = form;

	const [birthdate] = watch(['birthdate']);
	const hasLegalAge = checkIfOlderThan(birthdate, USER_LEGAL_AGE);

	const showLegaGuardian = () => {
		setShowLegalGuardianForm(true);

		setTimeout(() => {
			legalGuardianFieldRef.current?.focus();
		}, 500);
	};

	const onSubmit = handleSubmit((formData) => {
		setFormValues(formData);

		navigation.push(AppRoutes.UserRoleForm);
	});

	const loading = formState.isSubmitting;

	return (
		<FormProvider {...form}>
			<Stack
				noValidate
				px="20px"
				alignItems="center"
				component="form"
				flexGrow={1}
			>
				<Stack
					alignItems="center"
					justifyContent="center"
					width={1}
					flexGrow={1}
					maxWidth={500}
				>
					<Box pt={4} />

					<Typography variant="h5" textAlign="center" maxWidth={350}>
						{t('birthdate_input')}
					</Typography>

					<Box pt={4} />

					<SeparatedDateForm
						id="signup-birthdate"
						name="birthdate"
						ref={birthdateFieldRef}
						autoComplete="bday"
						nextRef={
							!hasLegalAge && showLegalGuardianForm
								? legalGuardianFieldRef
								: nextButtonRef
						}
						disabled={loading}
					/>

					<Grow
						in={!hasLegalAge && showLegalGuardianForm}
						mountOnEnter
						unmountOnExit
					>
						<Box>
							<Box pt={6} />

							<Typography
								variant="h5"
								textAlign="center"
								maxWidth={350}
							>
								{t('legal_guardian_title')}
							</Typography>

							<Box pt={4} />

							<TextFieldForm
								fullWidth
								id="signup-legalguadian-email"
								variant="outlined"
								name="legalGuardianEmail"
								type="email"
								autoComplete="email"
								placeholder="name@example.com"
								inputRef={legalGuardianFieldRef}
								nextRef={nextButtonRef}
								label={t('legal_guardian_placeholder')}
								disabled={loading}
								inputProps={{
									sx: {
										...transformLowercase,
									},
									autoCapitalize: 'off',
								}}
							/>
						</Box>
					</Grow>
				</Stack>

				<Box pt={8} />

				<Button
					color="inherit"
					variant="outlined"
					type="button"
					ref={nextButtonRef}
					onClick={
						!hasLegalAge && !showLegalGuardianForm && birthdate.day
							? showLegaGuardian
							: onSubmit
					}
					disabled={loading}
				>
					{t('next_button')}
				</Button>

				<Box pt={8} />
			</Stack>
		</FormProvider>
	);
};
