import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import Background1 from 'src/Components/Backgrounds/Background1.svg';

export const BottomButton = styled(Button)(() => ({
	borderBottomLeftRadius: 0,
	borderBottomRightRadius: 0,
	borderBottom: 0,
	height: 70,
	backgroundImage: `url(${Background1})`,
	backgroundSize: 'cover',
	border: '1px solid #252525',
}));
