import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const EmailIcon: React.FC<CustomIconProps> = (props) => {
	const { color } = useCustomIcon(props);
	return (
		<SvgIcon viewBox="0 0 20 15" sx={props.sx}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					transform="translate(-2.000000, -5.000000)"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				>
					<g transform="translate(2.500000, 6.000000)">
						<polyline points="0 0 9.5 8.38168189 19 0"></polyline>
						<line
							x1="18.6868151"
							y1="6.5"
							x2="11.8700748"
							y2="12.3675804"
							id="Line-39-Copy-3"
							strokeLinecap="square"
							transform="translate(15.278445, 9.433790) scale(-1, 1) translate(-15.278445, -9.433790) "
						></line>
						<line
							x1="7.22088091"
							y1="6.5"
							x2="0.317466066"
							y2="12.3675804"
							id="Line-39-Copy-4"
							strokeLinecap="square"
						></line>
						<rect x="0" y="0" width="19" height="12.6666667"></rect>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
