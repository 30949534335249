import _ from 'lodash';
import React from 'react';
import { ControllerProps, FieldPath, FieldPathValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FieldController } from './FieldController';

export interface SelectOption<TValue> {
	name: string;
	value: TValue;
}

export interface SelectControllerProps<
	TFieldValues,
	TName extends FieldPath<TFieldValues>,
> extends Pick<ControllerProps<TFieldValues, TName>, 'control' | 'name'> {
	options: SelectOption<FieldPathValue<TFieldValues, TName>>[];
	translation: string;
	label?: string;
	disabled?: boolean;
}

export const SelectController = <
	TFieldValues,
	TName extends FieldPath<TFieldValues>,
>({
	translation,
	name,
	label,
	control,
	disabled,
	options,
}: SelectControllerProps<TFieldValues, TName>) => {
	const { t } = useTranslation(translation);

	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));
	return (
		<FieldController
			name={name}
			control={control}
			translation={translation}
			render={({ field: { helperText, error, ...field } }) => {
				return (
					<FormControl fullWidth variant="filled" error={error}>
						<InputLabel>{t(label ?? name)}</InputLabel>
						<Select
							{...field}
							disabled={disabled}
							size={sm ? 'medium' : 'small'}
							MenuProps={{
								PaperProps: {
									style: { maxHeight: 36 * 5 + 8 },
								},
							}}
						>
							{_.map(options, (o, i) => (
								<MenuItem
									key={`${i}-${o.value}`}
									value={`${o.value}`}
									sx={{ minHeight: 'auto !important' }}
								>
									{t(o.name)}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>{helperText}</FormHelperText>
					</FormControl>
				);
			}}
		/>
	);
};
