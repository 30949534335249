import _ from 'lodash';
import React, { Fragment, ReactFragment, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
	Box,
	Collapse,
	IconButton,
	Paper,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TablePaginationProps,
	TableRow,
	TableSortLabel,
	useMediaQuery,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { visuallyHidden } from '@mui/utils';

export interface RowProps<TRow> {
	row: TRow;
	colSpan: number;

	renderRowCells(row: TRow): ReactFragment;

	renderRowDetails?(row: TRow): ReactFragment;

	onRowClick?(row: TRow, rect: DOMRect): void;
}

export interface HeadCell {
	label: string;
	sort?: string;
	hidden?: boolean;
	sx?: SxProps<Theme>;
}

type DisplayedRowsLabelType = TablePaginationProps['labelDisplayedRows'];

export interface TableProps<TRow>
	extends Omit<RowProps<TRow>, 'row' | 'colSpan'> {
	empty?: ReactFragment;
	headCells: HeadCell[];
	loading?: boolean;
	rows: TRow[];
	toolbar?: ReactFragment;
	displayedRowsLabel?: DisplayedRowsLabelType;
	rowsPerPageLabel: string;
	keyName: keyof TRow;
}

const Row = <TRow,>({
	...props
}: RowProps<TRow>): ReturnType<React.FC<RowProps<TRow>>> => {
	const { colSpan, row, renderRowCells, renderRowDetails } = props;
	const clickable = !_.isNil(props.onRowClick);
	const onRowClick = (props.onRowClick = _.get(
		props,
		'onRowClick',
		() => {},
	));
	const [open, setOpen] = useState(false);

	let rowCells = renderRowCells(row);
	let details = null;
	if (_.isFunction(renderRowDetails)) {
		rowCells = (
			<TableRow
				sx={{
					'& > *': {
						borderBottom: 'unset !important',
						maxWidth: '100%',
					},
				}}
			>
				<TableCell sx={{ width: 0, padding: '0 0 0 8px' }}>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? (
							<KeyboardArrowUpIcon />
						) : (
							<KeyboardArrowDownIcon />
						)}
					</IconButton>
				</TableCell>
				{rowCells}
			</TableRow>
		);
		details = (
			<TableRow sx={{ maxWidth: '100%' }}>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={colSpan + 1}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box
							sx={{
								margin: 1,
								'& > *': {
									wordBreak: 'break-all',
								},
							}}
						>
							{renderRowDetails(row)}
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		);
	} else {
		rowCells = (
			<TableRow
				hover
				onClick={(event) => {
					const target = event.target as HTMLTableRowElement;
					onRowClick(
						row,
						(
							target.parentElement ?? target
						).getBoundingClientRect(),
					);
				}}
				sx={{
					maxWidth: '100%',
					cursor: clickable ? 'pointer' : 'initial',
				}}
			>
				{rowCells}
			</TableRow>
		);
	}

	return (
		<Fragment>
			{rowCells}
			{details}
		</Fragment>
	);
};

export const CollapsibleTable = <TRow,>({
	empty,
	keyName,
	headCells,
	loading = false,
	rows,
	rowsPerPageLabel,
	displayedRowsLabel,
	toolbar,
	...props
}: TableProps<TRow>): ReturnType<React.FC<RowProps<TRow>>> => {
	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));

	const [order, setOrder] = useState<any>('asc');
	const [orderBy, setOrderBy] = useState<any>(undefined);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		sort: string,
	) => {
		const isAsc = orderBy === sort && order === 'asc';
		const defaultOrder = orderBy === sort && !isAsc;
		setOrderBy(defaultOrder ? keyName : sort);
		setOrder(defaultOrder || isAsc ? 'desc' : 'asc');
	};

	const createSortHandler =
		(sort: string) => (event: React.MouseEvent<unknown>) => {
			handleRequestSort(event, sort);
		};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	let head = (
		<>
			{_.map(headCells, (c, i) => {
				const suffix = _.isNil(c.sort) ? '' : `-${c.sort}`;
				const key = `th-${i}${suffix}`;
				if (_.isNil(c.sort)) {
					return <TableCell key={key}>{c.label}</TableCell>;
				}
				return (
					<TableCell
						key={key}
						hidden={c.hidden}
						sortDirection={orderBy === c.sort ? order : false}
						component={'th'}
						sx={c.sx}
					>
						<TableSortLabel
							active={orderBy === c.sort}
							direction={orderBy === c.sort ? order : 'asc'}
							onClick={createSortHandler(c.sort)}
						>
							{c.label}
							{orderBy === c.sort ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc'
										? 'sorted descending'
										: 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				);
			})}
		</>
	);

	let colSpan = headCells.length;
	if (_.isFunction(props.renderRowDetails)) {
		colSpan++;
		head = (
			<>
				<TableCell sx={{ width: 0, padding: '0 0 0 8px' }} />
				{head}
			</>
		);
	}

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
	return (
		<Box className={'fullwidth'} sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%' }}>
				{toolbar ?? null}
				<TableContainer>
					<Table
						sx={{
							maxWidth: '100%',
							'[hidden]': { display: 'none' },
						}}
						aria-label="collapsible table"
					>
						<TableHead sx={{ maxWidth: '100%' }}>
							<TableRow
								sx={{
									'& > *': {
										textTransform: 'uppercase',
									},
								}}
							>
								{head}
							</TableRow>
						</TableHead>
						<TableBody sx={{ maxWidth: '100%' }}>
							{_.orderBy(rows, orderBy, order)
								.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage,
								)
								.map((row) => {
									if (loading) {
										return (
											<TableRow key={_.get(row, keyName)}>
												<TableCell
													padding={'none'}
													colSpan={colSpan}
												>
													<Skeleton
														variant="rectangular"
														height={53}
													/>
												</TableCell>
											</TableRow>
										);
									}
									return (
										<Row
											key={_.get(row, keyName)}
											row={row}
											colSpan={colSpan}
											{...props}
										/>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 53 * emptyRows,
									}}
								>
									<TableCell colSpan={colSpan} />
								</TableRow>
							)}
							{loading && rows.length === 0 && (
								<TableRow>
									<TableCell
										padding={'none'}
										colSpan={colSpan}
									>
										<Skeleton
											variant="rectangular"
											height={53}
										/>
									</TableCell>
								</TableRow>
							)}
							{!loading && rows.length === 0 && (
								<TableRow>
									<TableCell colSpan={colSpan}>
										{empty ?? null}
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					sx={{
						'& .MuiTablePagination-toolbar': {
							justifyContent: sm ? 'unset' : 'space-evenly',
						},
						'& .MuiTablePagination-spacer': {
							display: sm ? 'block' : 'none',
						},
					}}
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					labelDisplayedRows={displayedRowsLabel}
					labelRowsPerPage={sm ? rowsPerPageLabel : ''}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</Box>
	);
};
