import _ from 'lodash';
import React, { Fragment, useCallback } from 'react';
import {
	Controller,
	ControllerProps,
	ControllerRenderProps,
} from 'react-hook-form';
import { FieldPath } from 'react-hook-form';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import {
	Avatar,
	Box,
	Checkbox,
	Divider,
	Fade,
	FormControl,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Skeleton,
	Zoom,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { User } from '../../../graphql/types';

// <IconButton
// 	sx={{
// 		width: 'fit-content',
// 		height: 'fit-content',
// 		padding: '0',
// 		border: 0,
// 	}}
// >
// 	<HighlightOff fontSize={'medium'} end={'edge'} />
// </IconButton>

export interface UserListItemData<
	TFieldValues extends Record<string, string[]>,
	TName extends FieldPath<TFieldValues>,
> {
	valueKey: string;
	primaryKey: string;
	secondaryKey: string;
	loading?: boolean;
	disabled?: boolean;
	field: ControllerRenderProps<TFieldValues, TName>;
	items: (User & { disabled?: boolean })[];
}

const UserListItem = <
	TFieldValues extends Record<string, string[]>,
	TName extends FieldPath<TFieldValues>,
>({
	data,
	index,
	style,
}: ListChildComponentProps<UserListItemData<TFieldValues, TName>>) => {
	const {
		field,
		items,
		loading,
		disabled,
		primaryKey,
		secondaryKey,
		valueKey,
	} = data;
	const user = items[index];
	const isLast = !(index < items.length - 1);

	const handleOnClick = useCallback(
		(value: string) => {
			let codes;
			if (_.includes(field.value, value)) {
				codes = _.filter(field.value, (c) => c !== value);
			} else {
				codes = [...field.value, value];
			}
			field.onChange(codes);
		},
		[field],
	);
	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));
	return (
		<Fragment>
			<ListItem
				onClick={() =>
					!disabled && handleOnClick(_.get(user, valueKey))
				}
				style={style}
				sx={{
					height: '100%',
					'& .MuiListItemSecondaryAction-root': {
						fontSize: 0,
					},
					'&.Mui-disabled': {
						opacity: 'unset',
						backgroundColor: 'rgba(255, 255, 255, 0.12)',
					},
				}}
				disablePadding
				key={_.get(user, valueKey)}
				secondaryAction={
					<Checkbox
						edge="end"
						disabled={loading || disabled}
						size={sm ? 'medium' : 'small'}
						checked={
							!loading &&
							field.value?.includes(_.get(user, valueKey))
						}
					/>
				}
				className={disabled ? 'Mui-disabled' : ''}
			>
				<ListItemButton
					sx={{
						height: '100%',
						backgroundColor: 'rgba(255, 255, 255, 0.09)',
						transition:
							'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
						'&:hover': {
							backgroundColor: 'rgba(255, 255, 255, 0.13)',
						},
					}}
					disabled={user.disabled || loading || disabled}
				>
					<ListItemAvatar
						sx={{ height: 40, width: 40, position: 'relative' }}
					>
						<Avatar
							sx={{
								position: 'absolute',
								height: 40,
								width: 40,
								top: 0,
								left: 0,
							}}
							src={`${user.avatarUrl}`}
						/>
						<Zoom
							in={loading && _.isEmpty(user.avatarUrl)}
							timeout={{ appear: 0, enter: 0, exit: 250 }}
							style={{ transitionDelay: loading ? '0' : '500ms' }}
						>
							<Avatar
								sx={{
									position: 'absolute',
									height: 40,
									width: 40,
									top: 0,
									left: 0,
								}}
							>
								<Skeleton variant="circular" />
							</Avatar>
						</Zoom>
					</ListItemAvatar>
					<ListItemText
						sx={{
							'& > *': {
								wordBreak: 'keep-all',
								whiteSpace: 'nowrap',
								overflowX: 'hidden',
								textOverflow: 'ellipsis',
							},
						}}
						primary={
							loading ? (
								<Skeleton height={24} variant="text" />
							) : (
								_.get(user, primaryKey)
							)
						}
						secondary={
							loading ? (
								<Skeleton height={20.02} variant="text" />
							) : (
								_.get(user, secondaryKey)
							)
						}
					/>
				</ListItemButton>
			</ListItem>
			{!isLast && <Divider variant="inset" component="li" />}
		</Fragment>
	);
};

export const VirtualListController = <
	TFieldValues extends Record<string, string[]>,
	TName extends FieldPath<TFieldValues>,
>({
	control,
	disabled,
	items,
	loading,
	name,
	onChange,
	primaryKey = 'name',
	secondaryKey = 'email',
	valueKey = 'sub',
	visibleItems = 5,
}: {
	disabled?: boolean;
	items: (User & { disabled?: boolean })[];
	loading: boolean;
	onChange?: (value: string[]) => void;
	primaryKey: string;
	secondaryKey: string;
	valueKey: string;
	visibleItems?: number;
} & Pick<ControllerProps<TFieldValues, TName>, 'control' | 'name'>) => {
	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));
	visibleItems = Math.min(visibleItems, items.length);
	return (
		<FormControl
			sx={{
				minHeight: 73,
				position: 'relative',
				borderTopLeftRadius: '4px',
				borderTopRightRadius: '4px',
				overflow: 'hidden',
				'& > :before': {
					content: '""',
					position: 'absolute',
					bottom: 1,
					width: '100%',
					borderTop: '1px solid rgba(255, 255, 255, 0.7)',
				},
				'& > .Mui-disabled:before': {
					borderTopStyle: 'dotted',
				},
			}}
			variant={'filled'}
		>
			<Controller
				name={name}
				control={control}
				render={({ field }) => {
					const _onChange = field.onChange;

					field.onChange = (
						value: Parameters<typeof _onChange>[0],
					) => {
						_onChange(value);
						if (_.isFunction(onChange)) {
							onChange(value);
						}
					};

					return (
						<>
							<Fade timeout={{ appear: 0 }} in={loading}>
								<Box
									width={'100%'}
									height={'100%'}
									sx={{
										position: 'absolute',
									}}
								>
									<Skeleton
										width={'100%'}
										height={'100%'}
										variant={'rectangular'}
									/>
								</Box>
							</Fade>
							<FixedSizeList<
								UserListItemData<TFieldValues, TName>
							>
								className={disabled ? 'Mui-disabled' : ''}
								height={73 * visibleItems}
								width={sm ? 500 : 320}
								itemSize={73}
								itemCount={items.length}
								itemData={{
									items,
									field,
									loading,
									valueKey,
									primaryKey,
									secondaryKey,
									disabled,
								}}
								overscanCount={visibleItems * 3}
								innerElementType={List}
							>
								{UserListItem}
							</FixedSizeList>
						</>
					);
				}}
			/>
		</FormControl>
	);
};
