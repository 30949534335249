import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { ArrowLeftIcon } from 'src/Components/Icons/ArrowLeftIcon';
import { ArrowRightIcon } from 'src/Components/Icons/ArrowRightIcon';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessionNumber } from 'src/Sessions/useSessionNumber';
import { useSessions } from 'src/Sessions/useSessions';
import { useUserScores } from 'src/Sessions/useUserScores';

import { useSessionPlayerArgs } from '../../../../redux/hooks';
import { useGetTrainingVideosQuery } from '../../../../redux/rtk-query';

export const SessionSelector = () => {
	const { t } = useTranslation(['performance-stats', 'months']);

	const { setQuery } = useNavigation();
	const params = useParams<{ userId: string }>();
	const { sessions, selectedRole } = useSessions();
	const { userScores } = useUserScores();
	const { previousSession, selectedSession, nextSession, loading } =
		useSessionNumber();

	useGetTrainingVideosQuery(
		useSessionPlayerArgs(previousSession, selectedRole),
	);
	useGetTrainingVideosQuery(
		useSessionPlayerArgs(selectedSession, selectedRole),
	);
	useGetTrainingVideosQuery(useSessionPlayerArgs(nextSession, selectedRole));

	const chosenSessions = params.userId ? userScores : sessions;

	if (loading) {
		return (
			<Box pb={4} pt={1}>
				<Typography variant="h4" color="textSecondary" align="center">
					<Skeleton width="100%" />
				</Typography>
			</Box>
		);
	}

	if (!chosenSessions?.length || !selectedSession) {
		return (
			<Box pb={4} pt={1}>
				<Typography
					variant="body1"
					color="textSecondary"
					align="center"
				>
					{t('noTrackingData')}
				</Typography>
			</Box>
		);
	}

	const currentDate = new Date(selectedSession.date);

	return (
		<>
			<Box
				display="flex"
				py={2}
				alignItems="center"
				width={1}
				justifyContent="space-between"
			>
				{previousSession ? (
					<IconButton
						size="small"
						onClick={() =>
							setQuery(
								'sessionNumber',
								previousSession.sessionNumber.toString(),
							)
						}
					>
						<ArrowLeftIcon color="white" />
					</IconButton>
				) : (
					<Box width={32} height={32} />
				)}

				<Typography variant="body1" color="textSecondary">
					{`${t(
						'months:' + currentDate.getMonth().toString(),
					)} ${currentDate.getFullYear()}`}
				</Typography>

				{nextSession ? (
					<IconButton
						size="small"
						onClick={() =>
							setQuery(
								'sessionNumber',
								nextSession.sessionNumber.toString(),
							)
						}
					>
						<ArrowRightIcon color="white" />
					</IconButton>
				) : (
					<Box width={32} height={32} />
				)}
			</Box>

			<Divider sx={{ mx: '-20px' }} />

			<Box pt={2} />
		</>
	);
};
