import _ from 'lodash';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useAuth } from 'src/Auth/useAuth';
import { ScoutingEvent, useGetEventLazyQuery } from 'src/graphql/types';

import { EventContext } from './EventContext';

export const EventProvider: React.FC = () => {
	const params = useParams<{ eventNumber: string }>();
	const eventNumber = _.toInteger(params.eventNumber);

	const { isAuthorized, user } = useAuth();
	const eventRef = useRef(false);
	const [loading, setLoading] = useState(true);
	const [event, setEvent] = useState<ScoutingEvent | null>(null);

	const [getEventQuery] = useGetEventLazyQuery({
		variables: { eventNumber },
		onCompleted: (data) => {
			setEvent(data?.getEvent || null);
			setLoading(false);
		},
	});

	const refetch = useCallback(() => {
		setLoading(true);
		if (eventNumber !== 0) {
			getEventQuery({ variables: { eventNumber } });
		}
	}, [eventNumber, getEventQuery]);

	useEffect(() => {
		if (isAuthorized && user && !eventRef.current) {
			refetch();
			eventRef.current = true;
		}
	}, [user, isAuthorized, refetch]);

	const value = useMemo(
		() => ({
			event,
			loading,
			refetch,
			setEvent,
		}),
		[loading, event, refetch],
	);

	return (
		<EventContext.Provider value={value}>
			<Outlet />
		</EventContext.Provider>
	);
};
