import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
// import IconButton from '@mui/material/IconButton';
// import { FlashIcon } from 'src/Components/Icons/FlashIcon';
// import Box from '@mui/material/Box';
import { Capacitor } from '@capacitor/core';
import QrScanner from 'qr-scanner';
import React, { useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

export const useQRScanner = (onScan: (code: string) => void) => {
	const theme = useTheme();
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const scanedRef = useRef<boolean>(false);
	// const [flash, setFlash] = useState(false);

	// TODO: MAKE FLASH WORK
	// const switchFlash = () => {
	// 	if (Capacitor.isNativePlatform()) {
	// 		setFlash(() => {
	// 			if (!flash) {
	// 				window.QRScanner.enableLight();
	// 			} else {
	// 				window.QRScanner.disableLight();
	// 			}

	// 			return !flash;
	// 		});
	// 	}
	// };

	useEffect(() => {
		if (!Capacitor.isNativePlatform() && videoRef.current) {
			let qrScanner: QrScanner | null | undefined = new QrScanner(
				videoRef.current,
				(result) => {
					if (!scanedRef.current) {
						onScan(result.data);
						scanedRef.current = true;
					}
				},
				{
					maxScansPerSecond: 1,
				},
			);

			qrScanner?.start().catch(() => null);

			return () => {
				qrScanner?.destroy();
				qrScanner = null;
			};
		}
	}, [videoRef, scanedRef, onScan]);

	useEffect(() => {
		if (Capacitor.isNativePlatform()) {
			BarcodeScanner.prepare().then(async () => {
				const permissions = await BarcodeScanner.checkPermission({
					force: true,
				});
				if (permissions.granted) {
					document.body.style.backgroundColor = 'transparent';
					BarcodeScanner.hideBackground();

					const result = await BarcodeScanner.startScan();

					if (result.hasContent && !scanedRef.current) {
						onScan(result.content as string);
						scanedRef.current = true;
					}
				}
			});

			return () => {
				BarcodeScanner.stopScan();
				document.body.style.backgroundColor =
					theme.palette.background.default;
				BarcodeScanner.showBackground();
				// window.QRScanner.disableLight(() => {
				// 	window.QRScanner.destroy();
				// });
			};
		}
	}, [onScan, scanedRef, theme.palette.background.default]);

	const QRScannerComponents: React.FC = () =>
		!Capacitor.isNativePlatform() ? (
			<Box
				component="video"
				ref={videoRef}
				width={1}
				height={1}
				position="absolute"
				zIndex={-1}
				sx={{
					objectFit: 'cover',
				}}
			/>
		) : null;
	return QRScannerComponents;
};
