import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { apolloClient } from 'src/Apollo/ApolloClient';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useShowWarning } from 'src/Warning/useShowSuccess';
import CHECK_EMAIL_TOKEN_QUERY from 'src/graphql/documents/queries/checkEmailToken';
import { CheckEmailTokenQuery, User } from 'src/graphql/types';

export const useVerifyLink = (template: string) => {
	const { t } = useTranslation('resend-token');
	const [tokenExpired, setTokenExpired] = useState(false);
	const [user, setUser] = useState<User | null>(null);
	const { query, replace } = useNavigation();
	const showWarning = useShowWarning();
	const type = query.get('type') || '';
	const uuid = query.get('uuid') || '';
	const sub = query.get('sub') || '';

	useEffect(() => {
		const verifyLink = async () => {
			const { data, errors } =
				await apolloClient.query<CheckEmailTokenQuery>({
					query: CHECK_EMAIL_TOKEN_QUERY,
					variables: {
						input: {
							type,
							sub,
							uuid,
						},
					},
				});

			const hasTokenExpiredConfirm = errors?.some(
				(error) => error.message === 'EmailTokenExpired',
			);

			if (hasTokenExpiredConfirm) {
				return setTokenExpired(true);
			}

			const hasTokenNotFoundConfirm = errors?.some(
				(error) => error.message === 'EmailTokenNotFound',
			);

			if (hasTokenNotFoundConfirm) {
				return showWarning({
					title: t('token_not_found_error'),
					nextRoute: AppRoutes.Login,
					duration: 3000,
				});
			}

			if (errors) {
				return showWarning({
					title: t('generic_error'),
					nextRoute: AppRoutes.Login,
					duration: 3000,
				});
			}

			setUser(data.checkEmailToken.user);
		};

		if (type === template && uuid && sub) {
			verifyLink();
		} else {
			replace(AppRoutes.Login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [tokenExpired, user] as [boolean, User | null];
};
