import React from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardIcon from '@mui/icons-material/Keyboard';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { GuideButton } from 'src/Screens/Dashboard/Scoring/Buttons/GuideButtons';
import { convertValidCode } from 'src/helpers/convertValidCode';

import { useQRScanner } from './useQRScanner';

export const ScanCodeScreen = () => {
	const { t } = useTranslation('add-session');
	const navigation = useNavigation();

	const QRScannerComponents = useQRScanner((code) => {
		const validCode = convertValidCode(code);

		navigation.push(`${AppRoutes.CheckSession}/${validCode}`);
	});

	return (
		<>
			<QRScannerComponents />
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height={1}
				flexDirection="column"
				bgcolor="none"
				overflow="hidden"
			>
				<Box
					border="1px solid"
					borderColor="primary.main"
					width={280}
					height={280}
					sx={{ outline: '10000px solid rgba(0,0,0, 0.9)' }}
				></Box>
				<Box width={280} padding={2} textAlign="center">
					<Typography variant="body2">{t('hint')}</Typography>
					<Box mt={6} />

					<GuideButton variant="outlined" color="inherit" />

					<Box pt={2} />

					<Button
						variant="outlined"
						color="inherit"
						onClick={() =>
							navigation.push(AppRoutes.AddManualSession)
						}
					>
						<KeyboardIcon fontSize="small" />
						<Box ml={1} />
						{t('typeQR')}
					</Button>
				</Box>
			</Box>
		</>
	);
};
