import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'src/Auth/useAuth';

export const useGleap = () => {
	const { user } = useAuth();
	const { i18n } = useTranslation();

	const open = useCallback(async () => {
		const Gleap = await import('gleap').then((value) => value.default);

		Gleap.setLanguage(i18n.language);
		Gleap.open();

		if (user) {
			Gleap.identify(user?.sub, {
				email: user.email ?? '',
				name: user.name || '',
			});
		}

		Gleap.on('close', () => {
			Gleap.clearIdentity();
		});
	}, [i18n.language, user]);

	return open;
};
