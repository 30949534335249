import _ from 'lodash';
import { Moment } from 'moment';
import React from 'react';
import {
	ControllerProps,
	ControllerRenderProps,
	FieldPath,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextField, useMediaQuery } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { useTheme } from '@mui/material/styles';
import {
	MobileDatePicker,
	MobileDateTimePicker,
	MobileDateTimePickerProps,
} from '@mui/x-date-pickers';

import { FieldController } from './FieldController';

export interface ForwardedProps<
	TFieldValues,
	TName extends FieldPath<TFieldValues>,
	TDate,
> extends Partial<
		Omit<
			MobileDateTimePickerProps<TDate, TDate>,
			'value' | 'renderInput' | 'onChange'
		>
	> {
	onChange?: (
		value: TDate | null,
		field: ControllerRenderProps<TFieldValues, TName>,
	) => void;
}

export interface DateTimePickerControllerProps<
	TFieldValues,
	TName extends FieldPath<TFieldValues>,
	TDate,
> extends Pick<ControllerProps<TFieldValues, TName>, 'control' | 'name'>,
		Pick<TextFieldProps, 'variant' | 'size'> {
	label?: string;
	pickerProps?: ForwardedProps<TFieldValues, TName, TDate>;
	translation: string;
	datetime?: boolean;
}

export const DateTimePickerController = <
	TFieldValues extends Record<string, TDate>,
	TName extends FieldPath<TFieldValues>,
	TDate = Moment,
>({
	control,
	label,
	name,
	pickerProps: { onChange, ...pickerProps } = {},
	translation,
	variant = 'filled',
	size = 'small',
	datetime = true,
}: DateTimePickerControllerProps<TFieldValues, TName, TDate>) => {
	const { t } = useTranslation(translation);

	const MobilePicker = datetime ? MobileDateTimePicker : MobileDatePicker;

	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));
	return (
		<FieldController
			name={name}
			control={control}
			translation={translation}
			render={({ field }) => {
				const { error, helperText, color, ...props } = field;
				return (
					<MobilePicker
						{...(props as any)}
						{...(pickerProps as any)}
						onChange={(value) => {
							if (_.isFunction(onChange)) {
								onChange(value as any, props);
							}
							field.onChange(value);
						}}
						renderInput={(params) => (
							<TextField
								{..._.assign(params, {
									error,
									helperText,
									color,
								})}
								fullWidth
								label={t(label ?? name)}
								size={sm ? 'medium' : size}
								variant={variant}
							/>
						)}
					/>
				);
			}}
		/>
	);
};
