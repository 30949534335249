import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { CircularProgressAnimation } from 'src/Components/CircularProgressAnimation';
import { CheckMarkIcon } from 'src/Components/Icons/CheckMarkIcon';
import { UserIcon } from 'src/Components/Icons/UserIcon';
import { ImageDecoration } from 'src/Components/ImageDecoration';
import { useSessionNumber } from 'src/Sessions/useSessionNumber';
import { useSessions } from 'src/Sessions/useSessions';
import {
	Score,
	Session,
	UserRole,
	useCancelReleasedPlayerScoreMutation,
	useReleasePlayerScoreMutation,
} from 'src/graphql/types';

export const UserWithProgress = () => {
	// Default Size. Maybe we modify this in the future for different devices.
	const size = 64;
	const params = useParams<{ userId: string }>();

	const { selectedRole } = useSessions();
	const { t } = useTranslation('performance-stats');
	const { selectedSession, loading, setSelectedSession } = useSessionNumber();
	const [releasePlayerScoreMutation] = useReleasePlayerScoreMutation();
	const [cancelReleasePlayerScoreMutation] =
		useCancelReleasedPlayerScoreMutation();
	const [loadingRelease, setLoadingRelease] = useState(false);

	const score = Math.round(selectedSession?.score || 0);
	const avatar =
		selectedRole === UserRole.Player || params?.userId
			? (selectedSession as Score)?.user?.avatarUrl
			: selectedSession?.club?.logoUrl;

	const releasePlayerScore = async () => {
		const session = selectedSession as Score;

		setLoadingRelease(true);

		const { data } = await releasePlayerScoreMutation({
			variables: {
				input: {
					playerSessionId: session.playerSessionId,
					sessionNumber: session.sessionNumber,
				},
			},
		}).catch(convertNetworkErrors);

		setLoadingRelease(false);

		if (data?.releasePlayerScore.sessionNumber) {
			session.released = true;
			setSelectedSession({ ...session });
		}
	};

	const cancelReleasePlayerScore = async () => {
		const session = selectedSession as Score;

		setLoadingRelease(true);

		const { data } = await cancelReleasePlayerScoreMutation({
			variables: {
				input: {
					playerSessionId: session.playerSessionId,
					sessionNumber: session.sessionNumber,
				},
			},
		}).catch(convertNetworkErrors);

		setLoadingRelease(false);

		if (data?.cancelReleasedPlayerScore.sessionNumber) {
			session.released = false;
			setSelectedSession({ ...session });
		}
	};

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			width={1}
			pb={1}
			flexDirection="column"
		>
			{selectedRole === UserRole.Trainer &&
			!params?.userId &&
			selectedSession &&
			!loading ? (
				<>
					<Typography variant="caption" color="textSecondary">
						{t('releasedBy', {
							current: (selectedSession as Session)
								.playerReleased,
							total: (selectedSession as Session).playerCount,
						})}
					</Typography>
					<Box pt={2} />
				</>
			) : null}

			<Box display="flex">
				<Box position="relative" left={8}>
					{/* Loading Avatar */}
					{loading ? (
						<Skeleton
							variant="circular"
							width={size}
							height={size}
						/>
					) : null}

					{/* User Avatar */}
					{!loading && avatar ? (
						<Avatar
							src={avatar || ''}
							sx={{
								width: size,
								height: size,
							}}
						/>
					) : null}

					{/* Default Avatar */}
					{!loading && !avatar ? (
						<Avatar
							sx={{
								width: size,
								height: size,
								backgroundColor: 'inherit',
								border: '1px solid #454545',
							}}
						>
							{selectedRole === UserRole.Player ||
							params?.userId ? (
								<UserIcon color="white" />
							) : (
								<ImageDecoration
									width={20}
									height={30}
									strokeWidth={2}
									src=""
									color="white"
								/>
							)}
						</Avatar>
					) : null}
				</Box>

				<Box
					position="relative"
					width={size}
					right={8}
					borderRadius="50%"
					bgcolor="black"
				>
					<CircularProgressAnimation
						size={size}
						animate={true}
						duration={2_000}
						maxValue={score}
					/>

					<Box
						position="absolute"
						width={size}
						height={size}
						top={0}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						{/* Loading Score */}
						{loading && (
							<Typography variant="h6">
								<Skeleton width={20} />
							</Typography>
						)}

						{/* Score */}
						{!loading && score ? (
							<Typography variant="h6">{score}</Typography>
						) : null}

						{/* No Score */}
						{!loading && !score ? (
							<Typography
								variant="caption"
								color="textSecondary"
								align="center"
							>
								{t('noData')}
							</Typography>
						) : null}
					</Box>
				</Box>
			</Box>

			<Box pt={2} />

			{selectedRole === UserRole.Trainer && !params.userId ? (
				<Typography variant="caption" color="textSecondary">
					{loading ? (
						<Skeleton width={80} />
					) : (
						selectedSession?.club?.name &&
						`${selectedSession?.club?.name} · ${selectedSession?.ageGroupValue}`
					)}
				</Typography>
			) : null}

			{selectedRole === UserRole.Trainer && params.userId ? (
				<Typography variant="subtitle1">
					{loading ? (
						<Skeleton width={80} />
					) : (
						(selectedSession as Score)?.user?.name ||
						`${t('player')} ${
							(selectedSession as Score)?.playerSessionId
						}`
					)}
				</Typography>
			) : null}

			{selectedRole === UserRole.Player &&
			!params?.userId &&
			selectedSession &&
			selectedSession.sessionNumber !== 0 ? (
				(selectedSession as Score).released ? (
					<Button
						color="inherit"
						disabled={loadingRelease}
						onClick={cancelReleasePlayerScore}
						variant="outlined"
						endIcon={
							loadingRelease ? (
								<CircularProgress size={20} color="inherit" />
							) : (
								<CheckMarkIcon color="white" />
							)
						}
					>
						{t('shared')}
					</Button>
				) : (
					<Button
						color="inherit"
						disabled={loadingRelease}
						onClick={releasePlayerScore}
						variant="outlined"
						endIcon={
							loadingRelease ? (
								<CircularProgress size={20} color="inherit" />
							) : null
						}
					>
						{t('clickToShare')}
					</Button>
				)
			) : null}
		</Box>
	);
};
