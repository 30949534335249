const TOKEN_KEY = 'refreshToken';

export function getLocalStorageRefreshToken() {
	return localStorage.getItem(TOKEN_KEY);
}

export function setLocalStorageRefreshToken(token: string) {
	return localStorage.setItem(TOKEN_KEY, token);
}

export function removeLocalStorageRefreshToken() {
	return localStorage.removeItem(TOKEN_KEY);
}
