import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useShowSuccess } from 'src/Success/useShowSuccess';
import { useResendExpiredLinkMutation } from 'src/graphql/types';
import { useServerErrors } from 'src/helpers/useServerErrors';

export const ResendToken = () => {
	const { query, replace } = useNavigation();
	const type = query.get('type') || '';
	const uuid = query.get('uuid') || '';
	const sub = query.get('sub') || '';
	const showSuccess = useShowSuccess();
	const [resendExpiredLinkMutation, { loading }] =
		useResendExpiredLinkMutation();
	const { setServerErrors, resetServerErrors, ServerErrors } =
		useServerErrors();
	const { t } = useTranslation('resend-token');

	const resendToken = async () => {
		resetServerErrors();

		const { errors } = await resendExpiredLinkMutation({
			variables: {
				input: {
					sub,
					type,
					uuid,
				},
			},
		}).catch(convertNetworkErrors);

		if (errors?.length) {
			return setServerErrors(errors);
		}

		showSuccess({
			title: t('resend_success'),
			onClose: () => {
				replace(AppRoutes.Login);
			},
			duration: 3000,
		});
	};

	return (
		<>
			<Typography variant="h5" textAlign="center" maxWidth={500}>
				{t('link_expired_description_text')}
			</Typography>

			<Box pt={8} />

			<Typography
				variant="h5"
				textAlign="center"
				fontSize={18}
				maxWidth={500}
			>
				{t('link_expired_description_extra_text')}
			</Typography>

			<Box pt={4} />

			<Button
				variant="outlined"
				color="inherit"
				onClick={resendToken}
				disabled={loading}
			>
				{t('resend_button')}
			</Button>

			<Box pt={4} />

			{loading && <CircularProgress color="inherit" />}

			<ServerErrors />
		</>
	);
};
