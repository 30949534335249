import { useTranslation } from 'react-i18next';

import Button, { ButtonProps } from '@mui/material/Button';

import { PlayIcon } from 'src/Components/Icons/PlayIcon';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { OtherButton, OtherButtonsProps } from './OtherButton';

export const VideosButton = (props: ButtonProps) => {
	const navigation = useNavigation();
	const { t } = useTranslation('scoring');

	return (
		<Button
			variant="outlined"
			color="inherit"
			startIcon={<PlayIcon color="white" sx={{ p: '2px' }} />}
			onClick={() => navigation.push(AppRoutes.Videos)}
			{...props}
		>
			{t('videos')}
		</Button>
	);
};

export const VideosGroupButton: React.FC<OtherButtonsProps> = ({ onClick }) => {
	const { t } = useTranslation('scoring');

	return (
		<OtherButton
			label={t('videos')}
			link={AppRoutes.Videos}
			onClick={onClick}
		>
			<PlayIcon strokeWidth={2} color="black" sx={{ p: '2px' }} />
		</OtherButton>
	);
};
