import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { AppRoutes } from 'src/Router/AppRoutes';
import { Score } from 'src/graphql/types';

import { CardForm } from './Card/CardForm';
import CardSvg from './Card/CardSvg.svg';
import { ItemPrice } from './ItemPrice';
import { PaymentMethod } from './PaymentMethod';
import { PaypalForm } from './Paypal/PaypalForm';
import PaypalSvg from './Paypal/PaypalSvg.svg';

export type PaymentMethods = 'card' | 'paypal';

type LocationState = {
	session: Score;
} | null;

export const PaymentScreen = () => {
	const { code } = useParams<{ code: string }>();
	const [paymentSelected, setPaymentSelected] =
		useState<PaymentMethods | null>(null);
	const location = useLocation();
	const locationState = location.state as LocationState;
	const session = locationState?.session;
	const { t, i18n } = useTranslation('add-session');

	if (!session) {
		return (
			<Navigate
				replace
				to={`/${i18n.language}${AppRoutes.CheckSession}/${code}`}
			/>
		);
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			width={1}
			height={1}
			paddingX="20px"
			overflow="auto"
		>
			<Box maxWidth={500}>
				<ItemPrice />

				<Box pt={6} />

				<Typography variant="caption" color="textSecondary">
					{t('method')}
				</Typography>

				<Box pt={1} />

				<PaymentMethod
					checked={paymentSelected === 'paypal'}
					onClick={() => setPaymentSelected('paypal')}
					text={t('paypal')}
					Image={
						<img width={98} height={26} src={PaypalSvg} alt="" />
					}
					Content={<PaypalForm />}
				/>

				<Box pt={1} />

				<PaymentMethod
					checked={paymentSelected === 'card'}
					onClick={() => setPaymentSelected('card')}
					text={t('creditCard')}
					Image={<img width={72} height={25} src={CardSvg} alt="" />}
					Content={<CardForm />}
				/>

				<Box pt={6} />
			</Box>
		</Box>
	);
};
