import { Controller, useFormContext } from 'react-hook-form';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';

export type CheckboxFormOption = {
	value: string;
	label: string;
};

export type CheckboxFormProps = {
	name: string;
	options: CheckboxFormOption[];
	defaultValue: string;
};

export const RadioButtonForm: React.FC<CheckboxFormProps> = ({
	name,
	options,
	defaultValue,
}) => {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<RadioGroup
					defaultValue={defaultValue}
					{...field}
					sx={{ width: 1 }}
				>
					<Stack spacing={2} width={1}>
						{options.map((option) => (
							<FormControlLabel
								key={option.value}
								value={option.value}
								control={<Radio color="default" />}
								label={option.label}
								sx={{
									borderRadius: '4px',
									border: '1px solid #454545',
									p: '10px',
									m: 0,
								}}
							/>
						))}
					</Stack>
				</RadioGroup>
			)}
		/>
	);
};
