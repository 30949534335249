import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const NotificationIcon: React.FC<CustomIconProps> = (props) => {
	const { color, strokeWidth } = useCustomIcon(props);

	return (
		<SvgIcon viewBox="0 0 32 32" sx={props.sx}>
			<g
				stroke="none"
				strokeWidth={strokeWidth}
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Group-2"
					transform="translate(1.100000, 1.500000)"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				>
					<g id="Group">
						<path
							d="M5.30769231,0 C5.30769231,3.31226934 5.30769231,8.38367761 5.30769231,10.9136978 C5.36506722,15.1926323 7.86550964,20.1938227 14.7927576,20.1689557 C21.8760846,20.1451993 24.4329439,15.1915975 24.4151098,10.9136978 C24.4151098,7.85282754 24.4151098,1.47099169 24.4151098,0"
							transform="translate(14.861538, 10.084615) rotate(-180.000000) translate(-14.861538, -10.084615) "
						></path>
						<path
							d="M5.71348122,24.5945713 C5.71348122,22.8612773 4.03985768,20.41774 0.738598647,20.41774"
							strokeLinecap="round"
							transform="translate(3.226040, 22.506156) rotate(-270.000000) translate(-3.226040, -22.506156) "
						></path>
						<path
							d="M28.9912416,24.5945713 C28.9912416,22.8612773 27.317618,20.41774 24.016359,20.41774"
							strokeLinecap="round"
							transform="translate(26.503800, 22.506156) scale(-1, 1) rotate(-270.000000) translate(-26.503800, -22.506156) "
						></path>
						<line
							x1="0.530769231"
							y1="25.0079451"
							x2="29.1923077"
							y2="25.0079451"
							strokeLinecap="square"
						></line>
					</g>
					<path
						d="M12.9423133,23.7133618 C14.1477726,23.689795 16.4422923,24.2585862 16.4422923,27.3878998 C16.3225051,30.1727124 14.0032729,30.6139842 12.9536383,30.6139842"
						transform="translate(14.692303, 27.163329) rotate(-270.000000) translate(-14.692303, -27.163329) "
					></path>
				</g>
			</g>
		</SvgIcon>
	);
};
