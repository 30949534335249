import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { ResendToken } from 'src/Components/ResendToken';
import { SafeAreaTop } from 'src/Components/SafeArea';
import { TopBar } from 'src/Components/TopBar';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useShowSuccess } from 'src/Success/useShowSuccess';
import { useShowWarning } from 'src/Warning/useShowSuccess';
import { useVerifyUserEmailMutation } from 'src/graphql/types';

export type ConfirmUserObject = {
	id: string;
	type: string;
};

export const ConfirmUserScreen = () => {
	const { query, replace } = useNavigation();
	const [verifyUserEmailMutation] = useVerifyUserEmailMutation();
	const showSuccess = useShowSuccess();
	const showWarning = useShowWarning();
	const { t } = useTranslation('confirm-user-screen');
	const type = query.get('type') || '';
	const uuid = query.get('uuid') || '';
	const sub = query.get('sub') || '';
	const [isTokenExpired, setTokenExpired] = useState(false);

	useEffect(() => {
		const confirmUserEmail = async () => {
			const { errors } = await verifyUserEmailMutation({
				variables: {
					input: {
						sub,
						type,
						uuid,
					},
				},
			}).catch(convertNetworkErrors);

			const hasTokenExpiredConfirm = errors?.some(
				(error) => error.message === 'EmailTokenExpired',
			);

			if (hasTokenExpiredConfirm) {
				return setTokenExpired(true);
			}

			const hasTokenNotFoundConfirm = errors?.some(
				(error) => error.message === 'EmailTokenNotFound',
			);

			if (hasTokenNotFoundConfirm) {
				return showWarning({
					title: t('token_not_found_error'),
					nextRoute: AppRoutes.Login,
					duration: 3000,
				});
			}

			if (errors) {
				return showWarning({
					title: t('generic_error'),
					nextRoute: AppRoutes.Login,
					duration: 3000,
				});
			}

			showSuccess({
				title: t('email_confirmed'),
				nextRoute: AppRoutes.Login,
				duration: 3000,
			});
		};

		if (type === 'verify_email' && uuid && sub) {
			confirmUserEmail();
		} else {
			replace(AppRoutes.Login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Stack
			height={1}
			sx={{
				backgroundImage: `url(${Background1})`,
				backgroundSize: 'cover',
				overflowY: 'auto',
			}}
		>
			<SafeAreaTop />

			<TopBar
				title={t('title_text')}
				leftComponent={
					<IconButton
						color="inherit"
						size="large"
						onClick={() => replace(AppRoutes.Login)}
					>
						<ArrowBackIcon />
					</IconButton>
				}
			/>

			<Stack
				px="20px"
				alignItems="center"
				justifyContent="center"
				flexGrow={1}
			>
				{!isTokenExpired && <CircularProgress color="inherit" />}
				{isTokenExpired && <ResendToken />}
			</Stack>
		</Stack>
	);
};
