import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import {
	CreateStripeIntentMutation,
	Score,
	useCreateStripeIntentMutation,
} from 'src/graphql/types';

import { CheckoutForm } from './CheckoutForm';

type LocationState = {
	session: Score;
};

export const CardForm = () => {
	const [createStripeIntentMutation] = useCreateStripeIntentMutation();
	const [stripeData, setStripeData] =
		useState<CreateStripeIntentMutation | null>(null);
	const location = useLocation();
	const locationState = location.state as LocationState;
	const [error, setError] = useState(false);
	const score = locationState.session;
	const theme = useTheme();

	const { t } = useTranslation('server-error');

	useEffect(() => {
		const createIntent = async () => {
			const { data, errors } = await createStripeIntentMutation({
				variables: {
					input: {
						sessionNumber: score.sessionNumber,
						playerSessionId: score.playerSessionId,
					},
				},
			}).catch(convertNetworkErrors);

			if (!errors && data) {
				setStripeData(data);
			} else {
				setError(true);
			}
		};

		createIntent();
	}, [
		createStripeIntentMutation,
		setStripeData,
		score.playerSessionId,
		score.sessionNumber,
	]);

	return useMemo(() => {
		if (error) {
			return <Alert severity="error">{t('InternalServerError')}</Alert>;
		}

		if (!stripeData) {
			return (
				<Box display="flex" justifyContent="center" py={2}>
					<CircularProgress color="inherit" />
				</Box>
			);
		}

		const stripePromise = loadStripe(stripeData.createStripeIntent.pubKey);

		const options = {
			clientSecret: stripeData.createStripeIntent.clientSecret,
			appearance: {
				theme: 'night',
				variables: {
					colorPrimary: theme.palette.primary.main,
					colorBackground: '#000000',
					colorText: theme.palette.text.primary,
					spacingUnit: '4px',
					borderRadius: '2px',
				},
			},
		} as const;

		return (
			<Elements stripe={stripePromise} options={options}>
				<CheckoutForm
					clientSecret={stripeData.createStripeIntent.clientSecret}
				/>
			</Elements>
		);
	}, [
		stripeData,
		theme.palette.primary.main,
		theme.palette.text.primary,
		error,
		t,
	]);
};
