import React from 'react';
import { QRCode } from 'react-qrcode-logo';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { useAuth } from 'src/Auth/useAuth';
import { useSessions } from 'src/Sessions/useSessions';

import { UserSection } from '../UserSection';

export const QrCodeDialog: React.FC = () => {
	const { user } = useAuth();
	const { selectedRole, loading } = useSessions();

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
			alignItems="center"
			p={4}
		>
			<UserSection />

			<Box pt={6} />

			<Box>
				{!user || loading ? (
					<Skeleton width={200} height={200} />
				) : (
					<QRCode size={200} value={`${user.sub}|${selectedRole}`} />
				)}
			</Box>
		</Box>
	);
};
