import { useTranslation } from 'react-i18next';

import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import Button, { ButtonProps } from '@mui/material/Button';

import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import { UserRole } from 'src/graphql/types';

import { OtherButton, OtherButtonsProps } from './OtherButton';

export const HowItWorksButton = (props: ButtonProps) => {
	const navigation = useNavigation();
	const { t } = useTranslation('scoring');

	return (
		<Button
			startIcon={<QuestionMarkOutlinedIcon />}
			onClick={() => navigation.push(AppRoutes.HowItWork)}
			{...props}
		>
			{t('howItWork')}
		</Button>
	);
};

export const HowArrangeTrackingButton = (props: ButtonProps) => {
	const navigation = useNavigation();
	const { t } = useTranslation('scoring');

	return (
		<Button
			startIcon={<QuestionMarkOutlinedIcon />}
			onClick={() => navigation.push(AppRoutes.HowArrangeTracking)}
			{...props}
		>
			{t('howArrangeTracking')}
		</Button>
	);
};

export const GuideButton = (props: ButtonProps) => {
	const { selectedRole } = useSessions();

	return selectedRole === UserRole.Trainer ? (
		<HowArrangeTrackingButton {...props} />
	) : (
		<HowItWorksButton {...props} />
	);
};

export const GuideGroupButton: React.FC<OtherButtonsProps> = ({ onClick }) => {
	const { t } = useTranslation('scoring');

	const { selectedRole } = useSessions();

	const label =
		selectedRole === UserRole.Trainer
			? t('howArrangeTracking')
			: t('howItWork');
	const link =
		selectedRole === UserRole.Trainer
			? AppRoutes.HowArrangeTracking
			: AppRoutes.HowItWork;

	return (
		<OtherButton label={label} link={link} onClick={onClick}>
			<QuestionMarkOutlinedIcon />
		</OtherButton>
	);
};
