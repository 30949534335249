import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLClient } from 'graphql-request';
import _ from 'lodash';

import {
	getLocalStorageAccessToken,
	setLocalStorageAccessToken,
} from '../Auth/localStorageAccessToken';
import { getLocalStorageRefreshToken } from '../Auth/localStorageRefreshToken';

const client = new GraphQLClient(process.env.REACT_APP_KICKID_API ?? '');

export const api = createApi({
	baseQuery: graphqlRequestBaseQuery({
		client,
		prepareHeaders: (headers, _api) => {
			const token = getLocalStorageAccessToken();
			if (!_.isNil(token)) {
				headers.set('Authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: () => ({}),
});

export const ErrorHandler: Middleware = (mApi) => (next) => (action) => {
	if (
		isRejectedWithValue(action) &&
		action?.payload?.message?.includes('TokenExpired')
	) {
		_.get(
			api,
			'endpoints.refreshToken.initiate',
		)({
			input: { refreshToken: getLocalStorageRefreshToken() },
		})(mApi.dispatch).then(
			(response: {
				data: {
					refreshToken: { accessToken: string; refreshed: boolean };
				};
			}) => {
				if (response?.data?.refreshToken?.refreshed) {
					setLocalStorageAccessToken(
						response?.data?.refreshToken?.accessToken,
					);
					const { arg } = action.meta;
					return _.get(api, `endpoints.${arg.endpointName}.initiate`)(
						arg.originalArgs,
						arg.subscriptionOptions,
					)(mApi.dispatch, mApi.getState);
				}
			},
		);
	}
	return next(action);
};
