import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SafeAreaBottom, SafeAreaTop } from 'src/Components/SafeArea';
import { TopBar } from 'src/Components/TopBar';
import { useGleap } from 'src/Plugins/Gleap';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

export type Faq = {
	category: string;
	faqs: {
		question: string;
		answer: string;
	}[];
};

export const FaqScreen = () => {
	const { t } = useTranslation('faq');
	const navigation = useNavigation();
	const [expanded, setExpanded] = useState<string | null>(null);
	const openGleap = useGleap();

	const handleChange =
		(panel: string) =>
		(_event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : null);
		};

	const faqs = t('faq', {
		returnObjects: true,
	}) as Faq[];

	return (
		<Stack height={1} sx={{ overflowY: 'auto' }}>
			<SafeAreaTop />

			<TopBar
				title={t('title')}
				leftComponent={
					<IconButton
						color="inherit"
						size="large"
						onClick={() => navigation.goBackOrGoTo(AppRoutes.Login)}
					>
						<ArrowBackIcon />
					</IconButton>
				}
			/>

			{faqs.map((faqElement) => (
				<Box key={faqElement.category}>
					<Box px="20px" py={1}>
						<Typography variant="button" color="textSecondary">
							{t('faq.2.category')}
						</Typography>
					</Box>

					{faqElement.faqs.map((faqChildren) => (
						<Accordion
							key={faqChildren.answer}
							expanded={expanded === faqChildren.answer}
							onChange={handleChange(faqChildren.answer)}
							square
							elevation={0}
							TransitionProps={{
								unmountOnExit: true,
								mountOnEnter: true,
							}}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant="body1">
									{faqChildren.question}
								</Typography>
							</AccordionSummary>

							<AccordionDetails sx={{ p: 2 }}>
								<Typography variant="body2">
									{faqChildren.answer}
								</Typography>
							</AccordionDetails>
						</Accordion>
					))}
				</Box>
			))}

			<Box pt={4} />

			<Link
				component="button"
				color="textSecondary"
				variant="caption"
				textAlign="center"
				onClick={openGleap}
			>
				{t('problems')}
			</Link>

			<Box pt={8} />

			<SafeAreaBottom />
		</Stack>
	);
};
