import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { ChangePasswordFormData } from './ChangePasswordScreen';

type ChangePasswordControllerprops = {
	form: UseFormReturn<ChangePasswordFormData>;
	disabled: boolean;
};

export const ChangePasswordController: React.FC<
	ChangePasswordControllerprops
> = (props) => {
	const { t } = useTranslation('change-password-screen');
	const { control } = props.form;

	return (
		<>
			<Controller
				name="currentPassword"
				control={control}
				render={({ field, fieldState }) => (
					<TextField
						{...field}
						type="password"
						placeholder={t('currentPassword')}
						variant="outlined"
						helperText={fieldState.error?.message}
						error={!!fieldState.error}
						fullWidth
						autoComplete="current-password"
						disabled={props.disabled}
					/>
				)}
			/>
			<Box pt={2} />
			<Controller
				name="password"
				control={control}
				render={({ field, fieldState }) => (
					<TextField
						{...field}
						type="password"
						placeholder={t('newPassword')}
						variant="outlined"
						helperText={fieldState.error?.message}
						error={!!fieldState.error}
						fullWidth
						autoComplete="new-password"
						disabled={props.disabled}
					/>
				)}
			/>
			<Box pt={2} />
			<Controller
				name="passwordConfirmation"
				control={control}
				render={({ field, fieldState }) => (
					<TextField
						{...field}
						type="password"
						placeholder={t('newPasswordConfirmation')}
						variant="outlined"
						helperText={fieldState.error?.message}
						error={!!fieldState.error}
						fullWidth
						autoComplete="new-password"
						disabled={props.disabled}
					/>
				)}
			/>
		</>
	);
};
