import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useDeepLinks = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if (Capacitor.isNativePlatform()) {
			App.addListener('appUrlOpen', (deeplink: URLOpenListenerEvent) => {
				const url = new URL(deeplink.url);

				const path: string = url.pathname;

				const queryString = url.search || '';

				navigate(path + queryString);
			});

			return () => {
				App.removeAllListeners();
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
