import { useTranslation } from 'react-i18next';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { useNotifications } from 'src/Notifications/useNotifications';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { NotificationField } from './NotificationField';

const StyledDrawer = styled(Drawer)(() => ({
	[`& .${drawerClasses.paper}`]: {
		padding: '20px 0',
		paddingTop: 'max(env(safe-area-inset-top), 20px)',
		width: 'calc(100vw - 48px)',
		borderLeft: '1px solid #252525',
		maxWidth: 500,
	},
}));

export type SettingsMenuProps = {
	open: boolean;
};

export const NotificationsMenu: React.FC<SettingsMenuProps> = ({ open }) => {
	const { t } = useTranslation('notifications');
	const { notifications, loading } = useNotifications();

	const navigation = useNavigation();

	return (
		<StyledDrawer
			anchor="right"
			open={open}
			onClose={() => navigation.replace(AppRoutes.Scoring)}
		>
			<Box
				width={1}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				px="20px"
			>
				<Box width={46} />

				<Typography variant="subtitle1" align="center">
					{t('title')}
				</Typography>

				<IconButton
					color="inherit"
					onClick={() => navigation.replace(AppRoutes.Scoring)}
					size="large"
				>
					<ArrowForwardIosIcon />
				</IconButton>
			</Box>

			<Box pt="20px" />

			{loading && (
				<>
					<Box pl="20px" pb={2}>
						<Skeleton
							width={50}
							height={16}
							variant="rectangular"
						/>
					</Box>

					<Skeleton height={70} variant="rectangular" />
					<Box pt="3px" />
					<Skeleton height={70} variant="rectangular" />
				</>
			)}

			{!!notifications?.notificationList.length && !loading && (
				<>
					<Box px="20px" py={1} display="flex" alignItems="center">
						<Typography variant="button" color="textSecondary">
							{t('systemNotifications')}
						</Typography>
					</Box>

					<Divider />
				</>
			)}

			{!notifications?.notificationList.length && !loading && (
				<Box
					flexGrow={1}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography px="20px" color="textSecondary" variant="body1">
						{t('noMoreNotifications')}
					</Typography>
				</Box>
			)}

			{notifications?.notificationList.map((notification, i) => (
				<NotificationField key={i} notification={notification} />
			))}
		</StyledDrawer>
	);
};
