import { GraphQLError } from 'graphql';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { CloseIcon } from 'src/Components/Icons/CloseIcon';
import { GoalkeeperLeftIcon } from 'src/Components/Icons/GoalkeeperLeftIcon';
import { OldTopBar } from 'src/Components/OldTopBar';
import { useCustomForm } from 'src/Form/useCustomForm';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { matchesExpected } from './matchesExcepted';

import { CreateSessionFormData } from '../AdminForm';

export const AddPlayerScreen = () => {
	const { t } = useTranslation(['add-player', 'server-error']);

	const [loadingCode] = useState<boolean>(false);
	const [invalidCode, setInvalidCode] = useState<boolean>(false);

	const { form, setForm } = useCustomForm<CreateSessionFormData>();
	const navigation = useNavigation();
	const scanedRef = useRef(false);

	const [serverErrors, setServerErrors] = useState<readonly GraphQLError[]>(
		[],
	);

	const code = navigation.query.get('code');

	useEffect(() => {
		if (!form.ageGroup) {
			navigation.push(AppRoutes.EditSession);
		}
	}, [form.ageGroup, navigation]);

	const AddPlayer = useCallback(
		async (code: string) => {
			setServerErrors([]);

			const existingCode =
				form.playerCodes?.includes(code) ||
				form.trainerCodes?.includes(code);

			if (existingCode) {
				setInvalidCode(true);
				return;
			}

			// setLoadingCode(true);

			// const { data, errors } = await apolloClient.query<ValidateCodeQuery>({
			// 	query: VALIDATE_CODE_QUERY,
			// 	variables: {
			// 		code,
			// 	},
			// });

			// setLoadingCode(false);

			//if (data?.validateCode?.valid) {
			setForm({
				...form,
				playerCodes: [...(form?.playerCodes || []), code],
				playersCount: form.playersCount + 1,
			});
			// } else {
			// 	setInvalidCode(true);
			// }

			// if (errors) setServerErrors(errors);
		},
		[form, setForm],
	);

	useEffect(() => {
		if (code && !scanedRef.current) {
			AddPlayer(code);
			navigation.setQuery('code', null);
			scanedRef.current = true;
		}
	}, [code, AddPlayer, navigation, scanedRef]);

	const onSubmit = async () => {
		navigation.push(`${AppRoutes.EditSession}?confirm`);
	};

	const removePlayer = (code: string) => {
		const index =
			form.playerCodes?.findIndex((_code) => _code === code) || -1;

		form.playersCount = form.playersCount - 1;
		form.playerCodes?.splice(index, 1);

		setForm({ ...form });
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			width={1}
			height={1}
		>
			<OldTopBar
				title={t('title')}
				border
				onClickLeft={() => navigation.replace(AppRoutes.AddCoach)}
				iconLeft={<ArrowBackIcon />}
			/>

			<Box pt={2} />

			<Typography variant="body2">{`${t('numberPlayers')}${
				form.playersCount
			}`}</Typography>

			<Box pt={2} />

			<Box display="flex" justifyContent="center">
				<Button
					variant="contained"
					color="primary"
					disabled={form.playersCount >= 19}
					onClick={() => navigation.replace(AppRoutes.AddPlayerScan)}
				>
					{t('scanCode')}
				</Button>
			</Box>

			<Box pt={2} />

			<Typography variant="body2">{`${t('matchesExpected')}${
				matchesExpected[form.playersCount as 10] ?? '-'
			}`}</Typography>

			<Box pt={2} />

			<Box
				borderTop="1px solid #252525"
				px="20px"
				width={1}
				sx={{ overflowY: 'auto', overflowX: 'hidden' }}
				pt={2}
			>
				{form.playerCodes?.map((code, index) => (
					<Box
						display="flex"
						p={2}
						border="1px solid #202020"
						key={index}
						width={1}
						height={66}
						bgcolor="#0d0d0d"
						alignItems="center"
						justifyContent="space-between"
						mb={2}
					>
						<Box display="flex">
							<Typography variant="button">
								{t('player')} {index + 1}
							</Typography>

							<Box pl={1} />
							<Typography variant="button">-</Typography>
							<Box pl={1} />

							<Typography variant="button">{code}</Typography>
						</Box>
						{index <= 1 && form.fixedGoalkeepers && (
							<GoalkeeperLeftIcon />
						)}

						<IconButton
							size="small"
							sx={{ backgroundColor: '#730100' }}
							onClick={() => removePlayer(code)}
						>
							<CloseIcon color="white" />
						</IconButton>
					</Box>
				))}

				{loadingCode && (
					<>
						<Skeleton
							height={55}
							width="100%"
							variant="rectangular"
						></Skeleton>

						<Box pt={2} />
					</>
				)}

				{invalidCode ? (
					<>
						<Alert
							severity="error"
							onClose={() => setInvalidCode(false)}
						>
							{t('invalidCode')}
						</Alert>

						<Box pt={2} />
					</>
				) : null}

				{serverErrors.map((error) => (
					<>
						<Alert
							key={error.message}
							severity="error"
							onClose={() => setServerErrors([])}
						>
							{t('server-error:' + error.message) ||
								error.message}
						</Alert>

						<Box pt={2} />
					</>
				))}

				{form.playersCount >= 10 ? (
					<Box display="flex" justifyContent="center">
						<Button
							variant="contained"
							color="primary"
							onClick={onSubmit}
						>
							{t('continue')}
						</Button>
					</Box>
				) : (
					<Typography variant="body2" align="center">
						{t('playersContinue')}
					</Typography>
				)}

				<Box pb={2} />
			</Box>
		</Box>
	);
};
