import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { SafeAreaBottom, SafeAreaTop } from 'src/Components/SafeArea';
import { TopBar } from 'src/Components/TopBar';
import { AppRoutes } from 'src/Router/AppRoutes';
import { LocaleLink } from 'src/Router/LocaleLink';

export type PasswordSentDialogProps = {
	email: string;
};

export const PasswordSentScreen: React.FC<PasswordSentDialogProps> = ({
	email,
}) => {
	const { t, i18n } = useTranslation('forgot-password-screen');

	return (
		<Stack
			height={1}
			alignItems="center"
			sx={{
				backgroundImage: `url(${Background1})`,
				backgroundSize: 'cover',
				overflowY: 'auto',
			}}
		>
			<SafeAreaTop />

			<TopBar
				rigthComponent={
					<IconButton
						size="large"
						href={`/${i18n.language}${AppRoutes.Login}`}
					>
						<CloseIcon />
					</IconButton>
				}
			/>

			<Stack
				flexGrow={1}
				spacing={4}
				alignItems="center"
				justifyContent="center"
			>
				<Typography variant="h5">{t('dialog_title_text')}</Typography>

				<Typography variant="body1">{email}</Typography>

				<Typography
					variant="body1"
					color="textSecondary"
					align="center"
					maxWidth={300}
				>
					{t('dialog_description_text')}
				</Typography>
			</Stack>

			<Button
				color="inherit"
				variant="outlined"
				component={LocaleLink}
				to={AppRoutes.Login}
			>
				{t('dialog_go_back_button')}
			</Button>

			<Box pt={8} />

			<SafeAreaBottom />
		</Stack>
	);
};
