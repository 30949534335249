import { RouteObject } from 'react-router-dom';

import { ForgotPasswordScreen } from 'src/Screens/ForgotPassword/ForgotPasswordScreen';
import { LoginScreen } from 'src/Screens/Login/LoginScreen';
import { BirthdayForm } from 'src/Screens/SignUp/Forms/BirthdayForm';
import { FullNameForm } from 'src/Screens/SignUp/Forms/FullNameForm';
import { UserInfoForm } from 'src/Screens/SignUp/Forms/UserInfoForm';
import { UserRoleForm } from 'src/Screens/SignUp/Forms/UserRoleForm';
import { SignUpScreen } from 'src/Screens/SignUp/SignUpScreen';
import { StartScreen } from 'src/Screens/Start/StartScreen';

export default [
	{
		path: '',
		element: <StartScreen />,
	},
	{
		path: 'login',
		element: <LoginScreen />,
	},
	{
		path: 'forgot-password',
		element: <ForgotPasswordScreen />,
	},
	{
		path: 'signup',
		element: <SignUpScreen />,
		children: [
			{
				path: 'user-info',
				element: <UserInfoForm />,
			},
			{
				path: 'birthday',
				element: <BirthdayForm />,
			},
			{
				path: 'user-role',
				element: <UserRoleForm />,
			},
			{
				path: 'full-name',
				element: <FullNameForm />,
			},
		],
	},
] as RouteObject[];
