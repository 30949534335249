import React from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

export type TopNavigationBarProps = {
	title?: string;
};

export const TopNavigationBar: React.FC<TopNavigationBarProps> = (props) => {
	const navigation = useNavigation();
	const { t } = useTranslation('add-session');

	const close = () => {
		navigation.replace(AppRoutes.Scoring);
	};

	return (
		<Box
			p="20px"
			pt="max(env(safe-area-inset-top), 20px)"
			bgcolor="rgba(0,0,0, 0.9)"
		>
			<Grid container alignItems="center">
				<Grid item>
					<Box width={48} />
				</Grid>
				<Grid item xs>
					<Typography variant="subtitle1" align="center">
						{props.title ?? t('title')}
					</Typography>
				</Grid>
				<Grid item>
					<IconButton onClick={close} size="large">
						<CloseIcon />
					</IconButton>
				</Grid>
			</Grid>
		</Box>
	);
};
