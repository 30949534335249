import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { useAuth } from 'src/Auth/useAuth';
import { ArrowRightIcon } from 'src/Components/Icons/ArrowRightIcon';
import { ExternalLinkIcon } from 'src/Components/Icons/ExternalLinkIcon';
import { LanguageMenu } from 'src/Components/LanguageMenu';
import { ToogleButton } from 'src/Components/ToggleButton';
import { useGleap } from 'src/Plugins/Gleap';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import { UserRole } from 'src/graphql/types';

const StyledDrawer = styled(Drawer)(() => ({
	[`& .${drawerClasses.paper}`]: {
		padding: '20px 0 0 0',
		paddingTop: 'max(env(safe-area-inset-top), 20px)',
		width: 'calc(100vw - 48px)',
		borderRight: '1px solid #252525',
		maxWidth: 500,
	},
}));

export type SettingsMenuProps = {
	open: boolean;
};

export const SettingsMenu: React.FC<SettingsMenuProps> = ({ open }) => {
	const { logout, user, isConfirmed } = useAuth();
	const { setSelectedRole, selectedRole } = useSessions();
	const { t } = useTranslation('settings');
	const navigation = useNavigation();
	const openGleap = useGleap();

	const [eventVersion, setEventVersion] = useState(
		localStorage.getItem('event-version') ?? 'v1',
	);

	useEffect(
		() => localStorage.setItem('event-version', eventVersion),
		[eventVersion],
	);

	return (
		<StyledDrawer
			anchor="left"
			open={open}
			onClose={() => navigation.replace(AppRoutes.Scoring)}
		>
			<Box
				width={1}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				px="20px"
			>
				<IconButton
					color="inherit"
					onClick={() => navigation.replace(AppRoutes.Scoring)}
					size="large"
				>
					<ArrowBackIosIcon />
				</IconButton>

				<Typography variant="subtitle1" align="center">
					{t('title')}
				</Typography>

				<Box width={46} />
			</Box>

			<Box pt="20px" />

			<Box width={1}>
				<Box px="20px" py={1} display="flex" alignItems="center">
					<Typography variant="button" color="textSecondary">
						{t('account')}
					</Typography>
				</Box>

				<Divider />

				{user?.roles?.includes(UserRole.Trainer) &&
					user.roles.includes(UserRole.Player) && (
						<>
							<Box
								display="flex"
								justifyContent="space-between"
								py={3}
								px="20px"
								bgcolor="#151515"
							>
								<Typography variant="body1">
									{t('role')}
								</Typography>

								<ToogleButton
									name="roles"
									disabled={
										user?.roles?.filter(
											(role) =>
												role === UserRole.Player ||
												role === UserRole.Trainer,
										)?.length !== 2
									}
									onChange={() =>
										setSelectedRole(
											selectedRole === UserRole.Trainer
												? UserRole.Player
												: UserRole.Trainer,
										)
									}
									checked={selectedRole === UserRole.Player}
									values={[t('player'), t('trainer')]}
									colors={['white', 'white']}
								></ToogleButton>
							</Box>

							<Divider />
						</>
					)}

				{isConfirmed ? (
					<>
						<Box
							display="flex"
							justifyContent="space-between"
							py={3}
							px="20px"
							bgcolor="#151515"
							sx={{
								cursor: 'pointer',
							}}
							onClick={() =>
								navigation.replace(
									`${AppRoutes.Scoring}?action=self-assessment`,
								)
							}
						>
							<Typography variant="body1">
								{t(
									selectedRole === UserRole.Trainer
										? 'teamAssessment'
										: 'selfAssessment',
								)}
							</Typography>

							<Box display="flex">
								<ArrowRightIcon color="white" />
							</Box>
						</Box>

						<Divider />
					</>
				) : null}

				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					py={3}
					px="20px"
					bgcolor="#151515"
				>
					<Typography variant="body1">{t('language')}</Typography>

					<Box display="flex" sx={{ cursor: 'pointer' }}>
						<LanguageMenu showIcon={false} />
					</Box>
				</Box>

				<Divider />
			</Box>

			{user?.roles?.includes(UserRole.Operator) && (
				<Box width={1}>
					<Box
						px="20px"
						pt={1.5}
						pb={1}
						display="flex"
						alignItems="center"
					>
						<Typography variant="button" color="textSecondary">
							{t('operator')}
						</Typography>
					</Box>

					<Divider />

					<Box
						display="flex"
						justifyContent="space-between"
						py={3}
						px="20px"
						bgcolor="#151515"
						sx={{
							cursor: 'pointer',
						}}
						onClick={() =>
							navigation.push(AppRoutes.CurrentSessions)
						}
					>
						<Typography variant="body1">
							{t('sessionCreator')}
						</Typography>

						<Box display="flex">
							<ArrowRightIcon color="white" />
						</Box>
					</Box>

					<Divider />
				</Box>
			)}

			{user?.roles?.includes(UserRole.ScoutingEventOperator) && (
				<Box width={1}>
					<Box
						px="20px"
						pt={1.5}
						pb={1}
						display="flex"
						alignItems="center"
					>
						<Typography variant="button" color="textSecondary">
							{t('scoutingEventOperator')}
						</Typography>
					</Box>

					<Divider />

					{user?.roles?.includes(UserRole.Admin) && (
						<>
							<Box
								display="flex"
								justifyContent="space-between"
								py={3}
								px="20px"
								bgcolor="#151515"
							>
								<Typography variant="body1">
									{t('Events')}
								</Typography>

								<ToogleButton
									name="version"
									disabled={
										!user?.roles?.includes(UserRole.Admin)
									}
									onChange={() =>
										setEventVersion(
											eventVersion === 'v2' ? 'v1' : 'v2',
										)
									}
									checked={eventVersion === 'v2'}
									values={[t('V2'), t('V1')]}
									colors={['white', 'white']}
								></ToogleButton>
							</Box>

							<Divider />
						</>
					)}

					<Box
						display="flex"
						justifyContent="space-between"
						py={3}
						px="20px"
						bgcolor="#151515"
						sx={{
							cursor: 'pointer',
						}}
						onClick={() => navigation.replace(AppRoutes.Events)}
					>
						<Typography variant="body1">
							{t('scoutingEventCreator')}
						</Typography>

						<Box display="flex">
							<ArrowRightIcon color="white" />
						</Box>
					</Box>

					<Divider />
				</Box>
			)}

			<Box width={1}>
				<Box
					px="20px"
					pt={1.5}
					pb={1}
					display="flex"
					alignItems="center"
				>
					<Typography variant="button" color="textSecondary">
						{t('profileSettings')}
					</Typography>
				</Box>

				<Divider />

				<Box
					display="flex"
					justifyContent="space-between"
					py={3}
					px="20px"
					bgcolor="#151515"
					sx={{
						cursor: 'pointer',
					}}
					onClick={() => navigation.push(AppRoutes.ChangePassword)}
				>
					<Typography variant="body1">
						{t('changePassword')}
					</Typography>

					<Box display="flex">
						<ArrowRightIcon color="white" />
					</Box>
				</Box>

				<Divider />
			</Box>

			<Box width={1}>
				<Box
					px="20px"
					pt={1.5}
					pb={1}
					display="flex"
					alignItems="center"
				>
					<Typography variant="button" color="textSecondary">
						{t('help')}
					</Typography>
				</Box>

				<Divider />

				<Box
					display="flex"
					justifyContent="space-between"
					py={3}
					px="20px"
					bgcolor="#151515"
					sx={{
						cursor: 'pointer',
					}}
					onClick={() => navigation.push(AppRoutes.Faq)}
				>
					<Typography variant="body1">{t('faq')}</Typography>

					<Box display="flex">
						<ArrowRightIcon color="white" />
					</Box>
				</Box>

				<Divider />

				<Box
					display="flex"
					justifyContent="space-between"
					py={3}
					px="20px"
					bgcolor="#151515"
					sx={{
						cursor: 'pointer',
					}}
					onClick={openGleap}
				>
					<Typography variant="body1">{t('feedback')}</Typography>

					<Box display="flex">
						<ArrowRightIcon color="white" />
					</Box>
				</Box>

				<Divider />
			</Box>

			<Box
				pb={4}
				flexGrow={1}
				display="flex"
				alignItems="center"
				flexDirection="column"
				justifyContent="flex-end"
			>
				<Box pt={3} />

				<Button
					variant="text"
					color="inherit"
					href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL || ''}
					startIcon={<ExternalLinkIcon color="white" />}
					target="_blank"
				>
					{t('privacyPolicy')}
				</Button>

				<Box pt={3} />

				<Button
					variant="text"
					color="inherit"
					href={process.env.REACT_APP_IMPRINT_URL || ''}
					startIcon={<ExternalLinkIcon color="white" />}
					target="_blank"
				>
					{t('imprint')}
				</Button>

				<Box pt={3} />

				<Button
					variant="text"
					color="inherit"
					href={process.env.REACT_APP_ABOUT_US_URL || ''}
					startIcon={<ExternalLinkIcon color="white" />}
					target="_blank"
				>
					{t('aboutUs')}
				</Button>

				<Box pt={4} />

				<Button color="inherit" variant="outlined" onClick={logout}>
					{t('logout')}
				</Button>

				<Box pt={2} />

				<Typography variant="caption" color="textSecondary">
					Version {process.env.REACT_APP_VERSION_NAME}
				</Typography>

				<Box pt={4} />

				<Typography
					onClick={openGleap}
					variant="caption"
					color="textSecondary"
					sx={{
						cursor: 'pointer',
					}}
				>
					{t('problems')}
				</Typography>

				<Box pt={4} />

				<Typography
					onClick={() => navigation.push(AppRoutes.DeleteAccount)}
					variant="caption"
					color="textSecondary"
					sx={{
						cursor: 'pointer',
					}}
				>
					{t('deleteAccount')}
				</Typography>
			</Box>
		</StyledDrawer>
	);
};
