export const chartTabs = [
	{
		subScore: 'Rank',
		label: 'Top Score',
	},
	{
		subScore: 'Control',
		label: 'Control',
	},
	{
		subScore: 'Impact',
		label: 'Impact',
	},
	{
		subScore: 'Passing',
		label: 'Passing',
	},
	{
		subScore: 'Physical',
		label: 'Physical',
	},
	{
		subScore: 'Dribbling',
		label: 'Dribbling',
	},
	{
		subScore: 'Defense',
		label: 'Defense',
	},
	{
		subScore: 'Shot',
		label: 'Shot',
	},
	{
		subScore: 'Pace',
		label: 'Pace',
	},
];
