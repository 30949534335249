import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { TopScore } from 'src/Screens/Dashboard/Scoring/TopScore/TopScore';
import { Score, useReleasePlayerScoreMutation } from 'src/graphql/types';

type LocationState = {
	session: Score;
};

export const ShareWithCoachScreen = () => {
	const { t } = useTranslation('add-session');
	const navigation = useNavigation();
	const location = useLocation();
	const locationState = location.state as LocationState;
	const session = locationState?.session;
	//const difference = '-7';
	const formUseForm = useForm<FormData>();
	const { handleSubmit } = formUseForm;
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [releasePlayerScoreMutation] = useReleasePlayerScoreMutation();

	const onSubmit = handleSubmit(async () => {
		const { data, errors } = await releasePlayerScoreMutation({
			variables: {
				input: {
					playerSessionId: session.playerSessionId,
					sessionNumber: session.sessionNumber,
				},
			},
		}).catch(convertNetworkErrors);

		if (errors) setErrorMessage(errors[0].message);

		if (data?.releasePlayerScore.sessionNumber) {
			navigation.replace(AppRoutes.Scoring);
		}
	});

	return (
		<Box
			height={1}
			display="flex"
			flexDirection="column"
			alignItems="center"
		>
			{/* This should be done when we have the differences ready */}

			{/* <Typography
				variant="subtitle1"
				align="center"
			>
				{`${t('differenceText')} ${difference}!`}
			</Typography> */}

			<Box pt={8} />

			<TopScore value={session.score} />

			<Box pt={12} />

			<Button
				variant="contained"
				color="primary"
				onClick={() => onSubmit()}
			>
				{t('shareCoach')}
			</Button>

			{errorMessage && (
				<Alert key={errorMessage} severity="error">
					{errorMessage}
				</Alert>
			)}

			<Box pt={4} />

			<Button
				variant="text"
				color="inherit"
				onClick={() => navigation.replace(AppRoutes.Scoring)}
			>
				{t('notNow')}
			</Button>

			<Box p={4} />
		</Box>
	);
};
