import React, { createContext } from 'react';

import { User, UserRole } from 'src/graphql/types';

export const AuthContext = createContext<
	| {
			isAuthorized: boolean;
			isConfirmed: boolean;
			isCompleted: boolean;
			loginRef: React.MutableRefObject<{
				loaded: boolean;
				[UserRole.Player]: boolean;
				[UserRole.Trainer]: boolean;
			}>;
			user: User | null;
			loading: boolean;
			logout: () => void;
			setUser: (user: User) => void;
			setTokens: (accessToken: string, refreshToken: string) => void;
			refetch: () => void;
	  }
	| undefined
>(undefined);
