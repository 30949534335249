import React from 'react';
import { useTranslation } from 'react-i18next';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';

import { ComparisonIcon } from 'src/Components/Icons/ComparisonIcon';
import { PlayIcon } from 'src/Components/Icons/PlayIcon';
import { ScoringIcon } from 'src/Components/Icons/ScoringIcon';
import { SafeAreaBottom } from 'src/Components/SafeArea';
import { TabsDashboard } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

type BottonTabsProps = {
	tab: typeof TabsDashboard[number];
};

export const BottomTabs: React.FC<BottonTabsProps> = ({ tab }) => {
	const navigation = useNavigation();
	const { t } = useTranslation('dashboard');

	return (
		<Box>
			<BottomNavigation
				value={tab}
				onChange={(_: any, value) => {
					navigation.push(`/auth/dashboard/${value}`);
				}}
				showLabels
				sx={{
					bgcolor: '#040404',
				}}
			>
				<BottomNavigationAction
					label={t('scoring')}
					value="scoring"
					icon={
						<ScoringIcon
							strokeWidth={2}
							selected={tab === 'scoring'}
						/>
					}
				/>

				<BottomNavigationAction
					label={t('videos')}
					value="videos"
					icon={
						<PlayIcon
							strokeWidth={2}
							sx={{ p: '2px' }}
							selected={tab === 'videos'}
						/>
					}
				/>

				<BottomNavigationAction
					label={t('comparison')}
					value="comparison"
					icon={
						<ComparisonIcon
							strokeWidth={2}
							selected={tab === 'comparison'}
						/>
					}
				/>
			</BottomNavigation>
			<SafeAreaBottom bgcolor="#040404" />
		</Box>
	);
};
