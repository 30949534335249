import {
	ApolloClient,
	ApolloLink,
	InMemoryCache,
	fromPromise,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import {
	getLocalStorageAccessToken,
	removeLocalStorageAccessToken,
	setLocalStorageAccessToken,
} from 'src/Auth/localStorageAccessToken';
import {
	getLocalStorageRefreshToken,
	removeLocalStorageRefreshToken,
} from 'src/Auth/localStorageRefreshToken';
import REFRESH_TOKEN_MUTATION from 'src/graphql/documents/mutations/refreshToken';

const authLink = setContext((_: any, { headers = {} }) => {
	const accessToken = getLocalStorageAccessToken();

	return {
		headers: {
			...headers,
			Authorization: accessToken ? `Bearer ${accessToken}` : '',
		},
	};
});

const httpLink = createUploadLink({
	uri: process.env.REACT_APP_KICKID_API,
	credentials: 'include',
});

const errorLink = onError(
	({ networkError, graphQLErrors, forward, operation }) => {
		const invalidRefreshToken = graphQLErrors?.some(
			(error) => error.message === 'InvalidRefreshToken',
		);

		if (invalidRefreshToken) {
			removeLocalStorageAccessToken();
			removeLocalStorageRefreshToken();
			return;
		}

		const tokenExpired = graphQLErrors?.some(
			(error) => error.message === 'TokenExpired',
		);

		if (tokenExpired) {
			return fromPromise(
				apolloClient
					.mutate({
						mutation: REFRESH_TOKEN_MUTATION,
						variables: {
							input: {
								refreshToken:
									getLocalStorageRefreshToken() || '',
							},
						},
					})
					.then((value) => {
						const accessToken =
							value?.data?.refreshToken?.accessToken;

						if (!accessToken) {
							removeLocalStorageAccessToken();
							removeLocalStorageRefreshToken();
						} else {
							setLocalStorageAccessToken(accessToken);
						}

						return accessToken;
					}),
			)
				.filter((value) => Boolean(value))
				.flatMap((accessToken) => {
					const oldHeaders = operation.getContext().headers;

					operation.setContext({
						headers: {
							...oldHeaders,
							Authorization: `Bearer ${accessToken}`,
						},
					});

					return forward(operation);
				});
		}

		if (networkError)
			console.log(`[Network Error]: ${networkError.message}`);
	},
);

export const apolloClient = new ApolloClient({
	link: ApolloLink.from([errorLink, authLink, httpLink]),
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'ignore',
		},
		query: {
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		},
		mutate: {
			errorPolicy: 'all',
		},
	},
});
