import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { SlideLeft } from 'src/Components/Transitions/Slide';
import { TextFieldForm } from 'src/Form/Inputs/TextFieldForm';
import { transformLowercase } from 'src/Form/helpers/transformLowercase';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useForgotUserPasswordMutation } from 'src/graphql/types';
import { useServerErrors } from 'src/helpers/useServerErrors';

import { ForgotPasswordFormValues } from './ForgotPasswordScreen';
import { PasswordSentScreen } from './PasswordSentScreen';

export const ForgotPasswordForm = () => {
	const { t } = useTranslation('forgot-password-screen');
	const [forgotPasswordMutation, { loading }] =
		useForgotUserPasswordMutation();
	const { handleSubmit, setError } =
		useFormContext<ForgotPasswordFormValues>();
	const navigation = useNavigation();
	const [emailSent, setEmailSent] = useState('');
	const { setServerErrors, resetServerErrors, ServerErrors } =
		useServerErrors();

	const onSubmit = handleSubmit(async (formData) => {
		resetServerErrors();

		const { errors, data } = await forgotPasswordMutation({
			variables: {
				input: formData,
			},
		}).catch(convertNetworkErrors);

		if (errors) {
			const isInvalidUsernameOrEmail = errors.some(
				(error) => error.message === 'InvalidUsernameOrEmail',
			);

			if (isInvalidUsernameOrEmail) {
				setError('usernameOrEmail', {});
			}

			setServerErrors(errors);
		}

		if (data?.forgotUserPassword.emailSent) {
			setEmailSent(data?.forgotUserPassword.email);
		}
	});

	return (
		<Stack
			px="20px"
			alignItems="center"
			component="form"
			flexGrow={1}
			onSubmit={onSubmit}
			noValidate
		>
			<Stack
				width={1}
				alignItems="center"
				justifyContent="center"
				flexGrow={1}
				maxWidth={500}
			>
				<Box pt={4} />

				<Typography variant="h5" textAlign="center" fontSize={18}>
					{t('description_text')}
				</Typography>

				<Box pt={4} />

				<TextFieldForm
					fullWidth
					variant="outlined"
					name="usernameOrEmail"
					autoComplete="username"
					label={t('username_or_email_placeholder')}
					disabled={loading}
					inputProps={{
						sx: {
							...transformLowercase,
						},
						enterKeyHint: 'go',
						autoCapitalize: 'off',
					}}
				/>

				<Box pt={2} />

				<Box pt={4} />

				{loading && <CircularProgress color="inherit" />}

				<ServerErrors />
			</Stack>

			<Box pt={4} />

			<Button
				color="inherit"
				variant="outlined"
				type="submit"
				disabled={loading}
			>
				{t('submit_button')}
			</Button>

			<Box pt={8} />

			<Dialog
				fullScreen
				open={!!emailSent}
				onClose={() => navigation.push(AppRoutes.Login)}
				TransitionComponent={SlideLeft}
			>
				<PasswordSentScreen email={emailSent} />
			</Dialog>
		</Stack>
	);
};
