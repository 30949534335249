import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AppStore from './app-store.svg';
import GooglePlay from './google-play.png';

export const UpdateApp: React.FC = ({}) => {
	const { t } = useTranslation('action-required');

	const platform = Capacitor.getPlatform();

	return (
		<Dialog open={true} TransitionComponent={Fade} fullWidth maxWidth="xs">
			<Box bgcolor="white" color="black" pt={4} px={2}>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
				>
					<Avatar sx={{ bgcolor: 'error.main', color: 'white' }}>
						!
					</Avatar>

					<Box pt={4} />

					<Typography variant="h6" textAlign="center" fontSize={20}>
						{t('new_version_title')}
					</Typography>

					<Box pt={3} />

					<Typography variant="body1" textAlign="center">
						{t('new_version_description')}
					</Typography>
				</Box>

				<Stack py={4} alignItems="center">
					{platform === 'ios' ? (
						<Link href={process.env.REACT_APP_APP_STORE_URL}>
							<Box
								width={120}
								height={40}
								sx={{
									backgroundImage: `url(${AppStore})`,
								}}
							/>
						</Link>
					) : (
						<Link
							href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL}
						>
							<Box
								width={134}
								height={40}
								sx={{
									backgroundSize: 'cover',
									backgroundImage: `url(${GooglePlay})`,
								}}
							/>
						</Link>
					)}
				</Stack>
			</Box>
		</Dialog>
	);
};
