import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useNavigation } from 'src/Router/useNavigation';

export type OtherButtonsProps = {
	onClick: () => void;
};

export type OtherButtonProps = {
	label: string;
	link: string;
	onClick: () => void;
};

export const OtherButton: React.FC<OtherButtonProps> = ({
	label,
	link,
	children,
	onClick,
}) => {
	const navigation = useNavigation();

	return (
		<Box display="flex" alignItems="center">
			<Typography
				variant="button"
				bgcolor="black"
				color="white"
				px={1}
				py={0.5}
				borderRadius={20}
				textAlign="right"
				border="1px solid #454545"
			>
				{label}
			</Typography>

			<Box pl={2} />

			<IconButton
				size="large"
				color="primary"
				onClick={() => {
					onClick();
					navigation.push(link);
				}}
			>
				{children}
			</IconButton>
		</Box>
	);
};
