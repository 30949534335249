import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useAuth } from 'src/Auth/useAuth';
import { PlusIcon } from 'src/Components/Icons/PlusIcon';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

export const NoSessionAdded: React.FC = () => {
	const navigation = useNavigation();
	const { t } = useTranslation('videos');
	const { isConfirmed } = useAuth();

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			flexGrow={1}
		>
			<Typography
				variant="caption"
				color="textSecondary"
				align="center"
				maxWidth={300}
			>
				{t('description')}
			</Typography>

			<Box pt={3} />

			<Button
				variant={!isConfirmed ? 'outlined' : 'contained'}
				color={!isConfirmed ? 'inherit' : 'primary'}
				startIcon={
					<PlusIcon color={!isConfirmed ? '#4b4b4b' : 'black'} />
				}
				disabled={!isConfirmed}
				onClick={() => navigation.push(AppRoutes.AddSession)}
			>
				{t('addCode')}
			</Button>
		</Box>
	);
};
