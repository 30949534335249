import { GraphQLError } from 'graphql';

export function convertNetworkErrors(errors: any) {
	return {
		errors: [
			{
				message: errors?.message,
			} as GraphQLError,
		],
		data: null,
	};
}
