import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from 'src/Auth/useAuth';
import { Score, useFindPlayerScoresByUserIdLazyQuery } from 'src/graphql/types';

import { UserScoreContext } from './UserScoreContext';

export const UserScoreProvider: React.FC = ({ children }) => {
	const { isAuthorized, user } = useAuth();
	const userRef = useRef<string | null>(null);
	const [loading, setLoading] = useState(true);
	const [userScores, setUserScores] = useState<Score[]>([]);
	const [lastUserScore, setLastUserScore] = useState<Score | null>(null);
	const params = useParams<{ userId: string }>();
	const userId = params.userId;

	const [teamPlayerSessionsQuery] = useFindPlayerScoresByUserIdLazyQuery({
		onCompleted: (data) => {
			setUserScores(data?.findPlayerScoresByUserId || []);
			setLastUserScore(data?.findPlayerScoresByUserId?.[0] || null);
			setLoading(false);
		},
	});

	useEffect(() => {
		if (isAuthorized && user && userId && userRef.current !== userId) {
			teamPlayerSessionsQuery({
				variables: {
					userId,
				},
			});

			userRef.current = userId;
		}
	}, [isAuthorized, user, userId, teamPlayerSessionsQuery]);

	const value = useMemo(
		() => ({
			loading,
			userScores,
			lastUserScore,
		}),
		[loading, userScores, lastUserScore],
	);

	return (
		<UserScoreContext.Provider value={value}>
			{children}
		</UserScoreContext.Provider>
	);
};
