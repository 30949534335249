import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

export type CustomAccordionProps = {
	defaultOpen?: boolean;
	title: (open: boolean) => JSX.Element;
	content: JSX.Element;
	bgColorContent?: string;
	onToggle?: (open: boolean) => void;
};

export const CustomAccordion: React.FC<CustomAccordionProps> = ({
	defaultOpen = false,
	title,
	content,
	onToggle = () => {},
	bgColorContent = '#151515',
}) => {
	const [open, setOpen] = useState(defaultOpen);

	const styles = open
		? {
				backgroundImage: 'linear-gradient(180deg, #46FF44, #0BFF8A)',
		  }
		: {
				backgroundColor: '#151515',
		  };

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				p={2}
				position="relative"
				border="1px solid #252525"
				borderRadius="2px"
				onClick={() => {
					setOpen(!open);
					onToggle(!open);
				}}
				sx={{ cursor: 'pointer', ...styles }}
				color={open ? 'black' : 'white'}
			>
				{title(open)}
			</Box>

			<Collapse in={open}>
				<Box
					p={2}
					border="1px solid #252525"
					borderTop={0}
					borderBottom={0}
					borderRadius="2px"
					bgcolor={bgColorContent}
				>
					{content}
				</Box>
			</Collapse>
		</Box>
	);
};
