import React from 'react';
import { useTranslation } from 'react-i18next';

import { Warning } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { UserIcon } from 'src/Components/Icons/UserIcon';
import { SlideUp } from 'src/Components/Transitions/Slide';

export type DeleteAccountDialogProps = {
	open: boolean;
	onClose: () => void;
	handleDelete: () => void;
	loading: boolean;
};

export const DeleteAccountDialog: React.FC<DeleteAccountDialogProps> = ({
	open,
	onClose,
	handleDelete,
	loading,
}) => {
	const { t } = useTranslation('delete-account');

	return (
		<Dialog
			open={open}
			onClose={() => !loading && onClose()}
			TransitionComponent={SlideUp}
		>
			<DialogContent
				sx={{
					background: 'white',
					color: 'black',
					padding: '20px',
					pb: 0,
				}}
			>
				<Box display="flex" alignItems="center" flexDirection="column">
					<Box pt={1} />

					<Warning
						color="error"
						sx={{
							width: 48,
							height: 48,
							color: 'error',
						}}
					>
						<UserIcon color="black" sx={{ fontSize: 28 }} />
					</Warning>
					<Box pt={3} />
					<Typography variant="body1" color="initial" align="center">
						{t('dialog_text')}
					</Typography>

					<Box pt={4} />
				</Box>

				<Box display="flex" justifyContent="center" alignItems="center">
					<Button
						variant="text"
						color="error"
						onClick={handleDelete}
						disabled={loading}
					>
						{loading ? (
							<CircularProgress color="inherit" />
						) : (
							t('submit')
						)}
					</Button>
				</Box>
				<Box
					height={72}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<Button
						onClick={onClose}
						color="inherit"
						disabled={loading}
					>
						{loading ? (
							<CircularProgress color="inherit" />
						) : (
							t('cancel')
						)}
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
};
