import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { SafeAreaBottom, SafeAreaTop } from 'src/Components/SafeArea';
import { TopBar } from 'src/Components/TopBar';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { DeleteAccountForm } from './DeleteAccountForm';

export type DeleteAccountFormValues = {
	password: string;
};

export const DeleteAccountScreen = () => {
	const { t } = useTranslation('delete-account');
	const navigation = useNavigation();
	const form = useForm<DeleteAccountFormValues>({
		resolver: yupResolver(
			yup.object({
				password: yup.string().required(),
			}),
		),
		defaultValues: {
			password: '',
		},
	});

	return (
		<Stack
			height={1}
			sx={{
				backgroundImage: `url(${Background1})`,
				backgroundSize: 'cover',
				overflowY: 'auto',
			}}
		>
			<SafeAreaTop />

			<TopBar
				title={t('title')}
				leftComponent={
					<IconButton
						color="inherit"
						size="large"
						onClick={() =>
							navigation.goBackOrGoTo(AppRoutes.Settings)
						}
					>
						<ArrowBackIcon />
					</IconButton>
				}
			/>

			<FormProvider {...form}>
				<DeleteAccountForm />
			</FormProvider>

			<SafeAreaBottom />
		</Stack>
	);
};
