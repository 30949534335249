import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';

import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { FormProvider } from 'src/Form/FormProvider';
import { OperatorSession } from 'src/graphql/types';

export type CreateSessionFormData = OperatorSession & {
	trainerCodes?: string[];
	playerCodes?: string[];
};

export const AdminForm: React.FC = () => {
	const defaultValues = {};
	const location = useLocation();

	const isScanner = location.pathname.includes('/scan');

	return (
		<FormProvider initialValue={defaultValues}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				width={1}
				height={1}
				sx={{
					backgroundImage: !isScanner
						? `url(${Background1})`
						: 'none',
					backgroundSize: !isScanner ? 'cover' : 'none',
				}}
			>
				<Outlet />
			</Box>
		</FormProvider>
	);
};
