import decode from 'jwt-decode';
import _ from 'lodash';
import moment from 'moment/moment';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { apolloClient } from 'src/Apollo/ApolloClient';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import {
	User,
	UserRole,
	useLogoutMutation,
	useRefreshTokenMutation,
	useUserLazyQuery,
} from 'src/graphql/types';

import { AuthContext } from './AuthContext';
import {
	getLocalStorageAccessToken,
	removeLocalStorageAccessToken,
	setLocalStorageAccessToken,
} from './localStorageAccessToken';
import {
	getLocalStorageRefreshToken,
	removeLocalStorageRefreshToken,
	setLocalStorageRefreshToken,
} from './localStorageRefreshToken';
import { removeLocalStorageRole } from './localStorageRole';

import { api } from '../redux/rtk-query';

function isValidToken() {
	const refreshToken = getLocalStorageRefreshToken();

	if (!refreshToken) {
		return false;
	}

	const currentTime = Date.now() / 1000;
	let decoded: any;

	try {
		decoded = decode(refreshToken);
	} catch {
		return false;
	}

	return decoded.exp > currentTime;
}

export const AuthProvider: React.FC = ({ children }) => {
	const { replace, reload } = useNavigation();
	const dispatch = useDispatch();

	const [logoutMutation] = useLogoutMutation();
	const [refreshTokenMutation] = useRefreshTokenMutation();

	const [user, setUser] = useState<User | null>(null);

	const [userQuery, { loading }] = useUserLazyQuery({
		onCompleted: (data) => {
			if (data?.user) {
				setUser(data.user);
			}
		},
	});

	const isAuthorized = isValidToken();

	const isConfirmed = user?.legalGuardian?.email
		? !!user?.legalGuardian?.consent && !!user?.emailVerified
		: !!user?.emailVerified;

	const isCompleted =
		isConfirmed && !!user?.avatarUrl && !!user?.backgroundUrl;

	const setTokens = useCallback(
		(accessToken: string, refreshToken: string) => {
			setLocalStorageAccessToken(accessToken);
			setLocalStorageRefreshToken(refreshToken);
		},
		[],
	);

	const removeTokens = () => {
		removeLocalStorageAccessToken();
		removeLocalStorageRefreshToken();
	};

	const clearUserData = useCallback(async () => {
		removeTokens();
		removeLocalStorageRole();
		localStorage.clear();
		await apolloClient.clearStore().catch();
		dispatch(api.util.resetApiState());
		setUser(null);
		replace(AppRoutes.Start, {});
		reload();
	}, [dispatch, reload, replace]);

	const logout = useCallback(async () => {
		const refreshToken = getLocalStorageRefreshToken();

		await logoutMutation({
			variables: {
				input: {
					refreshToken: `${refreshToken}`,
				},
			},
		}).catch();

		await clearUserData();
	}, [clearUserData, logoutMutation]);

	const refresh = useCallback(async () => {
		const refreshToken = `${getLocalStorageRefreshToken()}`;
		const { data } = await refreshTokenMutation({
			variables: {
				input: {
					refreshToken,
				},
			},
		}).catch();

		if (!_.isNil(data)) {
			const {
				refreshToken: { refreshed, accessToken },
			} = data;
			if (refreshed) {
				setTokens(`${accessToken}`, refreshToken);
				return true;
			}
		}
		return false;
	}, [refreshTokenMutation, setTokens]);

	const timeLeft = useCallback(() => {
		try {
			const token = `${getLocalStorageAccessToken()}`;
			const { exp } = decode(token) as { exp: number };
			// Remaining millis till access token is expired: (exp - now) * 1000 = timeout in ms
			// Buffer: 5min = (5 * 60 * 1000)ms
			return (exp - moment().unix()) * 1000 - 5 * 60 * 1000;
		} catch (error) {
			console.error(error);
			return 0;
		}
	}, []);

	const refetch = useCallback(() => {
		userQuery();
	}, [userQuery]);

	useEffect(() => {
		if (isAuthorized) {
			userQuery();
		}
	}, [isAuthorized, userQuery]);

	useEffect(() => {
		let pause = false;
		let sessionExpired: NodeJS.Timeout;

		if (isAuthorized) {
			sessionExpired = setInterval(async () => {
				if (pause) {
					return;
				}
				if (!isValidToken()) {
					await logout();
				} else if (timeLeft() < 3000) {
					pause = true;
					if (await refresh()) {
						pause = false;
					} else {
						await logout();
					}
				}
			}, 3000);
		}

		return () => clearInterval(sessionExpired);
	}, [isAuthorized, logout, refresh, timeLeft]);

	const loginRef = useRef({
		loaded: false,
		[UserRole.Player]: false,
		[UserRole.Trainer]: false,
	});

	const value = useMemo(
		() => ({
			loading,
			isAuthorized,
			isConfirmed,
			isCompleted,
			user,
			logout,
			setUser,
			setTokens,
			refetch,
			loginRef,
		}),
		[
			loading,
			isAuthorized,
			isConfirmed,
			isCompleted,
			user,
			logout,
			setUser,
			setTokens,
			refetch,
			loginRef,
		],
	);

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};
