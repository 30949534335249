import { ButtonProps } from '@mui/material/Button';
import Box from '@mui/system/Box';

import { UserRole } from 'src/graphql/types';

import { AddCodeButton } from './Buttons/AddCodeButtons';
import { GuideButton, GuideGroupButton } from './Buttons/GuideButtons';
import { MyIdGroupButton } from './Buttons/MyIdButtons';
import { OtherButtonsProps } from './Buttons/OtherButton';
import { ShareGroupButton } from './Buttons/ShareCardButtons';
import { VideosGroupButton } from './Buttons/VideosButtons';

export function buttonMapping(
	userRole: UserRole,
	isConfirmed: boolean,
	hasSessions: boolean,
) {
	if (!isConfirmed) {
		return {
			MainButton: ({ color, variant, ...props }: ButtonProps) => (
				<GuideButton
					variant={variant || 'contained'}
					color={color || 'primary'}
					{...props}
				/>
			),
			OtherButtons: ({ onClick }: OtherButtonsProps) => (
				<MyIdGroupButton onClick={onClick} />
			),
		};
	}

	if (!hasSessions) {
		return {
			MainButton: ({ color, variant, ...props }: ButtonProps) => (
				<AddCodeButton
					variant={variant || 'contained'}
					color={color || 'primary'}
					{...props}
				/>
			),
			OtherButtons: ({ onClick }: OtherButtonsProps) => (
				<>
					<MyIdGroupButton onClick={onClick} />
					<Box pt={2} />
					<GuideGroupButton onClick={onClick} />
				</>
			),
		};
	}

	return {
		MainButton: ({ color, variant, ...props }: ButtonProps) => (
			<AddCodeButton
				variant={variant || 'outlined'}
				color={color || 'inherit'}
				{...props}
			/>
		),
		OtherButtons: ({ onClick }: OtherButtonsProps) => (
			<>
				<MyIdGroupButton onClick={onClick} />
				<Box pt={2} />
				{userRole === UserRole.Player ? (
					<ShareGroupButton onClick={onClick} />
				) : null}
				<Box pt={2} />
				<VideosGroupButton onClick={onClick} />
			</>
		),
	};
}
