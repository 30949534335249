import { Theme, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';

export type CustomIconProps = {
	selected?: boolean;
	selectedColor?: string;
	color?: string;
	sx?: SxProps<Theme> | undefined;
	className?: string;
	strokeWidth?: number;
};

export const useCustomIcon = (props: CustomIconProps) => {
	const theme = useTheme();
	const selectedColor = props.selectedColor || theme.palette.primary.main;
	const defaultColor = props.color || theme.palette.text.secondary;
	const color = props.selected ? selectedColor : defaultColor;
	const strokeWidth = props.strokeWidth || 1;
	return { color, strokeWidth };
};
