import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { SlideUp } from 'src/Components/Transitions/Slide';

const StyledDialog = styled(Dialog)(() => ({
	[`& .${dialogClasses.container}`]: {
		alignItems: 'flex-end',
	},

	[`& .${dialogClasses.paper}`]: {
		backgroundColor: 'black',
		backgroundImage: 'none',
	},
}));

export type FullScreenDialogProps = {
	title: string;
	open?: boolean;
	onClose?: () => void;
};

export const FullScreenDialog: React.FC<FullScreenDialogProps> = ({
	title,
	open = false,
	onClose,
	children,
}) => (
	<StyledDialog
		open={open}
		onClose={onClose}
		fullScreen
		TransitionComponent={SlideUp}
	>
		<DialogTitle
			sx={{
				p: '20px',
				pt: 'max(env(safe-area-inset-top), 20px)',
			}}
		>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Box width={50} />
				<Typography
					variant="subtitle1"
					align="center"
					sx={{
						width: 244,
					}}
				>
					{title}
				</Typography>
				<IconButton onClick={() => onClose && onClose()} size="large">
					<CloseIcon />
				</IconButton>
			</Box>
		</DialogTitle>
		<DialogContent sx={{ px: '20px' }}>{children}</DialogContent>
	</StyledDialog>
);
