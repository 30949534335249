import { Capacitor } from '@capacitor/core';
import {
	OrientationType,
	ScreenOrientation,
} from '@robingenz/capacitor-screen-orientation';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { CustomPlayer } from 'src/Components/CustomPlayer';
import { useSessions } from 'src/Sessions/useSessions';
import { useFindVideoLazyQuery, useFindVideosQuery } from 'src/graphql/types';

const StyledBox = styled(Box)(() => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-around',
	overflow: 'hidden',
	width: '100%',

	'.react-player__preview': {
		borderRadius: 3,
		backgroundSize: 'auto !important',

		'&:after': {
			content: '""',
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			background: 'rgba(0, 0, 0, 0.1)',
			pointerEvents: 'none',
		},
	},
}));

const StyledImageList = styled(ImageList)(() => ({
	flexWrap: 'nowrap',
	transform: 'translateZ(0)',
}));

export const TrackingVideosScreen: React.FC = () => {
	const { t } = useTranslation(['scoring', 'months']);
	const { selectedRole } = useSessions();

	const [findVideoQuery, { data: findVideoData }] = useFindVideoLazyQuery();

	const formatDate = (value: string) => {
		if (!value) return '';
		const date = new Date(value);
		return `${t(
			'months:' + date.getMonth().toString(),
		)} ${date.getFullYear()}`;
	};

	const { data, loading, error } = useFindVideosQuery({
		variables: {
			role: selectedRole,
		},
	});

	useEffect(() => {
		if (Capacitor.getPlatform() !== 'android') {
			return;
		}

		ScreenOrientation.unlock();

		return () => {
			ScreenOrientation.lock({ type: OrientationType.PORTRAIT });
		};
	}, []);

	return (
		<>
			<Box>
				<Typography
					variant="subtitle1"
					py={1}
					width={1}
					textAlign="center"
				>
					{t('trackingVideosTitle')}
				</Typography>
			</Box>

			<Box pt={2} />

			{loading ? (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="flex-start"
					width={1}
					justifyContent="flex-start"
				>
					<Skeleton variant="text" width={100} />

					<Box pt={3} />

					<Skeleton variant="rectangular" height={176} width={311} />

					<Box pt={5} />

					<Skeleton variant="text" width={66} />

					<Box pt={3} />

					<Skeleton variant="rectangular" height={121} width={214} />
				</Box>
			) : error ? (
				<Alert key={error.message} severity="error">
					{error.message}
				</Alert>
			) : (
				data?.findVideos?.map((session, indexSession) => (
					<Box
						key={indexSession}
						display="flex"
						flexDirection="column"
						alignItems="flex-start"
						width={1}
						justifyContent="flex-start"
						pb={4}
					>
						<Typography
							variant={indexSession === 0 ? 'body1' : 'caption'}
							color="inherit"
						>
							{formatDate(session.date)}
						</Typography>
						<StyledBox pt={1}>
							{session.videos.length !== 0 ? (
								<StyledImageList
									gap={8}
									cols={session.videos.length}
								>
									{session.videos.map((video) => (
										<Grid
											item
											key={video.match}
											position="relative"
										>
											<Box
												position="absolute"
												width={24}
												height={24}
												left={10}
												top={10}
												display="flex"
												alignItems="center"
												justifyContent="center"
												borderRadius="50%"
												bgcolor="black"
												sx={{
													opacity: 0.6,
												}}
											>
												<Typography
													variant="caption"
													color="inherit"
												>
													{+video.match < 10
														? `0${video.match}`
														: video.match}
												</Typography>
											</Box>

											{findVideoData?.findVideo?.id ===
												video.id && (
												<CustomPlayer
													url={
														findVideoData.findVideo
															.url
													}
													config={{
														hlsOptions: {
															xhrSetup: (
																xhr: XMLHttpRequest,
															) => {
																xhr.withCredentials =
																	true;
															},
														},
													}}
													width={
														indexSession === 0
															? 311
															: 214
													}
													height={
														indexSession === 0
															? 176
															: 121
													}
													playing
													customControls
													muted
												/>
											)}

											{findVideoData?.findVideo?.id !==
												video.id && (
												<CustomPlayer
													onClickPreview={() => {
														findVideoQuery({
															variables: {
																id: video.id,
																sessionNumber:
																	session.sessionNumber,
																playerSessionId:
																	session.playerSessionId,
															},
														});
													}}
													url={video.thumb}
													width={
														indexSession === 0
															? 311
															: 214
													}
													height={
														indexSession === 0
															? 176
															: 121
													}
													light={video.thumb}
													customControls
													muted
												/>
											)}
										</Grid>
									))}
								</StyledImageList>
							) : (
								<Box
									pt={1}
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Typography
										variant={
											indexSession === 0
												? 'body2'
												: 'caption'
										}
										color="textSecondary"
										align="center"
									>
										{t('noVideos')}
									</Typography>
								</Box>
							)}
						</StyledBox>
					</Box>
				))
			)}
		</>
	);
};
