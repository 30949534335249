import { useEffect, useRef, useState } from 'react';

export const useScrollSubScoreCard = (selected: boolean, loaded = true) => {
	const ref = useRef<HTMLDivElement>(null);
	const [entered, setEntered] = useState(false);

	useEffect(() => {
		if (selected && loaded && ref.current && entered) {
			ref.current?.scrollIntoView({
				behavior: 'smooth',
			});
			setEntered(false);
		}
	}, [selected, loaded, entered, setEntered]);

	const onEntered = () => {
		setEntered(true);
	};

	return { ref, onEntered };
};
