import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useAuth } from 'src/Auth/useAuth';
import { BottomButton } from 'src/Components/BottomButton';
import { ArrowDownIcon } from 'src/Components/Icons/ArrowDownIcon';
import { SafeAreaBottom } from 'src/Components/SafeArea';
import { useNotifications } from 'src/Notifications/useNotifications';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';

import { buttonMapping } from './buttonMapping';

let timeout: NodeJS.Timeout;

export const ScoringButtons = () => {
	const {
		lastSession,
		selectedRole,
		loading: loadingSessions,
	} = useSessions();
	const { isConfirmed, loading: loadingUser } = useAuth();
	const { t } = useTranslation('scoring');
	const navigation = useNavigation();
	const anchorEl = useRef<HTMLButtonElement>(null);
	const params = useParams<{ dialog: string }>();
	const [open, setOpen] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(false);
	const swipeable = useSwipeable({
		onSwipedUp: () => navigation.push(AppRoutes.PerformanceStats),
	});
	const { openHint } = useNotifications();
	const handleClick = () => {
		setOpen((prev) => !prev);
	};

	const loading = loadingSessions || loadingUser;

	const { MainButton, OtherButtons } = buttonMapping(
		selectedRole,
		isConfirmed,
		!!lastSession,
	);

	useEffect(() => {
		const hintShown = localStorage.getItem('hint') === 'shown';

		clearTimeout(timeout);

		if (hintShown || params.dialog || openHint || open) return;

		timeout = setTimeout(() => {
			setOpenTooltip(true);
			localStorage.setItem('hint', 'shown');
		}, 10_000);
	}, [params.dialog, setOpenTooltip, openHint, open]);

	return (
		<>
			<Tooltip
				open={openTooltip}
				title={
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
					>
						<Avatar sx={{ bgcolor: 'error.main', color: 'white' }}>
							!
						</Avatar>

						<Box pt={2} />

						<Typography
							variant="body1"
							textAlign="center"
							color="black"
						>
							{t('otherButtonsHint')}
						</Typography>
					</Box>
				}
				placement="top-end"
				arrow
				onClick={() => setOpenTooltip(false)}
				PopperProps={{
					onClick: () => setOpenTooltip(false),
					sx: {
						'& .MuiTooltip-tooltip': {
							background: 'white',
							width: 180,
							padding: 2,
						},
						'& .MuiTooltip-arrow': {
							right: 30,
							transform: 'initial !important',
							left: 'initial !important',
							color: 'white',
						},
					},
				}}
			>
				<Box sx={{ zIndex: (theme) => theme.zIndex.drawer }}>
					<Backdrop
						open={openTooltip}
						sx={{ backgroundColor: '#000000b3' }}
						onClick={() => setOpenTooltip(false)}
					/>
					<Popper
						open={open}
						anchorEl={anchorEl.current}
						transition
						placement="top-end"
						sx={{
							zIndex: (theme) => theme.zIndex.drawer,
						}}
					>
						{({ TransitionProps }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin: 'center bottom',
								}}
							>
								<Paper
									sx={{
										paddingRight: 1.5,
										boxShadow: 0,
										background: 'none',
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
									}}
								>
									<OtherButtons
										onClick={() => setOpen(false)}
									/>

									<Box pt={3} />
								</Paper>
							</Grow>
						)}
					</Popper>
					<Backdrop
						sx={{ backgroundColor: '#000000b3' }}
						open={open}
						onClick={() => setOpen(false)}
					/>

					{loading ? (
						<Skeleton variant="text" width={200} height={48} />
					) : (
						<ButtonGroup variant="outlined" color="inherit">
							<MainButton
								color={open ? 'inherit' : undefined}
								variant={open ? 'outlined' : undefined}
								sx={{
									zIndex:
										open || openTooltip ? -1 : undefined,
								}}
							/>

							<Button
								size="large"
								color="inherit"
								variant="outlined"
								onClick={handleClick}
								ref={anchorEl}
							>
								{open ? (
									<MoreVertOutlinedIcon />
								) : (
									<MoreHorizOutlinedIcon />
								)}
							</Button>
						</ButtonGroup>
					)}
				</Box>
			</Tooltip>

			<Box pt="70px" />
			<Box
				position="fixed"
				bottom={69}
				left={-1}
				right={-1}
				sx={{
					zIndex: (theme) =>
						open || openTooltip
							? theme.zIndex.appBar
							: theme.zIndex.drawer,
				}}
			>
				<BottomButton
					color="inherit"
					fullWidth
					variant="outlined"
					startIcon={<ArrowDownIcon color="white" />}
					onClick={() => navigation.push(AppRoutes.PerformanceStats)}
					{...swipeable}
				>
					{t('viewStats')}
				</BottomButton>

				<SafeAreaBottom />
			</Box>
		</>
	);
};
