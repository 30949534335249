import { useContext } from 'react';

import { SessionContext } from './SessionContext';

export const useSessions = () => {
	const context = useContext(SessionContext);

	if (!context)
		throw new Error('SessionContext must be used with SessionProvider!');

	return context;
};
