import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { ScoutingEvent } from '../../../graphql/types';
import { useEvent } from '../useEvent';

export type DetailsCardProps = {
	event: ScoutingEvent;
};

export const EventDetailsCard: React.FC<DetailsCardProps> = ({ event }) => {
	const { t } = useTranslation('current-event');
	const navigation = useNavigation();

	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));

	const editable = moment().isBefore(moment(event.end));

	const { setEvent } = useEvent();

	return (
		<Grid container spacing={3}>
			<Grid item xs={6}>
				<Typography variant="h6">{event.players?.length}</Typography>
				<Typography variant="body2" color="textSecondary">
					{t('playerNumber')}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="h6">{event.trainers?.length}</Typography>
				<Typography variant="body2" color="textSecondary">
					{t('coachesNumber')}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="h6">{event.sessions?.length}</Typography>
				<Typography variant="body2" color="textSecondary">
					{t('sessionsNumber')}
				</Typography>
			</Grid>
			<Grid container item spacing={3}>
				{!editable && sm ? <Grid item xs></Grid> : null}
				<Grid item xs>
					<Button
						color="inherit"
						variant="outlined"
						fullWidth
						onClick={() => {
							setEvent(event);
							navigation.push(
								AppRoutes.CurrentEventSessions.replace(
									':eventNumber',
									`${event.eventNumber}`,
								),
							);
						}}
					>
						{t('showSessions')}
					</Button>
				</Grid>
				{editable ? (
					<Grid flexBasis={!sm ? '100%' : 0} item xs>
						<Button
							color="inherit"
							variant="outlined"
							fullWidth
							onClick={() => {
								setEvent(event);
								navigation.push(
									AppRoutes.EditEvent.replace(
										':eventNumber',
										`${event.eventNumber}`,
									),
									event,
								);
							}}
						>
							{t('editEvent')}
						</Button>
					</Grid>
				) : null}
			</Grid>
		</Grid>
	);
};
