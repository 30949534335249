import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { AddSessionLayout } from 'src/Screens/AddSession/AddSessionLayout';
import { CheckCodeScreen } from 'src/Screens/AddSession/CheckCode/CheckCodeScreen';
import { ManualCodeScreen } from 'src/Screens/AddSession/ManualCode/ManualCodeScreen';
import { PaymentScreen } from 'src/Screens/AddSession/Payment/PaymentScreen';
import { ScanCodeScreen } from 'src/Screens/AddSession/ScanCode/ScanCodeScreen';
import { ShareWithCoachScreen } from 'src/Screens/AddSession/ShareWithCoach/ShareWithCoachScreen';
import { ChangePasswordScreen } from 'src/Screens/ChangePassword/ChangePasswordScreen';
import { ComparisonLayout } from 'src/Screens/Dashboard/Comparison/ComparisonLayout';
import { ComparisonScreen } from 'src/Screens/Dashboard/Comparison/ComparisonScreen';
import { CreateComparisonScreen } from 'src/Screens/Dashboard/Comparison/CreateComparison/CreateComparisonScreen';
import { ViewComparisonScreen } from 'src/Screens/Dashboard/Comparison/ViewComparison/ViewComparisonScreen';
import { DashboardLayout } from 'src/Screens/Dashboard/DashboardLayout';
import { ScoringScreen } from 'src/Screens/Dashboard/Scoring/ScoringScreen';
import { SlideControlLayout } from 'src/Screens/Dashboard/SlideControls/SlideControlLayout';
import { SlideControlScreen } from 'src/Screens/Dashboard/SlideControls/SlideControlScreen';
import { VideosScreen } from 'src/Screens/Dashboard/Videos/VideosScreen';
import { DeleteAccountScreen } from 'src/Screens/DeleteAccount/DeleteAccountScreen';

import { AppRoutes } from '../AppRoutes';

export default [
	{
		path: 'dashboard',
		element: <Outlet />,
		children: [
			{
				path: '',
				element: <Navigate replace to={AppRoutes.Scoring} />,
			},
			{
				path: 'scoring',
				element: <DashboardLayout tab="scoring" />,
				children: [
					{
						path: '',
						element: <ScoringScreen />,
					},
					{
						path: ':dialog',
						element: <ScoringScreen />,
					},
					{
						path: ':dialog/user/:userId',
						element: <ScoringScreen />,
					},
				],
			},
			{
				path: 'videos',
				element: <DashboardLayout tab="videos" />,
				children: [
					{
						path: '',
						element: <VideosScreen />,
					},
				],
			},
			{
				path: 'comparison',
				element: <DashboardLayout tab="comparison" />,
				children: [
					{
						path: '',
						element: (
							<ComparisonLayout>
								<ComparisonScreen />
							</ComparisonLayout>
						),
					},
				],
			},
			{
				path: '*',
				element: <Navigate replace to={AppRoutes.Scoring} />,
			},
		],
	},
	{
		path: 'add-session',
		element: <Outlet />,
		children: [
			{
				path: '',
				element: <Navigate replace to={AppRoutes.AddSession} />,
			},
			{
				path: 'scan',
				element: (
					<AddSessionLayout>
						<ScanCodeScreen />
					</AddSessionLayout>
				),
			},
			{
				path: 'check/:code',
				element: (
					<AddSessionLayout>
						<CheckCodeScreen />
					</AddSessionLayout>
				),
			},
			{
				path: 'manual',
				element: <ManualCodeScreen />,
			},
			{
				path: 'payment/:code',
				element: (
					<AddSessionLayout>
						<PaymentScreen />
					</AddSessionLayout>
				),
			},
			{
				path: 'share/:code',
				element: (
					<AddSessionLayout>
						<ShareWithCoachScreen />
					</AddSessionLayout>
				),
			},
			{
				path: '*',
				element: <Navigate replace to={AppRoutes.AddSession} />,
			},
		],
	},
	{
		path: 'comparison/view',
		element: (
			<ComparisonLayout>
				<ViewComparisonScreen />
			</ComparisonLayout>
		),
	},
	{
		path: 'comparison/create',
		element: <CreateComparisonScreen />,
	},
	{
		path: 'change-password',
		element: <ChangePasswordScreen />,
	},
	{
		path: 'delete-account',
		element: <DeleteAccountScreen />,
	},
	{
		path: 'slidecontrol',
		element: (
			<SlideControlLayout>
				<SlideControlScreen />
			</SlideControlLayout>
		),
	},
] as RouteObject[];
