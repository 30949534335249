import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useAddClubDialogValidations = () => {
	const { t } = useTranslation('validations');

	const validationSchema = useMemo(
		() =>
			yup.object({
				clubName: yup.string().required(t('required')),
			}),
		[t],
	);

	const resolver = yupResolver(validationSchema);

	return resolver;
};
