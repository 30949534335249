import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { TableCell, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { OperatorSession } from '../../../graphql/types/rtk-query';
import { useEventArgs } from '../../../redux/hooks';
import { useGetEventQuery } from '../../../redux/rtk-query';
import { CollapsibleTable, HeadCell } from '../Components/DataTable';

export const EventSessionList = () => {
	const { t } = useTranslation('event-sessions');

	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up(768));

	const headCells: HeadCell[] = [
		{
			label: '',
			sx: { width: 0, padding: '0 0 0 8px' },
		},
		{
			sort: 'ageGroup',
			label: _.upperCase(t('age-group')),
			sx: { whiteSpace: 'nowrap' },
		},
		{
			sort: 'playersCount',
			label: _.upperCase(t('player')),
			sx: { textAlign: 'right', width: 0, whiteSpace: 'nowrap' },
		},
		{
			sort: 'matchesCount',
			label: _.upperCase(t('matches')),
			sx: { textAlign: 'right', width: 0, whiteSpace: 'nowrap' },
			hidden: !sm,
		},
	];

	const { data, isLoading } = useGetEventQuery(useEventArgs());
	return (
		<Fragment>
			<CollapsibleTable
				loading={isLoading}
				rows={(data?.getEvent?.sessions ?? []) as OperatorSession[]}
				keyName={'sessionNumber'}
				headCells={headCells}
				renderRowCells={(row) => (
					<>
						<TableCell sx={{ width: 0, padding: '0 0 0 8px' }}>
							{row.processed ? (
								<CheckBox display={'block'} color={'primary'} />
							) : (
								<CheckBoxOutlineBlank display={'block'} />
							)}
						</TableCell>
						<TableCell>{row.ageGroup}</TableCell>
						<TableCell
							align="right"
							sx={{ width: 0, whiteSpace: 'nowrap' }}
						>
							{row.playersCount}
						</TableCell>
						<TableCell
							align="right"
							sx={{ width: 0, whiteSpace: 'nowrap' }}
							hidden={!sm}
						>
							{row.matchesCount}
						</TableCell>
					</>
				)}
				empty={t('no-sessions')}
				rowsPerPageLabel={t('rows-per-page')}
			/>
		</Fragment>
	);
};
