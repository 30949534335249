import { GraphQLError } from 'graphql';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { CloseIcon } from 'src/Components/Icons/CloseIcon';
import { OldTopBar } from 'src/Components/OldTopBar';
import { useCustomForm } from 'src/Form/useCustomForm';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useUpdateOperatorSessionMutation } from 'src/graphql/types';

import { CreateSessionFormData } from '../AdminForm';

export const AddCoachScreen = () => {
	const [updateOperatorSessionMutation] = useUpdateOperatorSessionMutation();
	const { t } = useTranslation(['add-coach', 'server-error']);

	const [loadingCode] = useState<boolean>(false);
	const [invalidCode, setInvalidCode] = useState<boolean>(false);

	const { form, setForm } = useCustomForm<CreateSessionFormData>();
	const navigation = useNavigation();
	const scanedRef = useRef(false);

	const [serverErrors, setServerErrors] = useState<readonly GraphQLError[]>(
		[],
	);

	const code = navigation.query.get('code');

	useEffect(() => {
		if (!form.ageGroup) {
			navigation.push(
				`${
					AppRoutes.EditSession +
					(form.sessionNumber
						? `?sessionNumber=${form.sessionNumber}`
						: '')
				}`,
			);
		}
	}, [form.ageGroup, form.sessionNumber, navigation]);

	const AddTrainer = useCallback(
		async (code: string) => {
			setServerErrors([]);

			const existingCode =
				form.playerCodes?.includes(code) ||
				form.trainerCodes?.includes(code);

			if (existingCode) {
				setInvalidCode(true);
				return;
			}

			// setLoadingCode(true);

			// const { data, errors } = await apolloClient.query<ValidateCodeQuery>({
			// 	query: VALIDATE_CODE_QUERY,
			// 	variables: {
			// 		code,
			// 	},
			// });

			// setLoadingCode(false);

			// if (data?.validateCode?.valid) {
			setForm({
				...form,
				trainerCodes: [...(form?.trainerCodes || []), code],
				trainersCount: form.trainersCount + 1,
			});
			// } else {
			// 	setInvalidCode(true);
			// }

			// if (errors) setServerErrors(errors);
		},
		[form, setForm],
	);

	useEffect(() => {
		if (code && !scanedRef.current) {
			AddTrainer(code);
			navigation.setQuery('code', null);
			scanedRef.current = true;
		}
	}, [code, AddTrainer, navigation, scanedRef]);

	const onSubmit = async () => {
		if (!form.sessionNumber) {
			navigation.push(
				`${
					AppRoutes.AddPlayer +
					(form.sessionNumber
						? `?sessionNumber=${form.sessionNumber}`
						: '')
				}`,
			);
		} else {
			const { data, errors } = await updateOperatorSessionMutation({
				variables: {
					input: {
						sessionNumber: form.sessionNumber,
						ageGroup: form.ageGroup,
						fixedGoalkeepers: form.fixedGoalkeepers,
						sprintTest: form.sprintTest,
						shotTest: form.shotTest,
						clubId: form.club.id,
						trainerCodes:
							form.trainerCodes?.filter((code) => code) || [],
					},
				},
			}).catch(convertNetworkErrors);

			if (errors) setServerErrors(errors);

			if (data?.updateOperatorSession.updated) {
				navigation.replace(AppRoutes.CurrentSessions);
			}
		}
	};

	const removeTrainer = (code: string) => {
		const index =
			form.trainerCodes?.findIndex((_code) => _code === code) || -1;

		form.trainersCount = form.trainersCount - 1;
		form.trainerCodes?.splice(index, 1);

		setForm({ ...form });
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			width={1}
			height={1}
		>
			<OldTopBar
				title={t('title')}
				border
				onClickLeft={() =>
					navigation.push(
						`${
							AppRoutes.EditSession +
							(form.sessionNumber
								? `?sessionNumber=${form.sessionNumber}`
								: '')
						}`,
					)
				}
				iconLeft={<ArrowBackIcon />}
			/>

			<Box zIndex={1}>
				<Box pt={2} />

				<Typography variant="body2">{`${t('numberCoaches')}${
					form.trainersCount
				}`}</Typography>

				<Box pt={2} />
			</Box>

			<Box display="flex" justifyContent="center" pb={2}>
				<Button
					variant="contained"
					color="primary"
					onClick={() => navigation.replace(AppRoutes.AddCoachScan)}
				>
					{t('scanCode')}
				</Button>
			</Box>

			<Box
				borderTop="1px solid #252525"
				px="20px"
				width={1}
				sx={{ overflowY: 'auto', overflowX: 'hidden' }}
				pt={2}
			>
				{form.trainerCodes?.map((code, index) => (
					<Box
						display="flex"
						p={2}
						border="1px solid #202020"
						key={index}
						width={1}
						height={66}
						bgcolor="#0d0d0d"
						alignItems="center"
						justifyContent="space-between"
						mb={2}
					>
						<Box display="flex">
							<Typography variant="button">
								{t('coach')} {index + 1}
							</Typography>

							<Box pl={1} />
							<Typography variant="button">-</Typography>
							<Box pl={1} />

							<Typography variant="button">
								{code ? code : t('hidden')}
							</Typography>
						</Box>
						{!!code && (
							<IconButton
								size="small"
								sx={{ bgcolor: '#730100' }}
								onClick={() => removeTrainer(code)}
							>
								<CloseIcon color="white" />
							</IconButton>
						)}
					</Box>
				))}

				{loadingCode && (
					<>
						<Skeleton
							height={55}
							width="100%"
							variant="rectangular"
						></Skeleton>

						<Box pt={2} />
					</>
				)}

				{invalidCode ? (
					<>
						<Alert
							severity="error"
							onClose={() => setInvalidCode(false)}
						>
							{t('invalidCode')}
						</Alert>

						<Box pt={2} />
					</>
				) : null}

				{serverErrors.map((error) => (
					<>
						<Alert
							key={error.message}
							severity="error"
							onClose={() => setServerErrors([])}
						>
							{t('server-error:' + error.message) ||
								error.message}
						</Alert>

						<Box pt={2} />
					</>
				))}

				<Box display="flex" justifyContent="center">
					<Button
						variant="contained"
						color="primary"
						onClick={onSubmit}
					>
						{form.sessionNumber ? t('finishEdit') : t('continue')}
					</Button>
				</Box>

				<Box pb={2} />
			</Box>
		</Box>
	);
};
