import { Geolocation, Position } from '@capacitor/geolocation';

export const useGeolocation = () => {
	function onSuccess(position: Position) {
		const latitude: number = position.coords.latitude;
		const longitude: number = position.coords.longitude;

		return {
			latitude,
			longitude,
		};
	}

	async function getCurrentPosition() {
		return Geolocation.getCurrentPosition()
			.then((position: Position) => onSuccess(position))
			.catch(() => null);
	}

	return getCurrentPosition;
};
