import React, { useRef } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { useAuth } from 'src/Auth/useAuth';
import { PullToRefresh } from 'src/Components/PullToRefresh';
import { SafeAreaTop } from 'src/Components/SafeArea';
import { useSessions } from 'src/Sessions/useSessions';

import { NoSessionAdded } from './NoSessionAdded';

import { TrackingVideosScreen } from '../Scoring/Dialogs/TrackingVideosScreen';

export const VideosScreen: React.FC = () => {
	const {
		sessions,
		loading: loadingSessions,
		refetch: refetchSessions,
	} = useSessions();
	const { refetch: refetchUser } = useAuth();
	const scrollableRef = useRef<HTMLDivElement | undefined>();

	return (
		<>
			<PullToRefresh
				scrollableRef={scrollableRef}
				onRefresh={() => {
					refetchUser();
					refetchSessions();
				}}
			/>

			<SafeAreaTop />

			<Box
				ref={scrollableRef}
				display="flex"
				flexDirection="column"
				flexGrow={1}
				height={1}
				px="20px"
				pt="20px"
				sx={{ overflowY: 'auto' }}
			>
				{loadingSessions && (
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						flexGrow={1}
					>
						<Skeleton width={250} />
						<Skeleton width={300} />
						<Box pt={3} />
						<Skeleton
							variant="rectangular"
							width={139}
							height={48}
						/>
					</Box>
				)}
				{!loadingSessions && !sessions?.length && <NoSessionAdded />}
				{!loadingSessions && !!sessions?.length && (
					<TrackingVideosScreen />
				)}
			</Box>
		</>
	);
};
