import { useContext } from 'react';

import { WarningContext } from './WarningProvider';

export const useShowWarning = () => {
	const context = useContext(WarningContext);

	if (!context)
		throw new Error('WarningContext must be used with SuccessProvider!');

	return context;
};
