import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const ComparisonIcon: React.FC<CustomIconProps> = (props) => {
	const { color, strokeWidth } = useCustomIcon(props);

	return (
		<SvgIcon viewBox="0 0 32 32" sx={props.sx}>
			<g
				stroke="none"
				strokeWidth={strokeWidth}
				fill="none"
				fillRule="evenodd"
			>
				<g
					transform="translate(1.000000, 1.237522)"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				>
					<g transform="translate(0.000000, 6.762478)">
						<path
							d="M5.51002841,0.49999901 C6.34779312,0.498332096 6.93679665,0.775765537 7.32300302,1.2277005 C7.83317705,1.82470121 8,2.68396053 8.0000229,3.49521435 C7.99185531,4.34851779 7.83111349,5.19845064 7.33123213,5.78340698 C6.94646485,6.23365792 6.3551927,6.5 5.50903355,6.5 C4.66686034,6.5 4.06778815,6.22652593 3.67442535,5.76736742 C3.17512576,5.18455264 3,4.34226264 3,3.48766137 C3,2.68206027 3.1828998,1.83269092 3.69524514,1.23885006 C4.08802162,0.783597132 4.6797827,0.5 5.51002841,0.49999901 Z"
							id="Path-11"
						/>
						<path
							d="M0,9.52487025 C0,9.7840208 0,10.3133492 0,11.1128554 C0.0330298399,13.1411205 1.47249548,15.5117434 5.46040391,15.5 C9.53816462,15.4886954 11.0101086,13.14063 10.9998418,11.1128554 C10.9998418,9.66196691 10.9998418,10.1972673 10.9998418,9.5"
							strokeLinecap="square"
							transform="translate(5.500000, 12.500000) rotate(-180.000000) translate(-5.500000, -12.500000) "
						/>
						<path
							d="M24.5100284,0.49999901 C25.3477931,0.498332096 25.9367966,0.775765537 26.323003,1.2277005 C26.8331771,1.82470121 27,2.68396053 27.0000229,3.49521435 C26.9918553,4.34851779 26.8311135,5.19845064 26.3312321,5.78340698 C25.9464648,6.23365792 25.3551927,6.5 24.5090336,6.5 C23.6668603,6.5 23.0677881,6.22652593 22.6744253,5.76736742 C22.1751258,5.18455264 22,4.34226264 22,3.48766137 C22,2.68206027 22.1828998,1.83269092 22.6952451,1.23885006 C23.0880216,0.783597132 23.6797827,0.5 24.5100284,0.49999901 Z"
							id="Path-11"
						/>
						<path
							d="M19,9.52487025 C19,9.7840208 19,10.3133492 19,11.1128554 C19.0330298,13.1411205 20.4724955,15.5117434 24.4604039,15.5 C28.5381646,15.4886954 30.0101086,13.14063 29.9998418,11.1128554 C29.9998418,9.66196691 29.9998418,10.1972673 29.9998418,9.5"
							strokeLinecap="square"
							transform="translate(24.500000, 12.500000) rotate(-180.000000) translate(-24.500000, -12.500000) "
						/>
					</g>
					<line
						x1="15"
						y1="-4.32621619e-07"
						x2="15"
						y2="28.9796876"
						strokeLinecap="round"
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
