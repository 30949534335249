import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { OldTopBar } from 'src/Components/OldTopBar';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import {
	ComparisonSelected,
	useFindComparisonOptionsLazyQuery,
} from 'src/graphql/types';

import { ChooseBar } from './ChooseBar';
import { PlayerBox } from './PlayerBox';
import { PreviousTrackingBox } from './PreviousTrackingBox';
import { TeamBox } from './TeamBox';

export const CreateComparisonScreen: React.FC = () => {
	const navigation = useNavigation();
	const { selectedRole, loading: loadingSessions } = useSessions();
	const { t } = useTranslation('create-comparison');
	const [findComparisonOptionsQuery, { data, loading }] =
		useFindComparisonOptionsLazyQuery();

	const subFrom = navigation.query.get('subFrom');
	const selected = navigation.query.get('selected') as ComparisonSelected;

	useEffect(() => {
		findComparisonOptionsQuery({
			variables: {
				input: {
					role: selectedRole,
					subFrom,
					selected,
				},
			},
		});
	}, [findComparisonOptionsQuery, selectedRole, subFrom, selected]);

	return (
		<Box height={1} display="flex" flexDirection="column">
			<OldTopBar
				title={t('createComparison')}
				onClickLeft={() => navigation.goBack()}
				iconLeft={<ArrowBackIcon />}
			/>

			<ChooseBar compareFrom={data?.findComparisonOptions?.compareFrom} />

			<Box flex={1} sx={{ overflowY: 'auto' }}>
				{(loading || loadingSessions) && (
					<>
						<Skeleton variant="rectangular" height={72} />

						<Box pt="1px" />
						<Skeleton variant="rectangular" height={72} />
						<Divider />
					</>
				)}

				{!loading &&
					!loadingSessions &&
					data?.findComparisonOptions?.team && (
						<>
							<Box py={1} px="20px">
								<Typography
									variant="button"
									color="textSecondary"
								>
									{t('team')}
								</Typography>
							</Box>

							<Divider />

							<TeamBox
								club={data?.findComparisonOptions?.team}
								compareFrom={
									data?.findComparisonOptions?.compareFrom
								}
							/>

							<Divider />
						</>
					)}

				{!loading &&
					!loadingSessions &&
					data?.findComparisonOptions?.players && (
						<>
							<Box py={1} px="20px">
								<Typography
									variant="button"
									color="textSecondary"
								>
									{t('players')}
								</Typography>
							</Box>

							<Divider />

							{data.findComparisonOptions.players.map(
								(player, index) => (
									<React.Fragment key={index}>
										<PlayerBox
											player={player}
											compareFrom={
												data?.findComparisonOptions
													?.compareFrom
											}
										/>
										<Divider />
									</React.Fragment>
								),
							)}
						</>
					)}

				{!loading &&
					!loadingSessions &&
					!!data?.findComparisonOptions?.previous?.length && (
						<>
							<Box py={1} px="20px">
								<Typography
									variant="button"
									color="textSecondary"
								>
									{t('previousTrackings')}
								</Typography>
							</Box>

							<Divider />

							{data?.findComparisonOptions?.previous?.map(
								(session) => (
									<React.Fragment
										key={session?.sessionNumber}
									>
										<PreviousTrackingBox
											compareFrom={
												data?.findComparisonOptions
													?.compareFrom
											}
											session={session}
											role={selectedRole}
										/>

										<Divider />
									</React.Fragment>
								),
							)}
						</>
					)}
				<Box pt={5} />
			</Box>
		</Box>
	);
};
