import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { SafeAreaBottom, SafeAreaTop } from 'src/Components/SafeArea';
import { TopBar } from 'src/Components/TopBar';
import { MagicFormProvider } from 'src/Form/MagicFormProvider';
import { usePreventReload } from 'src/Form/usePreventReload';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { UserRole } from 'src/graphql/types';

export type SignUpFormValues = {
	email: string;
	password: string;
	birthdate: {
		day: string;
		month: string;
		year: string;
	};
	newsletterSubscription: boolean;
	legalGuardianEmail: string;
	role: UserRole;
	firstName: string;
	lastName: string;
};

const initialValues = {
	email: '',
	password: '',
	birthdate: {
		day: '',
		month: '',
		year: '',
	},
	legalGuardianEmail: '',
	role: UserRole.Player,
	firstName: '',
	lastName: '',
	newsletterSubscription: false,
};

export const SignUpScreen = () => {
	const navigation = useNavigation();
	const { t } = useTranslation('sign-up-screen');

	usePreventReload();

	return (
		<Stack
			height={1}
			sx={{
				backgroundImage: `url(${Background1})`,
				backgroundSize: 'cover',
				overflowY: 'auto',
			}}
		>
			<SafeAreaTop />

			<TopBar
				title={t('topbar_title')}
				leftComponent={
					<IconButton
						color="inherit"
						size="large"
						onClick={() => {
							navigation.goBackOrGoTo(AppRoutes.Start);
						}}
					>
						<ArrowBackIcon />
					</IconButton>
				}
			/>

			<MagicFormProvider
				initialRoute={AppRoutes.UserInfoForm}
				initialValues={initialValues}
			>
				<Outlet />
			</MagicFormProvider>

			<SafeAreaBottom />
		</Stack>
	);
};
