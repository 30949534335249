import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { useNavigation } from 'src/Router/useNavigation';

import { chartTabs } from '../StatsOverTime/chartTabs';

export type SubScoreTitleProps = {
	loading: boolean;
	selected?: boolean;
	title: string;
	value?: number | null;
	onClose: () => void;
};

export const SubScoreTitle: React.FC<SubScoreTitleProps> = ({
	loading,
	selected,
	value,
	title,
	onClose,
}) => {
	const { setQuery } = useNavigation();
	const { t } = useTranslation('performance-stats');

	const titleValue =
		chartTabs.find((chartTab) => chartTab.subScore === title)?.label ||
		null;

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			p={2}
			position="relative"
			border="1px solid #252525"
			borderRadius="2px"
			onClick={() =>
				!selected ? setQuery('subScore', titleValue) : onClose()
			}
			sx={{ cursor: 'pointer' }}
		>
			<Typography
				variant="body1"
				color={value ? 'textPrimary' : 'textSecondary'}
				zIndex={1}
				fontWeight={500}
			>
				{titleValue}
			</Typography>

			<Box display="flex" alignItems="center" height={38}>
				{loading && (
					<Typography variant="h6" zIndex={1}>
						<Skeleton width={40} />
					</Typography>
				)}

				{!loading && !value && (
					<Typography variant="caption" color="textSecondary">
						{t('noData')}
					</Typography>
				)}

				{!loading && value && !selected && (
					<Typography variant="h6" zIndex={1}>
						{value || 0}
					</Typography>
				)}
			</Box>

			{/* Gradient Background */}
			{value && selected && (
				<Box
					position="absolute"
					height={1}
					left={0}
					width={(value || 0) + '%'}
					display="flex"
					alignItems="center"
					overflow="hidden"
					pl="16px"
					sx={{
						backgroundImage:
							'linear-gradient(180deg, #46FF44, #0BFF8A)',
					}}
				>
					<Typography
						variant="body1"
						color="secondary"
						zIndex={1}
						fontWeight={500}
					>
						{titleValue}
					</Typography>
				</Box>
			)}

			{/* Green line */}
			{value && !selected && (
				<Box
					position="absolute"
					height={1}
					left={0}
					width={(value || 0) + '%'}
					bgcolor="#151515"
					borderRight="1px solid #28FF67"
				/>
			)}
		</Box>
	);
};
