import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const WorkoutIcon: React.FC<CustomIconProps> = (props) => {
	const { color } = useCustomIcon(props);

	return (
		<SvgIcon viewBox="0 0 32 32" sx={props.sx}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path
					d="M31.878471,-7.84663053 C33.4646948,-7.83975051 38.6743801,-6.80699645 38.6622173,0.00672160948"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				/>
				<g
					transform="translate(0.500000, 2.000000)"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				>
					<path d="M1.52196006,4.59032794 C0.111960059,6.84632794 -0.668039941,10.1143279 0.752960059,11.0033279 C0.826960059,11.0983279 16.5859601,21.2173279 27.1149601,27.7973279 C29.4719601,28.5103279 31.8739601,25.7593279 30.0439601,23.1103279 C25.2699601,15.6793279 18.1149601,6.01632794 17.2029601,4.94932794 C16.0889601,4.25432794 12.0469601,7.28332794 9.68996006,5.81132794 C5.41696006,3.53832794 6.76296006,0.323327936 6.27096006,0.0153279364 C5.89096006,-0.221672064 2.93196006,2.33432794 1.52196006,4.59032794 Z" />
					<line
						x1="24.2831601"
						y1="14.9717279"
						x2="19.3951601"
						y2="16.0997279"
						strokeLinecap="square"
					/>
					<line
						x1="21.1435601"
						y1="10.5771279"
						x2="16.2555601"
						y2="11.7051279"
						strokeLinecap="square"
					/>
					<line
						x1="3.17130804"
						y1="16.0212148"
						x2="4.58947595"
						y2="13.9558148"
						strokeLinecap="square"
					/>
					<line
						x1="9.25479403"
						y1="19.9025646"
						x2="10.664277"
						y2="17.7949214"
						strokeLinecap="square"
					/>
					<line
						x1="15.5"
						y1="23.7763577"
						x2="16.9691084"
						y2="21.5003443"
						strokeLinecap="square"
					/>
					<line
						x1="21.5803663"
						y1="27.5245587"
						x2="23.006528"
						y2="25.3994279"
						strokeLinecap="square"
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
