import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const TeamIcon: React.FC<CustomIconProps> = (props) => {
	const { color } = useCustomIcon(props);

	return (
		<SvgIcon viewBox="0 0 32 32" sx={props.sx}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					transform="translate(2.000000, 3.014724)"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				>
					<path d="M22.2334811,4.48527527 C23.2075174,4.48333722 23.8904755,4.80850089 24.3389995,5.33335839 C24.9269211,6.02133728 25.1222482,7.01078694 25.1222711,7.94715022 C25.1128536,8.93103911 24.9241883,9.90924486 24.3483267,10.5831137 C23.9015554,11.105922 23.2162761,11.4186096 22.2324862,11.4186096 C21.2536585,11.4186096 20.559302,11.0980499 20.1026355,10.5649993 C19.5272631,9.89338733 19.3222482,8.92358845 19.3222482,7.93795915 C19.3222482,7.00867848 19.5358028,6.03027655 20.1263827,5.34575686 C20.5824601,4.81713413 21.2686712,4.48527626 22.2334811,4.48527527 Z" />
					<path
						d="M16.3408917,13.6104141 C16.3408917,13.9057861 16.3408917,14.5090986 16.3408917,15.4203516 C16.3783256,17.7321071 18.00972,20.4340716 22.5293495,20.4206868 C25.9710046,20.4110915 27.7747973,18.9244049 28.4666667,17.2114073"
						strokeLinecap="square"
						transform="translate(22.403779, 17.015550) rotate(-180.000000) translate(-22.403779, -17.015550) "
					/>
					<path d="M9.89964945,0.49999901 C11.5550451,0.496705242 12.707777,1.06052108 13.4678889,1.94999508 C14.4445471,3.09286899 14.7823942,4.73327739 14.7824171,6.29520312 C14.7667501,7.93200403 14.4384665,9.55156712 13.4827029,10.6699944 C12.7259116,11.5555859 11.5705971,12.1 9.89865459,12.1 C8.23655428,12.1 7.06577607,11.5440123 6.29259128,10.641501 C5.33685453,9.52590323 4.9823942,7.91855995 4.9823942,6.27779046 C4.9823942,4.73011194 5.34922308,3.10654716 6.3309758,1.96863329 C7.10355781,1.07316157 8.26201865,0.5 9.89964945,0.49999901 Z" />
					<path
						d="M5.32907052e-15,15.3320043 C5.32907052e-15,15.8011246 5.32907052e-15,16.7593268 5.32907052e-15,18.206611 C0.0594537117,21.8782227 2.65049187,26.1695781 9.82872705,26.1483198 C17.1686963,26.1278559 19.8181955,21.8773347 19.7997152,18.206611 C19.7997152,15.5801798 19.7997152,16.5491928 19.7997152,15.2869837"
						strokeLinecap="square"
						transform="translate(9.900000, 20.717652) rotate(-180.000000) translate(-9.900000, -20.717652) "
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
