import Badge, { badgeClasses } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

export const NumberBadge = styled(Badge)(() => ({
	[`& .${badgeClasses.badge}`]: {
		height: 16,
		minWidth: 16,
		padding: '0 4px',
		zIndex: 0,
	},
}));

NumberBadge.defaultProps = {
	color: 'primary',
	overlap: 'circular',
};
