import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const ArrowLeftIcon: React.FC<CustomIconProps> = (props) => {
	const { color } = useCustomIcon(props);

	return (
		<SvgIcon viewBox="0 0 24 24" sx={props.sx}>
			<g transform="translate(14.854 19.425) rotate(-135)">
				<path
					d="M.075,0V9.107"
					transform="translate(10.093 0.064)"
					fill="none"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
					strokeLinecap="square"
					strokeMiterlimit="10"
					strokeWidth="1"
				/>
				<path
					d="M8.8.076H0"
					transform="translate(0.864 0)"
					fill="none"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
					strokeLinecap="square"
					strokeMiterlimit="10"
					strokeWidth="1"
				/>
			</g>
		</SvgIcon>
	);
};
