import React, { Suspense, SuspenseProps, lazy } from 'react';

import { MatchCardProps } from './MatchCard';

const LazyHomePage = lazy(() => import('./MatchCard'));

const MatchCard: React.FC<MatchCardProps & SuspenseProps> = ({
	fallback = null,
	...props
}) => (
	<Suspense fallback={fallback}>
		<LazyHomePage {...props} />
	</Suspense>
);

export default MatchCard;
