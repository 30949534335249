import { Trans, useTranslation } from 'react-i18next';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { AppRoutes } from 'src/Router/AppRoutes';
import { LocaleLink } from 'src/Router/LocaleLink';
import { NotificationType, SystemNotification } from 'src/graphql/types';

export type ActionRequiredProps = {
	notifications?: SystemNotification[];
	open: boolean;
	setOpen: (open: boolean) => void;
};

export const ActionRequired: React.FC<ActionRequiredProps> = ({
	notifications,
	open,
	setOpen,
}) => {
	const { t } = useTranslation('action-required');

	const validateEmail = notifications?.some(
		(notification) => notification.type === NotificationType.EmailRequired,
	);

	const validateLegalGuardian = notifications?.some(
		(notification) =>
			notification.type === NotificationType.LegalGuardianRequired,
	);

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			TransitionComponent={Fade}
			fullWidth
			maxWidth="xs"
		>
			<Box bgcolor="white" color="black" pt={4} px={2}>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
				>
					<Avatar sx={{ bgcolor: 'error.main', color: 'white' }}>
						!
					</Avatar>

					<Box pt={4} />

					<Typography variant="body1" textAlign="center">
						{t('title')}
					</Typography>

					<Box pt={4} />

					{validateEmail && (
						<Typography
							variant="body1"
							color="text.secondary"
							textAlign="center"
						>
							{t('email')}
						</Typography>
					)}

					{validateEmail && validateLegalGuardian && <Box pt={1} />}

					{validateLegalGuardian && (
						<Typography
							variant="body1"
							color="text.secondary"
							textAlign="center"
						>
							{t('legalGuardian')}
						</Typography>
					)}

					<Box pt={3} />

					<Box display="flex" justifyContent="center" py={1}>
						<Typography
							variant="caption"
							color="text.secondary"
							textAlign="center"
						>
							<Trans
								i18nKey="checkSpam"
								ns="action-required"
								components={[
									<Link
										key="link"
										color="textSecondary"
										variant="caption"
										component={LocaleLink}
										to={AppRoutes.Notifications}
										onClick={() => setOpen(false)}
									/>,
								]}
							/>
						</Typography>
					</Box>
				</Box>

				<Box height="1px" bgcolor="#E5E5E5" />

				<Button
					sx={{ p: 3 }}
					size="medium"
					color="inherit"
					fullWidth
					onClick={() => setOpen(false)}
				>
					OK
				</Button>
			</Box>
		</Dialog>
	);
};
