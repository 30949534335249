import { useContext } from 'react';

import { SuccessContext } from './SuccessProvider';

export const useShowSuccess = () => {
	const context = useContext(SuccessContext);

	if (!context)
		throw new Error('SuccessContext must be used with SuccessProvider!');

	return context;
};
