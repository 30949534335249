const TOKEN_KEY = 'accessToken';

export function getLocalStorageAccessToken() {
	return localStorage.getItem(TOKEN_KEY);
}

export function setLocalStorageAccessToken(token: string) {
	return localStorage.setItem(TOKEN_KEY, token);
}

export function removeLocalStorageAccessToken() {
	return localStorage.removeItem(TOKEN_KEY);
}
