import { createContext } from 'react';

export const FormContext = createContext<
	| {
			form: any;
			setForm: (form: any) => void;
			resetForm: () => void;
	  }
	| undefined
>(undefined);
