import gql from 'graphql-tag';

const CHECK_EMAIL_EXISTS_QUERY = gql`
	query checkEmailExist($input: CheckEmailExistInput!) {
		checkEmailExist(input: $input) {
			email
			exist
		}
	}
`;

export default CHECK_EMAIL_EXISTS_QUERY;
