import gql from 'graphql-tag';

const CHECK_USERNAME_EXISTS_QUERY = gql`
	query checkUserNameExist($input: CheckUsernameExistInput!) {
		checkUserNameExist(input: $input) {
			username
			exist
		}
	}
`;

export default CHECK_USERNAME_EXISTS_QUERY;
