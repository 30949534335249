import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { OldTopBar } from 'src/Components/OldTopBar';
import { useCustomForm } from 'src/Form/useCustomForm';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useQRScanner } from 'src/Screens/AddSession/ScanCode/useQRScanner';
import { convertValidCode } from 'src/helpers/convertValidCode';

import { CreateSessionFormData } from '../AdminForm';

export const ScanPlayerCodeScreen = () => {
	const { t } = useTranslation('add-player');
	const navigation = useNavigation();
	const { form } = useCustomForm<CreateSessionFormData>();

	const QRScannerComponents = useQRScanner((code) => {
		const validCode = convertValidCode(code);

		navigation.replace(`${AppRoutes.AddPlayer}?code=${validCode}`);
	});

	useEffect(() => {
		if (!form.ageGroup) {
			navigation.push(
				`${
					AppRoutes.EditSession + form.sessionNumber
						? `?sessionNumber=${form.sessionNumber}`
						: ''
				}`,
			);
		}
	}, [form.ageGroup, form.sessionNumber, navigation]);

	return (
		<Box display="flex" width={1} height={1}>
			<QRScannerComponents />

			<OldTopBar
				title={t('scanCode')}
				iconRight={<CloseIcon />}
				onClickRight={() => navigation.replace(AppRoutes.AddPlayer)}
			/>

			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				width={1}
				flexDirection="column"
				bgcolor="none"
				overflow="hidden"
			>
				<Box
					border="1px solid"
					borderColor="primary.main"
					width={280}
					height={280}
					sx={{ outline: '10000px solid rgba(0,0,0, 0.9)' }}
				></Box>
				<Box width={280} padding={2} textAlign="center">
					<Typography variant="body2">{t('hint')}</Typography>
				</Box>
			</Box>
		</Box>
	);
};
