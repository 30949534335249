import { useContext } from 'react';

import { EventContext } from './EventContext';

export const useEvent = () => {
	const context = useContext(EventContext);

	if (!context)
		throw new Error('EventContext must be used with EventProvider!');

	return context;
};
