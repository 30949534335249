import {
	Dispatch,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { useNavigation } from 'src/Router/useNavigation';

interface MagicFormContext<T = any> {
	formValues: T;
	initialValues: T;
	initialRoute: string;
	setFormValues: Dispatch<SetStateAction<T>>;
}

export const magicFormContext = createContext<
	MagicFormContext<any> | undefined
>(undefined);

export type MagicFormProviderProps<T> = {
	initialValues: T;
	initialRoute: string;
	children: React.ReactNode;
};

export const useMagicForm = <T,>() => {
	const context = useContext(magicFormContext);

	if (!context)
		throw new Error(
			'MagicFormContext must be used with MagicFormProvider!',
		);

	return context as MagicFormContext<T>;
};

export const MagicFormProvider = <T,>(props: MagicFormProviderProps<T>) => {
	const { initialValues, initialRoute } = props;
	const [formValues, _setFormValues] = useState<T>(initialValues);
	const location = useLocation();
	const navigation = useNavigation();

	const setFormValues = (newFormValues: T) => {
		_setFormValues({ ...formValues, ...newFormValues });
	};

	useEffect(() => {
		const equal = formValues === initialValues;

		if (equal && !location.pathname.includes(initialRoute)) {
			navigation.replace(initialRoute);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const value = {
		formValues,
		setFormValues,
		initialValues,
		initialRoute,
	};

	return (
		<magicFormContext.Provider value={value}>
			{props.children}
		</magicFormContext.Provider>
	);
};
