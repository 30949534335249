import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { useAuth } from 'src/Auth/useAuth';
import { UserIcon } from 'src/Components/Icons/UserIcon';
import { SlideUp } from 'src/Components/Transitions/Slide';
import { useNotifications } from 'src/Notifications/useNotifications';
import {
	useUpdateUserAvatarMutation,
	useUpdateUserBackgroundMutation,
} from 'src/graphql/types';

export type ImagesDialogProps = {
	open: boolean;
	onClose: () => void;
};

export const ImagesDialog: React.FC<ImagesDialogProps> = ({
	open,
	onClose,
}) => {
	const { t } = useTranslation('images-dialog');
	const profileRef = useRef<HTMLInputElement>(null);
	const backgroundRef = useRef<HTMLInputElement>(null);
	const { user, setUser } = useAuth();
	const [userAvatarMutation] = useUpdateUserAvatarMutation();
	const [userBackgroundMutation] = useUpdateUserBackgroundMutation();
	const [loadingProfile, setLoadingProfile] = useState(false);
	const [loadingBackground, setLoadingBackground] = useState(false);
	const { refetch } = useNotifications();

	const onChangeProfile = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		if (!event?.target?.files?.[0]) return;

		setLoadingProfile(true);

		const { data } = await userAvatarMutation({
			variables: {
				file: event.target.files[0],
			},
		}).catch(convertNetworkErrors);

		if (data?.updateUserAvatar?.user) {
			setUser(data.updateUserAvatar.user);
		}

		refetch();
		setLoadingProfile(false);
	};

	const onChangeBackground = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		if (!event?.target?.files?.[0]) return;

		setLoadingBackground(true);

		const { data } = await userBackgroundMutation({
			variables: {
				file: event.target.files[0],
			},
		}).catch(convertNetworkErrors);

		if (data?.updateUserBackground?.user) {
			setUser(data.updateUserBackground.user);
		}

		refetch();
		setLoadingBackground(false);
	};

	return (
		<Dialog
			open={open}
			onClose={() => onClose()}
			TransitionComponent={SlideUp}
		>
			<DialogContent
				sx={{
					background: 'white',
					color: 'black',
					padding: '20px',
					pb: 0,
				}}
			>
				<Box display="flex" alignItems="center" flexDirection="column">
					<Box pt={1} />

					<Avatar
						sx={{
							width: 48,
							height: 48,
							backgroundImage:
								'linear-gradient(180deg, #46FF44, #0BFF8A)',
						}}
					>
						<UserIcon color="black" sx={{ fontSize: 28 }} />
					</Avatar>
					<Box pt={3} />
					<Typography variant="body1" color="initial" align="center">
						{t('description')}
					</Typography>

					<Box pt={4} />
				</Box>

				<Box mx="-20px" height="1px" bgcolor="#E5E5E5" />

				<Box
					height={72}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography variant="body1" color="initial">
						{t('profile')}
					</Typography>
					<Box
						sx={{ cursor: 'pointer' }}
						onClick={() => {
							if (user && profileRef.current) {
								profileRef.current.value = '';
								profileRef.current.click();
							}
						}}
					>
						{loadingProfile ? (
							<CircularProgress color="inherit" />
						) : user?.avatarUrl ? (
							<Box
								component="img"
								src={user.avatarUrl}
								width={36}
								height={36}
								alt=""
								sx={{ objectFit: 'cover' }}
							/>
						) : (
							<Typography variant="body1" color="textSecondary">
								{t('chooseImage')}
							</Typography>
						)}
					</Box>
					<Box
						component="input"
						display="none"
						ref={profileRef}
						type="file"
						accept="image/jpeg, image/png"
						onChange={onChangeProfile}
					/>
				</Box>

				<Box mx="-20px" height="1px" bgcolor="#E5E5E5" />

				<Box
					height={72}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography variant="body1" color="initial">
						{t('background')}
					</Typography>

					<Box
						sx={{ cursor: 'pointer' }}
						onClick={() => {
							if (user && backgroundRef.current) {
								backgroundRef.current.value = '';
								backgroundRef.current.click();
							}
						}}
					>
						{loadingBackground ? (
							<CircularProgress color="inherit" />
						) : user?.backgroundUrl ? (
							<Box
								component="img"
								src={user.backgroundUrl}
								width={36}
								height={36}
								alt=""
								sx={{ objectFit: 'cover' }}
							/>
						) : (
							<Typography variant="body1" color="textSecondary">
								{t('chooseImage')}
							</Typography>
						)}
					</Box>

					<Box
						component="input"
						display="none"
						ref={backgroundRef}
						type="file"
						accept="image/jpeg, image/png"
						onChange={onChangeBackground}
					/>
				</Box>
			</DialogContent>
		</Dialog>
	);
};
