import { GraphQLError } from 'graphql';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { convertNetworkErrors } from 'src/Apollo/convertNetworkErrors';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';
import { useSessions } from 'src/Sessions/useSessions';
import { useShowSuccess } from 'src/Success/useShowSuccess';
import { UserRole, useAddCodeMutation } from 'src/graphql/types';

function loadPaypalSdk(currency = 'EUR') {
	const script = document.createElement('script');
	script.src = `https://www.paypal.com/sdk/js?currency=${currency}&disable-funding=card,sofort&client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}`;
	document.body.appendChild(script);
}

let timeout: NodeJS.Timeout;

export const CheckCodeScreen = () => {
	const { code } = useParams<{ code: string }>();
	const { t } = useTranslation(['add-session', 'server-error']);
	const navigation = useNavigation();
	const [addCodeMutation] = useAddCodeMutation();
	const showSuccess = useShowSuccess();
	const submitRef = useRef<boolean>();
	const { setSelectedRole, refetch } = useSessions();

	const [loading, setLoading] = useState<boolean>(true);
	const [serverErrors, setServerErrors] = useState<readonly GraphQLError[]>(
		[],
	);

	const onSubmit = useCallback(async () => {
		const { errors, data } = await addCodeMutation({
			variables: {
				input: {
					code: code as string,
				},
			},
		}).catch(convertNetworkErrors);

		setLoading(false);

		if (errors) {
			setServerErrors(errors);
			timeout = setTimeout(() => {
				navigation.goBackOrGoTo(AppRoutes.AddSession);
			}, 3000);
		}

		if (!errors && data?.addCode) {
			if (data.addCode.__typename === 'Score') {
				if (data.addCode.payment?.amount) {
					loadPaypalSdk(data.addCode.payment?.currency?.toString());

					showSuccess({
						title: t('successfulQR'),
						nextRoute: `${AppRoutes.Payment}/${code}`,
						nextRouteState: {
							session: data.addCode,
						},
						duration: 3000,
					});
				} else {
					setSelectedRole(UserRole.Player);
					refetch();

					showSuccess({
						title: t('successfulPaid'),
						nextRoute: `${AppRoutes.ShareWithCoach}/${code}`,
						nextRouteState: {
							session: data.addCode,
						},
						duration: 3000,
					});
				}
			} else {
				setSelectedRole(UserRole.Trainer);
				refetch();

				showSuccess({
					title: t('successfulQR'),
					nextRoute: AppRoutes.Scoring,
					duration: 3000,
				});
			}
		}
	}, [
		addCodeMutation,
		setSelectedRole,
		code,
		navigation,
		showSuccess,
		refetch,
		t,
	]);

	useEffect(() => {
		if (!submitRef.current) {
			onSubmit();
			submitRef.current = true;
		}
	}, [onSubmit, submitRef]);

	useEffect(() => {
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, []);

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			height={1}
			flexDirection="column"
		>
			{loading ? (
				<CircularProgress />
			) : (
				serverErrors.map((error) => (
					<Alert key={error.message} severity="error">
						{t('server-error:' + error.message) || error.message}
					</Alert>
				))
			)}
		</Box>
	);
};
