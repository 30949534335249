import React from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';

import { NotificationsProvider } from 'src/Notifications/NotificationsProvider';
import { TabsDashboard } from 'src/Router/AppRoutes';

import { BottomTabs } from './BottomTabs';

type DashboardLayoutProps = {
	tab: typeof TabsDashboard[number];
};

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({ tab }) => {
	return (
		<NotificationsProvider>
			<Box
				height={1}
				display="flex"
				justifyContent="space-between"
				flexDirection="column"
			>
				<Box
					display="flex"
					flexDirection="column"
					overflow="hidden"
					height={1}
				>
					<Outlet />
				</Box>

				<BottomTabs tab={tab} />
			</Box>
		</NotificationsProvider>
	);
};
