import { yupResolver } from '@hookform/resolvers/yup';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useEventValidations = (eventNumber: number) => {
	const { t } = useTranslation('validations');

	const validationSchema = useMemo(
		() =>
			yup.object({
				name: yup.string().required(t('required')),
				start: yup
					.object()
					.nullable()
					.test('required', t('required'), (value) => {
						const date = value as never as Moment;
						return (
							date?.isValid() &&
							(!date.isBefore(moment().startOf('minute')) ||
								eventNumber !== 0)
						);
					}),
				end: yup
					.object()
					.nullable()
					.test('required', t('required'), (value) => {
						const date = value as never as Moment;
						return (
							date?.isValid() &&
							(!date.isBefore(moment().startOf('minute')) ||
								eventNumber !== 0)
						);
					}),
				club: yup
					.object()
					.nullable()
					.test('required', t('required'), (value) => {
						return !!value?.id;
					}),
			}),
		[eventNumber, t],
	);

	return yupResolver(validationSchema);
};
