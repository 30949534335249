import moment, { Moment } from 'moment';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { SeparatedDate } from 'src/graphql/types';

export type SeparatedDateFormProps = {
	id: string;
	name: string;
	disabled?: boolean;
	autoComplete?: string;
	label?: string;
	nextRef?: React.RefObject<HTMLInputElement | HTMLButtonElement>;
	onChange?: (value: SeparatedDate | null) => void;
};

function separatedDateToMomentDate(separatedDate: SeparatedDate) {
	if (
		isNaN(+separatedDate.day) ||
		isNaN(+separatedDate.month) ||
		isNaN(+separatedDate.year)
	)
		return null;

	if (!separatedDate.day || !separatedDate.month || !separatedDate.year)
		return null;

	return moment(
		`${separatedDate.year}-${separatedDate.month}-${separatedDate.day}`,
	);
}

export const SeparatedDateForm = forwardRef<
	HTMLInputElement,
	SeparatedDateFormProps
>(({ id, name, label, autoComplete, nextRef, onChange, disabled }, ref) => {
	const { control, formState, setValue, getValues } = useFormContext();
	const { t } = useTranslation(['validations', 'translation']);
	const [open, setOpen] = useState(false);

	const monthRef = useRef<HTMLInputElement>(null);
	const yearRef = useRef<HTMLInputElement>(null);

	const [date, setDate] = useState<Moment | null>(
		separatedDateToMomentDate(getValues(name)),
	);

	const [day, setDay] = useState<Moment | null>(date);
	const [month, setMonth] = useState<Moment | null>(date);
	const [year, setYear] = useState<Moment | null>(date);

	const yearError = formState.errors?.[name]?.year?.message;
	const monthError = formState.errors?.[name]?.month?.message;
	const dayError = formState.errors?.[name]?.day?.message;

	const dateError = formState.errors?.[name]?.message;

	const error = yearError || monthError || dayError || dateError;

	useEffect(() => {
		if (day && month && year) {
			const dd = day.format('DD');
			const mm = month.format('MM');
			const yyyy = year.format('YYYY');

			const newDate = moment();

			newDate.date(day.date());
			newDate.month(month.month());
			newDate.year(year.year());

			const value = {
				day: dd,
				month: mm,
				year: yyyy,
			};

			setValue(name, value);

			if (day.isValid() && month.isValid() && year.isValid()) {
				setDate(newDate);
				onChange?.(value);
			}
		}
	}, [day, month, year, name, setValue, setDate, onChange]);

	return (
		<Stack alignItems="center">
			{!!label && (
				<>
					<Typography
						variant="body2"
						color={error ? 'error' : 'textSecondary'}
					>
						{label}
					</Typography>

					<Box pt={1.5} />
				</>
			)}

			<Controller
				control={control}
				name={name}
				render={({ field, fieldState: { error } }) => (
					<Stack direction="row" spacing={2}>
						<DesktopDatePicker
							{...field}
							inputRef={ref}
							label={t('translation:day')}
							mask="__"
							inputFormat="DD"
							value={day}
							closeOnSelect
							disableOpenPicker
							open={false}
							onChange={(newValue, newInput) => {
								setDay(newValue);

								if (newInput?.length === 2) {
									monthRef.current?.focus();
								}
							}}
							disabled={disabled}
							renderInput={(params) => (
								<TextField
									{...params}
									id={`${id}-day`}
									autoComplete={autoComplete}
									onBlur={(event) => {
										const value = parseInt(
											event.target.value,
										);

										if (value < 10) {
											const newValue = moment();
											newValue.date(value || 1);
											setDay(newValue);
										}
									}}
									error={!!error || !!formState.errors[name]}
									inputProps={{
										...params.inputProps,
										enterKeyHint: 'next',
										onKeyDown: (event) => {
											if (event.key === 'Enter') {
												event.preventDefault();
												monthRef.current?.focus();
											}
										},
									}}
								/>
							)}
						/>

						<DesktopDatePicker
							{...field}
							inputRef={monthRef}
							label={t('translation:month')}
							mask="__"
							inputFormat="MM"
							value={month}
							closeOnSelect
							disableOpenPicker
							onChange={(newValue, newInput) => {
								setMonth(newValue);

								if (newInput?.length === 2) {
									yearRef.current?.focus();
								}
							}}
							disabled={disabled}
							renderInput={(params) => (
								<TextField
									{...params}
									id={`${id}-month`}
									autoComplete={autoComplete}
									error={!!error || !!formState.errors[name]}
									onBlur={(event) => {
										const value = parseInt(
											event.target.value,
										);

										if (value < 10) {
											const newValue =
												date?.clone() || moment();

											newValue.month(
												value <= 0 ? 0 : value - 1,
											);

											setMonth(newValue);
										}
									}}
									inputProps={{
										...params.inputProps,
										enterKeyHint: 'next',
										onKeyDown: (event) => {
											if (event.key === 'Enter') {
												event.preventDefault();
												yearRef.current?.focus();
											}
										},
									}}
								/>
							)}
						/>

						<DesktopDatePicker
							inputRef={yearRef}
							label={t('translation:year')}
							mask="____"
							inputFormat="YYYY"
							value={year}
							closeOnSelect
							maxDate={moment()}
							disableOpenPicker
							onChange={(newValue) => {
								setYear(newValue);
							}}
							disabled={disabled}
							renderInput={(params) => (
								<TextField
									{...params}
									id={`${id}-year`}
									autoComplete={autoComplete}
									error={!!error || !!formState.errors[name]}
									inputProps={{
										...params.inputProps,
										enterKeyHint: nextRef ? 'next' : 'done',
										onKeyDown: (event) => {
											if (event.key === 'Enter') {
												event.preventDefault();
												nextRef?.current?.focus();
											}
										},
									}}
								/>
							)}
						/>
					</Stack>
				)}
			/>

			<MobileDatePicker
				views={['day', 'month', 'year']}
				onChange={setDate}
				value={date}
				open={open}
				onAccept={() => {
					setDay(date);
					setMonth(date);
					setYear(date);
				}}
				onClose={() => setOpen(false)}
				renderInput={(params) => (
					<TextField
						{...params}
						sx={{
							display: 'none',
						}}
					/>
				)}
			/>

			{error ? (
				<FormHelperText
					sx={{
						color: 'error.main',
						ml: '14px',
						mt: '3px',
						alignSelf: 'flex-start',
					}}
				>
					{t(error)}
				</FormHelperText>
			) : null}

			<Box pt={4} />

			<Button
				variant="outlined"
				color="inherit"
				endIcon={<CalendarMonthIcon />}
				onClick={() => setOpen(true)}
			>
				{t('translation:calendar_button')}
			</Button>
		</Stack>
	);
});
