import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { CustomIconProps, useCustomIcon } from './CustomIcon';

export const ShareDataIcon: React.FC<CustomIconProps> = (props) => {
	const { color, strokeWidth } = useCustomIcon(props);

	return (
		<SvgIcon viewBox="0 0 32 32" sx={props.sx}>
			<g
				stroke="none"
				strokeWidth={strokeWidth}
				fill="none"
				fillRule="evenodd"
			>
				<g
					transform="translate(2.500000, 2.546252)"
					stroke={color}
					style={{
						transition:
							'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					}}
				>
					<polyline
						strokeLinecap="square"
						points="-1.08663079e-14 13.2066943 0 27.2066943 27 27.2066943 27 13.2066943"
					/>
					<polyline
						transform="translate(13.911165, 6.263863) rotate(-43.000000) translate(-13.911165, -6.263863) "
						points="9.66805065 2 18.1542791 2 18.1542791 10.527726"
					/>
					<line
						x1="19.7902044"
						y1="3.23457014"
						x2="8.2097956"
						y2="15.6530382"
						id="Line-5"
						strokeLinecap="square"
						transform="translate(14.000000, 9.443804) rotate(-43.000000) translate(-14.000000, -9.443804) "
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
