import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import Background1 from 'src/Components/Backgrounds/Background1.svg';
import { SafeAreaBottom, SafeAreaTop } from 'src/Components/SafeArea';
import { TopBar } from 'src/Components/TopBar';
import { AppRoutes } from 'src/Router/AppRoutes';
import { useNavigation } from 'src/Router/useNavigation';

import { LoginForm } from './LoginForm';

export type LoginFormValues = {
	username: string;
	password: string;
};

export const LoginScreen = () => {
	const navigation = useNavigation();
	const { i18n } = useTranslation();
	const form = useForm<LoginFormValues>({
		resolver: yupResolver(
			yup.object({
				username: yup.string().trim().lowercase().required(),
				password: yup.string().required(),
			}),
		),
		defaultValues: {
			username: '',
			password: '',
		},
	});

	return (
		<Stack
			height={1}
			sx={{
				backgroundImage: `url(${Background1})`,
				backgroundSize: 'cover',
				overflowY: 'auto',
			}}
		>
			<SafeAreaTop />

			<TopBar
				leftComponent={
					<IconButton
						color="inherit"
						size="large"
						onClick={() => navigation.goBackOrGoTo(AppRoutes.Start)}
					>
						<ArrowBackIcon />
					</IconButton>
				}
				rigthComponent={
					<IconButton
						color="inherit"
						size="large"
						href={`/${i18n.language}${AppRoutes.Faq}`}
					>
						<QuestionMarkIcon />
					</IconButton>
				}
			/>

			<FormProvider {...form}>
				<LoginForm />
			</FormProvider>

			<SafeAreaBottom />
		</Stack>
	);
};
