import { useEffect, useState } from 'react';

export const useTopScoreAnimation = (
	maxValue: number,
	duration: number,
	loading: boolean,
) => {
	const [frameValue, setFrameValue] = useState(0);
	const [started, setStarted] = useState(false);

	useEffect(() => {
		const frame = duration / maxValue;

		let interval: NodeJS.Timeout;

		if (!loading && maxValue) {
			setStarted(true);

			interval = setInterval(() => {
				setFrameValue((prevFrameValue) => {
					if (prevFrameValue < maxValue) return prevFrameValue + 1;
					clearInterval(interval);
					return maxValue;
				});
			}, frame);
		}

		return () => {
			clearInterval(interval);
		};
	}, [loading, maxValue, duration]);

	return { started, frameValue };
};
